import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { getColor } from 'utils';

const GlobalContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const InputContainer = styled('div')`
  position: relative;
  display: inline-block;
  width: 3.15rem;
  height: 1.5rem;
  padding: 0.1875rem;

  > input {
    display: none;
  }
`;

const Input = styled('input')`
  &:checked + span {
    background-color: ${props => getColor(props, 'accent')};
  }

  &:disabled + span {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:disabled:checked + span {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:focus + span {
    box-shadow: 0 0 0.0625rem ${props => getColor(props, 'accentVariant')};
  }

  &:checked + span:before {
    transform: translateX(1.625rem);
  }
`;

const Slider = styled('span')`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => getColor(props, 'mediumGrey')};
  transition: 0.2s;
  border-radius: 2.125rem;
  width: 3.15rem;

  &:before {
    position: relative;
    border-radius: 50%;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: 0.125rem;
    background-color: ${props => getColor(props, 'white')};
    transition: 0.2s;
  }
`;

const Label = styled('div')`
  position: absolute;
  left: ${props => (props.checked ? '0.45rem' : '1.6rem')};
  color: ${props => getColor(props, 'onPrimary')};
  font-size: 0.65rem;
  top: 0.3rem;
  z-index: 1;
  user-select: none;
  cursor: pointer;
  transition: unset;
`;

const FovToggle = withTheme(props => {
  const { theme, accessibility, callback, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const handleChange = e => {
    const checked = e.target.checked;
    callback?.(checked);
  };

  return (
    <GlobalContainer>
      <InputContainer>
        {accessibility && (
          <Label
            theme={theme}
            $selectedTheme={selectedTheme}
            checked={rest.checked}
          >
              {rest.checked ? 'ON' : 'OFF'}
          </Label>
        )}

        <Input
          theme={theme}
          $selectedTheme={selectedTheme}
          type="checkbox"
          onChange={handleChange}
          {...rest}
        />

        <Slider theme={theme} $selectedTheme={selectedTheme} />
      </InputContainer>
    </GlobalContainer>
  );
});

export { FovToggle };