import { actions as materialActions } from 'store/actions/materialEditor';
import u from 'updeep';

const initialState = {
  gameManager: null,
};

const materialEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case materialActions.SET_GAMEMANAGER:
      return u({ gameManager: action.payload }, state);
    default:
      return state;
  }
};

export { materialEditorReducer };
