import React, { Fragment, useState, useMemo, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Category } from './Category';
import { paramsToObject } from 'utils/paramHelpers';
import { getCategoryIcon } from './categoryUtils';
import {
  H4,
  VerticalSpacer,
  AppstoreOutlined,
  HorizontalSpacer,
  DocNote,
  FovSkeletonLoader,
} from 'components';
import { FilterSection, FilterTitle, TitleGroup, NoSelectionMessage, ClearAll } from './panelStyles';
import { withTheme } from 'styled-components';
import { decode } from 'utils';
import _ from 'lodash';
import { ModelContext } from 'pages/Home/ModelContext';

const Categories = withTheme(props => {
  const { theme, onCreators } = props;
  const { getModels, workingData, searchParam } = useContext(ModelContext);

  const [ openedCategory, setOpenedCategory ] = useState(null);
  const [ searchParams, setSearchParams ] = useSearchParams();

  const { search } = useLocation();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const userPreferences = userDataStore.userPreferences;
  const filterPanel = userPreferences?.filterPanel;
  const allPartners = useMemo(() => {
    return workingData?.allPartners?.map(partner => partner);
  }, [ onCreators, workingData ]);
  const params = paramsToObject(decode(search));
  const totalActiveModels = useMemo(() => {
    if (!params.categories) return workingData?.filteredTotalModels;

    const categoryName = params.categories.replaceAll((' ', '+'));
    return workingData?.allPartners
      ?.reduce((prev, partner) => {
        const subCategory = partner.categories?.find(category => category.categoryName === categoryName);
        if (!subCategory) {
          return prev;
        }

        return prev + subCategory?.filterCount;
      }, 0);
  }, [ onCreators, workingData ]);

  useEffect(() => {
    if (!onCreators) {
      getModels();
    }
  }, [ onCreators ]);

  const buildSubCategories = subCategories => {
    const workingSubCategories = subCategories?.map((category, index) => {
      const { categoryName, totalCategoryModels, filterCount } = category;
      const isActive = params.categories && params.categories?.replaceAll('+', ' ') === categoryName;
      const differentCount = !_.isEqual(totalCategoryModels, filterCount);
      const isMisc = categoryName.toLowerCase() === 'misc';
      const subIcon = getCategoryIcon(isMisc ? openedCategory : categoryName, 'onPrimary', 'accent');

      return(
        <Fragment key={index}>
          <Category
            icon={!onCreators && subIcon}
            name={categoryName}
            modelCount={(isActive && differentCount) || (isActive && params.tags)
              ? filterCount
              : totalCategoryModels
            }
            total={totalCategoryModels}
            isSubCategory={true}
            isOpen={openedCategory === categoryName}
            isActive={isActive}
            callback={() => {
              delete params.tags;

              if (categoryName) {
                setSearchParams({ ...params, [searchParam]: categoryName, page: 1 });
                getModels();
              }
            }}
          />
        </Fragment>
      );
    });

    return workingSubCategories;
  };

  const buildCategories = () => {
    const workingCategories = allPartners?.map((item, index) => {
      const { name, totalModels, categories: subCategories } = item;
      const isSelected = name && params.creators?.includes(name?.replaceAll('+', ' '));
      const isActiveCategory = () => {
        if (!params[searchParam]) false;

        if (subCategories.length) {
          return !!subCategories.find(category => params[searchParam] === category.categoryName.replaceAll('+', ' '));
        } else {
          return params[searchParam] === name;
        }
      };
      const isActive = isActiveCategory();

      if (isSelected || !onCreators) {
        return (
          <Fragment key={index}>
            <Category
              icon={!onCreators && getCategoryIcon(name, 'onPrimary', 'accent')}
              name={name.replaceAll('_', ' ')}
              modelCount={totalModels}
              total={totalModels}
              subCategories={!_.isEmpty(subCategories)}
              isOpen={openedCategory === name}
              isActive={isActive}
              callback={() => {
                if (_.isEmpty(subCategories)) {
                  delete params.tags;
                }

                if (!_.isEmpty(subCategories)) {
                  setOpenedCategory(openedCategory === name ? null : name);
                  return;
                }

                if (name) {
                  setSearchParams({ ...params, [searchParam]: name, page: 1 });
                  getModels();
                }
              }}
            />

            {openedCategory === name && buildSubCategories(subCategories)}
          </Fragment>
        );
      }
    }).filter(item => item);

    return !_.isEmpty(workingCategories) ? workingCategories : (
      <NoSelectionMessage theme={theme} $selectedTheme={selectedTheme}>
        <p>No categories found.</p>
      </NoSelectionMessage>
    );
  };

  return (
    <FilterSection collapsed={filterPanel}>
      <FilterTitle>
        <TitleGroup>
          <AppstoreOutlined isTitleIcon={true} $selectedTheme={selectedTheme} />
          <HorizontalSpacer size={8} />
          <H4>Categories</H4>

          <DocNote path={[ 'filterMyFiles', 'categories' ]} top={-1} left={150} />
        </TitleGroup>

        {params.categories && (
          <ClearAll
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={() => {
              searchParams.delete(searchParam);
              searchParams.delete('tags');
              searchParams.set('page', 1);

              setSearchParams(searchParams);
              getModels();
            }}
          >
            Clear all
          </ClearAll>
        )}
      </FilterTitle>

      <VerticalSpacer size={18} />

      {((allPartners && params.creators) || !onCreators) && (
        <Category
          icon={null}
          name='All'
          modelCount={totalActiveModels}
          total={workingData?.totalModels}
          subCategories={false}
          callback={() => {
            searchParams.delete(searchParam);
            searchParams.delete('tags');
            searchParams.set('page', 1);

            setSearchParams(searchParams);
            getModels();
          }}
        />
      )}

      <FovSkeletonLoader type='categories' resource={workingData}>
        {buildCategories()}
      </FovSkeletonLoader>

      <VerticalSpacer size={42} />
    </FilterSection>
  );
});

export { Categories };