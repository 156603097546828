import React from 'react';
import styled from 'styled-components';

const TextContainer = styled('div')`
  text-align: center;
`;

const NoAccessText = () => {
  return (
    <TextContainer>
      <p>
        Your token may not have been returned, or your
        account may need higher privileges before we can show
        this content.
      </p>

      <p>
        Try clicking the FOVEA logo to try again,<br />
        or reauthenticate from the Profile menu to get a new token.
      </p>
    </TextContainer>
  );
};

export default NoAccessText;