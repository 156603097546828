import { actions as rpcActions } from 'store/actions/rpc';
import u from 'updeep';

const initialState = {
  data: [],
  isAuthorizing: false,
  modelLoaded: false,
  engineState: true,
  publishName: '',
  rpcCategories: [],
  allModelsSaveData: null,
  originalSaveData: null,
  modelSaveData: null,
};

const rpcReducer = (state = initialState, action) => {
  switch (action.type) {
    case rpcActions.SET_ENGINE_STATE:
      return u({ engineState: action.payload }, state);

    case rpcActions.SET_MODEL_LOADED:
      return u({ modelLoaded: action.payload }, state);

    case rpcActions.GET_RPC_CATEGORIES:
      return u({ rpcCategories: action.payload }, state);

    case rpcActions.SET_HIGH_INSTANCE_MESH_COUNT:
      return u({ highInstanceMeshCount: action.payload }, state);

    case rpcActions.SET_PUBLISH_NAME:
      return u({ publishName: action.payload }, state);

    case rpcActions.CLEAR_PUBLISH_NAME:
      return u({ publishName: action.payload }, state);

    case rpcActions.GET_ALL_SAVE_DATA:
      return u({ allModelsSaveData: action.payload }, state);

    case rpcActions.GET_MODEL_SAVE_DATA:
      return u({
        modelSaveData: action.payload,
        originalSaveData: action.meta.preserveOriginalData
          ? action.payload
          : state.originalSaveData,
      }, state);

    case rpcActions.SET_MODEL_SAVE_DATA:
      return u({
        modelSaveData: action.payload,
        originalSaveData: action.meta?.updateOriginalData
          ? action.payload
          : state.originalSaveData,
      }, state);

    default:
      return state;
  }
};

export { rpcReducer };