import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { css, withTheme } from 'styled-components';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

const CardContainer = styled('div')`
  display: grid;
  grid-template-rows: 1fr auto;

  width: 20rem;
  height: 13rem;

  ${props => props.border && css`
    border: 0.0625rem solid ${getColor(props, 'mediumGrey')};
  `};

  border-radius: 0.25rem;
  box-shadow: 0 0.2rem 0.25rem ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'black');
  }};
  cursor: pointer;
  margin: 1rem;
`;

const Image = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${props => getColor(props, 'lightGrey')};
  border-radius: 0.25rem 0.25rem 0 0;

  ${props => props.image && css`
    background-image: url('${props.image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: -0.125rem;
  `}
`;

const Title = styled('div')`
  text-align: center;
  height: 100%;
  padding: 0.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  color: ${props => getColor(props, 'onPrimary')};
  background-color: ${props => getColor(props, 'primary')};
`;

const FovCard = withTheme(props => {
  const { theme, title, image, border, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CardContainer
      theme={theme}
      $selectedTheme={selectedTheme}
      border={border}
      {...rest}
    >
      <Image
        theme={theme}
        $selectedTheme={selectedTheme}
        image={image}
      />

      <Title theme={theme} $selectedTheme={selectedTheme}>
        <strong>
          {title || 'Title missing.'}
        </strong>
      </Title>
    </CardContainer>
  );
});


export { FovCard };