import React from 'react';
import styled, { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { VerticalSpacer } from 'components';
import { FovTypography, Bold } from 'components/typography';
import { usePagination, DOTS } from 'components/hooks/usePagination';
import { LeftOutlined, RightOutlined } from 'components/icons';

const PaginationContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background: ${props => props.colors.primary};
`;

const ButtonContainer = styled('div')`
  color: ${props =>
    props.isCurrentPage && props.colors
      ? props.colors.onPrimary
      : props.colors[
          props.selectedTheme === 'light' ? 'darkGrey' : 'lightGrey'
        ]};
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      !props.isDOTS ? props.colors?.lightGrey : ''};
  }
`;

const ArrowButton = styled('div')`
  display: flex;
  align-items: center;
  height: 2rem;
  cursor: ${props => (!props.disabled ? 'pointer' : '')};

  &:hover {
    background-color: ${props =>
      !props.disabled ? props.colors?.lightGrey : ''};
  }
`;

const FovPagination = withTheme(props => {
  const {
    theme,
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const colors = theme.colors[selectedTheme];

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1];
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (isLastPage) {
      return;
    }

    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (isFirstPage) {
      return;
    }

    onPageChange(currentPage - 1);
  };

  const generatePages = () => {
    return paginationRange.map((page, key) => {
      if (page === DOTS) {
        return (
          <ButtonContainer
            key={`page-${key}`}
            selectedTheme={selectedTheme}
            isDOTS={true}
            colors={colors}
          >
            <FovTypography>{DOTS}</FovTypography>
          </ButtonContainer>
        );
      }

      return (
        <ButtonContainer
          key={`page-${key}`}
          colors={colors}
          selectedTheme={selectedTheme}
          isCurrentPage={page === currentPage}
          onClick={() => onPageChange(page)}
        >
          {page === currentPage ? (
            <Bold>{page}</Bold>
          ) : (
            <FovTypography>{page}</FovTypography>
          )}
        </ButtonContainer>
      );
    });
  };

  return (
    <>
      <VerticalSpacer size={15} />

      <PaginationContainer colors={colors}>
        <ArrowButton disabled={isFirstPage} onClick={onPrevious} colors={colors}>
          <LeftOutlined
            color={isFirstPage ? colors.mediumGrey : colors.onPrimary}
            width={15}
            height={15}
          />
        </ArrowButton>

        {generatePages()}

        <ArrowButton disabled={isLastPage} onClick={onNext} colors={colors}>
          <RightOutlined
            color={isLastPage ? colors.mediumGrey : colors.onPrimary}
            width={15}
            height={15}
          />
        </ArrowButton>
      </PaginationContainer>
    </>
  );
});

export { FovPagination };
