import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const CouchSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const CouchOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CouchSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 20 13'
    >
      <path
        fill='currentColor'
        strokeWidth={0.25}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M19 5.5C19 5.0578 18.757 4.675 18.4 4.4668V3.6766C18.4 2.2006 17.1994 1 15.7234 1H11.4484C10.9165 1 10.4116 1.1755 10 1.4995C9.5884 1.1755 9.0835 1 8.5516 1H4.2766C2.8006 1 1.6 2.2006 1.6 3.6766V4.4668C1.243 4.675 1 5.0578 1 5.5V11.5H1.3V12.4H3.1V11.5H16.9V12.4H18.7V11.5H19V5.5ZM3.4 6.7H8.5516C9.0184 6.7 9.4576 6.8818 9.7876 7.2118L10 7.4245L10.2118 7.2124C10.5418 6.8824 10.9813 6.7003 11.4481 6.7003H16.6V7.6003H3.4V6.7ZM17.2 5.59C17.2 5.3155 17.3446 5.0674 17.5687 4.9576C17.6296 4.9276 17.689 4.9117 17.7493 4.9054C17.7622 4.9042 17.7748 4.9018 17.7883 4.9015C17.8093 4.9009 17.8306 4.9033 17.8519 4.9057C17.8657 4.9069 17.8795 4.9078 17.8933 4.9099C17.9278 4.9162 17.9629 4.9252 17.9995 4.9381L18.0469 4.9549C18.2545 5.0491 18.4 5.2573 18.4 5.5V10.9H17.2V5.59ZM15.7234 1.6C16.8685 1.6 17.8 2.5315 17.8 3.6766V4.3C17.1382 4.3 16.6 4.8382 16.6 5.5V6.1H11.4484C11.0392 6.1 10.6471 6.2059 10.3 6.4015V2.0323C10.6186 1.7539 11.0212 1.6 11.4484 1.6H15.7234ZM2.2 3.6766C2.2 2.5315 3.1315 1.6 4.2766 1.6H8.5516C8.9788 1.6 9.3817 1.7539 9.7 2.0323V6.4012C9.3529 6.2059 8.9608 6.1 8.5516 6.1H3.4V5.5C3.4 4.8382 2.8618 4.3 2.2 4.3V3.6766ZM1.6 5.5C1.6 5.2573 1.7455 5.0491 1.9531 4.9546L2.0005 4.9378C2.0371 4.9246 2.0722 4.9159 2.1064 4.9096C2.1202 4.9075 2.134 4.9066 2.1481 4.9054C2.1694 4.9033 2.1907 4.9009 2.2117 4.9012C2.2249 4.9015 2.2375 4.9039 2.2504 4.9051C2.3107 4.9117 2.3704 4.9276 2.4313 4.9573C2.6554 5.0674 2.8 5.3155 2.8 5.59V10.9H1.6V5.5ZM2.5 11.8H1.9V11.5H2.5V11.8ZM16.6 10.9H3.4V8.2H16.6V10.9ZM18.1 11.8H17.5V11.5H18.1V11.8Z'
      />
    </CouchSVG>
  );
});

export { CouchOutlined };