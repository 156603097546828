import React, { useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import { FovPopover } from 'components';
import { getColor } from 'utils';

const ChannelHolder = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const PreviewColor = styled('div')`
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  border-radius: 0.3125rem;
  background-repeat: round;
  background-size: cover;
  background-color: ${props => props.color || getColor(props, 'white')};
  height: 2.25rem;
  width: 2.25rem;
  cursor: pointer;
  outline: none;
`;

const PaletteContainer = styled('div')`
  .sketch-picker {
    padding: unset !important;
    background: ${props => getColor(props, 'transparent')} !important;
    box-shadow: unset !important;

    div {
      border-radius: 0.25rem;
    }

    input {
      width: 100% !important;
      font-size: 0.9rem !important;
      border-radius: 0.2rem;
      background-color: ${props => getColor(props, 'primary')};
      color: ${props => getColor(props, 'onPrimary')};
      box-shadow: none !important;
      border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')} !important;
    }

    label {
      color: ${props => getColor(props, 'onPrimary')} !important;
    }

    .flexbox-fix {
      border-radius: unset;
      border-top: 0.0625rem solid ${props => getColor(props, 'mediumGrey')} !important;
    }
  }
`;

const ColorSwatch = withTheme(props => {
  const { theme, color, updateChannel } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const changeColor = useCallback(newColor => {
    updateChannel(newColor.hex);
  }, [ updateChannel ]);

  return (
    <ChannelHolder>
      <FovPopover
        noArrow={true}
        position='top'
        bottom={48}
        left={-160}
        keepOpened={false}
        content={
          <PaletteContainer theme={theme} $selectedTheme={selectedTheme}>
            <SketchPicker
              disableAlpha={true}
              color={color}
              onChangeComplete={changeColor}
            />
          </PaletteContainer>
        }
      >
        <PreviewColor
          theme={theme}
          $selectedTheme={selectedTheme}
          color={color}
          onClick={changeColor}
        />
      </FovPopover>
    </ChannelHolder>
  );
});

export { ColorSwatch };