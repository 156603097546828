import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FovSelect, FovOption, FovToggle, VerticalSpacer, DocNote, FovTooltip, InfoOutlined, Body } from 'components';
import { SCALE_UNITS, updateCanvas } from 'utils';
import constants from 'constants/index';
import styled from 'styled-components';
import _ from 'lodash';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;
const unitless = 'unitless';
const useSourceUnits = 'Use Source Units';

const UnitsSelectContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const BBoxSwitchContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-content: center;
  margin: 0.125rem;
`;

const DisplayUnitTitle = styled('div')`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

const UnitTipContainer = styled('div')`
  max-width: 14rem;
`;

const UnitsAndScale = () => {
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const selectedSourceUnit = serializedData?.metadata.selectedSourceUnit;
  const selectedDisplayUnit = serializedData?.metadata.selectedDisplayUnit;
  const boundingBoxEnabled = serializedData?.metadata.boundingBoxEnabled;
  const hasValidSourceUnit = !_.isEmpty(selectedSourceUnit) && selectedSourceUnit !== unitless;
  const friendlyName = SCALE_UNITS.find(unit => unit.abbreviation === selectedDisplayUnit);
  const placeholder = _.capitalize(friendlyName?.name || useSourceUnits);
  const dispatch = useDispatch();

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MESH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const displayUnitOptions = () => {
    const options = [
      <FovOption key={unitless} value={unitless}>
        {useSourceUnits}
      </FovOption>,
    ];

    SCALE_UNITS.forEach(unit => {
      const { name, abbreviation } = unit;

      options.push(
        <FovOption key={name} value={abbreviation}>
          {_.capitalize(name)}
        </FovOption>,
      );
    });

    return options;
  };

  return (
    <>
      <BBoxSwitchContainer>
        Bounding Box
        <DocNote path={[ 'modelPropertiesPanel', 'boundingBox' ]} top={-2} left={107} />

        <FovToggle
          checked={boundingBoxEnabled}
          callback={newVal => {
            updateCanvas(updateArgs, { boundingBoxEnabled: newVal });
          }}
        />
      </BBoxSwitchContainer>

      {boundingBoxEnabled && (
        <>
          <VerticalSpacer size={20} />
          <DisplayUnitTitle>
              Display Units

              <FovTooltip
                position='top'
                bottom={32}
                content={(
                  <UnitTipContainer>
                    <Body size='X-Small'>
                      Displays source units as another unit.<br />
                      Does not affect source units or scale.
                    </Body>
                  </UnitTipContainer>
                )}
              >
                <InfoOutlined width={20} height={20} />
              </FovTooltip>
          </DisplayUnitTitle>
          <VerticalSpacer size={15} />

          <UnitsSelectContainer>
            {!hasValidSourceUnit && (
              <Body size='X-Small'>
                Needs source units before converting display units.
              </Body>
            )}

            <FovSelect
              value={selectedDisplayUnit || selectedSourceUnit || ''}
              placeholder={placeholder}
              disabled={!hasValidSourceUnit}
              onChange={(e, newVal) => {
                if (selectedDisplayUnit !== newVal) {
                  const inboundData = { displayUnit: newVal };
                  updateCanvas(updateArgs, inboundData);
                }
              }}
            >
              {displayUnitOptions()}
            </FovSelect>
          </UnitsSelectContainer>
        </>
      )}
    </>
  );
};

export default UnitsAndScale;
