import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';
import { VerticalSpacer } from 'components';
import styled from 'styled-components';
import { HorizontalSpacer } from './HorizontalSpacer';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;
const speed = 2;

const Creators = styled('div')`
  width: 16.25rem;
  display: block;
`;

const Categories = styled('div')`
  width: 16.25rem;
  display: block;
`;

const Files = styled('div')`
  display: inline-flex;
  width: 100%;
`;

const Tags = styled('div')`
  display: inline-flex;
  width: 100%;
`;

const Sliders = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CreatorsLoader = props => {
  const { theme, selectedTheme } = props;

  return (
    <ContentLoader
      title={'Loading creators...'}
      speed={speed}
      width={260}
      backgroundColor={theme.colors[selectedTheme].mediumGrey}
      foregroundColor={theme.colors[selectedTheme][selectedTheme === light ? 'lightGrey' : 'darkGrey']}
      viewBox={'0 0 260 28'}
    >
      <rect x='0' y='4' rx='5' ry='5' width='20' height='20' />
      <rect x='30' y='4' rx='4' ry='4' width='155' height='20' />
      <rect x='200' y='4' rx='5' ry='5' width='45' height='20' />
    </ContentLoader>
  );
};

export const creatorsLoader = (theme, selectedTheme) => {
  const loadingShapes = [];

  for (let i = 1; i <= 1; i++) {
    loadingShapes.push(
      <Fragment key={`creator ${i}`}>
        {i === 1 && <VerticalSpacer size={20}/>}
        <CreatorsLoader theme={theme} selectedTheme={selectedTheme} />
        <VerticalSpacer size={10}/>
      </Fragment>,
    );
  }

  return (
    <Creators>
      {loadingShapes}
    </Creators>
  );
};

const CategoryLoader = props => {
  const { theme, selectedTheme } = props;

  return (
    <ContentLoader
      title={'Loading categories...'}
      speed={speed}
      width={260}
      backgroundColor={theme.colors[selectedTheme].mediumGrey}
      foregroundColor={theme.colors[selectedTheme][selectedTheme === light ? 'lightGrey' : 'darkGrey']}
      viewBox={'0 0 260 28'}
    >
      <rect x='0' y='4' rx='5' ry='5' width='20' height='20' />
      <rect x='30' y='4' rx='4' ry='4' width='155' height='20' />
      <rect x='230' y='4' rx='5' ry='5' width='20' height='20' />
    </ContentLoader>
  );
};

export const categoryLoader = (theme, selectedTheme) => {
  const loadingShapes = [];

  for (let i = 1; i <= 6; i++) {
    loadingShapes.push(
      <Fragment key={`category ${i}`}>
        {i === 1 && <VerticalSpacer size={20}/>}
        <CategoryLoader theme={theme} selectedTheme={selectedTheme} />
        <VerticalSpacer size={10}/>
      </Fragment>,
    );
  }

  return (
    <Categories>
      {loadingShapes}
    </Categories>
  );
};

const FileLoader = props => {
  const { theme, selectedTheme } = props;

  return (
    <ContentLoader
      title={'Loading files...'}
      speed={speed}
      width={100}
      backgroundColor={theme.colors[selectedTheme].mediumGrey}
      foregroundColor={theme.colors[selectedTheme][selectedTheme === light ? 'lightGrey' : 'darkGrey']}
      viewBox={'0 0 100 100'}
    >
      <rect x='0' y='0' rx='5' ry='5' width='100' height='100' />
    </ContentLoader>
  );
};

export const filesLoader = (theme, selectedTheme) => {
  const loadingShapes = [];

  for (let i = 1; i <= 3; i++) {
    loadingShapes.push(
      <Fragment key={`file ${i}`}>
        {i !== 1 && <HorizontalSpacer size={10}/>}
        <FileLoader theme={theme} selectedTheme={selectedTheme} />
        <HorizontalSpacer size={10}/>
      </Fragment>,
    );
  }

  return (
    <Files>
      {loadingShapes}
    </Files>
  );
};

const TagsLoader = props => {
  const { theme, selectedTheme } = props;

  return (
    <ContentLoader
      title={'Loading tags...'}
      speed={speed}
      width={260}
      backgroundColor={theme.colors[selectedTheme].mediumGrey}
      foregroundColor={theme.colors[selectedTheme][selectedTheme === light ? 'lightGrey' : 'darkGrey']}
      viewBox={'0 0 260 100'}
    >
      <rect x='0' y='0' rx='5' ry='5' width='100' height='25' />
      <rect x='110' y='0' rx='5' ry='5' width='120' height='25' />
      <rect x='0' y='35' rx='5' ry='5' width='50' height='25' />
      <rect x='60' y='35' rx='5' ry='5' width='80' height='25' />
    </ContentLoader>
  );
};

export const tagsLoader = (theme, selectedTheme) => {
  return (
    <Tags>
      <TagsLoader theme={theme} selectedTheme={selectedTheme}/>
    </Tags>
  );
};

const SlidersLoader = props => {
  const { theme, selectedTheme } = props;

  return (
    <ContentLoader
      title={'Loading attributes...'}
      speed={speed}
      width={340}
      backgroundColor={theme.colors[selectedTheme].mediumGrey}
      foregroundColor={theme.colors[selectedTheme][selectedTheme === light ? 'lightGrey' : 'darkGrey']}
      viewBox={'0 0 340 40'}
    >
      <circle cx='12' cy='22' r='7' />
      <rect x='0' y='20' rx='2' ry='2' width='200' height='4' />
      <rect x='224' y='4' rx='4' ry='4' width='36' height='36' />
      <rect x='276' y='4' rx='4' ry='4' width='36' height='36' />
    </ContentLoader>
  );
};

export const slidersLoader = (theme, selectedTheme) => {
  const loadingShapes = [];

  for (let i = 1; i <= 6; i++) {
    loadingShapes.push(
      <Fragment key={`slider ${i}`}>
        {i === 1 && <VerticalSpacer size={20}/>}
        <SlidersLoader theme={theme} selectedTheme={selectedTheme} />
        <VerticalSpacer size={10}/>
      </Fragment>,
    );
  }

  return (
    <Sliders>
      {loadingShapes}
    </Sliders>
  );
};