import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { getColor } from 'utils';

const Div = styled('div')`
  position: relative;
  background: ${props => getColor(props, 'primary')};

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  // Babylon inspector override
  .panes {
    input,
    button,
    select,
    optgroup,
    textarea {
      color: black;
    }
  }
`;

const AppContainer = withTheme(props => {
  const { children } = props;
  const theme = useSelector(state => state.userData.userPreferences.theme);

  return (
    <Div $selectedTheme={theme}>
      {children}
    </Div>
  );
});

export { AppContainer };
