import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  HorizontalSpacer,
  VerticalSpacer,
  ShareOutlined,
  ArrowLeftOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Image,
  Caption,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { publish as images } from './images';
import Summary from './Summary';

const PublishingModels = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    'Name, categorize, and tag models before publishing.',
    'Choose a "Proxy" type for an RPC model.',
    'Optimize and simplify the mesh of a model, globally.',
    'Publish a model.',
    'Learn about the credits system.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='The Publish Panel'>
        <p>
          The Publish panel&nbsp;<ShareOutlined /> allows you to name,
          categorize, and tag a model before you publish. The act of
          publishing will add a model to the <strong>&quot;My Files&quot;</strong>&nbsp;
          section of the app, as well as convert
          the model to a variety of file formats for download. From this panel,
          you can also downscale textures and simplify your model.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.publishPanel} width={400} alt='Publish panel.' />
        </ImageContainer>

        <VerticalSpacer size={15} />

        <DocSection subsection='Adding descriptions'>
          <p>
            You can add a name, category, and tags to your model.
            The <strong>name</strong> is the title of the card you will see on the&nbsp;
            <strong>&quot;My Files&quot;</strong>&nbsp;page. There are also multiple
            categories to choose from. You can add as many tags for a model as
            you need. Both categories and tags will help with filtering.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.descriptionArea} width={350} alt='Description section.' />
            <HorizontalSpacer size={10} />
            <Image src={images.categoryMenuOpen} width={350} alt='Open category menu.' />
          </ImageContainer>
        </DocSection>



        <DocSection subsection='Proxy types'>
          <p>
            Proxy types allow you to set a representation of what the outline of
            your model will look like when it is imported into another application.
            This is purely a representation of the model for placement and viewport
            performance &mdash; your actual model will remain unchanged.
          </p>

          <p>
            <strong>&quot;Billboard&quot;</strong> produces three cards, two of which
            bisect each other and the third placed at the bottom of those to represent
            the outer most bounds of the model.
          </p>

          <p>
            <strong>&quot;Simplified Mesh&quot;</strong> produces your model, but with
            a considerably reduced triangle count.  You have options for controlling this further.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.proxyMenu} width={350} alt='Proxy menu.'/>

            <HorizontalSpacer size={10} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={10} />

            <Image src={images.simplifiedProxyOptions} width={350} alt='Simplified proxy options.'/>
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The proxy types will only show when using the <strong>RPC</strong> file format.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='Optimization'>
        <DocSection subsection='Global texture downscaling'>
          <ImageContainer>
            <Image src={images.downscalingOff} width={350} alt='Texture downscaling toggled off' />
          </ImageContainer>

          <VerticalSpacer size={15} />

          <p>
            You can optimize every texture used in all material texture channels to a single
            resolution using the <strong>&quot;Texture downscaling&quot;</strong> option
            and subsequent menu.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.downscalingOn} width={350} alt='Texture downscaling toggled on' />

            <HorizontalSpacer size={10} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={10} />

            <Image src={images.downscalingMenu} width={350} alt='Texture downscaling menu' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            This will only affect textures larger than the targeted resolution.
          </Caption>
        </DocSection>



        <DocSection subsection='Global mesh simplification'>
          <p>
            Mesh simplification, as the name suggests, allows you to globally reduce the
            triangle count for each mesh item in your scene &mdash; this will replace any individual
            mesh level simplifcation that was set in the Materials panel.
          </p>

          <p>
            When toggled on, you will be presented with a UI that defaults to a custom optimization setting.
            The default is <strong>&quot;Estimated triangles&quot;</strong> and allows you to set a target
            reduction by percent, i.e. 20% means you want to reduce the total mesh count to
            20% of the model&apos;s current amount.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.opGlobalOff} width={350} alt='Optimization toggled off.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.opGlobalEstimated} width={300} alt='Optimization estimated.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Your estimated triangle count, based on the percent value you provided, will be updated immediately.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            You can switch optimization types from the optimization type select menu.  This will allow you
            to set a target reduction by triangle count, i.e. a value of 10,000 means you want your final
            triangle count to be reduced to 10,000 triangles.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.opGlobalType} width={350} alt='Optimization type select.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.opGlobalTarget} width={300} alt='Target optimization.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            <strong>&quot;Target triangles&quot;</strong> max count is the current triangle count and
            upper limit to what you can enter in the field to the right.<br />
            At this time, optimization will only take place after publishing.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            If quick mesh simplification is what you are after, you can click any of the three presets:&nbsp;
            <strong>&quot;Low&quot;</strong>,&nbsp;
            <strong>&quot;Medium&quot;</strong> or&nbsp;
            <strong>&quot;High&quot;</strong>
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer noSpace={true}>
            <Image src={images.opGlobalLow} width={340} alt='Low preset.' />
            <Image src={images.opGlobalMedium} width={340} alt='Medium preset.' />
            <Image src={images.opGlobalHigh} width={340} alt='Hight preset.' />
          </ImageContainer>
        </DocSection>
      </DocSection>



      <DocSection section='The Credits System'>
        <p>
          Credits allow you to publish models. There are two places to check
          your balance; the header under your user profile name, and the top of
          the publish panel. If you are running low on credits, click the&nbsp;
          <Link
            to="//archvision.com/fovea/#pricing"
            rel="noreferrer"
            target="_blank"
          >
            Add more credits...
          </Link>&nbsp;
          link under your credit balance in the publish panel.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.credits} width={250} alt='Credits'/>
          <Image src={images.creditBalanceHeader} width={281} alt='Credit balance in header.' />
        </ImageContainer>

        <VerticalSpacer size={15} />

        <p>
          A credit is only deducted from your balance when you publish a model.  Downloading
          a model after publishing is free.  If there is an error during the publishing process,
          your credit will be refunded to your balance, immediately.
        </p>
      </DocSection>
    </ContentContainer>
  );
});

export { PublishingModels };