/* eslint-disable */
import { actions as canvasActions } from 'store/actions/canvas';
import {
  cityBlock,
  cityBlockPreview,
  cityNight,
  cityNightPreview,
  path,
  pathPreview,
  skybox,
  tunnel,
  tunnelPreview,
} from 'assets/environments';
import u from 'updeep';

export const initialState = {
  serializedData: null,
  updateData: null,
  reinitializeCanvas: false,
  exportModel: false,
  exportedModelData: null,
  guidURL: null,
  billboardImages: [],
  defaultEnvironments: [
    {
      id: 'default',
      name: 'Default',
      envURL: skybox.default,
    },
    {
      id: 'cityBlock',
      name: 'City Block',
      imageURL: cityBlockPreview.default,
      envURL: cityBlock.default,
    },
    {
      id: 'tunnel',
      name: 'Tunnel',
      imageURL: tunnelPreview.default,
      envURL: tunnel.default,
    },
    {
      id: 'cityNight',
      name: 'City Night',
      imageURL: cityNightPreview.default,
      envURL: cityNight.default,
    },
    {
      id: 'path',
      name: 'Path',
      imageURL: pathPreview.default,
      envURL: path.default,
    },
  ],
};

const canvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case canvasActions.SET_SERIALIZED_DATA:
      return u({ serializedData: action.payload }, state);

    case canvasActions.CLEAR_SERIALIZED_DATA:
      return u({ serializedData: { metadata: {} } }, state);

    case canvasActions.SET_UPDATE_DATA:
      return u({ updateData: action.payload }, state);

    case canvasActions.CLEAR_UPDATE_DATA:
      return u({ updateData: null }, state);

    case canvasActions.SET_REINITIALIZE_CANVAS:
      return u({ reinitializeCanvas: action.payload }, state);

    case canvasActions.SET_EXPORT_MODEL:
      return u({ exportModel: action.payload }, state);

    case canvasActions.SET_EXPORTED_MODEL_DATA:
      return u({ exportedModelData: action.payload }, state);

    case canvasActions.SET_BILLBOARD_IMAGES:
      return u({ billboardImages: action.payload }, state);

    case canvasActions.SET_GUID_URL:
      return u({ guidURL: action.payload }, state);

    default:
      return state;
  }
};

export { canvasReducer };