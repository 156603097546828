import React, { Fragment } from 'react';
import { List } from 'react-content-loader';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { creatorsLoader, categoryLoader, filesLoader, tagsLoader, slidersLoader } from './loaderUtils';

const FovSkeletonLoader = withTheme(props => {
  const { resource, type, theme, children } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const buildLoader = () => {
    switch(type) {
      case 'creators':
        return creatorsLoader(theme, selectedTheme);
      case 'categories':
        return categoryLoader(theme, selectedTheme);
      case 'files':
        return filesLoader(theme, selectedTheme);
      case 'tags':
        return tagsLoader(theme, selectedTheme);
      case 'sliders':
        return slidersLoader(theme, selectedTheme);

      default:
        return <List />;
    }
  };

  return (
    <Fragment>
      {!resource ? buildLoader() : children}
    </Fragment>
  );
});

export { FovSkeletonLoader };