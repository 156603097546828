import { useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  FovSelect,
  FovOption,
  Checkbox,
  VerticalSpacer,
  HorizontalSpacer,
  FovUploader,
  FovSlider,
} from 'components';
import { updateCanvas, PROXY_TYPES } from 'utils';
import { Proxies } from 'store/actions/rpc';
import styled from 'styled-components';
import _ from 'lodash';

const ProxyBlock = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MeshControl = styled('div')`
  display: grid;
  gap: 0.25rem 2rem;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'slider'
    'checkbox';

  width: 100%;
`;

const Slider = styled(FovSlider)`
  width: 100%;
  grid-area: slider;
`;

const MeshCheckbox = styled('label')`
  display: inline-flex;
  grid-area: checkbox;
`;

const Proxy = props => {
  const { form, formDefaults, updateArgs } = props;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const proxy = serializedData?.metadata.publish.proxy;
  const isControl = serializedData?.metadata.publish.meshSimplification?.isControl || false;
  const controlValue = serializedData?.metadata.publish.meshSimplification?.decimateAmount || 0.5;
  const simpleProxyEnabled = proxy === 'simplified mesh';
  const customMeshRef = useRef();
  const isCustomMesh = _.toLower(proxy) === _.toLower(Proxies.customMesh);

  const buildProxyOptions = () => {
    const options = [];

    PROXY_TYPES.forEach((type, index) => {
      options.push(
        <FovOption key={index} value={type}>
          {_.startCase(type)}
        </FovOption>,
      );
    });

    return options;
  };

  useEffect(() => {
    if (!isCustomMesh && customMeshRef.current) {
      customMeshRef.current.value = '';
    }
  }, [ proxy ]);

  return (
    <ProxyBlock>
      <VerticalSpacer size={15} />

      <FovSelect
        value={proxy}
        placeholder={_.startCase(proxy)}
        onChange={(e, newVal) => updateCanvas(updateArgs, { proxy: newVal })}
      >
        {buildProxyOptions()}
      </FovSelect>

      {isCustomMesh && (
        <>
          <VerticalSpacer size={16} />

          <FovUploader
            form={form}
            formDefaults={formDefaults}
            instructions='Click or drag file here.'
            supportedExtensions={[ { name: 'GLB', value: 'GLB' } ]}
          />
        </>
      )}

      <VerticalSpacer size={15} />

      {simpleProxyEnabled && (
        <MeshControl>
          <Slider
            defaultValue={controlValue}
            disabled={!isControl}
            onAfterChange={newVal => {
              const inboundData = {
                meshSimplification: {
                  decimateAmount: newVal,
                },
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />

          <MeshCheckbox htmlFor='meshCheckbox'>
            <Checkbox
              name='meshCheckbox'
              checked={isControl}
              onChange={e => {
                const newVal = e.target.checked;
                const inboundData = {
                  meshSimplification: {
                    isControl: newVal,
                  },
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />
            <HorizontalSpacer size={10} />
            Mesh control
          </MeshCheckbox>
        </MeshControl>
      )}
    </ProxyBlock>
  );
};

export default Proxy;
