import styled from 'styled-components';
import { FovTypography } from 'components/typography';
import { getColor } from 'utils';

export const ControlsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const ControlHolder = styled(FovTypography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 6.12rem;
  height: 3.5rem;
  padding: 0.3rem 0.05rem 0.05rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
  user-select: none;
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
`;

export const KeyCombo = styled('strong')`
  display: inline-flex;
`;
