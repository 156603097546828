import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const GrassSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const GrassOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <GrassSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 17 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M8.93144 16C9.28858 13.5 10.6457 9.42857 16.0743 7.42857C14.2886 7.78571 10.7171 7.78571 7.50286 12.0714C6.78857 8.14286 7.64577 3.85714 9.64577 1C7.50288 3.14286 3.50283 8.28571 4.64569 14.5714C3.45523 13.7381 1.07438 11.1429 1.07438 8.85714C0.836298 10.2857 1.0743 13.8571 3.21717 16'
      />
    </GrassSVG>
  );
});

export { GrassOutlined };