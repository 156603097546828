import React from 'react';
import { useSelector } from 'react-redux';
import Summary from './Summary';
import {
  ArrowLeftOutlined,
  HorizontalSpacer,
  VerticalSpacer,
  ImageContainer,
  DocSection,
} from 'components';
import { Caption, Image, ContentContainer } from '../docStyles';
import { withTheme } from 'styled-components';
import { fromGLTF as images } from './images';

const CreateFromGLTF = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    <>
      Upload&nbsp;<strong>&quot;From glTF&quot;</strong>,
      in the&nbsp;<strong>&quot;Create&quot;</strong>&nbsp;menu.
    </>,
    <>
      Supported files:&nbsp;<strong>glTF</strong>&nbsp;and&nbsp;<strong>glb</strong>
    </>,
    'Open the model in the FOVEA viewport.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='From gLTF'>
        <p>
          Navigate to the Drag and Drop page from the <strong>&quot;Create&quot;</strong> menu in the app header
          and click <strong>&quot;From glTF&quot;</strong>.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.fromGLTF} width={400} alt='From glTF' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.dragAndDrop} width={350} alt='Drag and Drop into FOVEA Scene' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          You will be taken to the create page, allowing you to <strong>Drag and Drop</strong> a&nbsp;
          <strong>.glb</strong> or <strong>.gltf</strong>, <strong>.bin</strong> + textures folder.<br />

          The Drag and Drop screen is able to handle both single and multi-file uploads.
        </Caption>
      </DocSection>



      <DocSection section='Opening a model'>
        <p>
          Select the file(s) that you wish to open and drag them into the scene.  You can drag a single&nbsp;
          <strong>.glb</strong> or <strong>.gltf</strong>.  If your <strong>.gltf</strong> requires a supporting&nbsp;
          <strong>.bin</strong> or textures, you can drag them all (including the textures folder) onto this area.
        </p>

        <ImageContainer>
          <Image src={images.bikeFile} width={100} alt='A single glb file to be dragged.' />
          <Image src={images.multifileUpload} width={400} alt='Multiple associated files to be dragged.' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.bikeInScene} width={400} alt='File dragged into scene' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          Your model will load in the FOVEA viewport.
        </Caption>

        <VerticalSpacer size={15} />

        <p>
          From here, you will be able to access the different panels on the right side that will
          allow for further editing of the model before publishing.
        </p>
      </DocSection>
    </ContentContainer>
  );
});

export { CreateFromGLTF };