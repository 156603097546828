import React from 'react';
import {
  H4,
  H5,
  VerticalSpacer,
  FovDivider,
} from 'components';

const DocSection = props => {
  const { section, subsection, children } = props;

  return(
    <div>
      {!subsection && <VerticalSpacer size={15} />}
      {section && <H4>{section}</H4>}
      {subsection && <H5>{subsection}</H5>}
      <VerticalSpacer size={subsection ? 15 : 30} />

      {children}

      {children && (
        <>
          <VerticalSpacer size={30} />
          {!subsection && <FovDivider />}
        </>
      )}
    </div>
  );
};

export { DocSection };