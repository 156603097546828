import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const VanSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const VanOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <VanSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 19 13'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M13.4665 10.6333C13.4665 11.2589 13.9743 11.7666 14.5998 11.7666C15.2254 11.7666 15.7332 11.2589 15.7332 10.6333C15.7332 10.0077 15.2254 9.49994 14.5998 9.49994C13.9743 9.49994 13.4665 10.0077 13.4665 10.6333ZM13.4665 10.6333H5.53343M5.53343 10.6333C5.53343 10.0077 5.0257 9.49994 4.40011 9.49994C3.77451 9.49994 3.26678 10.0077 3.26678 10.6333M5.53343 10.6333C5.53343 11.2589 5.0257 11.7666 4.40011 11.7666C3.77451 11.7666 3.26678 11.2589 3.26678 10.6333M3.26678 10.6333L2.13332 10.6332C1.50773 10.6332 1 10.1255 1 9.49992V7.23328M15.1666 3.26654H11.2V10.6331L11.1999 7.23328M15.1666 3.26654L16.2999 6.09985M15.1666 3.26654L16.2999 3.26665C16.2999 3.26665 16.8666 1 14.5999 1H11.1999M16.2999 6.09985L17.4952 6.89696C17.8103 7.107 17.9999 7.4606 17.9999 7.83988V9.50209C17.9999 10.1277 17.4922 10.6354 16.8666 10.6354H15.7333M16.2999 6.09985L11.2 6.09998M11.1999 7.23328H1M11.1999 7.23328V1M1 7.23328V1.75555C1 1.33849 1.33849 1 1.75555 1H11.1999'
      />
    </VanSVG>
  );
});

export { VanOutlined };