import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const Reload = withTheme(props => {
  const { width = 32, height = 32, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const fill = color || theme.colors[selectedTheme].mediumGrey;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.45042 8.7172C1.45042 12.9531 4.52167 15.8386 7.97045 16.4359C8.3651 16.5042 8.62963 16.8794 8.5613 17.2742C8.49296 17.6688 8.11764 17.9333 7.72299 17.865C3.6715 17.1634 0 13.7486 0 8.7172C-1.39147e-06 6.57896 0.972803 4.89856 2.10884 3.62025C2.92264 2.70454 3.85367 1.96104 4.60245 1.39147H2.32543C1.94119 1.39147 1.6297 1.07997 1.6297 0.695734C1.6297 0.311494 1.94119 0 2.32543 0H6.49984C6.88408 0 7.19557 0.311494 7.19557 0.695734V4.87014C7.19557 5.25438 6.88408 5.56587 6.49984 5.56587C6.1156 5.56587 5.8041 5.25438 5.8041 4.87014V2.30209L5.802 2.30367L5.80188 2.30377L5.80186 2.30378H5.80185C5.00667 2.90352 4.03491 3.63641 3.193 4.58375C2.19877 5.7025 1.45042 7.05407 1.45042 8.7172ZM16.9064 9.28275C16.9064 5.09156 13.9003 2.22298 10.4958 1.58387C10.1022 1.50997 9.84298 1.13094 9.91688 0.737283C9.99078 0.343637 10.3698 0.0844343 10.7634 0.158335C14.7629 0.909171 18.3567 4.30524 18.3567 9.28275C18.3567 11.421 17.384 13.1013 16.248 14.3797C15.4341 15.2954 14.5031 16.0389 13.7543 16.6085H16.0315C16.4156 16.6085 16.7272 16.9199 16.7272 17.3043C16.7272 17.6884 16.4156 18 16.0315 18H11.857C11.4727 18 11.1613 17.6884 11.1613 17.3043V13.1299C11.1613 12.7455 11.4727 12.4341 11.857 12.4341C12.2412 12.4341 12.5527 12.7455 12.5527 13.1299V15.6978L12.5549 15.6961C13.3501 15.0964 14.3219 14.3635 15.1637 13.4162C16.1581 12.2974 16.9064 10.9459 16.9064 9.28275Z"
        fill="#D1DBE0"
      />
    </svg>
  );
});

export { Reload };
