import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const TruckSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const TruckOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <TruckSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 20 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M16.0002 14.8C16.0002 14.1376 15.4626 13.6 14.8002 13.6C14.1378 13.6 13.6002 14.1376 13.6002 14.8M16.0002 14.8C16.0002 15.4624 15.4626 16 14.8002 16C14.1378 16 13.6002 15.4624 13.6002 14.8M16.0002 14.8L17.7999 14.8001C18.4623 14.8001 18.9999 14.2625 18.9999 13.6001V7.60006C18.9999 5.61206 17.3879 4.00006 15.3999 4.00006H11.7999M13.6002 14.8L11.8001 14.8M5.79993 14.8C5.79993 14.1376 5.26233 13.6 4.59993 13.6C3.93753 13.6 3.39993 14.1376 3.39993 14.8M5.79993 14.8C5.79993 15.4624 5.26233 16 4.59993 16C3.93753 16 3.39993 15.4624 3.39993 14.8M5.79993 14.8L11.8001 14.8M3.39993 14.8L2.2 14.8C1.5376 14.8 1 14.2624 1 13.6V11.9824C1 11.5504 1.3504 11.2 1.7824 11.2H11.8M18.9999 8.80006H14.9999C14.5703 8.81206 14.2119 8.47286 14.1999 8.04326V4.00006M11.8001 14.8L11.7999 4.00006M18.9998 12.4001H17.4342M11.7999 4.00006L11.8 3.4848C11.8008 2.6816 11.4 1.9304 10.7312 1.4848L10 1'
      />
    </TruckSVG>
  );
});

export { TruckOutlined };