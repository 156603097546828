import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const ShrubSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const ShrubOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <ShrubSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9.07704 6.76929V16.0001M9.07704 11.9617C11.3847 11.9617 12.5385 10.8079 12.5385 8.50024M9.07685 9.65407C7.57458 9.74484 6.28308 8.60025 6.19231 7.09798C6.18539 6.98876 6.18539 6.87876 6.19231 6.76953M17.1537 9.07716C17.1506 7.50643 16.2352 6.08031 14.8076 5.42341C15.0399 4.19036 14.2284 3.00193 12.9945 2.7704C12.8445 2.74194 12.6915 2.72886 12.5384 2.73117C12.4899 2.73117 12.4446 2.74271 12.3961 2.74578C11.1354 0.912754 8.62696 0.44892 6.79393 1.70966C6.38779 1.98888 6.03703 2.34041 5.75781 2.74578C5.70935 2.74271 5.66396 2.73117 5.6155 2.73117C4.36092 2.71117 3.3271 3.71191 3.3071 4.96727C3.30479 5.12034 3.31787 5.27264 3.34633 5.42341C1.32408 6.35338 0.439483 8.74717 1.37023 10.7687C1.90252 11.9263 2.95096 12.7632 4.19708 13.0271C4.92629 14.6009 6.7924 15.2847 8.3662 14.5562C8.62235 14.4378 8.86157 14.2847 9.07695 14.1016C10.4 15.2232 12.3815 15.0593 13.5022 13.7363C13.6853 13.5209 13.8376 13.2809 13.9568 13.0248C15.8206 12.6286 17.1529 10.9825 17.1537 9.07716Z'
      />
    </ShrubSVG>
  );
});

export { ShrubOutlined };