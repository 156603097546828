import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const Text = withTheme(styled('div')`
  font-family: ${props => props.theme.typography.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: flex;
  flex-direction: row;
  color: ${props => (
    props.theme.colors[props.$selectedTheme].lightGrey
  )};
`);

const Copyright = withTheme(props => {
  const { children } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Text $selectedTheme={selectedTheme}>
      {children}
    </Text>
  );
});

export { Copyright };