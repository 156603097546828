import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const LibraryOutlined = withTheme(props => {
  const { width, height, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 33}
      height={height || 26}
      fill={color || theme.colors[selectedTheme].onPrimary}
      viewBox="0 0 33 26"
    >
      <path d="M8.94794 1.08333L10.0664 0H14.5404L15.6589 1.08333V24.9167L14.5404 26H10.0664L8.94794 24.9167V1.08333ZM11.1849 2.16667V23.8333H13.4219V2.16667H11.1849ZM18.2784 2.91417L18.9473 1.52533L23.1528 0.0433333L24.5845 0.691167L33 23.0858L32.3311 24.4747L28.1278 25.9567L26.6962 25.3088L18.2762 2.91417H18.2784ZM20.7615 3.1915L28.4119 23.5495L30.5147 22.8107L22.8642 2.4505L20.7615 3.1915ZM0 1.08333L1.11849 0H5.59246L6.71095 1.08333V24.9167L5.59246 26H1.11849L0 24.9167V1.08333ZM2.23698 2.16667V23.8333H4.47397V2.16667H2.23698Z" />
    </svg>
  );
});

export { LibraryOutlined };