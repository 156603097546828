import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme } from 'styled-components';

const CollapseSVG = styled('svg')`
  cursor: pointer;
  color: ${props => props.color || getColor(props, 'onPrimary')};

  &:hover {
    color: ${props => getColor(props, 'accent')};
  }
`;

export const Collapse = withTheme(props => {
  const { height, width, rotate, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CollapseSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      transform={`rotate(${rotate})`}
      color={color}
      width={width || 20}
      height={height || 20}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill='currentColor'
        d="M0 0v8h1V0H0zm6 0v8h2V0H6zM5 2L3 4l2 2V2z"
      />
    </CollapseSVG>
  );
});
