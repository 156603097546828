/* eslint-disable max-len */

import _ from 'lodash';

const referenceItems = {
  header: {
    logo: `Click to go back to the main FOVEA page from anywhere in the app.`,
    helpMenu:
      <>
        <p>
          Discover how to use the app in greater detail with documentation or quickly read about
          app functionality by toggling reference points as you work.
        </p>

        <p>
          Need additional help? Contact support &mdash; all from the Help menu.
        </p>
      </>,
    contentMenu:
      <>
        <p>
          View, edit, or download from your model library.
        </p>

        <p>
          You can also explore models from our Creators where you can download or edit and publish your own 3D model versions.
        </p>
      </>,

    createMenu:
      <>
        <p>
          You have two import choices: <strong>&quot;From File&quot;</strong> or <strong>&quot;From gLTF&quot;</strong>.
        </p>

        <p>
          The <strong>&quot;From File&quot;</strong> option allows you to import <strong>.rpc</strong>, <strong>.obj</strong>, <strong>.fbx</strong>, and<strong> .png</strong> files.
          Uploading <strong>.png</strong> files results in a 2D RPC and all other formats will generate a 3D RPC.
        </p>

        <p>
          If you have <strong>.gltf/.glb</strong> files ready to go, select <strong>&quot;From gLTF&quot;</strong> and drag those files onto the canvas to begin creating a FOVEA scene.,
        </p>
      </>,

    themeSwitcher: `Toggle between light and dark mode.`,
    infoSupportMenu: `View the roadmap, give feedback or read our terms and conditions and privacy policy.`,
    profileMenu: `Log in and out, as needed.`,
  },

  sampleFiles:
    <>
      <p>
        The quickest way to start exploring FOVEA is to load any of these three sample files.
      </p>

      <p>
        Load a model and get your bearings with; the interface, material editing and publishing your first model.
        Once you feel comfortable you can import your own models from the <strong>&quot;Create&quot;</strong> menu.
      </p>
    </>,

  uploadArea:
    <>
      Drop your <strong>.gltf</strong> and <strong>.bin</strong> files together, or a single &nbsp;
      <strong>.glb</strong> file to build a new FOVEA scene.
    </>,

  viewport: `This is the viewport. Your model and any changes you make to it will be shown here along with other FOVEA scene elements.`,

  panelsToolbar: {
    modelToolbar: `Click any panel icon to view its contents. Each panel controls a specific part of the FOVEA scene or, when ready, allows you to publish your model.`,
    settingsToolbar: `The settings panel displays mouse control options and a list of shortcuts to make scene manipulation easier.`,
  },

  materialsPanel: {
    materialsList: `Any materials found in your scene can be searched for and selected here, for further tweaking.`,
    materialActions: `Material actions can help you quickly achieve common tasks such as renaming, or selecting all objects in the scene that have the selected material applied.`,
    generalProperties: `General properties can quickly help you diagnose visual issues in your scene.`,
    optimization: `Customize mesh density and texture resolution to improve model performance at publish time.`,
    attributes: `Attributes quickly help you modify the look and feel of your material with values, color or textures.`,
    advancedAttributes: `Some materials may have more advanced attributes that allow for greater control over material results.`,
  },

  modelPropertiesPanel: {
    sourceUnits: `Globally adjusts the scale of all meshes in the scene to match the units supported by the source file.  This is useful for correcting models that appear too large or small in the scene.`,
    axisCompensation:
      <>
        <p>
          If your model is coming from a program that uses the Z-Axis as the up axis, you can compensate for this by selecting the correct axis orientation.
          Your model will be automatically set right-side up and placed back at the world origin.
        </p>

        <p>
          You can also use Shift+O to reset the model to the world origin at any time.
        </p>
      </>,

    transforms: `Transforms allow you to quickly manipulate the model's move, rotate and scale values with precision.  You can also use Shift+M and Shift+R to toggle the move and rotate gizmos in the viewport, accordingly.`,

    savedPositions:
      <>
        <p>
          You can bookmark a model&apos;s translations at any time by clicking the <strong>&quot;Save position&quot;</strong> button.  Return to an unmodified version, or select your saved position from the drop-down.
        </p>

        <p>
          If you no longer need a position, click the <strong>&quot;Delete position&quot;</strong> button to remove it from the list.
        </p>
      </>,

    boundingBox: `The bounding box can give you a look at your model's scale in the scene, in the units you prefer.  Try grabbing the corners for a way to uniformly scale your mesh.`,
    statistics: `Statistics can give you some insight on your model for cross-checking between apps, or after optimizations have been made.`,
  },

  cameraPanel: {
    orthographicViews:
      <>
        <p>
          Orthographic views can help ensure your model is displaying correctly by removing perspective.
        </p>

        <p>
          Click to enable an orthographic view or click again to disable it and return to the perspective camera.
        </p>
      </>,

    screenshot:
      <>
        <p>
          If you need a quick screenshot of your model, you can select your aspect ratio, position your model in the safe frame and click the <strong>&quot;Take screenshot&quot;</strong> button.
        </p>

        <p>
          A cleaned up screenshot of your model will be downloaded, locally, so you can show your RPC to the world.
        </p>

        <p>
          Using Shift+F, by default, will toggle the 1:1 ratio safe frame — which you can use to position your model in to ensure a neat thumbnail at publish time.
        </p>
      </>,

    savedViews:
      <>
        <p>
          You can bookmark a camera&apos;s position at any time by clicking the <strong>&quot;Save camera view&quot;</strong> button.
          Return to the default perspective camera, or select your saved camera view from the drop-down.
        </p>

        <p>
          If you no longer need a camera view, click the <strong>&quot;Delete view&quot;</strong> button to remove it from the list.
        </p>
      </>,
  },

  environmentPanel: {
    presetEnvironments: `Get started with these environments to help showcase your model or materials in different lighting conditions.`,

    customEnvironments:
      <>
        <p>
          You can add your own <strong>.hdr</strong> files by clicking the <strong>&quot;Add custom environment&quot;</strong> button.
          Return to the default environment preset, or select your custom environment from the drop-down.
        </p>

        <p>
          If you no longer need your custom environment, click the <strong>&quot;Remove selected environment&quot;</strong> button to remove it from the list.
        </p>
      </>,

    attributes:
      <>
        Control environment brightness with intensity or adjust environment blur, when <strong>&quot;Background&quot;</strong> is toggled on.
      </>,

    viewportVisibility: `Whether it's for aesthetics or performance, these toggles help you control the visibility of viewport items as you work.`,
  },

  visibilityPanel: `Controls to help mesh manipulation and selection.  Hide meshes to access other meshes for deletion, then unhide them — perfect for optimizing models by removing parts that aren't visible, needed or cleaning up before taking a screenshot.`,

  publishPanel: {
    addCredits: `Running low on credits?  Add more by following this link...`,
    availChannels: `If you are logged into AVAIL, you can automatically have your published model saved to the selected AVAIL channel.`,
    description: `Name, categorize and tag how your mesh will be described in your FOVEA library.`,
    proxy: `Control what type of stand-in mesh will be displayed once your model is imported into your application of choice.`,
    globalOptimization: `Customize mesh density and texture resolution to improve model performance after Publishing. These optimizations override any local material optimizations in the Materials panel.`,
  },

  content: {
    models: `This is where your content is viewed and managed.  You can preview models by clicking on them or perform specific actions like opening up the FOVEA scene for editing, downloading or deleting the model from your library.`,
    viewPreferences: `Sort, control thumbnail size or go between grid and list views.  You can also control whether new/outstanding jobs are pinned to the top or bottom of your library.`,
  },

  filterMyFiles: {
    categories:
      <>
        <p>
          Any category you&apos;ve selected while creating your files will be shown here. Selecting a category will filter your library to only show that category.
        </p>

        <p>
          Selecting the category again or <strong>&quot;All&quot;</strong> will clear that filter and bring back your entire library.
          If a model does not match an existing sub-category, we&apos;ll file it away for you in a new <strong>&quot;Misc.&quot;</strong> sub-category.
        </p>
      </>,

    tags:
      <>
        <p>
          Any tags you&apos;ve set across all of your models will show here.  Select one or more tags to filter your library to only show models with the selected tags.
        </p>

        <p>
          Selecting the tag again or <strong>&quot;Clear all&quot;</strong> will clear the filtered tags and bring back your entire library.
        </p>
      </>,
  },

  filterCreators: {
    creators:
      <>
        <p>
          Filter Creator content by name. Click <strong>&quot;All&quot;</strong> to quickly add the remaining creators or, if all are selected, to quickly deselect them for a clean selection slate.
        </p>

        <p>
          Selected creator content will be displayed in the content view.
        </p>
      </>,

    categories:
      <>
        <p>
          Creator defined categories will be shown here.  Selecting a Creator&apos;s sub-category will filter their library to only show content from that category.
        </p>

        <p>
          Selecting the category again or <strong>&quot;All&quot;</strong> will clear that filter and bring back the entire ArchVision library from all Creators.
        </p>
      </>,

    tags:
      <>
        <p>
          Any tags set across all selected Creator content will be shown here.  Select one or more tags to filter their libraries to only show models with the selected tags.
        </p>

        <p>
          Selecting the tag again or <strong>&quot;Clear all&quot;</strong> will clear the filtered tags and bring back the entire library of selected Creator content.
        </p>
      </>,
  },
};

const totalReferencePoints = () => {
  let totalCount = 0;

  Object.keys(referenceItems).forEach(key => {
    const value = referenceItems[key];
    const noChildren = !_.isObject(value);

    if (noChildren) {
      return totalCount += 1;
    }

    Object.keys(value).forEach(() => totalCount += 1);
  });

  return totalCount;
};

export { referenceItems, totalReferencePoints };