import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import { FovButton } from 'components/generic';
import { FovTypography } from 'components/typography';
import styled, { withTheme, css } from 'styled-components';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

const BannerContainer = styled(FovTypography)`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto;

  position: absolute;
  bottom: 4.75rem;
  left: 1rem;
  right: 1rem;
  margin: 0 auto;
  width: calc(100vw - 2rem);
  ${props => props.$maxWidth && css`max-width: ${props.$maxWidth / 16}rem;`}
  height: 3.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: ${props => getColor(props, 'onPrimary')};
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
  }};
  box-shadow: 0 0.1rem 0.25rem ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'black');
  }};
`;

const CTAContainer = styled('div')`
  display: inline-flex;
  min-width: 5rem;
`;

const CTAButton = styled(FovButton)`
  width: 6rem;
  margin: 0 0.25rem;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const BannerText = styled('p')`
  margin: 0;
  font-size: 0.95rem;
  padding: 0 1rem;
`;

const FovBanner = withTheme(props => {
  const { theme, text, ctaText, rejectText, callback, maxWidth, useAccept, useReject } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;
  const error = theme.colors[selectedTheme].error;
  const errorHover = theme.colors[selectedTheme].errorHover;

  return (
    <BannerContainer
      theme={theme}
      $selectedTheme={selectedTheme}
      $maxWidth={maxWidth}
    >
      <BannerText>
        {text}
      </BannerText>

      <CTAContainer>
        {useAccept && (
          <CTAButton text={ctaText || 'Accept'} callback={() => callback?.(true)} />
        )}

        {useReject && (
          <CTAButton
            text={rejectText || 'Decline'}
            callback={() => callback?.(false)}
            color={error}
            hoverColor={errorHover}
          />
        )}
      </CTAContainer>
    </BannerContainer>
  );
});

export { FovBanner };