import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { getColor } from 'utils';

const PlusSVG = styled('svg')`
  color: ${props => getColor(props, 'onPrimary')};

  &:hover {
    color: ${props => props.hover || getColor(props, 'accent')};
  }
`;

const PlusOutlined = withTheme(props => {
  const { width = 24, height = 24, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <PlusSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill='currentColor'
        d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"
      ></path>
      <path
        fill='currentColor'
        d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8Z"
      ></path>
    </PlusSVG>
  );
});

export { PlusOutlined };
