import React, { useEffect, useRef, createRef } from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { MessagesContainer } from './styles';
import { SingleMessage } from './SingleMessage';
import { ModalMessageHolder } from './ModalMessageHolder';
import { exists } from 'utils';

const FovMessages = withTheme(props => {
  const { messages, removeMessage, theme, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const targets = useRef([]);

  useEffect(() => {
    if (messages?.length >= 1) {
      const messageTime = messages[0]?.config?.timer;

      if (messageTime) {
        const timer = setTimeout(() => {
          removeMessage(0);
          clearTimeout(timer);
        }, messageTime * 1000);

        return () => clearTimeout(timer);
      }
    }
  }, [ messages ]);

  const removeMessageByIndex = i => removeMessage?.(i);

  const buildModalMessage = (message, index) => {
    const { closeButton } = message.config;

    return (
      <ModalMessageHolder
        key={index}
        visible={true}
        showCloseButton={closeButton ?? true}
        onClose={() => closeButton ?? removeMessageByIndex(index)}
      >
        <SingleMessage
          $selectedTheme={selectedTheme}
          theme={theme}
          ref={targets.current[index]}
          message={message}
          onClose={() => removeMessageByIndex(index)}
          showCloseButton={false}
          inModal={true}
          {...rest}
        />
      </ModalMessageHolder>
    );
  };

  const buildNotifications = () => {
    const refs = messages?.map((msg, i) => targets.current[i] ?? createRef());

    targets.current = [ ...refs ];

    return messages?.map((msg, i) => {
      if (msg?.config?.showModal) {
        return buildModalMessage(msg, i);
      }

      return (
        <SingleMessage
          $selectedTheme={selectedTheme}
          theme={theme}
          key={i}
          ref={targets.current[i]}
          message={msg}
          showCloseButton={exists(msg?.config?.closeButton) ? msg.config.closeButton : true}
          onClose={() => removeMessageByIndex(i)}
          {...rest}
        />
      );
    });
  };

  return (
    <MessagesContainer>
      {buildNotifications()}
    </MessagesContainer>
  );
});

export { FovMessages };
