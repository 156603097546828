import React, { Fragment } from 'react';
import { withTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Toolbar,
  ToolbarTab,
  ToolbarIconContainer,
  ModelPanel,
  PanelTitle,
  CloseIcon,
  IconTitlePair,
  ToolbarContainer,
  ToolbarArea,
} from './ModelToolbarStyles';
import { VerticalSpacer, FovTooltip, FovDivider, HorizontalSpacer, DocNote } from 'components';
import { toolbarTabs, utilityTabs } from './toolbarTabs';
import { setActivePanel } from 'store/actions/app';
import _ from 'lodash';

const ModelToolbar = props => {
  const { theme } = props;
  const appStore = useSelector(state => state.app);
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedTheme = userDataStore.userPreferences.theme;
  const activePanel = appStore.activePanel;
  const main = toolbarTabs(theme, selectedTheme);
  const utilities = utilityTabs(theme, selectedTheme);
  const publishSettings = serializedData?.metadata?.publish;
  const modelLoaded = !_.isEmpty(publishSettings);
  const dispatch = useDispatch();

  const buildTabs = (tabs, separateLast) => {
    return tabs.map((section, index) => {
      const { name, icon, panelContent } = section;
      const isActive = activePanel === name;

      const handleClick = () => {
        if (isActive) {
          return dispatch(setActivePanel(null));
        }

        dispatch(setActivePanel(name));
      };

      return (
        <Fragment key={index}>
          {index !== tabs.length - 1 && (
            <VerticalSpacer size={5} />
          )}

          {index === tabs.length - 1 && separateLast && (
            <>
              <VerticalSpacer size={15} />
              <FovDivider />
              <VerticalSpacer size={15} />
            </>
          )}

          <FovTooltip
            position="left"
            top={10}
            right={52}
            content={name}
          >
            <ToolbarTab
              key={index}
              $selectedTheme={selectedTheme}
              active={isActive}
              onClick={handleClick}
            >
              <ToolbarIconContainer
                theme={theme}
                $selectedTheme={selectedTheme}
                deleteIcon={name.toLowerCase().includes('delete') || false}
              >
                {icon}
              </ToolbarIconContainer>
            </ToolbarTab>
          </FovTooltip>

          {isActive && (
            <ModelPanel theme={theme} $selectedTheme={selectedTheme}>
              <VerticalSpacer size={10} />

              <PanelTitle theme={theme} $selectedTheme={selectedTheme}>
                <IconTitlePair>
                  {icon} <HorizontalSpacer size={10} /> {name}
                </IconTitlePair>

                <CloseIcon
                  theme={theme}
                  $selectedTheme={selectedTheme}
                  onClick={() => dispatch(setActivePanel(null))} />
              </PanelTitle>

              <VerticalSpacer size={10} />

              {panelContent}
            </ModelPanel>
          )}
        </Fragment>
      );
    });
  };

  if (!modelLoaded) return null;

  return (
    <ToolbarContainer>
      <Toolbar $selectedTheme={selectedTheme}>
        <div>
          {buildTabs(main, true)}
          <ToolbarArea>
            <DocNote path={[ 'panelsToolbar', 'modelToolbar' ]} right={12} />
          </ToolbarArea>
        </div>

        <div>
          <ToolbarArea>
            <DocNote path={[ 'panelsToolbar', 'settingsToolbar' ]} right={12} bottom={7} />
          </ToolbarArea>

          {buildTabs(utilities)}
        </div>
      </Toolbar>
    </ToolbarContainer>
  );
};

export default withTheme(ModelToolbar);