import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const AppstoreSolid = withTheme(props => {
  const { width, height, isTitleIcon, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 35 35"
    >
      <path fill={color || theme.colors[selectedTheme][isTitleIcon ? 'accent' : 'onPrimary']}
        d="M27.7083 26.7084C27.7083 27.2607 27.2606 27.7084 26.7083 27.7084H19.9583C19.406 27.7084 18.9583 27.2607 18.9583 26.7084V19.9584C18.9583 19.4061 19.406 18.9584 19.9583 18.9584H26.7083C27.2606 18.9584 27.7083 19.4061 27.7083 19.9584V26.7084ZM16.0416 26.7084C16.0416 27.2607 15.5939 27.7084 15.0416 27.7084H8.29163C7.73934 27.7084 7.29163 27.2607 7.29163 26.7084V19.9584C7.29163 19.4061 7.73934 18.9584 8.29163 18.9584H15.0416C15.5939 18.9584 16.0416 19.4061 16.0416 19.9584V26.7084ZM27.7083 15.0417C27.7083 15.594 27.2606 16.0417 26.7083 16.0417H19.9583C19.406 16.0417 18.9583 15.594 18.9583 15.0417V8.29175C18.9583 7.73946 19.406 7.29175 19.9583 7.29175H26.7083C27.2606 7.29175 27.7083 7.73946 27.7083 8.29175V15.0417ZM16.0416 15.0417C16.0416 15.594 15.5939 16.0417 15.0416 16.0417H8.29163C7.73934 16.0417 7.29163 15.594 7.29163 15.0417V8.29175C7.29163 7.73946 7.73934 7.29175 8.29163 7.29175H15.0416C15.5939 7.29175 16.0416 7.73946 16.0416 8.29175V15.0417Z" />
    </svg>
  );
});

export { AppstoreSolid };
