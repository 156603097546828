import React, { useContext, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { FilterPanel } from 'components';
import { withTheme } from 'styled-components';
import { Wrapper, ModelsContainer } from './styles';
import { Models } from './Models/Models';
import { ModelsHeader } from './ModelsHeader';
import { getQuerySortingParameters } from './Models/utils';
import { ModelContext } from './ModelContext';


const ModelContent = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const { sortingOptions } = useContext(ModelContext);
  const sorting = sortingOptions.find(option => option === userDataStore.userPreferences?.sorting) || 'newest first';

  const [ searchParams, setSearchParams ] = useSearchParams();

  useLayoutEffect(() => {
    if (!searchParams.has('page')) searchParams.set('page', 1);
    if (!searchParams.has('per_page')) searchParams.set('per_page', 25);
    const order_by = getQuerySortingParameters(sorting);
    searchParams.set('order_by', order_by);

    setSearchParams(searchParams, { replace: true });
  }, []);

  return (
    <Wrapper theme={theme} $selectedTheme={selectedTheme}>
      <FilterPanel />

      <ModelsContainer>
        <ModelsHeader />
        <Models />
      </ModelsContainer>
    </Wrapper>
  );
});

export { ModelContent };