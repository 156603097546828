import { FovTypography } from 'components/typography';
import { getColor } from 'utils';
import styled from 'styled-components';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

export const RoadmapContainer = styled(FovTypography)`
  display: grid;
  grid-template-rows: auto repeat(5, 1fr);
  gap: 0.5rem;
  padding: 2rem;
  width: 100%;
  height: 100%;
  color: ${props => getColor(props, 'onPrimary')};
  overflow: auto;
`;

export const QuarterContainer = styled('div')`
  display: grid;
  gap: 1rem;
  grid-template-columns: 2.75rem 1fr;
  align-items: center;
  height: 100%;
  min-height: 12rem;
  width: 100%;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + 80;
  }};
  border-radius: 0 0.25rem 0.25rem 0;
`;

export const Quarter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 0.25rem 0 0 0.25rem;

  // Colours are outside of our theme and must be defined manually.
  background-color: ${props => props.color};

  padding: 0.5rem 1rem;
  font-size: 1.15rem;
  font-weight: 500;
  color: ${props => getColor(props, 'black') + '90'};
  user-select: none;
`;

export const QuarterItems = styled('div')`
  display: inline-flex;
  align-items: center;
  height: -webkit-fill-available;
  overflow-x: auto;
`;

export const RoadmapItem = styled('div')`
  min-width: 17rem;
  max-width: 19rem;
`;

export const ItemTitle = styled('div')`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 0 0 0.25rem 0;
`;

export const ItemType = styled('p')`
  width: fit-content;
  padding: 0.15rem 0.5rem;
  font-weight: 500;
  color: ${props => (
    getColor(props, props.$type?.toLowerCase() === 'internal' ? 'black' : 'onPrimary')
  )};
  background-color: ${props => (
    getColor(props, props.$type?.toLowerCase() === 'internal' ? 'warning' : 'mediumGrey')
  )};
  border-radius: 0.25rem;
  font-size: 0.85rem;
  user-select: none;
`;

export const ItemDescription = styled('p')`
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
`;

export const RoadmapSpacer = styled('div')`
  position: relative;
  height: 0.25rem;
  min-width: 10rem;
  border-radius: 0.25rem;
  background-color: ${props => getColor(props, props.$complete ? 'accent' : 'mediumGrey')};
  margin: 0 2rem;
`;

export const RoadmapDot = styled('div')`
  position: absolute;
  top: calc(50% - 0.5rem);
  left: calc(50% - 0.5rem);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${props => getColor(props, props.$complete ? 'accent' : 'mediumGrey')};
`;