import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const PeopleSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const PeopleOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <PeopleSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 16'
    >
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={theme.colors[selectedTheme].transparent}
        d='M13.2496 13.9231L13.3846 15H15.5385L16.0769 10.6923H17.1538V9.07692C17.1538 7.59005 15.9484 6.38461 14.4615 6.38461C13.8793 6.38461 13.3121 6.57343 12.8462 6.92307M4.90421 13.9231L4.76923 15H2.61538L2.07692 10.6923H1V9.07692C1 7.59005 2.20544 6.38461 3.69231 6.38461C4.27456 6.38461 4.84174 6.57343 5.30769 6.92307M14.4615 1C15.6512 1 16.6154 1.96421 16.6154 3.15385C16.6154 4.34349 15.6512 5.30769 14.4615 5.30769C13.2719 5.30769 12.3077 4.34349 12.3077 3.15385C12.3077 1.96421 13.2719 1 14.4615 1ZM3.69231 1C4.88195 1 5.84615 1.96421 5.84615 3.15385C5.84615 4.34349 4.88195 5.30769 3.69231 5.30769C2.50266 5.30769 1.53846 4.34349 1.53846 3.15385C1.53846 1.96421 2.50266 1 3.69231 1ZM9.07692 4.23077C9.96933 4.23077 10.6923 4.95375 10.6923 5.84616C10.6923 6.73857 9.96933 7.46154 9.07692 7.46154C8.18451 7.46154 7.46154 6.73857 7.46154 5.84616C7.46154 4.95375 8.18451 4.23077 9.07692 4.23077ZM9.07692 8.53845C7.59005 8.53845 6.38461 9.74389 6.38461 11.2308V11.7692H7.46154L8 15H10.1538L10.6923 11.7692H11.7692V11.2308C11.7692 9.74389 10.5638 8.53845 9.07692 8.53845Z'
      />
    </PeopleSVG>
  );
});

export { PeopleOutlined };