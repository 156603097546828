export default {
  MAX_TABLE_SIZE: 10,
  HEADER_HEIGHT: 72,
  FOOTER_HEIGHT: 60,
  THEME: {
    DARK: 'dark',
    LIGHT: 'light',
  },
  TOKEN_NAMES: {
    ARCHVISION_TOKEN: 'archvision_token',
    ARCHVISION_REFRESH_TOKEN: 'archvision_refresh_token',
  },
  FOV_USER_PREFERENCES: 'fovPreferences',
  FOV_PANEL_STATES: 'fovPanelStates',
  PHOTO_RATIOS: {
    DEFAULT: 1,
    SDTV: 4 / 3,
    HDTV: 16 / 9,
  },
  ORTHO: {
    LEFT: 'orthoLeft',
    RIGHT: 'orthoRight',
    TOP: 'orthoTop',
    BOTTOM: 'orthoBottom',
    FRONT: 'orthoFront',
    BACK: 'orthoBack',
  },
  CANVAS_UPDATE_TYPES: {
    MATERIAL: 'material',
    MESH: 'mesh',
    CAMERA: 'camera',
    ENVIRONMENT: 'environment',
    TEXTURE: 'texture',
    VIEWPORT: 'viewport',
    PUBLISH: 'publish',
    LIGHTING: 'lighting',
  },
  CANVAS_UPDATE_METHODS: {
    ADD: 'add',
    UPDATE: 'update',
    REMOVE: 'remove',
  },
};
