import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const RightOutlined = withTheme(props => {
  const { width = 32, height = 32, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const fill = color || theme.colors[selectedTheme].onPrimary;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-hidden='true'
      role='img'
      width={width}
      height={height}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'
    >
      <path
        fill={fill}
        d='M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1l-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z'
      ></path>
    </svg>
  );
});

export { RightOutlined };
