import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const UnitsSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const UnitsOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <UnitsSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 19 14'
    >
      <path fill='currentColor' d='M9.37301 0.72876C5.65089 0.72876 2.57721 3.59525 2.24735 7.23671C2.24268 7.28823 2.19949 7.32768 2.14776 7.32768H1.09985C0.547569 7.32768 0.0998535 7.7754 0.0998535 8.32768V12.5288C0.0998535 13.081 0.547568 13.5288 1.09985 13.5288H17.6462C18.1985 13.5288 18.6462 13.081 18.6462 12.5288V8.32697C18.6462 7.77454 18.1982 7.32676 17.6458 7.32697L16.6897 7.32732L16.6217 7.32749L16.6041 7.32758L16.6082 7.32708L16.6101 7.32684C16.6162 7.32585 16.6763 7.28886 16.6972 7.22768C16.6972 7.27897 16.6585 7.32155 16.6082 7.32708L16.6017 7.32758C16.5483 7.33 16.5024 7.28996 16.4976 7.23671C16.1677 3.59524 13.094 0.72876 9.37301 0.72876ZM15.2516 12.4586C15.1964 12.4586 15.1516 12.4138 15.1516 12.3586V9.91666C15.1516 9.62101 14.9111 9.38049 14.6154 9.38049C14.32 9.38049 14.0803 9.62079 14.0803 9.91666V12.3586C14.0803 12.4138 14.0356 12.4586 13.9803 12.4586H11.5974C11.5422 12.4586 11.4974 12.4138 11.4974 12.3586V10.743C11.4974 10.4476 11.2571 10.2079 10.9613 10.2079C10.6654 10.2079 10.4251 10.4476 10.4251 10.743V12.3586C10.4251 12.4138 10.3803 12.4586 10.3251 12.4586H7.8908C7.83557 12.4586 7.7908 12.4138 7.7908 12.3586V9.96257C7.7908 9.66714 7.5505 9.42749 7.25462 9.42749C6.95897 9.42749 6.71955 9.66692 6.71955 9.96257V12.3586C6.71955 12.4138 6.67477 12.4586 6.61954 12.4586H4.23663C4.1814 12.4586 4.13663 12.4138 4.13663 12.3586V10.8097C4.13663 10.5139 3.896 10.2735 3.60155 10.2735C3.3059 10.2735 3.06538 10.5141 3.06538 10.8097V12.3586C3.06538 12.4138 3.02061 12.4586 2.96538 12.4586H1.27111C1.21588 12.4586 1.17111 12.4138 1.17111 12.3586V8.49894C1.17111 8.44371 1.21588 8.39894 1.27111 8.39894H3.28874L3.29059 7.86462C3.30171 4.52047 6.02991 1.80001 9.37411 1.80001C12.7183 1.80001 15.4476 4.52044 15.4576 7.86465L15.4585 8.39894H17.476C17.5312 8.39894 17.576 8.44371 17.576 8.49894V12.3586C17.576 12.4138 17.5312 12.4586 17.476 12.4586H15.2516Z' />
      <path fill='currentColor' d='M9.46147 3.67188C7.58356 3.67188 6.05762 5.2 6.05762 7.07791C6.05762 7.39908 6.31797 7.65943 6.63914 7.65943H12.2849C12.6061 7.65943 12.8664 7.39908 12.8664 7.07791C12.8653 5.2 11.3394 3.67188 9.46147 3.67188ZM7.29717 6.49421C7.55295 5.53995 8.42741 4.83491 9.46037 4.83491C10.4933 4.83491 11.3667 5.53995 11.6236 6.49311H7.29717V6.49421Z' />
    </UnitsSVG>
  );
});

export { UnitsOutlined };