import React, { useMemo, useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { VerticalSpacer, LinkedIcon } from 'components';
import { FovInput } from 'components/form';
import { updateCanvas, cleanNumber } from 'utils';
import constants from'constants/index';
import styled, { withTheme } from 'styled-components';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const AxisContainer = styled('div')`
  display: grid;
  gap: 0 0.5rem;
  align-items: center;
  grid-template-columns: repeat(5, auto);
  width: -webkit-fill-available;
`;

const AxisInput = styled(FovInput)`
  width: 100%;
  min-width: 4rem;
`;

const AxisWrapper = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const AxisLabel = styled('div')`
  position: absolute;
  top: -1.25rem;
  left: 0;
  font-size: 0.85rem;
`;

const Scale = withTheme(props => {
  const { theme } = props;
  const [ scaleLinking, setScaleLinking ] = useState(true);

  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const selectedMeshes = serializedData?.metadata.selectedMeshes;
  const selectedMeshId = selectedMeshes?.[0]?.id;
  const meshChangeTracking = serializedData?.metadata.meshChangeTracking;
  const changedMesh = meshChangeTracking?.find(mesh => mesh.meshId === selectedMeshId);
  const isGlobalTransform = selectedMeshes.length === 0;
  const isSingleSelection = selectedMeshes.length === 1;
  const isFieldDisabled = !isGlobalTransform && !isSingleSelection;
  const globalTransforms = serializedData?.metadata.globalTransforms;

  const [ xScale, setXScale ] = useState(cleanNumber(isGlobalTransform ? globalTransforms?.sx : changedMesh?.sx));
  const [ yScale, setYScale ] = useState(cleanNumber(isGlobalTransform ? globalTransforms?.sy : changedMesh?.sy));
  const [ zScale, setZScale ] = useState(cleanNumber(isGlobalTransform ? globalTransforms?.sz : changedMesh?.sz));

  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const mediumGrey = theme.colors[selectedTheme].mediumGrey;
  const accent = theme.colors[selectedTheme].accent;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MESH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  useEffect(() => {
    setXScale(cleanNumber(isGlobalTransform ? globalTransforms?.sx : changedMesh?.sx));
    setYScale(cleanNumber(isGlobalTransform ? globalTransforms?.sy : changedMesh?.sy));
    setZScale(cleanNumber(isGlobalTransform ? globalTransforms?.sz : changedMesh?.sz));
  }, [ changedMesh, globalTransforms ]);

  const handleChange = (key, value) => {
    const inboundData = {
      id: selectedMeshId,
      globalTransform: isGlobalTransform,
      transforms: {
        sx: scaleLinking ? +(value) : xScale,
        sy: scaleLinking ? +(value) : yScale,
        sz: scaleLinking ? +(value) : zScale,
      },
    };

    if (!scaleLinking) {
      inboundData.transforms[key] = +(value);
    }

    if (Object.values(inboundData.transforms).every(val => val !== 0)) {
      updateCanvas(updateArgs, inboundData);
    }
  };

  const handleFieldChange = (value, axis, isBlur, existingValue) => {
    const setNewScales = () => {
      if (axis === 'sx') setXScale(value);
      if (axis === 'sy') setYScale(value);
      if (axis === 'sz') setZScale(value);

      if (scaleLinking) {
        const fallbackX = isGlobalTransform ? globalTransforms.sx : changedMesh?.sx || 1;
        const fallbackY = isGlobalTransform ? globalTransforms.sy : changedMesh?.sy || 1;
        const fallbackZ = isGlobalTransform ? globalTransforms.sz : changedMesh?.sz || 1;

        switch(axis) {
          case 'sx':
            setYScale(isBlur ? fallbackY : value);
            setZScale(isBlur ? fallbackZ : value);
            break;

          case 'sy':
            setXScale(isBlur ? fallbackX : value);
            setZScale(isBlur ? fallbackZ : value);
            break;

          case 'sz':
            setXScale(isBlur ? fallbackX : value);
            setYScale(isBlur ? fallbackY : value);
            break;

          default:
            break;
        }
      }
    };

    if (isBlur) {
      if (+(existingValue) === 0) setNewScales();
      return;
    }

    setNewScales();
    handleChange(axis, value);
  };

  return (
    <>
      <VerticalSpacer size={15} />

      <AxisContainer>
        <AxisWrapper>
          <AxisLabel>X</AxisLabel>

          <AxisInput
            type='number'
            value={xScale}
            step={0.1}
            fallback={isGlobalTransform ? globalTransforms?.sx : changedMesh?.sx || 1}
            width={64}
            disabled={isFieldDisabled}
            onChange={e => handleFieldChange(e.target.value, 'sx')}
            blurOverride={() => {
              const isBlur = true;
              const newVal = isGlobalTransform ? globalTransforms?.sx : changedMesh?.sx || 1;
              handleFieldChange(newVal, 'sx', isBlur, xScale);
            }}
          />
        </AxisWrapper>

        <div role='presentation' onClick={() => !isFieldDisabled && setScaleLinking(!scaleLinking)}>
          <LinkedIcon
            width={16}
            height={16}
            pointer={true}
            color={scaleLinking ? accent : mediumGrey}
            disabled={isFieldDisabled}
          />
        </div>

        <AxisWrapper>
          <AxisLabel>Y</AxisLabel>

          <AxisInput
            type='number'
            value={yScale}
            step={0.1}
            fallback={isGlobalTransform ? globalTransforms?.sy : changedMesh?.sy || 1}
            width={64}
            disabled={isFieldDisabled}
            onChange={e => handleFieldChange(e.target.value, 'sy')}
            blurOverride={() => {
              const isBlur = true;
              const newVal = isGlobalTransform ? globalTransforms?.sy : changedMesh?.sy || 1;
              handleFieldChange(newVal, 'sy', isBlur, yScale);
            }}
          />
        </AxisWrapper>

        <div role='presentation' onClick={() => !isFieldDisabled && setScaleLinking(!scaleLinking)}>
          <LinkedIcon
            width={16}
            height={16}
            pointer={true}
            color={scaleLinking ? accent : mediumGrey}
            disabled={isFieldDisabled}
          />
        </div>

        <AxisWrapper>
          <AxisLabel>Z</AxisLabel>

          <AxisInput
            type='number'
            value={zScale}
            step={0.1}
            fallback={isGlobalTransform ? globalTransforms?.sz : changedMesh?.sz || 1}
            width={64}
            disabled={isFieldDisabled}
            onChange={e => handleFieldChange(e.target.value, 'sz')}
            blurOverride={() => {
              const isBlur = true;
              const newVal = isGlobalTransform ? globalTransforms?.sz : changedMesh?.sz || 1;
              handleFieldChange(newVal, 'sz', isBlur, zScale);
            }}
          />
        </AxisWrapper>
      </AxisContainer>
    </>
  );
});

export default Scale;