import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const TreeSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const TreeOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <TreeSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 14 19'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M6.79758 15.6711V18.0001M12.0114 15.671C12.3328 15.6718 12.5937 15.4117 12.5944 15.0911C12.5944 14.9607 12.5517 14.8341 12.471 14.7317L9.12659 10.4308H10.2911C10.6133 10.43 10.8733 10.1684 10.8725 9.84624C10.8725 9.72125 10.8314 9.59937 10.7569 9.49922L7.9621 5.77285H9.20422C9.52562 5.77285 9.78646 5.51201 9.78646 5.19061C9.78646 5.07571 9.75231 4.96314 9.68865 4.86766L7.28203 1.25929C7.10348 0.991455 6.74093 0.919257 6.4731 1.09859C6.40944 1.14129 6.35432 1.19563 6.3124 1.25929L3.90579 4.86766C3.72723 5.13549 3.79943 5.49648 4.06726 5.67503C4.16353 5.73947 4.2761 5.77285 4.39177 5.77285H5.63389L2.83911 9.49922C2.64581 9.75619 2.6986 10.1211 2.95556 10.3144C3.05649 10.3897 3.17915 10.4308 3.30491 10.4308H4.4694L1.12343 14.7317C0.925468 14.9847 0.970495 15.3504 1.22358 15.5476C1.32605 15.6283 1.45259 15.671 1.58302 15.671H12.0114Z'
      />
    </TreeSVG>
  );
});

export { TreeOutlined };