import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';
import { getColor } from 'utils';
import { CheckmarkOutlined } from 'components/icons';
import { FovTypography } from 'components/typography';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

const CheckBoxContainer = styled('label')`
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  display: flex;
  align-items: center;
  color: ${props => getColor(props, 'onPrimary')};
  margin-bottom: 0rem;

  &:hover {
    color: ${props => props.inheritHover ? 'inherit' : getColor(props, 'onPrimary')};
  }
`;

const Input = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0.0625rem;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 0.0625rem;
`;

const CheckboxBox = styled('div')`
  height: 0.875rem;
  width: 0.875rem;
  border: 0.0625rem solid ${props => getColor(props, 'onPrimary')};
  margin-right: 0.75rem;
  border-radius: 0.19rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.$disabled && css`
      background: ${props => {
        const lightTheme = props.$selectedTheme === light;
        return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
      }};
      border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
    `}
`;

const Label = styled('div')`
  ${props => props.fullWidth && css`width: 100%;`};
`;

const FovCheckbox = withTheme(props => {
  const {
    label,
    checked,
    theme,
    disabled,
    onChange,
    inheritHover,
    fullWidth,
    ...rest
  } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const disabledColor =
    selectedTheme === light
      ? theme.colors[selectedTheme].darkGrey
      : theme.colors[selectedTheme].lightGrey;

  const handleChange = e => {
    const value = e.target.checked;
    !disabled && onChange?.(value);
  };

  return (
    <CheckBoxContainer
      theme={theme}
      $selectedTheme={selectedTheme}
      inheritHover={inheritHover}
      $disabled={disabled}
    >
      <Input
        type="checkbox"
        onChange={handleChange}
        checked={checked}
        {...rest}
      />
      <CheckboxBox
        theme={theme}
        $selectedTheme={selectedTheme}
        $disabled={disabled}
      >
        {checked && <CheckmarkOutlined color={disabled && disabledColor} />}
      </CheckboxBox>

      <Label fullWidth={fullWidth}>
        <FovTypography>
          {label}
        </FovTypography>
      </Label>
    </CheckBoxContainer>
  );
});

export { FovCheckbox };
