import { FovInput } from 'components/form';
import { getColor } from 'utils';
import styled from 'styled-components';

const tagColor = (props, target, fallback) => props.$selected ? target : fallback;

export const Tag = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  font-size: 0.9rem;
  padding: 0.125rem 0.5rem;
  margin: 0.25rem;
  border-radius: 0.3125rem;
  white-space: nowrap;
  user-select: none;
  cursor: ${props => props.$usedAsFilter ? 'pointer' : 'default'};

  color: ${props => (
      getColor(props, tagColor(props, 'onAccent', 'onPrimary'))
  )};

  background-color: ${props => (
    getColor(props, tagColor(props, 'accent', 'lightGrey'))
  )};

  &:hover {
    background-color: ${props => (
      getColor(props, tagColor(props, 'accentHover', 'mediumGrey'))
    )}
  };
`;

export const NewTagButton = styled(Tag)`
  cursor: pointer;
  user-select: none;
  &:hover {
    box-sizing: border-box;
    background-color: ${props => getColor(props, 'mediumGrey')};
  }
`;
export const DeleteTagButton = styled('div')`
  margin-left: 0.25rem;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const TagInput = styled(FovInput)`
  min-height: 1.7rem;
  margin: 0.25rem;
  font-size: 0.9rem;
`;

export const TagsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-flow: wrap;
  align-items: center;
  min-height: 2.625rem;
`;
