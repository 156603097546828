import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerticalSpacer } from 'components';
import { FovInput } from 'components/form';
import { updateCanvas, cleanNumber } from 'utils';
import constants from'constants/index';
import styled from 'styled-components';

const AxisContainer = styled('div')`
  display: grid;
  gap: 0 0.5rem;
  align-items: center;
  grid-template-columns: repeat(3, auto);
  width: -webkit-fill-available;
`;

const AxisInput = styled(FovInput)`
  width: 100%;
  min-width: 4rem;
`;

const AxisWrapper = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const AxisLabel = styled('div')`
  position: absolute;
  top: -1.25rem;
  left: 0;
  font-size: 0.85rem;
`;

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Move = () => {
  const dispatch = useDispatch();
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const selectedMeshes = serializedData?.metadata.selectedMeshes;
  const selectedMesh = selectedMeshes?.[0];
  const selectedMeshId = selectedMesh?.id;
  const meshChangeTracking = serializedData?.metadata.meshChangeTracking;
  const changedMesh = meshChangeTracking?.find(mesh => mesh.meshId === selectedMeshId);
  const globalTransforms = serializedData?.metadata.globalTransforms;
  const isGlobalTransform = selectedMeshes.length === 0;
  const isSingleSelection = selectedMeshes.length === 1;
  const isFieldDisabled = !isGlobalTransform && !isSingleSelection;
  const xPosition = cleanNumber(isGlobalTransform ? globalTransforms?.tx : changedMesh?.tx);
  const yPosition = cleanNumber(isGlobalTransform ? globalTransforms?.ty : changedMesh?.ty);
  const zPosition = cleanNumber(isGlobalTransform ? globalTransforms?.tz : changedMesh?.tz);

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MESH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const handleChange = (key, value) => {
    const inboundData = {
      id: selectedMeshId,
      globalTransform: isGlobalTransform,
      transforms: {
        tx: xPosition,
        ty: yPosition,
        tz: zPosition,
        [key]: +(value),
      },
    };

    updateCanvas(updateArgs, inboundData);
  };

  return (
    <>
      <VerticalSpacer size={15} />

      <AxisContainer>
        <AxisWrapper>
          <AxisLabel>X</AxisLabel>

          <AxisInput
            type={'number'}
            value={xPosition}
            disabled={isFieldDisabled}
            onChange={e => handleChange('tx', e.target.value)}
          />
        </AxisWrapper>

        <AxisWrapper>
          <AxisLabel>Y</AxisLabel>

          <AxisInput
            type={'number'}
            value={yPosition}
            disabled={isFieldDisabled}
            onChange={e => handleChange('ty', e.target.value)}
          />
        </AxisWrapper>

        <AxisWrapper>
          <AxisLabel>Z</AxisLabel>

          <AxisInput
            type={'number'}
            value={zPosition}
            disabled={isFieldDisabled}
            onChange={e => handleChange('tz', e.target.value)}
          />
        </AxisWrapper>
      </AxisContainer>
    </>
  );
};

export default Move;
