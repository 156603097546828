import { getColor } from 'utils';
import styled from 'styled-components';

export const FormDiv = styled('div')`
  width: 100%;
`;

export const Label = styled('label')`
  width: 100%;
`;

export const ToggleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
`;

export const TagsContainer = styled('div')`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: ${props => (props.$opened ? '0.25rem 0.25rem 0 0;' : '0.25rem')};
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  padding: 0.25rem;
`;

export const HeightContainer = styled('div')`
  display: grid;
  gap: 0 1rem;
  grid-template-columns: 1fr 6rem;
  width: 100%;
`;

export const AvailLink = styled('span')`
  color: ${props => getColor(props, 'accent')};
  cursor: pointer;

  &:hover {
    color: ${props => getColor(props, 'accentHover')};
  }
`;

export const AvailChannelsContainer = styled('div')`
  position: relative;
`;

export const LabeledSliderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 1.25rem 0;
`;

export const OptimizationControlContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const OptimizationTogglesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;