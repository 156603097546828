import React, { useEffect, useCallback } from 'react';
import AppNavigator from './navigation/AppNavigator';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { removeMessage } from 'store/actions/app';
import { setUserPreferencesFromStore } from 'store/actions/userData';
import { FovMessages } from 'components/notifications';
import theme from './theme';

const App = () => {
  const dispatch = useDispatch();
  const appStore = useSelector(state => state.app);

  const removeMessageById = useCallback(
    payload => dispatch(removeMessage(payload)),
    [ dispatch ],
  );

  useEffect(() => {
    dispatch(setUserPreferencesFromStore());
  }, [ setUserPreferencesFromStore ]);

  return (
    <ThemeProvider theme={theme}>
      <AppNavigator />

      <FovMessages
        messages={appStore.messages}
        removeMessage={removeMessageById}
      />
    </ThemeProvider>
  );
};

export default App;