import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FovTypography, ThreeDRpcIcon, TwoDRpcIcon, DownOutlined, VerticalSpacer } from 'components';
import { MODEL_TYPES } from 'utils/enums';
import styled, { withTheme } from 'styled-components';
import default2DRpc from 'assets/Ui/default2DModel.png';
import default3DRpc from 'assets/Ui/default3DModel.png';
import constants from 'constants/index';
import _ from 'lodash';
import { getColor } from 'utils';

const light = constants.THEME.LIGHT;

const SampleContainer = styled('div')`
  position: relative;
`;

const HoverPrompt = styled('div')`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7rem;
  border-radius: 0.25rem;
  opacity: 0.8;
  color: ${props => getColor(props, 'onAccent')};
  background-color: ${props => getColor(props, 'accent')};
`;

const SampleModel = styled('div')`
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  grid-template-rows: 1fr 1.5rem;
  grid-template-areas:
    'icon preview .'
    'title title title';

  width: 7rem;
  height: 7rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  box-shadow: 0 0.1rem 0.25rem ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'mediumGrey' : 'black');
  }};
`;

const Icon = styled('div')`
  grid-area: icon;
`;

const Preview = styled('img')`
  grid-area: preview;
  margin: auto;
  width: ${props => props.type === MODEL_TYPES.twoD ? '2.5rem' : '4.5rem'};
  height: auto;
`;

const Title = styled(FovTypography)`
  grid-area: title;
  font-size: 0.8rem;
  text-align: center;
`;

const Text = styled(FovTypography)`
  color: ${props => getColor(props, 'accent')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

const Caret = styled('div')`
  transform: rotate(-90deg);
`;

const SampleCard = withTheme(props => {
  const { title, type, image, learnText, guid, theme, learnLink, ...rest } = props;
  const [ showPrompt, setShowPrompt ] = useState(false);
  const navigate = useNavigate();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const togglePrompt = newVal => {
    if (newVal !== showPrompt) {
      setShowPrompt(newVal);
    }
  };

  const handleClick = () => {
    navigate(`/models/${guid}`);
  };

  return (
    <SampleContainer>
      <SampleModel
        theme={theme}
        $selectedTheme={selectedTheme}
        onMouseEnter={() => togglePrompt(true)}
        onMouseLeave={() => togglePrompt(false)}
        onClick={handleClick}
        {...rest}
      >
        {showPrompt && (
          <HoverPrompt
            theme={theme}
            $selectedTheme={selectedTheme}
          >
            OPEN
          </HoverPrompt>
        )}

        <Icon>
          {type === MODEL_TYPES.twoD
            ? <TwoDRpcIcon />
            : <ThreeDRpcIcon />
          }
        </Icon>

        <Preview
          type={type}
          src={image || (type === MODEL_TYPES.twoD ? default2DRpc : default3DRpc)}
          alt='Type preview'
        />
        <Title>{title}</Title>
      </SampleModel>

      <VerticalSpacer size={20} />

      {learnText && (
        <Text
          theme={theme}
          $selectedTheme={selectedTheme}
          onClick={_.noop}
        >
          <a href={learnLink} rel='noreferrer' target='_blank'>
            {learnText}
          </a>

          <Caret>
            <DownOutlined
              width={14}
              height={14}
              color={theme.colors[selectedTheme].accent}
            />
          </Caret>
        </Text>
      )}
    </SampleContainer>
  );
});

export default SampleCard;