import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const DresserSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const DresserOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <DresserSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 14'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M1 3.39993H17M1 3.39993V6.59993M1 3.39993V1.4C1 1.1792 1.15929 1 1.35556 1H16.6444C16.8407 1 17 1.1792 17 1.4V3.39993M17 3.39993V6.59993M17 6.59993H1M17 6.59993V9.80007M1 6.59993V9.80007M17 9.80007H1M17 9.80007V12.6443C17 12.8406 16.6018 12.9999 16.1111 12.9999H1.88889C1.39822 12.9999 1 12.8406 1 12.6443V9.80007M9 3.39993V12.9999'
      />
    </DresserSVG>
  );
});

export { DresserOutlined };