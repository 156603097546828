import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const BedSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const BedOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <BedSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 15'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M17 11.6666V8.2888C17 7.50302 16.3636 6.86658 15.5778 6.86658H2.42222C1.63644 6.86658 1 7.50302 1 8.2888V11.6666M17 11.6666H1M17 11.6666V13.8001M1 11.6666V13.8001M15.4 6.86667V2.06667C15.4 1.47787 14.9221 1 14.3333 1H3.66663C3.07783 1 2.59996 1.47787 2.59996 2.06667V6.86667M6.51121 4.20007H11.489C11.8815 4.20007 12.2001 4.51865 12.2001 4.91118V6.86674H5.80009V4.91118C5.80009 4.51865 6.11867 4.20007 6.51121 4.20007Z'
      />
    </BedSVG>
  );
});

export { BedOutlined };