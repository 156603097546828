import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { getColor } from 'utils';

const ErrorSVG = styled('svg')`
  color: ${props => props.color || getColor(props, 'error')};
`;

const ErrorOutlined = withTheme(props => {
  const { theme, width, height, color } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <ErrorSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'
    >
      <path
        fill='currentColor'
        d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 0 1 0-96a48.01 48.01 0 0 1 0 96z'
      />
    </ErrorSVG>
  );
});

export { ErrorOutlined };
