import styled from 'styled-components';

export const FooterComponent = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #000000;
  height: 3.75rem;
  padding: 1rem 2rem 1rem 0;
`;

export const LogoContainer = styled('div')`
  width: 13.125rem;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2.5rem;
`;

export const SocialIcons = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const IconContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const UserRoleContainer = styled('div')`
  width: 9rem;
  position: absolute;
  top: -0.5rem;
  right: 12rem;
`;