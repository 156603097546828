import styled, { css } from 'styled-components';
import { FovTypography, FovSlider, FovButton } from 'components';
import { FovSelect } from 'components/form';
import { getColor } from 'utils';
import button_shapes_2 from 'assets/Ui/button_shapes_2.svg';
import constants from 'constants/index';

const { HEADER_HEIGHT, FOOTER_HEIGHT } = constants;
const offset = HEADER_HEIGHT + FOOTER_HEIGHT;
const light = constants.THEME.LIGHT;

export const Wrapper = styled(FovTypography)`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  height: 100%;
  ${() => css`max-height: calc(100vh - ${offset / 16}rem);`}
`;

export const ModelsContainer = styled('div')`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1rem;
  padding: 0 1rem;
`;

export const HeaderArea = styled('div')`
  display: grid;
  gap: 2rem 1rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'spacer spacer'
    'title explore'
    'search viewOptions';

  width: 100%;

  h3 {
    margin: 0;
  }
`;

export const Title = styled('div')`
  display: inline-flex;
  align-items: center;
  grid-area: title;
`;

export const ActionArea = styled('div')`
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  grid-area: explore;
`;

export const CTA = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 0.25rem;
  height: 2.55rem;
  width: 10.75rem;
  font-size: 0.95rem;
  font-weight: 600;
  background-color: black;
  cursor: pointer;
  background-image: url('${button_shapes_2}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: ${props => getColor(props, 'white')};
  user-select: none;

  grid-area: explore;

  &:hover {
    filter: brightness(1.15);
  }
`;

export const Search = styled('div')`
  grid-area: search;
`;

export const RefreshFiles = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
`;

export const ViewContainer = styled('div')`
  display: inline-flex;
  gap: 1rem;
  grid-area: viewOptions;
`;

export const SortingOptions = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 16rem;
`;

export const DisplayOptions = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const DisplayButtons = styled('div')`
  display: inline-flex;
  gap: 1rem;
`;

export const ViewButton = styled(FovButton)`
  background-color: ${props =>
    getColor(props, props.isActive ? 'accent' : 'primary')};
  border: ${props =>
    props.isActive
      ? 'none'
      : '0.0625rem solid ' + getColor(props, 'mediumGrey')};
  min-width: unset;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;

  &:hover {
    background-color: ${props =>
      getColor(props, props.isActive ? 'accent' : 'lightGrey')};
  }
`;

export const CreatorList = styled('div')`
  font-size: 0.95rem;
  color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'lightGrey');
  }};
`;

export const SortContainer = styled('div')`
  grid-area: sorting;
  align-items: center;
  margin-right: 1rem;
  width: 100%;
`;

export const SortSelect = styled(FovSelect)`
  min-width: 15.0625rem;
  width: 100%;
`;

export const Slider = styled(FovSlider)`
  margin: 0.625rem 0 0.625rem 0;
`;

export const SliderContainer = styled('div')`
  margin-left: auto;
  padding-right: 0.5rem;
`;

export const SliderMenu = styled('div')`
  width: 100%;
  grid-area: slider;
`;

export const PinContainer = styled('div')`
  margin-left: 0.25rem;
`;