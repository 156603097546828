import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const FilterIcon = withTheme(props => {
  const { color, width, height, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

    return (
      <svg
        width={width || 24}
        height={height || 24}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        fill={color || theme.colors[selectedTheme].onPrimary}>
       <path d="M7 6h10l-5.01 6.3L7 6zm-2.75-.39C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39A.998.998 0 0 0 18.95 4H5.04c-.83 0-1.3.95-.79 1.61z"/>
      </svg>
    );
  },
);

export { FilterIcon };