import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const LeftOutlined = withTheme(props => {
  const { width = 32, height = 32, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const fill = color || theme.colors[selectedTheme].onPrimary;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-hidden='true'
      role='img'
      width={width}
      height={height}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'
    >
      <path
        fill={fill}
        d='M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281l360-281.1c3.8-3 6.1-7.7 6.1-12.6z'
      ></path>
    </svg>
  );
});

export { LeftOutlined };
