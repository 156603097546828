import styled, { css } from 'styled-components';
import { FovTypography } from 'components/typography';
import { CloseOutlined } from 'components/icons/CloseOutlined';
import { FovButton } from 'components/generic';
import { hexToRgba } from 'theme';
import constants from 'constants/index';
import { getColor } from 'utils';

const light = constants.THEME.LIGHT;

const getMessageTable = props => {
  if (props.$noIcon && !props.$showCloseButton) {
    return css`
      grid-template-columns: 1fr;
    `;
  }

  if (props.$noIcon) {
    return css`
      grid-template-columns: 1fr 1rem;
    `;
  }

  return css`
    grid-template-columns: 3.5rem 1fr 1rem;
  `;
};

export const MessagesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 3rem;
  right: 0;
  z-index: 200;
  margin: 1rem;
`;

export const Message = styled(FovTypography)`
  display: grid;
  gap: 0.75rem;

  ${props => getMessageTable(props)}

  padding: ${props => (props.$shownAsModal ? '1rem 2rem 1rem 1rem' : '0.75rem')};
  margin: 0.5rem;
  height: fit-content;
  align-items: center;
  min-height: ${props => (props.$shownAsModal ? '6rem' : '4.5rem')};
  min-width: 19.5rem;
  max-width: 23rem;

  position: relative;
  right: 0;
  z-index: 300;

  border-radius: 0.35rem;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    const standardBG = lightTheme ? 'white' : 'darkGrey';
    return getColor(props, props.$shownAsModal ? 'primary' : standardBG);
  }};
  box-shadow: ${props => {
    const lightTheme = props.$selectedTheme === light;

    if (props.$shownAsModal) {
      return 'none';
    }

    return `0 0 0.25rem ${getColor(props, lightTheme ? 'darkGrey' : 'black')}`;
  }};
  color: ${props => getColor(props, 'onPrimary')};

  font-weight: 500;
  font-size: ${props => !props.$shownAsModal && '0.875rem'};
  text-align: center;
  user-select: none;
`;

export const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const MessageText = styled('div')`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CloseButtonContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 1.15rem;
`;

export const CloseIcon = styled(CloseOutlined)`
  padding: 0;
  cursor: pointer;
`;

export const ModalBG = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: ${props => hexToRgba(getColor(props, 'black'), 0.8)};
`;

export const ModalMessageContainer = styled('div')`
  position: fixed;
  top: 50%;
  right: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  min-width: 24rem;
  min-height: 5rem;
  width: max-content;
  height: max-content;
  padding: 1rem 0;
`;

export const MultiStepContainer = styled('div')`
  display: inline-flex;
  gap:0.25rem;
`;

export const Dots = styled('div')`
  display: inline-flex;
`;

export const MultiStepDot = styled('div')`
  width: 0.5rem;
  height: 0.5rem;
  content: '';
  border-radius: 50%;
  background-color: ${props => getColor(props, props.$fill ? 'accent' : 'mediumGrey')};
`;

export const ModalCloseIcon = styled(CloseIcon)`
  position: fixed;
  top: 2rem;
  right: 1.5rem;
  z-index: 300;
`;

export const ConfirmationHolder = styled('div')`
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
  grid-template-areas:
    'label label'
    'confirm cancel';
  padding: 1.5rem;
  margin: 0.5rem;
  min-width: 25rem;
  max-width: 30rem;
  max-height: 16rem;

  border-radius: 0.35rem;
  background-color: ${props => getColor(props, 'primary')};
`;

export const ConfirmationButton = styled(FovButton)`
  grid-area: confirm;
`;

export const CancelButton = styled(FovButton)`
  background: ${props => getColor(props, 'primary')};
  color: ${props => getColor(props, 'darkGrey')};
  grid-area: cancel;
`;

export const TextHolder = styled('div')`
    grid-area: label;
    padding: 1rem;
    text-align: center;
`;
