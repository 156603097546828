import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const getColor = (props, key) => {
  const themeColors = props.theme.colors[props.$selectedTheme];
  return themeColors[key];
};

const CreditsSVG = styled('svg')`
  color: ${props => props.color || getColor(props, 'onPrimary')};
`;

const CreditsOutlined = withTheme(props => {
  const { width, height, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CreditsSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
    >
      <path fill='currentColor' d="M12 5C7.031 5 2 6.546 2 9.5S7.031 14 12 14c4.97 0 10-1.546 10-4.5S16.97 5 12 5zm-5 9.938v3c1.237.299 2.605.482 4 .541v-3a21.166 21.166 0 0 1-4-.541zm6 .54v3a20.994 20.994 0 0 0 4-.541v-3a20.994 20.994 0 0 1-4 .541zm6-1.181v3c1.801-.755 3-1.857 3-3.297v-3c0 1.44-1.199 2.542-3 3.297zm-14 3v-3C3.2 13.542 2 12.439 2 11v3c0 1.439 1.2 2.542 3 3.297z" />
    </CreditsSVG>
  );
});

export { CreditsOutlined };