import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css, withTheme } from 'styled-components';
import constants from 'constants/index';
import { getColor } from 'utils';

const light = constants.THEME.LIGHT;
const padding = 0.35;

const TooltipContainer = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

const tooltipPositions = props => {
  const { top, right, bottom, left } = props;
  const positions = [];

  const addPosition = (name, value, opposite) => {
    if (value && !positions.includes(opposite)) {
      const styleString = css`${name}: ${(value ?? 0) / 16}rem;`;
      positions.push(styleString);
    }
  };

  addPosition('top', top, 'bottom');
  addPosition('right', right, 'left');
  addPosition('bottom', bottom, 'top');
  addPosition('left', left, 'right');

  return positions.flat();
};

const InnerContent = styled('div')`
  display: flex;
  position: absolute;
  ${props => tooltipPositions(props)}
  padding: ${padding}rem 1rem;
  border-radius: 0.15rem;
  background-color: ${props => getColor(props, props.$selectedTheme === light ? 'primary' : 'darkGrey')};
  color: ${props => getColor(props, 'onPrimary')};
  width: max-content;
  height: fit-content;
  filter: drop-shadow(0 0.1rem 0.25rem ${props => (
    getColor(props, props.$selectedTheme === light ? 'darkGrey' : 'black')
  )});
  user-select: none;
`;

const arrowPosition = position => {
  const offset = 0.25 * -1;

  switch(position) {
    case 'right':
      return css`
        left: ${offset}rem;
        top: calc(50% - ${padding}rem)`;

    case 'top':
      return css`
        bottom: ${offset}rem;
        right: calc(50% - ${padding}rem)`;

    case 'left':
      return css`
        right: ${offset}rem;
        top: calc(50% - ${padding}rem)`;

    default:
      return css`
        top: ${offset}rem;
        right: calc(50% - ${padding}rem)`;
  }
};

const Arrow = styled('div')`
  position: absolute;
  ${props => arrowPosition(props.position)};
  width: 0.55rem;
  height: 0.55rem;
  transform: rotate(45deg);
  background-color: ${props => getColor(props, props.$selectedTheme === light ? 'primary' : 'darkGrey')};
`;

const FovTooltip = withTheme(props => {
  const [ active, setActive ] =  useState(false);
  const {
    theme,
    position,
    content,
    children,
    top,
    right,
    bottom,
    left,
    ...rest
  } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <TooltipContainer
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      {...rest}
    >
      {children}

      {active && content && (
        <InnerContent
          theme={theme}
          $selectedTheme={selectedTheme}
          top={top}
          right={right}
          bottom={bottom}
          left={left}
        >
          {content}
          <Arrow theme={theme} $selectedTheme={selectedTheme} position={position} />
        </InnerContent>
      )}
    </TooltipContainer>
  );
});

export { FovTooltip };