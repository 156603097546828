export const SUPPORTED_EXTENSIONS = [
  { name: 'RPC', value: 'RPC' },
  { name: 'PNG', value: 'PNG' },
  { name: 'FBX', value: 'FBX' },
  { name: 'OBJ', value: 'OBJ' },
  // TODO: RPC-330, Renable when RPC API is no longer mishandling DAE.
  // Also add back to: onBoarding.js description, dynamicDocumentation.js > createMenu
  // eslint-disable-next-line
  // { name: 'DAE', value: 'DAE' },
  { name: 'ZIP', value: 'ZIP' },
  { name: 'GLB', value: 'GLB' },
  { name: 'GLTF + BIN', value: 'GLTF', required: [ 'BIN' ] },
  { name: 'BIN', value: 'BIN', required: [ 'GLTF' ], hideInList: true },
  { name: 'USD', value: 'USD' },
  { name: 'USDZ', value: 'USDZ' },
];

export const RPC_TYPES = {
  rpc: 'RPC_TO_AVS',
  png: 'PNG_TO_RPC',
  fbx: 'FBX_TO_RPC',
  obj: 'OBJ_TO_RPC',
  dae: 'DAE_TO_RPC',
  glb: 'GLB_TO_RPC',
  gltf: 'GLTF_TO_RPC',
  usd: 'USD_TO_RPC',
  usdz: 'USDZ_TO_RPC',
};

export const JOB_STATUSES = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  PROCESSING: 'PROCESSING',
  QUEUED: 'QUEUED',
  WAITING_FOR_FILE: 'WAITING_FOR_FILE',
};

export const MODEL_TYPES = {
  twoD: '2D',
  threeD: '3D',
};

export const TRANSPARENCY_MODES = {
  simple: 'simple',
  complex: 'complex',
  stencil: 'stencil',
};

export const DOWNSCALE_SIZES = [
  { name: '4K', value: 4096 },
  { name: '2K', value: 2048 },
  { name: '1K', value: 1024 },
  { name: '512', value: 512 },
  { name: '256', value: 256 },
];

export const PROXY_TYPES = [ 'billboard', 'simplified mesh', 'bounding box', 'custom mesh' ];


export const SCALE_UNITS = [
  { name: 'millimeters', abbreviation: 'mm', scaleFactor: 0.001 },
  { name: 'centimeters', abbreviation: 'cm', scaleFactor: 0.01 },
  { name: 'meters', abbreviation: 'm', scaleFactor: 1 },
  { name: 'inches', abbreviation: 'in', scaleFactor: 0.0254 },
  { name: 'feet', abbreviation: 'ft', scaleFactor: 0.3048 },
  { name: 'yards', abbreviation: 'yd', scaleFactor: 0.9144 },
];
