
import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Copyright } from 'components/typography';
import { FooterComponent, LogoContainer, SocialIcons, IconContainer, UserRoleContainer } from './styles';
import {
  TwitterIcon,
  FacebookIcon,
  LinkedInIcon,
  YouTubeIcon,
  FooterLogo,
} from 'components/icons';
import { useCookies } from 'react-cookie';
import { getCreators, setTemporaryRole } from 'store/actions/userData';
import { FovSelect, FovOption } from 'components';
import { HorizontalSpacer } from 'components/layout';
import { allRoles, superUsers } from 'components/generic/ProtectedContent';
import { hasAccess } from 'navigation/navigationHelpers';
import { withTheme } from 'styled-components';
import packageJSON from '../../../package.json';
import moment from 'moment';
import _ from 'lodash';

const buildSocial = () => {
  const networks = [
    {
      name: 'Twitter',
      icon: <TwitterIcon />,
      path: 'https://twitter.com/archvision',
    },
    {
      name: 'Facebook',
      icon: <FacebookIcon />,
      path: 'https://facebook.com/AskArchVision/',
    },
    {
      name: 'YouTube',
      icon: <YouTubeIcon />,
      path: 'https://youtube.com/channel/UCoZK_aJcpSYQNSO_1Hsntfw',
    },
    {
      name: 'LinkedIn',
      icon: <LinkedInIcon />,
      path: 'https://linkedin.com/company/archvision',
    },
  ];

  return networks.map((network, index) => {
    const { icon, path } = network;

    return (
      <Fragment key={index}>
        {index !== 0 && (
          <HorizontalSpacer size={10} />
        )}

        <IconContainer>
          <a href={path} rel="noreferrer" target="_blank">
            {icon}
          </a>
        </IconContainer>

        {index !== networks.length - 1 && (
          <HorizontalSpacer size={10} />
        )}
      </Fragment>
    );
  });
};


const Footer = withTheme(props => {
  const { theme } = props;
  const { version } = packageJSON;

  const [ cookies ] = useCookies([ 'userAuth' ]);

  const userDataStore = useSelector(state => state.userData);
  const userRoles = userDataStore?.data?.roles;
  const dispatch = useDispatch();
  const selectedTheme = userDataStore.userPreferences.theme;
  const white = theme.colors[selectedTheme].white;

  const getModels = useCallback(temporaryRole => {
    const onCreators = location.pathname.includes('/creators');
    const token = cookies.archvision_token;
    const defaultRole = temporaryRole === 'default';
    const workingRoles = !defaultRole ? [ temporaryRole ] : userRoles;
    const args = { token, userRoles: defaultRole ? null : workingRoles };

    if (onCreators) {
      dispatch(getCreators(args));
    }
  }, [ userRoles, cookies, location ]);

  return (
    <FooterComponent>
      <LogoContainer>
        <a href="https://archvision.com" rel="noreferrer" target="_blank">
          <FooterLogo />
        </a>
      </LogoContainer>

      <Copyright>
        Copyright &copy; All rights reserved ArchVision&reg; {moment().format('YYYY')}
        , FOVEA v{version}
      </Copyright>

      <SocialIcons>
        {hasAccess(userRoles, superUsers) && (
          <UserRoleContainer>
            <FovSelect
              value={userDataStore.temporaryRole}
              placeholder={_.capitalize(userDataStore.temporaryRole)}
              position='top'
              color={white}
              onChange={(e, newVal) => {
                dispatch(setTemporaryRole(newVal));
                getModels(newVal);
              }}
            >
              {[ 'default', ...allRoles ]?.map((option, index) => (
                <FovOption key={index} value={option}>
                  {_.capitalize(option)}
                </FovOption>
              ))}
            </FovSelect>
          </UserRoleContainer>
        )}

        {buildSocial()}
      </SocialIcons>
    </FooterComponent>
  );
});

export { Footer };