import React from 'react';
import * as Elements from './elements';

export const docsPath = '/documentation';

const docsItems = {
  welcome: [
    {
      title: 'Overview',
      path: `${docsPath}/overview`,
      element: <Elements.Overview />,
    },
  ],

  creation: [
    {
      title: 'Create "From File"',
      path: `${docsPath}/create-from-file`,
      element: <Elements.CreateFromFile />,
    },
    {
      title: 'Create "From glTF"',
      path: `${docsPath}/create-from-gltf`,
      element: <Elements.CreateFromGLTF />,
    },
  ],

  FOVEA_scene: [
    {
      title: 'Intro to the FOVEA Scene',
      path: `${docsPath}/intro-to-the-fovea-scene`,
      element: <Elements.IntroToFoveaScene />,
    },
    {
      title: 'Editing Materials',
      path: `${docsPath}/editing-materials`,
      element: <Elements.EditingMaterials />,
    },
    {
      title: 'Editing Properties',
      path: `${docsPath}/editing-properties`,
      element: <Elements.EditingProperties />,
    },
    {
      title: 'Editing Cameras',
      path: `${docsPath}/editing-cameras`,
      element: <Elements.EditingCameras />,
    },
    {
      title: 'Editing Environments',
      path: `${docsPath}/editing-environments`,
      element: <Elements.EditingEnvironments />,
    },
    {
      title: 'Controlling Visibility',
      path: `${docsPath}/controlling-visibility`,
      element: <Elements.ControllingVisibility />,
    },
    {
      title: 'Publishing a Model',
      path: `${docsPath}/publishing`,
      element: <Elements.PublishingModels />,
    },
  ],

  content: [
    {
      title: 'Filtering',
      path: `${docsPath}/filtering`,
      element: <Elements.Filtering />,
    },
    {
      title: 'View Preferences',
      path: `${docsPath}/view-preferences`,
      element: <Elements.ViewPreferences />,
    },
  ],

  general: [
    {
      title: 'Downloding a Model',
      path: `${docsPath}/downloading-model`,
      element: <Elements.DownloadingModels />,
    },
    {
      title: 'Using your new RPC',
      path: `${docsPath}/using-rpc`,
      element: <Elements.UsingRPC />,
    },
  ],
};

export default docsItems;