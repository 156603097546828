import styled from 'styled-components';

export const Ul = styled('ul')`
  minWidth: ${props => props.minWidth}
  width: ${props => props.width || 'auto'};
  list-style-type: none;
  overflow-y: auto;
  height: ${props => props.height};
  overflow-x: hidden;
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding};
`;