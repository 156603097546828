import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  LinkOutlined,
  EditOutlined,
  PlusOutlined,
  HighlightOutlined,
  FovModal,
  FovOption,
  DocNote,
  VerticalSpacer,
  FovSelect,
  FovInput,
  Body,
  FovButton,
} from 'components';
import { getColor, updateCanvas } from 'utils';
import { ProtectedContent } from 'components/generic/ProtectedContent';
import styled, { withTheme } from 'styled-components';
import constants from 'constants/index';
import _ from 'lodash';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;
const iconSize = 24;

const MaterialActions = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ListContainer = styled('div')`
  position: relative;
`;

const MaterialAction = styled('div')`
  display: flex;
  flex-direction: column;
  color: ${props => getColor(props, 'onPrimary')};
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 0.85rem;
  cursor: pointer;

  svg {
    color: ${props => getColor(props, 'onPrimary')};
  }

  &:hover {
    color: ${props => getColor(props, 'accent')};

    svg {
      color: ${props => getColor(props, 'accent')};
    }
  }
`;


const ListTab = withTheme(props => {
  const { theme } = props;

  const dispatch = useDispatch();
  const [ nameModalVisible, setNameModalVisible ] = useState(false);
  const [ nameInputValue, setNameInputValue ] = useState('');

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const materials = serializedData?.metadata.materials || [];
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterialId = selectedMaterials?.[0]?.materialId || '';
  const errorColor = theme.colors[selectedTheme].error;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const buildMaterialsList = () => {
    const workingMaterials = materials.map((material, index) => {
      const { materialId, name } = material;

      return (
        <FovOption key={index} value={materialId}>
          {name}
        </FovOption>
      );
    });

    return workingMaterials;
  };

  const nameModal = () => {
    const reservedNames = [ 'default', ...materials.map(material => _.toLower(material.name)) ];
    const nameVal = nameInputValue.toLowerCase().trim();
    const onModalClose = () => {
      setNameModalVisible(false);
      setNameInputValue('');
    };

    const nameIsReserved = () => {
      const reservedNameUsed = reservedNames.includes(nameVal);
      return reservedNameUsed;
    };

    return (
      <FovModal
        visible={nameModalVisible}
        title='Rename material'
        onClose={onModalClose}
        noOverflow={true}
      >
        Name
        <VerticalSpacer size={20} />

        <FovInput
          value={nameInputValue}
          onChange={e => setNameInputValue(e.target.value)}
        />

        {nameIsReserved() && (
          <>
            <VerticalSpacer size={15} />

            <Body size='Small' color={errorColor}>
              {_.toLower(nameInputValue) === 'default'
                ? 'Default is reserved.'
                : `${nameInputValue}, is already in use.`
              }
            </Body>
          </>
        )}

        <VerticalSpacer size={20} />

        <FovButton
          text='Rename material'
          disabled={nameIsReserved() || _.isEmpty(nameInputValue)}
          callback={() => {
            const inboundData = {
              id: selectedMaterialId,
              newId: nameInputValue,
            };

            updateCanvas(updateArgs, inboundData);
            onModalClose();
          }}
        />
      </FovModal>
    );
  };

  return (
    <>
      <ListContainer>
        <DocNote path={[ 'materialsPanel', 'materialsList' ]} top={7} left={-10} />

        <FovSelect
          value={selectedMaterialId}
          placeholder={_.startCase(selectedMaterialId || 'Select Material')}
          onChange={(e, newVal) => {
            const inboundData = {
              id: newVal,
              newSelectedMaterial: true,
            };

            updateCanvas(updateArgs, inboundData);
          }}
        >
          {buildMaterialsList()}
        </FovSelect>
      </ListContainer>

      <VerticalSpacer size={25} />

      <MaterialActions>
        <DocNote path={[ 'materialsPanel', 'materialActions' ]} top={0} left={145} />

        <MaterialAction
          theme={theme}
          $selectedTheme={selectedTheme}
          onClick={() => setNameModalVisible(true)}
        >
          <EditOutlined width={iconSize} height={iconSize} />
          Rename
        </MaterialAction>

        <ProtectedContent>
          <MaterialAction
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={_.noop}
          >
            <PlusOutlined width={iconSize} height={iconSize} />
            Variant
          </MaterialAction>
        </ProtectedContent>

        <ProtectedContent>
          <MaterialAction
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={_.noop}
          >
            <LinkOutlined width={iconSize} height={iconSize} />
            Presets
          </MaterialAction>
        </ProtectedContent>

        <MaterialAction
          theme={theme}
          $selectedTheme={selectedTheme}
          onClick={() => {
            const inboundData = {
              id: selectedMaterialId,
              selectMaterial: true,
            };

            updateCanvas(updateArgs, inboundData);
          }}
        >
          <HighlightOutlined width={iconSize} height={iconSize} />
          Select
        </MaterialAction>
      </MaterialActions>

      <VerticalSpacer size={15} />

      {nameModal()}
    </>
  );
});

export default ListTab;
