const fovea = 'FOVEA';
const internal = 'Internal';

const roadmap = [
  {
    year: 2022,
    quarters: [
      {
        quarter: 'Q3',
        items: [
          {
            title: 'Roadmap',
            type: fovea,
            complete: true,
            description: 'A rough estimate on scheduled feature development, subject to change.',
          },
          {
            title: 'Dynamic documentation',
            type: fovea,
            complete: true,
            description: 'Integrated directly in FOVEA, accessible from the info and support menu or with F1.',
          },
          {
            title: 'Materials: Clear-coat',
            type: fovea,
            complete: true,
            description: 'Advanced clear-coat material property in the Materials panel.',
          },
          {
            title: 'Inherit file name',
            type: internal,
            complete: true,
            description: 'Use provided file name on Create > From File/GLTF.',
          },
          {
            title: 'Cookies banner',
            type: internal,
            complete: true,
            description: 'Cookie agreement banner on log in.',
          },
        ],
      },

      {
        quarter: 'Q4',
        items: [
          {
            title: 'Real-time job status updates',
            type: fovea,
            complete: true,
            description: 'WebSocket-based updates for pending job statuses on recently published models.',
          },
          {
            title: '2022 Polish pass',
            type: internal,
            complete: true,
            description: 'Bug fixes or improvements to newly added features for the last quarter.',
          },
        ],
      },
    ],
  },

  {
    year: 2023,
    quarters: [
      {
        quarter: 'Q1',
        items: [
          {
            title: 'Back-end filtering',
            type: internal,
            complete: true,
            description: 'Transition all front-end content filtering to the back-end.',
          },
          {
            title: 'Materials: Local texture downscaling',
            type: fovea,
            complete: true,
            description: 'Per material texture downscaling in the Materials panel.',
          },
          {
            title: 'Canvas refactor',
            type: internal,
            complete: true,
            description: 'Extracts Babylon to own repo and reintegrates into FOVEA.',
          },
          {
            title: 'Material editor',
            type: fovea,
            description: 'Build and manage your custom materials and presets.',
          },
        ],
      },

      {
        quarter: 'Q2',
        items: [
          {
            title: 'WebGPU Transition',
            type: fovea,
            complete: true,
            description: 'Increase viewport performance by offloading calculations to the GPU when spec is finalized.',
          },
          {
            title: 'RPC Saving',
            type: fovea,
            description: 'Manually save changes when editing an existing RPC without needing to Publish.',
          },
          {
            title: 'Restore progress from gLTF',
            type: fovea,
            description: 'Restore progress after working in, leaving and returning to an unpublished RPC.',
          },
          {
            title: 'Projects architecture',
            type: fovea,
            description: 'Import multiple RPC files into a single project file.',
          },
          {
            title: 'Material variants',
            type: fovea,
            description: 'Store and retrieve different material variations of your RPC.',
          },
        ],
      },

      {
        quarter: 'Q3',
        items: [
          {
            title: 'Blender support',
            type: internal,
            description: 'Support for .blend files.',
          },
          {
            title: 'Vendor uploader',
            type: fovea,
            description: 'Sell your RPC content.',
          },
          {
            title: 'Vendor manager',
            type: fovea,
            description: 'Manage your RPC content for sale.',
          },
          {
            title: 'File import',
            type: fovea,
            description: 'Import multiple RPC files into a single RPC.',
          },
          {
            title: 'RPC slide-deck service',
            type: internal,
            description: 'Return an array of images for potential 360 model view.',
          },
        ],
      },
    ],
  },
];

export default roadmap;