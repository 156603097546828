import styled from 'styled-components';
import { FovTypography } from 'components';
import bcg_launch from 'assets/Ui/bcg_launch.png';
import button_shapes_2 from 'assets/Ui/button_shapes_2.svg';
import { getColor } from 'utils';

export const Welcome = styled(FovTypography)`
  width: 100%;
  height: 100%;
  padding: 0 5rem 5rem;
  color: ${props => getColor(props, 'onPrimary')};
  background-image: url('${bcg_launch}');
  background-position: bottom -5.8rem right;
  background-repeat: no-repeat;
  background-size: 52%;
`;

export const Sections = styled('div')`
  display: inline-flex;
`;

export const Section = styled('div')`
  max-width: 28rem;
`;

export const SectionTitle = styled('div')`
  display: inline-grid;
  grid-template-columns: auto 1fr;
  gap: 0 2rem;
  align-items: center;
  font-weight: bold;
  color: ${props => getColor(props, 'accent')};
  width: 100%;
  user-select: none;
`;

export const GetStartedTitle = styled('strong')`
  font-size: 2.75rem;
`;

export const CTA = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 0.25rem;
  height: 2.55rem;
  width: 10.75rem;
  font-size: 0.95rem;
  font-weight: 600;
  background-color: black;
  cursor: pointer;
  background-image: url('${button_shapes_2}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: ${props => getColor(props, 'white')};

  &:hover {
    filter: brightness(1.15);
  }
`;

export const TitleFiller = styled('div')`
  position: relative;
`;

export const Circle = styled('div')`
  position: absolute;
  top: -0.45rem;
  right: 0;
  background-color: ${props => getColor(props, 'white')};
  height: 1rem;
  width: 1rem;
  border: 0.25rem solid ${props => getColor(props, 'accent')};
  border-radius: 50%;
`;

export const SampleProjects = styled('div')`
  display: inline-flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
`;