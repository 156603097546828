import React, { useRef, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTheme } from 'styled-components';
import {
  FovDivider,
  VerticalSpacer,
  FovButton,
  FovOption,
  FovSelect,
  FovConfirmation,
  Body,
  DocNote,
  FovToggle,
  FovTypography,
  FovSlider,
} from 'components';
import {
  EnvironmentsContainer,
  HDRContainer,
  HDRElement,
  EnvironmentContainer,
  UploadInput,
  ChannelTextContainer,
} from './styles';
import { VisibilityToggle } from '../Visibility/styles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';
import _ from 'lodash';
import Lighting from './Lighting';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const EnvironmentTab = withTheme(props => {
  const { theme } = props;
  const [ confirmationVisible, setConfirmationVisible ] = useState(false);
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const selectedTheme = userDataStore.userPreferences.theme;
  const textureRef = useRef();
  const errorColor = theme.colors[selectedTheme].error;
  const errorHoveredColor = theme.colors[selectedTheme].errorHover;
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const envRotation = serializedData?.metadata.environmentRotation;
  const envIntensity = serializedData?.metadata.environmentIntensity;
  const envBlur = serializedData?.metadata.environmentBlur || 0;
  const defaultEnvironments = canvasStore.defaultEnvironments;
  const userEnvironments = serializedData?.metadata.userEnvironments || [];
  const selectedUserEnvironment = serializedData?.metadata.selectedUserEnvironment;
  const defaultEnvURL = serializedData?.metadata?.defaultEnvironment || '';
  const defaultEnvActive = defaultEnvironments.some(env => env.id === selectedUserEnvironment?.id);
  const backgroundEnabled = serializedData?.metadata.viewportEnvironment;
  const dispatch = useDispatch();

  const defaultRotation = {
    rotation: { ry: envRotation },
  };

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.ENVIRONMENT,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const addArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.ADD };
  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const customEnvironmentOptions = () => {
    const environments = [
      <FovOption key='default' value={defaultEnvURL}>
        Default
      </FovOption>,
    ];

    userEnvironments.forEach((item, index) => {
      const { id, url } = item;

      environments.push(
        <FovOption key={index} value={url}>
          {id}
        </FovOption>,
      );
    });

    return environments;
  };

  const buildPresetList = () => {
    const environments = defaultEnvironments.map((hdr, index) => {
      const { id, imageURL, envURL } = hdr;
      const isActive = serializedData?.metadata.selectedUserEnvironment?.url === envURL;

      if (_.toLower(id) !== 'default') {
        return (
          <HDRElement
            key={index}
            theme={theme}
            selectedTheme={selectedTheme}
            isActive={isActive}
            image={imageURL}
            onClick={() => {
              if (isActive) {
                updateCanvas(updateArgs, { url: defaultEnvURL, transforms: defaultRotation });
                return;
              }

              updateCanvas(updateArgs, { url: envURL, transforms: defaultRotation });
            }}
          />
        );
      }
    });

    return environments;
  };

  const nameIsReserved = nameVal => {
    const reservedNames = [ 'default', ...defaultEnvironments?.map(env => _.toLower(env.id)) || [] ];
    return reservedNames.includes(_.toLower(nameVal));
  };

  return (
    <>
      <EnvironmentsContainer>
        <VerticalSpacer size={15} />

        <HDRContainer>
          {buildPresetList()}
          <DocNote path={[ 'environmentPanel', 'presetEnvironments' ]} top={97} left={142} />
        </HDRContainer>

        <VerticalSpacer size={25} />

        <Body size='Medium Bold'>
          Custom environments
          <DocNote path={[ 'environmentPanel', 'customEnvironments' ]} top={-2} left={180} />
        </Body>

        <VerticalSpacer size={15} />

        <FovSelect
          value={selectedUserEnvironment?.url || ''}
          placeholder={defaultEnvActive ? 'Default' : (selectedUserEnvironment?.id || 'Select an environment')}
          onChange={(e, newVal) => {
            if (!defaultEnvActive || newVal !== selectedUserEnvironment.url) {
              updateCanvas(updateArgs, { url: newVal, transforms: defaultRotation });
            }
          }}
        >
          {customEnvironmentOptions()}
        </FovSelect>

        <VerticalSpacer size={15} />

        <FovButton
          text='Add custom environment'
          callback={() => textureRef.current.click()}
        />

        <VerticalSpacer size={15} />

        <FovButton
          text='Delete environment'
          color={errorColor}
          hoverColor={errorHoveredColor}
          disabled={nameIsReserved(selectedUserEnvironment?.id) || userEnvironments.length === 0}
          callback={() => setConfirmationVisible(true)}
        />

        <UploadInput
          ref={textureRef}
          type='file'
          accept='.hdr'
          value={''}
          onChange={e => {
            const file = e.target.files[0];
            const id = file.name;
            const envURL = URL.createObjectURL(file);
            const newEnvironment = { id, url: envURL };
            const exists = userEnvironments.find(env => env.id === id);

            if (!exists) updateCanvas(addArgs, newEnvironment);
          }}
        />

        <VerticalSpacer size={40} />
        <FovDivider />
        <VerticalSpacer size={15} />

        <Body size='Medium Bold'>
          Environment Settings
          <DocNote path={[ 'environmentPanel', 'attributes' ]} top={-2} left={175} />
        </Body>

        <VerticalSpacer size={24} />

        <EnvironmentContainer>
          <ChannelTextContainer>
            <div>
              <FovSlider
                label='Rotation'
                defaultValue={envRotation}
                max={360}
                step={1}
                onAfterChange={newVal => updateCanvas(updateArgs, {
                  transforms: {
                    rotation: { ry: newVal },
                  },
                })}
              />

              <VerticalSpacer size={8} />
            </div>
          </ChannelTextContainer>
        </EnvironmentContainer>

        <VerticalSpacer size={8} />

        <EnvironmentContainer>
          <ChannelTextContainer>
            <div>
              <FovSlider
                label='Intensity'
                defaultValue={envIntensity}
                onAfterChange={newVal => updateCanvas(updateArgs, { envIntensity: newVal })}
              />

              <VerticalSpacer size={8} />
            </div>
          </ChannelTextContainer>
        </EnvironmentContainer>

        <VerticalSpacer size={8} />

        <EnvironmentContainer>
          <ChannelTextContainer>
            <div>
              <FovSlider
                label='Blur'
                defaultValue={envBlur}
                disabled={!backgroundEnabled}
                onAfterChange={newVal => updateCanvas(updateArgs, { envBlur: newVal })}
              />

              <VerticalSpacer size={8} />
            </div>
          </ChannelTextContainer>
        </EnvironmentContainer>

        <VisibilityToggle>
          <FovTypography>Background</FovTypography>

          <FovToggle
            checked={backgroundEnabled}
            callback={newVal => {
              const updateViewportArgs = {
                ...updateArgs,
                type: CANVAS_UPDATE_TYPES.VIEWPORT,
              };

              updateCanvas(updateViewportArgs, { envVisible: newVal });
            }}
          />
        </VisibilityToggle>

        <VerticalSpacer size={24} />
        <FovDivider />
        <VerticalSpacer size={15} />
        <Body size='Medium Bold'>
          Lighting Settings
          <DocNote path={[ 'environmentPanel', 'attributes' ]} top={-2} left={140} />
        </Body>
        <Lighting />
      </EnvironmentsContainer>

      <FovConfirmation
        onConfirm={() => updateCanvas(removeArgs, { id: selectedUserEnvironment?.id })}
        onClose={() => setConfirmationVisible(false)}
        confirmationText='Remove environment'
        isOpened={confirmationVisible}
        confirmColor={errorColor}
        confirmHoverColor={errorHoveredColor}
      >
        <Body size='Medium'>{`Remove ${selectedUserEnvironment?.id}?`}</Body>
      </FovConfirmation>
    </>
  );
});

export default EnvironmentTab;
