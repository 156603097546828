import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { getHasUserModels } from 'store/actions/userData';
import { FovLoader } from 'components';
import styled from 'styled-components';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Home = () => {
  const [ cookies ] = useCookies([ 'userAuth' ]);
  const token = cookies.archvision_token;
  const userDataStore = useSelector(state => state.userData);
  const hasUserModels = userDataStore.hasUserModels;
  const userId = userDataStore.data.userId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getHasModels = useCallback(() => {
    if (hasUserModels === null) {
      dispatch(getHasUserModels({ token, userId }));
    }
  }, [ dispatch, getHasUserModels ]);

  useEffect(() => {
    getHasModels();
  }, []);

  useEffect(() => {
    const welcome = '/welcome';
    const myFiles = '/my-files';

    if (hasUserModels === null) return;

    navigate(!hasUserModels ? welcome : myFiles);
  }, [ hasUserModels ]);

  return (
    <Wrapper>
      <FovLoader text='Checking models...' />
    </Wrapper>
  );
};

export { Home };
