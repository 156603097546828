import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const FlowerSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const FlowerOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <FlowerSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 16 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M10.5001 9.99998C10.5001 9.99998 12.306 8.2487 11.5 6M11.5 6C12.8807 6 14 4.88067 14 3.5V1L11.5 2L9 1V3.5C9 4.88067 10.1193 6 11.5 6ZM4.49999 5.99997C4 8 5 9 5.99999 9.99997M4.49999 5.99997C5.88065 5.99997 7.00006 4.88067 7.00006 3.5V1L4.50006 2L2.00006 1V3.5C2.00006 4.88067 3.11932 5.99997 4.49999 5.99997ZM7.99994 12V14M4.50006 12L5.00006 14M11.4999 12L10.9999 14M14.2787 14.3287C14.118 15.2933 13.284 16 12.306 16H3.694C2.716 16 1.882 15.2933 1.72133 14.3287L1 10H15L14.2787 14.3287Z'
      />
    </FlowerSVG>
  );
});

export { FlowerOutlined };