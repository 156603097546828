import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const Text = styled('h4')`
  color: ${props => props.color || props.theme.colors[props.$selectedTheme].onPrimary};
  font-family: ${props => props.theme.typography.primary.regular};
  font-style: normal;
  font-size: 1.15rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin: 0;
`;

const H4 = withTheme(props => {
  const { children, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Text color={color} theme={theme} $selectedTheme={selectedTheme}>
      {children}
    </Text>
  );
});

export { H4 };
