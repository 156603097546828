import { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import { FovInput, HorizontalSpacer, Body } from 'components';
import {
  SliderContainer,
  SliderThumb,
  SliderTrack,
  SliderFilled,
  SliderLabel,
  MainSliderContainer,
  InputContainer,
  FloatingValue,
} from './sliderStyles';
import { withTheme } from 'styled-components';
import constants from 'constants/index';
import _ from 'lodash';

const { THEME } = constants;
const light = THEME.LIGHT;

const FovSlider = withTheme(props => {
  const {
    theme,
    label,
    name,
    defaultValue = 0,
    min = 0,
    max = 1,
    step = 0.01,
    onChange,
    onAfterChange,
    showInput,
    disabled,
  } = props;

  const [ inputValue, setInputValue ] = useState(defaultValue);
  const [ showFloatingValue, setShowFloatingValue ] = useState(false);

  const trackRef = useRef();
  const valueRef = useRef();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const positionCalc = (inputValue - min) / (max - min);
  const leftPercentage = inputValue !== 0 ? positionCalc * 100 : 0;
  const lightTheme = selectedTheme === light;
  const floatingValueColor = getColor({ theme, selectedTheme }, lightTheme ? 'primary' : 'onPrimary');

  useEffect(() => {
    if (inputValue !== undefined && !_.isEqual(inputValue, valueRef.current)) {
      valueRef.current = inputValue;
    }
  }, [ inputValue, valueRef.current ]);

  useEffect(() => {
    if (!_.isEqual(defaultValue, valueRef.current)) {
      setInputValue(defaultValue);
      valueRef.current = defaultValue;
    }
  }, [ defaultValue ]);

  const calculateValueFromMouse = useCallback(clientX => {
    const track = trackRef.current?.getBoundingClientRect();
    const ratio = (clientX - track.left) / track.width;
    const rawValue = min + ratio * (max - min);
    const steppedValue = Math.round(rawValue / step) * step;

    return Math.min(Math.max(steppedValue, min), max);
  }, [ min, max, step ]);

  const runPostFunctions = (newValue, eventComplete) => {
    onChange?.(newValue);
    eventComplete && onAfterChange?.(newValue);
  };

  const handleValueChange = e => {
    const newValue = e?.target?.value ?? 0;
    const exceededMax = newValue > max;
    const exceededMin = newValue < min;

    if (exceededMax) {
      setInputValue(max);
      runPostFunctions(max, true);
      return;
    }

    if (exceededMin) {
      setInputValue(min);
      runPostFunctions(min, true);
      return;
    }

    setInputValue(newValue);
    runPostFunctions(newValue, true);
  };

  const handleMouseDown = () => {
    if (!disabled) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  const handleMouseMove = useCallback(e => {
    e.preventDefault();
    handleChange?.(e);
  }, [ calculateValueFromMouse, onChange, inputValue ]);

  const handleMouseUp = useCallback(e => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    handleChange?.(e, true);
  }, [ handleMouseMove, onAfterChange ]);

  const handleChange = (e, eventComplete) => {
    const newValue = calculateValueFromMouse(e.clientX);

    if (!disabled && !_.isEqual(newValue, inputValue)) {
      setInputValue(newValue);
      runPostFunctions(newValue, eventComplete);
    }
  };

  return (
    <MainSliderContainer>
       <SliderContainer
          onMouseOver={() => !showInput && setShowFloatingValue(true)}
          onMouseOut={() => !showInput && setShowFloatingValue(false)}
        >
       {!_.isEmpty(label) && (
          <SliderLabel theme={theme} $selectedTheme={selectedTheme}>
            <Body size='X-Small'>
              {label}
            </Body>
          </SliderLabel>
        )}

        <SliderTrack
          ref={trackRef}
          theme={theme}
          $selectedTheme={selectedTheme}
          disabled={disabled}
          onMouseDown={handleMouseDown}
          onClick={handleChange}
        >
          <SliderFilled theme={theme} $selectedTheme={selectedTheme} width={leftPercentage} disabled={disabled} />
          <SliderThumb theme={theme} $selectedTheme={selectedTheme} xPosition={leftPercentage} disabled={disabled} />

          {!showInput && (
            <Body size='X-Small' color={floatingValueColor}>
              <FloatingValue
                theme={theme}
                $selectedTheme={selectedTheme}
                xPosition={leftPercentage}
                showFloatingValue={showFloatingValue}
              >
                {inputValue?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
              </FloatingValue>
            </Body>
          )}
        </SliderTrack>
      </SliderContainer>

      {showInput && (
        <InputContainer>
          <HorizontalSpacer size={16} />

          <FovInput
            type='number'
            name={name}
            width={86}
            value={inputValue}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
            onChange={handleValueChange}
          />
        </InputContainer>
      )}
    </MainSliderContainer>
  );
});

export { FovSlider };