import styled from 'styled-components';

export const EnvironmentsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const HDRContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
`;

export const HDRElement = styled('div')`
  background-image: url(${props => props.image});
  background-repeat: round;
  border: ${props => {
    const color = props.theme.colors[props.selectedTheme][
      props.isActive ? 'accent' : 'lightGrey'
    ];

    return `0.125rem solid ${color}`;
  }};
  border-radius: 0.25rem;
  width: 9.4rem;
  height: 6rem;
  margin: 0.4rem 0 0.4rem 0;
  cursor: pointer;

  &:hover {
    border: 0.125rem solid
      ${props => props.theme.colors[props.selectedTheme].accent};
  }
`;

export const EnvironmentContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const UploadInput = styled('input')`
  display: none;
`;

export const ChannelTextContainer = styled('div')`
  width: 100%;
`;

