import styled, { css } from 'styled-components';
import { FovTypography } from 'components/typography';
import { getColor } from 'utils';
import constants from 'constants/index';

const { HEADER_HEIGHT, FOOTER_HEIGHT } = constants;
const offset = HEADER_HEIGHT + FOOTER_HEIGHT;
const light = constants.THEME.LIGHT;

export const Wrapper = styled('div')`
  height: 100%;
  width: ${props => props.collapsed ? 4 : 20.33125}rem;
  border-right: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  transition: width 0.2s ease;
  overflow-y: auto;
  padding-bottom: 2rem;
  color: ${props => getColor(props, 'onPrimary')};
  ${() => css`max-height: calc(100vh - ${offset / 16}rem)`};
`;

export const FilterTitle = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  position: relative;
  left: -0.5rem;
  width: 100%;
`;

export const CollapseContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${props => props.collapsed ? 'center' : 'flex-end'};
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  transition: justify-content 0.2s ease;
`;

export const FilterSection = styled('div')`
  display: ${props => props.collapsed ? 'none' : 'block'};
  padding: 0rem 2rem;
`;

export const TagsContainer = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0.25rem;
`;

export const TitleGroup = styled('div')`
  display: inline-flex;
  align-items: center;
`;

export const Tag = styled('div')`
  border-radius: 0.25rem;
  width: fit-content;
  padding: 0.15rem 0.5rem;
  font-size: 0.9rem;
  background-color: ${props => (
    props.active ? getColor(props, 'accent') : getColor(props, 'lightGrey')
  )};
  color: ${props => getColor(props, props.active ? 'onAccent' : 'onPrimary')};
  margin: 0.25rem;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => getColor(props, props.active ? 'accentHover' : 'mediumGrey')};
  }
`;

export const ClearAll = styled('div')`
  line-height: 1.2;
  color: ${props => getColor(props, 'accent')};

  &:hover {
    color: ${props => getColor(props, 'accentHover')};
    text-decoration: underline;
  }

  cursor: pointer;
`;

export const NoSelectionMessage = styled(FovTypography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    color: ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'darkGrey' : 'lightGrey');
    }}
  }
`;

export const FilteredModels = styled('div')`
  color: ${props => getColor(props, 'mediumGrey')};
`;