import React from 'react';
import constants from 'constants/index';
import { useSelector } from 'react-redux';
import { FovTypography } from 'components/typography';
import styled, { withTheme } from 'styled-components';

const light = constants.THEME.LIGHT;

const Error = styled('p')`
  font-weight: 600;
  margin: 0 0 1rem 0;
  font-size: 1.75rem;
  color: ${props => (
    props.theme.colors[props.$selectedTheme][props.type ? 'accent' : 'error']
  )};
`;

const PageErrorContainer = styled(FovTypography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return props.theme.colors[props.$selectedTheme][lightTheme ? 'darkGrey' : 'lightGrey'];
  }};

  width: 100%;
  height: 100%;
`;

const PageError = withTheme(props => {
  const { theme, type, title, text, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <PageErrorContainer theme={theme} $selectedTheme={selectedTheme} {...rest}>
      <Error theme={theme} $selectedTheme={selectedTheme} type={type}>
        {title || 'There has been an error...'}
      </Error>

      {text || 'We are unable to show content at this time.'}
    </PageErrorContainer>
  );
});

export { PageError };