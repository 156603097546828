import { setUpdateData } from 'store/actions/canvas';
import _ from 'lodash';

const { VITE_CDN_HOST: cdnHost } = process.env;

export const formatRpcImage = guid => {
  return `${cdnHost}/public/service.thumbnail-cache/${guid}.rpc.png`;
};

export const getBase64StringFromDataURL = dataURL => {
  if (typeof dataURL !== 'string') return '';
  return dataURL.replace('data:', '').replace(/^.+,/, '');
};

export const createBlobFromBase64String = (base64String, mimeType = 'image/png') => {
  if (typeof base64String !== 'string') return null;

  const binaryString = atob(base64String);
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const byteArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  return new Blob([ arrayBuffer ], { type: mimeType });
};

export const getColor = (props, key) => {
  const themeColors = props.theme.colors[props.$selectedTheme || props.selectedTheme];
  return themeColors[key];
};

export const cleanNumber = value => {
  const decimalPlaces = value?.toLocaleString('en', { maximumFractionDigits: 10 });

  if (decimalPlaces === '-0') return 0;
  return +(decimalPlaces) || 0;
};

export const getHeaderFooterBuffer = (constants, padding = 32) => {
  const { HEADER_HEIGHT, FOOTER_HEIGHT } = constants;
  return (HEADER_HEIGHT + FOOTER_HEIGHT + padding) / 16;
};

export const trimText = (body, limit, characterTrim) => {
  let trimText = body?.substr(0, limit || 115);
  trimText = trimText?.substr(0, Math.min(trimText.length, trimText.lastIndexOf(characterTrim ? '' : ' ')));

  if (trimText) {
    return trimText.length >= limit ? `${_.trim(trimText)}...` : trimText;
  }
};

export const decode = value => {
  const isArray = _.isArray(value);
  const isString = _.isString(value);
  const isValid = value && value !== 'undefined' && (isString || isArray);

  const cleanItem = item => {
    return decodeURI(item)
      .replaceAll('%2B', ' ')
      .replaceAll('+', ' ')
      .replaceAll('%2C', ',')
      .replaceAll('%2F', '/')
      .replaceAll('%26', '&')
      .replaceAll('%5E', '^')
      .replaceAll('%24', '$')
      .replaceAll('%23', '#')
      .replaceAll('%40', '@')
      .replaceAll('%3D', '=')
      .replaceAll('%3B', ';')
      .replaceAll('%3A', ':')
      .replaceAll('%3F', '?');
  };

  if (!isValid) {
    return;
  }

  if (isArray) {
    return value.map(item => cleanItem(item));
  }

  return cleanItem(value);
};

export const exists = value => {
  return value !== undefined;
};

export const updateCanvas = (updateArgs, payload) => {
  const { dispatch, type, method } = updateArgs;

  const inboundData = {
    type,
    method,
    payload,
  };

  dispatch(setUpdateData(inboundData));
};

export const rgbToHex = (r, g, b) => {
  const isGreater = r > 255 || g > 255 || b > 255 ;
  const isLessThan = r < 0 || g < 0 || b < 0;
  if (isGreater || isLessThan) {
    throw new Error('Invalid rgb values.  Each value should be between 0-255');
  }

  return '#' + r.toString(16) + g.toString(16) + b.toString(16);
};

export const hexToRgb = hex => {
  const hexValue = hex.replace('#', '');
  if (hexValue.length !== 6) {
    throw new Error('Invalid hex value.');
  }

  const r = hexValue.substring(0, 2);
  const g = hexValue.substring(2, 4);
  const b = hexValue.substring(4, 6);

  const numR = parseInt(r, 16);
  const numG = parseInt(g, 16);
  const numB = parseInt(b, 16);

  return { r: numR, g: numG, b: numB };
};