import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTheme } from 'styled-components';
import { FovSelect, FovOption, VerticalSpacer, Body } from 'components';
import {
  SwitchContainer,
  TypeContainer,
  Option,
  DetailsContainer,
  CountInputBox,
} from './styles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';
import _ from 'lodash';

const { CANVAS_UPDATE_METHODS } = constants;
const simplificationOptions = [ 'low', 'medium', 'high', 'custom' ];

const SimplificationSwitch = withTheme(props => {
  const { theme, updateType } = props;
  const isMaterial = updateType === constants.CANVAS_UPDATE_TYPES.MATERIAL;

  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const meshSimplification = isMaterial
    ? selectedMaterial.meshSimplification
    : serializedData?.metadata.publish.meshSimplification;
  const basedOnEstimate = meshSimplification.optimizationType === 'estimated triangles';
  const custom = meshSimplification.type === 'custom';
  const basedOnTarget = meshSimplification.optimizationType === 'target triangles';
  const tooltipColor = selectedTheme === constants.THEME.LIGHT
    ? theme.colors[selectedTheme].darkGrey
    : theme.colors[selectedTheme].lightGrey;

  const updateArgs = {
    dispatch,
    type: updateType,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const getMultiplier = option => {
    switch(option) {
      case 'low':
        return 25;
      case 'medium':
        return 50;
      case 'high':
        return 75;

      default:
        return 99;
    }
  };

  const buildSimplificationOptions = () => {
    return simplificationOptions.map(option => {

      return (
        <Option
          key={option}
          theme={theme}
          $selectedTheme={selectedTheme}
          $optionSelected={meshSimplification.type === option}
          onClick={() => {
            const inboundData = {
              id: isMaterial && selectedMaterialId,
              meshSimplification: {
                type: option,
                estimatedTriangles: getMultiplier(option),
              },
            };

            if (meshSimplification.type !== option) {
              updateCanvas(updateArgs, inboundData);
            }
          }}
        >
          <Body size='Small'>
            {_.startCase(option)}
          </Body>
        </Option>
      );
    });
  };

  const buildSelectType = () => {
    if (custom) {
      return (
        <>
          <FovSelect
            value={meshSimplification.optimizationType}
            placeholder={_.capitalize(meshSimplification.optimizationType)}
            onChange={(e, newVal) => {
              const inboundData = {
                id: isMaterial && selectedMaterialId,
                meshSimplification: {
                  optimizationType: newVal,
                },
              };

              if (newVal !== meshSimplification.optimizationType) {
                updateCanvas(updateArgs, inboundData);
              }
            }}
          >
            <FovOption value='estimated triangles'>
              Estimated triangles
            </FovOption>

            <FovOption value='target triangles'>
              Target triangles
            </FovOption>
          </FovSelect>

          <VerticalSpacer size={16} />
        </>
      );
    }
  };

  const buildDetails = () => {
    return (
      <DetailsContainer inputWidth={meshSimplification.optimizationType}>
        <div>
          {
            !basedOnTarget || !custom
              ? 'Estimated triangles'
              : 'Target triangles'
          }:

          <br />

          <strong>
            {
              basedOnEstimate || !custom
                ? `${meshSimplification.targetTriangles.toLocaleString()}`
                : <Body size="X-Small" color={tooltipColor}>
                    {`max: ${meshSimplification.totalTriangles.toLocaleString()} triangles`}
                  </Body>
              }
          </strong>
        </div>

        <div>
          {custom && (
            <CountInputBox
              value={basedOnEstimate ? meshSimplification.estimatedTriangles : meshSimplification.userTargetTriangles}
              type='number'
              label={basedOnEstimate && '%'}
              min={1}
              max={basedOnEstimate ? 99 : meshSimplification.totalTriangles}
              onChange={e => {
                const key = basedOnEstimate ? 'estimatedTriangles' : 'userTargetTriangles';
                const inboundData = {
                  id: isMaterial && selectedMaterialId,
                  meshSimplification: {
                    [key]: +(e.target.value),
                  },
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />
          )}
        </div>
      </DetailsContainer>
    );
  };

  return (
    <SwitchContainer>
      <TypeContainer theme={theme} $selectedTheme={selectedTheme}>
        {buildSimplificationOptions()}
      </TypeContainer>

      <VerticalSpacer size={24} />

      {custom && (
        <>
          Optimization based on:
          <VerticalSpacer size={8} />
        </>
      )}


      {custom && buildSelectType()}
      {buildDetails()}
    </SwitchContainer>
  );
});

export { SimplificationSwitch };