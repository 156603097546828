import React from 'react';
import { useSelector } from 'react-redux';
import {
  HorizontalSpacer,
  VerticalSpacer,
  ArrowLeftOutlined,
  EyeOutlined,
  Rotate,
  DocSection,
  ImageContainer,
} from 'components';
import {
  Image,
  Caption,
  ExampleElement,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { visibility as images } from './images';
import Summary from './Summary';

const ControllingVisibility = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    'Access visibility panel from the right when a model is loaded.',
    <>
      Select one or more meshes by clicking or&nbsp;
      <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
        Shift + <Rotate />
      </ExampleElement>&nbsp;
      over them.
    </>,
    <>
      Hide selected meshes with&nbsp;
      <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
        Shift + H
      </ExampleElement>.
    </>,
    <>
      Deleted selected meshes with&nbsp;
      <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
        Delete
      </ExampleElement>.
    </>,
    <>
      Unhide all hidden meshes with&nbsp;
      <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
        Shift + U
      </ExampleElement>.
    </>,
    <>
      Unhide last hidden mesh with the&nbsp;<strong>&quot;Unhide last&quot;</strong>&nbsp;button.
    </>,
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='The Visibility Panel'>
        <p>
          The visibility panel <EyeOutlined /> helps with controlling what parts of a model are visible and
          hiding parts of the model to access more underlying meshes.
        </p>

        <ImageContainer>
          <Image src={images.visibilityPanel} width={400} alt='Visibility panel.' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          You will need to have at least one mesh item selected for the buttons to be enabled.
        </Caption>
      </DocSection>



      <DocSection section='Visibility Workflows'>
        <DocSection subsection='Selecting meshes'>
          <p>
            A common example where mesh visibility needs to be controlled is optimizing a model that
            has underlying meshes that may not need to be there.  In this example, we will remove the interior
            seats of this model, but first we need to select the glass.
          </p>

          <ImageContainer>
            <Image src={images.glassSelected} width={500} alt='Glass selected.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            You can select mesh items by clicking on them, directly.<br />
            Holding shift and dragging allows you to select multiple mesh items at once.
          </Caption>
        </DocSection>



        <DocSection subsection='Hiding and deleting meshes'>
          <p>
            With the glass selected, we can click the <strong>&quot;Hide selected&quot;</strong> button to
            hide the glass where we can then click on the interior seats to select the interior.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.hideSelectedButton} width={150} alt='Hide selected button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.interiorSelected} width={500} alt='Interior selected.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
            You can also use the shortcut&nbsp;
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Shift + H
            </ExampleElement>
            &nbsp;to hide your selected meshes.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            With the interior selected, you can click the <strong>&quot;Delete selected&quot;</strong> button
            to delete the mesh.  You will be asked to confirm before the mesh is disposed.
          </p>

          <ImageContainer>
            <Image src={images.deleteSelectedButton} width={150} alt='Delete selected button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.deleteConfirm} width={400} alt='Delete confirmation.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.interiorDeleted} width={500} alt='Interior deleted.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
            You can also use the shortcut&nbsp;
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Delete
            </ExampleElement>
            &nbsp;to delete your selected meshes.
          </Caption>
        </DocSection>



        <DocSection subsection='Unhiding meshes'>
          <p>
            To unhide all hidden meshes, click the <strong>&quot;Unhide All&quot;</strong> button.
            If you only want to reveal the last hidden mesh, there is also the&nbsp;
            <strong>&quot;Unhide last&quot;</strong> button.
          </p>

          <ImageContainer>
            <Image src={images.unhideAllButton} width={150} alt='Unhide all button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.glassUnhidden} width={500} alt='Glass unhidden.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
            You can also use the shortcut&nbsp;
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Shift + U
            </ExampleElement>
            &nbsp;to unhide all hidden meshes.
          </Caption>
        </DocSection>
      </DocSection>
    </ContentContainer>
  );
});

export { ControllingVisibility };