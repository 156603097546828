import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const LampSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const LampOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <LampSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 17 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.081 6.76018L7.94617 3.61865M12.1597 12.8744L14.5396 8.11523M14.9636 6.22644C15.5139 6.22644 15.9605 6.67305 15.9605 7.22333C15.9605 7.77361 15.5139 8.22022 14.9636 8.22022C14.4133 8.22022 13.9667 7.77361 13.9667 7.22333C13.9667 6.67305 14.4133 6.22644 14.9636 6.22644ZM7.23771 8.03552C7.43044 7.84279 7.52149 7.5703 7.48295 7.29981L7.08419 4.50852L7.77404 3.81867C8.43265 3.20858 8.47253 2.18045 7.86243 1.52118C7.253 0.862565 6.22421 0.822689 5.5656 1.43279C5.53436 1.46203 5.50379 1.49193 5.47521 1.52317L4.78536 2.21102L1.99407 1.81227C1.52221 1.74049 1.08159 2.06481 1.00981 2.53667C0.967941 2.81182 1.06165 3.09028 1.26103 3.28501L6.01154 8.03552C6.34782 8.3738 6.89478 8.37579 7.23306 8.04017C7.23505 8.03818 7.23771 8.03552 7.23771 8.03552ZM14.2319 16.0001C14.5908 16.0001 14.8819 15.709 14.8819 15.3501C14.8819 15.2823 14.8712 15.2145 14.85 15.1494C14.2332 13.6301 12.7226 12.6671 11.0844 12.7495C9.44615 12.6671 7.93553 13.6301 7.31879 15.1494C7.2078 15.491 7.39455 15.8572 7.73615 15.9682C7.80062 15.9894 7.86841 16.0001 7.93686 16.0001H14.2319Z"
      />
    </LampSVG>
  );
});

export { LampOutlined };