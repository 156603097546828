import React from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

const WidgetContainer = styled('div')`
  width: 100%;
  height: auto;

  iframe {
    width: 100%;
    min-height: 37rem;
    border: 0;
  }
`;

const { VITE_API_HOST: apiHost } = process.env;

const OktaSignInWidget = () => {
  const userDataStore = useSelector(state => state.userData);
  const [ cookies ] = useCookies([ 'userAuth' ]);

  const iframeSrc = `${apiHost}/connect/v1/avail`
    + `?uid=${userDataStore.data.userId}`
    + `&accesstoken=${cookies.archvision_token}`;

  return (
    <WidgetContainer>
      <iframe title='Login' src={iframeSrc} />
    </WidgetContainer>
  );
};

export { OktaSignInWidget };