import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { BrowserTracing } from '@sentry/tracing';
import { RouteChangeTracker } from 'components';
import { store } from 'store';
import ReactGA from 'react-ga4';
import App from './App';
import PackageJSON from '../package.json';
import * as Sentry from '@sentry/react';
import 'antd/dist/antd.css';
import './index.css';
import './App.css';

const {
  NODE_ENV,
  VITE_SENTRY_DSN: sentryDSN,
  VITE_GOOGLE_ANALYTICS_ID: analyticsID,
} = process.env;

const { name, version } = PackageJSON;
const inProduction = NODE_ENV === 'production';
if (analyticsID) {
    ReactGA.initialize(analyticsID);
}
if (inProduction && sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    release: `${name}@${version}`,
    integrations: [ new BrowserTracing() ],
    tracesSampleRate: 1.0,
  });
}

const target = document.querySelector('#root');

const FovApp = (
  <Provider store={store}>
    <BrowserRouter>
      <App />

      {analyticsID && inProduction && (
        <RouteChangeTracker />
      )}
    </BrowserRouter>
  </Provider>
);

ReactDOM.createRoot(target).render(FovApp);
