import React from 'react';
import { useSelector } from 'react-redux';
import {
  HorizontalSpacer,
  VerticalSpacer,
  MaterialOutlined,
  ArrowLeftOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Image,
  Caption,
  ExampleElement,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { materials as images } from './images';
import Summary from './Summary';

const EditingMaterials = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    'Select materials with the material select menu.',
    'Rename and show meshes with the current material selection in the viewport using quick actions.',
    <>
      Use&nbsp;<strong>&quot;Back-face culling&quot;</strong>&nbsp;
      to stop rendering of rear-facing triangles.
    </>,
    <>
      Use&nbsp;<strong>&quot;Wireframe&quot;</strong>&nbsp;
      to view mesh wireframe to confirm mesh optimization results.
    </>,
    <>
      Simplify individual meshes that have the current material assigned to it with&nbsp;
      <strong>&quot;Mesh simplification&quot;</strong>.
    </>,
    'Control basic attributes with sliders, color and texture channels.',
    <>
      Add advanced attributes to your material like&nbsp;
      <strong>&quot;Clear-coat&quot;</strong>
      &nbsp;and&nbsp;
      <strong>&quot;Transparency&quot;</strong>.
    </>,
    <>
      Switch between&nbsp;
      <strong>&quot;Simple&quot;</strong>&nbsp;transparency for alpha control, or use&nbsp;
      <strong>&quot;Complex&quot;</strong>&nbsp;for materials like glass, water etc.
    </>,
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='Materials'>
        <p>
          The Materials panel &nbsp;<MaterialOutlined /> allows you to edit pre-assigned surfaces on your model.
          It contains a plethora of sliders, toggles and selections that will help you achieve the
          most accurate look.  It also allows you to correct surface issues as well as troubleshoot and
          optimize meshes with the assigned material.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.materialsPanel} width={400} alt='Materials panel.' />
        </ImageContainer>

        <VerticalSpacer size={15} />

        <DocSection subsection = 'The materials list'>
          <p>
            The material list menu is where all of the materials for your model can be found.  Clicking a material
            will load that material&apos;s properties and settings for futher editing.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.materialsMenu} width={350} alt='Materials menu.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Clicking a mesh item in the viewport will automatically make that mesh item&apos;s attached material active
            in the Materials panel.
          </Caption>
        </DocSection>



        <DocSection subsection='Renaming materials'>
          <p>
            Below the material menu are common actions.  One of which is renaming the selected material. Clicking this
            button will bring up a naming dialogue that, once changed and confirmed, will rename your material to your
            new name.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.shoesMaterial} width={350} alt='Materi to rename, selected.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.rename} width={75} alt='Rename button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.renameModal} width={400} alt='Rename modal.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.shoesRenamed} width={350} alt='Renamed material.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Your new name must be unique from any material in the list, including the original name.
          </Caption>
        </DocSection>



        <DocSection subsection='Selecting meshes with assigned material'>
          <p>
            As mentioned above, you can select a mesh in the viewport and it will automatically select it in
            the Materials panel, but this can be performed the other way around as well, with the&nbsp;
            <strong>&quot;Select&quot;</strong> button.
          </p>

          <p>
            The <strong>&quot;Select&quot;</strong> button will highlight, in the viewport, any mesh items that
            currently have the selected material assigned to it. A soft blue outline will show on any selected
            meshes. This can be a great way to quickly find and select meshes on more complex models.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.select} width={65} alt='Select button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.shoesSelected} width={500} alt='Shoes selected in viewport.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
            You can use&nbsp;
            <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
            Shift + C
          </ExampleElement>
          &nbsp;to zoom in and frame up the selected meshes.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='General Properties'>
        <ImageContainer>
          <Image src={images.generalProperties} width={350} alt='General properties.' />
        </ImageContainer>

        <VerticalSpacer size={15} />

        <DocSection subsection='Back-face culling'>
          <p>
            As you may know, each face on a mesh has a normal direction that tells the viewport which
            direction faces are pointing, inward or outward.  If a face&apos;s direction is pointing outward,
            you have the option to control whether the back-side of that face, when visible to the camera,
            is rendered or not.
          </p>

          <p>
            Enabling <strong>&quot;Back-face culling&quot;</strong> will cause the back-side of any single-sided
            mesh to appear invisible.  Of course if the camera is rotated around to the front-facing side, the
            outward facing side will still be present and rendered as expected.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.viewportFaces} width={350} alt='Back-faces rendered.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.viewportFacesCulled} width={350} alt='Back-faces culled.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.backfaceCullOff} width={350} alt='Back-face toggled off.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.backfaceCullOn} width={350} alt='Back-face toggled on.' />
          </ImageContainer>
        </DocSection>



        <DocSection subsection='Wireframe'>
          <p>
            The wireframe toggle allows you to see the wireframe of any mesh item that has the currently
            selected material assigned to it.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.noWireframe} width={350} alt='Wireframe disabled in viewport.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.viewportWireframe} width={350} alt='Wireframe in viewport.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.wireframeOff} width={350} alt='Wireframe toggled off.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.wireframeOn} width={350} alt='Wireframe toggled on.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            This is great for confirming material optimization after publishing.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='Optimization'>

        <DocSection subsection='Mesh simplification'>
          <p>
            Mesh simplification, as the name suggests, allows you to individually reduce the
            triangle count for each mesh item that has the currently selected material assigned to it.
            Each material can have it&apos;s own simplification setting, resulting in assigned mesh items being
            able to be optimized apart from other meshes in the scene.
          </p>

          <p>
            When toggled on, you will be presented with a UI that defaults to a custom optimization setting.
            The default is <strong>&quot;Estimated triangles&quot;</strong> and allows you to set a target
            reduction by percent, i.e. 20% means you want to reduce the current material&apos;s mesh count to
            20% of it&apos;s current amount.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.optimizationOff} width={350} alt='Optimization toggled off.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.optimizationEstimated} width={300} alt='Optimization estimated.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Your estimated triangle count, based on the percent value you provided, will be updated immediately.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            You can switch optimization types from the optimization type select menu.  This will allow you
            to set a target reduction by triangle count, i.e. a value of 10,000 means you want your final
            triangle count to be reduced to 10,000 triangles.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.optimizationType} width={350} alt='Optimization type select.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.optimizationTarget} width={300} alt='Target optimization.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            <strong>&quot;Target triangles&quot;</strong> max count is the current triangle count and
            upper limit to what you can enter in the field to the right.<br />
            At this time, optimization will only take place after publishing.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            If quick mesh simplification is what you are after, you can click any of the three presets:&nbsp;
            <strong>&quot;Low&quot;</strong>,&nbsp;
            <strong>&quot;Medium&quot;</strong> or&nbsp;
            <strong>&quot;High&quot;</strong>
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer noSpace={true}>
            <Image src={images.optimizationLow} width={340} alt='Low preset.' />
            <Image src={images.optimizationMedium} width={340} alt='Medium preset.' />
            <Image src={images.optimizationHigh} width={340} alt='Hight preset.' />
          </ImageContainer>
        </DocSection>
      </DocSection>



      <DocSection section='Basic Attributes'>
        <p>
          The basics include a collection of sliders, color and material channels
          that make up the core of most materials.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.basicAttributes} width={350} alt='Basic attributes.' />
        </ImageContainer>

        <VerticalSpacer size={15} />

        <DocSection subsection = 'Color and texture channels'>
          <ImageContainer>
            <Image src={images.channel} width={350} alt='Channel slider.' />
          </ImageContainer>

          <VerticalSpacer size={15}/>

          <p>
            Channels allow you to have greater visual control over your material.  There are two
            types of channels, located to the right of the channel&apos;s slider: color channels and texture
            channels.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.colorChannel} width={50} alt='Color channel.' />
            <Image src={images.textureChannel} width={50} alt='Texture channel.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Color channels are white by default, while unpopulated texture channels have a red slash through them.
          </Caption>
        </DocSection>



        <DocSection subsection='Changing the color channel'>
          <p>
            Clicking a color channel will bring up the color palette, allowing you to choose or specifically set
            a color using the palette or input fields.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.colorChannel} width={50} alt='Color channel.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.colorPalette} width={200} alt='Color palette.' />
          </ImageContainer>
        </DocSection>



        <DocSection subsection = 'Adding a texture to the texture channel'>
          <p>
            Clicking an unpopulated texture channel will bring up the channel menu for that channel with
            the option to upload a new texture from your local drive.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.textureChannel} width={50} alt='Texture channel.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.upload} width={150} alt='Channel menu upload.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.explorerWindow} width={700} alt='Explorer window.' />
          </ImageContainer>

          <VerticalSpacer size={15} />

          <p>
            Once you have selected your file, a channel preview will be shown, signifying that channel now has
            a texture mapped to it.
          </p>

          <ImageContainer>
            <Image src={images.channelLoaded} width={350} alt='Texture channel loaded.' />
          </ImageContainer>
        </DocSection>



        <DocSection subsection='Uploading or removing a texture'>
          <p>
            If you click the texture channel with image preview, the channel menu will appear with
            a few more options...
          </p>

          <p>
            You can click <strong>&quot;Upload&quot;</strong> again to replace the current file
            with a new file. <strong>&quot;Download&quot;</strong> if you need to manipulate or change
            the existing file before re-uploading.  Or remove the texture completely with&nbsp;
            <strong>&quot;Remove&quot;</strong>.
          </p>

          <ImageContainer>
            <Image src={images.channelDownload} width={200} alt='Download file.' />
            <Image src={images.channelRemove} width={200} alt='Remove file.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Two new options on the channel menu for manipulating the existing file.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            Click the <strong>&quot;Remove&quot;</strong> button to clear the texture channel.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.removeButton} width={125} alt='Remove button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.textureRemoved} width={350} alt='Texture removed.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The texture channel has been cleared.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='Advanced Properties'>
        <p>
          The advanced section contains more in-depth material properties that
          allows you to add more advanced shading to your material.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.advancedAttributes} width={350} alt='Advanced attributes.' />
        </ImageContainer>

        <VerticalSpacer size={15} />

        <DocSection subsection='Clear-coat'>
          <p>
            When toggled on, clear-coat adds another reflective layer over the current material&apos;s existing shading.
            The effect is commonly used on metallic surfaces, such as car paint, but can be used creatively to
            create other convicing effects like sealed floors etc.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.clearCoatOn} width={350} alt='Clear-coat enabled.' />
          </ImageContainer>

          <VerticalSpacer size={15} />

          <p>
            <strong>&quot;Intensity&quot;</strong> controls how visible the effect is, while&nbsp;
            <strong>&quot;Roughness&quot;</strong> will diffuse or sharpen the clear-coat effect.&nbsp;
            <strong>&quot;IOR&quot;</strong> controls how prominent the effect is.
          </p>
        </DocSection>



        <DocSection subsection='Transparency'>
          <p>
            When toggled on, the UI for a simple transparency is shown.  Simple transparency allows you
            to control the visibility of the mesh the current material is attached to.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.transparencySimple} width={350} alt='Simple transparency.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Great for cutaways, basic glass etc.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            If you need to make a more convincing transparent material, such as glass or water, you&apos;ll
            need to switch to the <strong>&quot;Complex&quot;</strong> transparency type from the
            transparency select menu.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.transparencyMenu} width={300} alt='Transparency menu.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.transparencyComplex} width={350} alt='Complex transparency.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Toggling transparency off will reset the transparency type back to Simple with a full alpha
            value of <strong>1</strong>, but switching between transparency types will maintain your
            values.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            <strong>&quot;Alpha&quot;</strong> controls how visible the effect is, while&nbsp;
            <strong>&quot;Refraction intensity&quot;</strong> will increase or decrease reflection visibility.&nbsp;
            <strong>&quot;IOR&quot;</strong> controls how thick or thin the material is, i.e.&nbsp;
            <strong>1</strong> for air, <strong>1.33</strong> for water,&nbsp;
            <strong>1.54 for glass</strong> etc.
          </p>
        </DocSection>
      </DocSection>
    </ContentContainer>
  );
});

export { EditingMaterials };