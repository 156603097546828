import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DocNote, VerticalSpacer } from 'components';
import { withTheme } from 'styled-components';
import {
  InstructionsContainer,
  LogoContainer,
  Logo,
  UploadInteractions,
  Droptext,
  Droptext2,
  Droptext3,
} from './styles';
import fullScreenLogo from 'assets/branding/Fovea_arc.svg';
import _ from 'lodash';

const DragDropInstructions = withTheme(props => {
  const { theme } = props;
  const { pathname } = useLocation();

  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedTheme = userDataStore.userPreferences.theme;
  const onCreate = pathname.includes('/create');
  const publishSettings = serializedData?.metadata?.publish;
  const modelLoaded = userDataStore.modelLoaded || !_.isEmpty(publishSettings);
  const showUploadArea = (onCreate && !modelLoaded);

  return (
    <InstructionsContainer
      theme={theme}
      $selectedTheme={selectedTheme}
      modelLoaded={modelLoaded}
    >
      {showUploadArea && (
        <>
          <LogoContainer>
            <Logo src={fullScreenLogo} />
          </LogoContainer>

          <VerticalSpacer size={48} />

          <UploadInteractions>
            <Droptext>
              <DocNote path={[ 'uploadArea' ]} right={-35} top={2} />
              Drag & Drop Files To Begin
              <VerticalSpacer size={10} />
            </Droptext>

            <Droptext2>
              must be in the following formats:
            </Droptext2>

            <Droptext3>
              gLTF (with .bin + textures folder, if needed) or GLB
            </Droptext3>
          </UploadInteractions>
        </>
      )}
    </InstructionsContainer>
  );
});

export { DragDropInstructions };