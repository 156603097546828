import React from 'react';
import { useSelector } from 'react-redux';
import { Body } from 'components';
import { withTheme } from 'styled-components';
import { ProtectedContent } from 'components/generic/ProtectedContent';

const DevNote = withTheme(props => {
  const { theme, ticketNumber, note } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <ProtectedContent requiredRoles={[ 'developer' ]}>
      <Body size="X-Small" color={theme.colors[selectedTheme].warning + 60}>
        {note}
        {ticketNumber && (
          <a
            href={`https://archvision.atlassian.net/browse/RPCCREATOR-${ticketNumber}`}
            rel='noreferrer'
            target='blank'
          >
            RPCCREATOR-{ticketNumber}
          </a>
        )}
      </Body>
    </ProtectedContent>
  );
});

export { DevNote };