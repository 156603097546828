import _ from 'lodash';

export const buildJSONObjects = args => {
  const {
    values,
    userDataStore,
    publishName,
    metadata,
    debugState,
    useCustomProxyMesh,
    callback,
  } = args;

  const meshSimplification = metadata?.publish?.meshSimplification;
  const textureDownscaling = metadata?.publish?.textureDownscaling;
  const proxyType = metadata?.publish?.proxy;

  let propertiesJSON = {
    isEnablingDebug: debugState,
   };

  const downscaledMaterials = metadata?.materials?.map(material => {
    const { materialId, textureDownscaling } = material;

    if (textureDownscaling?.enabled) {
      return {
        name: materialId,
        width: textureDownscaling?.size,
        height: textureDownscaling?.size,
      };
    }
  }).filter(mat => mat) || [];

  const simplifiedMaterials = metadata?.materials.map(material => {
    const { materialId, meshSimplification } = material;
    const optimizationType = meshSimplification.optimizationType;
    const optimizationAmount = optimizationType === 'target triangles'
      ? meshSimplification?.userTargetTriangles / meshSimplification?.totalTriangles
      : meshSimplification?.estimatedTriangles * 0.01;

    if (meshSimplification?.enabled) {
      return {
        materialName: materialId,
        simplificationAmt: optimizationAmount,
      };
    }
  }).filter(mat => mat) || [];

  // Metadata.json generation
  // NOTE: The author key should eventually be used when users can be vendors
  const metadataJSON = {
    author: userDataStore.data && userDataStore.data?.name,
    categoryName: values.categoryName,
    contentName: location.pathname?.includes('models') && publishName || values.contentName,
    email: userDataStore.data && userDataStore.data?.email,
    description: values.tags,
  };

  // Global mesh simplification
  if (meshSimplification.enabled) {
    const optimizationType = meshSimplification.optimizationType;
    const optimizationAmount = optimizationType === 'target triangles'
      ? meshSimplification.userTargetTriangles / meshSimplification.totalTriangles
      : meshSimplification.estimatedTriangles * 0.01;

    propertiesJSON = {
      ...propertiesJSON,
      useModelOptimization: true,
      optimizationAmount,
    };
  } else if (simplifiedMaterials?.length > 0) {
    // Material specific mesh simplification
    propertiesJSON = {
      ...propertiesJSON,
      simplifiedMaterials,
    };
  }

  // Global texture downscaling
  if (textureDownscaling.enabled) {
    propertiesJSON = {
      ...propertiesJSON,
      useTextureSimplification: true,
      textureWidth: `${textureDownscaling.size}`,
      textureHeight: `${textureDownscaling.size}`,
    };
  }

  // Material specific downscaling
  propertiesJSON = {
    ...propertiesJSON,
    downscaledMaterials,
  };

  // Proxy mesh type
  propertiesJSON = {
    ...propertiesJSON,
    useCustomProxyMesh,
    proxyMesh: proxyType,
  };

  if (_.toLower(proxyType) === 'simplified mesh') {
    propertiesJSON = {
      ...propertiesJSON,
      decimateAmount: meshSimplification.decimateAmount,
    };
  }

  // Statistics
  propertiesJSON = {
    ...propertiesJSON,
    statistics: {
      materials: metadata?.materials?.length,
      textures: +metadata?.statistics?.textures.replace(/,/g, ''),
      meshes: metadata.meshChangeTracking.length,
      triangles: +(metadata.statistics.triangles.replace(/,/g, '')),
      vertices: +(metadata.statistics.vertices.replace(/,/g, '')),
    },
  };

  callback?.({ metadataJSON, propertiesJSON });
};