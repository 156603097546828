import { CloseOutlined } from 'components/icons';
import styled from 'styled-components';
import constants from 'constants/index';

const getColor = (props, key, useError) => {
  const themeColors = props.theme.colors[props.$selectedTheme];

  if (useError) {
    return themeColors.error;
  }

  return themeColors[key];
};

const { HEADER_HEIGHT, FOOTER_HEIGHT } = constants;

export const CanvasContainer = styled('div')`
  position: relative;
  width: 100%;
  height: calc(100vh - ${(HEADER_HEIGHT + FOOTER_HEIGHT) / 16}rem);
`;

export const ToolbarContainer = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 4rem;
`;

export const Toolbar = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-left: 0.125rem solid ${props => getColor(props, 'mediumGrey')};
  background-color: ${props => getColor(props, 'primary')};
  padding: 0 0 1rem 0;
`;

export const ToolbarTab = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  color: ${props => getColor(props, props.active ? 'accent' : 'onPrimary')};

  &:hover {
    color: ${props => getColor(props, 'accent')};
  }
`;

export const ToolbarIconContainer = styled('div')`
  background-color: ${props => getColor(props, 'primary')};
`;

export const ToolbarArea = styled('div')`
  position: relative;
`;

export const ModelPanel = styled('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 4rem;
  width: 23rem;
  padding: 1.5rem;
  height: 100%;
  border-left: 0.125rem solid ${props => getColor(props, 'mediumGrey')};
  background-color: ${props => getColor(props, 'primary')};
  color: ${props => getColor(props, 'onPrimary')};
  overflow-y: auto;
`;

export const PanelTitle = styled('div')`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  margin: 0;
  color: ${props => getColor(props, 'onPrimary')};
`;

export const CloseIcon = styled(CloseOutlined)`
  cursor: pointer;
`;

export const IconTitlePair = styled('div')`
  display: inline-flex;
  align-items: center;
`;

export const DeletionControlsContainer = styled('div')`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;