import React, { useState, Fragment, useMemo, useRef, useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withTheme } from 'styled-components';
import { Body } from 'components/typography';
import { FovToggle } from 'components/generic';
import { useCookies } from 'react-cookie';
import { WarningOutlined, CreditsOutlined, FovButton, DocNote } from 'components';
import { VerticalSpacer, FovDivider, HorizontalSpacer } from 'components/layout';
import {
  FovInput,
  SimplificationSwitch,
  FovSelect,
  FovOption,
  FovFieldError,
} from 'components/form';
import {
  TagsContainer,
  MainPublishContainer,
  ToggleContainer,
  TitleWithTooltipContainer,
  CreditBalance,
  BalanceContainer,
  AddCreditsContainer,
  ButtonContainer,
} from './publishStyles';
import { Tags } from 'components/tags';
import { ProtectedContent } from 'components/generic/ProtectedContent';
import { setPublishName, createRpc, Proxies } from 'store/actions/rpc';
import { getUserData } from 'store/actions/userData';
import { MESSAGE_TYPES } from 'components/notifications';
import { addMessage } from 'store/actions/app';
import { setExportedModelData, setExportModel, setBillboardImages } from 'store/actions/canvas';
import { updateCanvas, getBase64StringFromDataURL, wssCallback, DOWNSCALE_SIZES, buildJSONObjects } from 'utils';
import JSZip from 'jszip';
import constants from 'constants/index';
import Proxy from './Proxy';
import _ from 'lodash';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const buildCategoriesOptions = rpcCategories => {
  if (!_.isEmpty(rpcCategories)) {
    return rpcCategories.map(category => {
      const hasChildren = category.children?.length > 0;
      const childrenArray = hasChildren && [ ...category.children, category ];
      const arrayWithNested = hasChildren ? childrenArray.reverse() : [ category ];

      return arrayWithNested.map((item, index) => {
        const { title, value, children } = item;

        return (
          <FovOption
            key={index}
            value={value}
            indent={!children}
            groupParent={children}
          >
            {title}
          </FovOption>
        );
      });
    });
  }

  return (
    <FovOption value='No categories found.'>
      No categories found.
    </FovOption>
  );
};
const downscaleOptions = DOWNSCALE_SIZES?.map((option, index) => (
  <FovOption key={index} value={option.value}>
    {option.name}
  </FovOption>
));

const Publish = withTheme(props => {
  const { theme } = props;

  const [ cookies ] = useCookies([ 'userAuth' ]);

  const { modelId } = useParams();
  const dispatch = useDispatch();
  const publishData = useRef();
  const token = cookies.archvision_token;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const category = serializedData?.metadata.publish.category;
  const meshSimplification = serializedData?.metadata.publish.meshSimplification;
  const textureDownscaling = serializedData?.metadata.publish.textureDownscaling;
  const statistics = serializedData?.metadata.statistics;
  const fileName = serializedData?.metadata.fileName;
  const userDataStore = useSelector(state => state.userData);
  const modelsLoading = userDataStore.modelsLoading;
  const selectedTheme = userDataStore.userPreferences.theme;
  const metadata = canvasStore.serializedData?.metadata;
  const billboardImages = canvasStore.billboardImages;
  const proxy = serializedData?.metadata.publish.proxy;
  const isCustomMesh = _.toLower(proxy) === _.toLower(Proxies.customMesh);
  const userId = userDataStore.data.userId;

  const formikRef = useRef();
  const rpcStore = useSelector(state => state.rpc);
  const publishName = rpcStore.publishName || userDataStore.creatorUser?.find(item => item.rpc_guid === modelId)?.title;

  const [ debugState, setDebugState ] = useState(false);

  const location = useLocation();
  const guid = location.pathname?.split('models/')[1];
  const onModels = location.pathname?.includes('/models');
  const onCreators = location.pathname?.includes('/creator-models');
  const creditBalance = userDataStore?.data?.user_points || 0;

  const exportedModelData = canvasStore.exportedModelData;
  const creators = useMemo(() => userDataStore.creators, [ userDataStore.creators ]);
  const userModels = useMemo(() => userDataStore.userModels, [ userDataStore.userModels ]);
  const models = useMemo(() => {
    const workingData = onCreators ? creators : userModels;
    const allModels = workingData?.filteredResults?.map(partner => partner.models)?.flat() || [];

    return allModels;
  }, [ onCreators, userDataStore ]);

  const modelInfo = useMemo(() => {
    return userDataStore.creatorUser?.find(item => item.rpc_guid === modelId)
    || models?.find(model => model.rpc_guid === modelId);
  }, [ userDataStore.creatorUser, models, modelId ]);

  const metadataTags = serializedData?.metadata.publish.tags;
  const tagsString = modelInfo?.tags && (_.isString(modelInfo.tags) ? JSON.parse(modelInfo.tags) : modelInfo.tags);
  const publishedTags = tagsString || [];
  const tags = !_.isEmpty(metadataTags) ? metadataTags?.join(',') : publishedTags?.join(',');
  const rpcCategories = rpcStore.rpcCategories;
  const tooltipColor = selectedTheme === constants.THEME.LIGHT
    ? theme.colors[selectedTheme].darkGrey
    : theme.colors[selectedTheme].lightGrey;

  const allCategories = useMemo(() => {
    const workingCategories = rpcCategories.map(category => {
      const { children } = category;
      const all = [ category.title ];
      children.forEach(subCategory => all.push(subCategory.title));

      return all;
    }).flat();

    return workingCategories;
  }, [ rpcCategories ]);

  const categoryExists = allCategories.includes(modelInfo?.category);


  const defaults = {
    contentName: publishName || modelInfo?.title || fileName || '',
    categoryName: (categoryExists && modelInfo?.category) || category || 'Other',
    tags: !_.isEmpty(tags) ? tags : '',
    useModelOptimization: meshSimplification?.enabled,
    useTextureSimplification: textureDownscaling?.enabled,
    files: '',
  };

  const schema = yup.object().shape({
    contentName: yup.string().required('Name is required.'),
    categoryName: yup.string().required('Category is required.'),
    tags: yup.string().required('At least one tag is required.'),
  });

  const categories = useMemo(() => {
    return buildCategoriesOptions(rpcCategories);
  }, [ rpcCategories ]);

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.PUBLISH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  useEffect(() => {
    if (!_.isEmpty(exportedModelData) && publishData.current && !_.isEmpty(billboardImages)) {
      submitCallback();
    }
  }, [ exportedModelData, publishData, billboardImages ]);

  const submitCallback = () => {
    const propertiesJSON = publishData?.current?.data?.propertiesJSON;
    const metadataJSON = publishData?.current?.data?.metadataJSON;
    const setSubmitting = publishData?.current?.setSubmitting;
    const proxyMesh = publishData?.current?.proxyMesh;
    const zip = new JSZip();
    const updateCredits = () => dispatch(getUserData(token));

    // Zip metadata.json and properties.json
    zip.file('metadata.json', JSON.stringify(metadataJSON));
    zip.file('properties.json', JSON.stringify(propertiesJSON));

    // Zip custom proxy mesh
    if (proxyMesh?.size > 0) {
      const ext = proxyMesh?.name?.split('.').pop();
      ext && zip.file(`proxyMesh.${ext}`, proxyMesh);
    }

    const cleanup = () => {
      setSubmitting(false);
      dispatch(setExportModel(false));
      dispatch(setExportedModelData(null));
      dispatch(setBillboardImages([]));
      publishData.current = null;
    };

    // Zip preview.png, top.png, front.png and right.png
    const previewImage = billboardImages.find(image => image.name === 'perspective')?.imageData;
    const topImage = billboardImages.find(image => image.name === 'orthoTop')?.imageData;
    const frontImage = billboardImages.find(image => image.name === 'orthoFront')?.imageData;
    const rightImage = billboardImages.find(image => image.name === 'orthoRight')?.imageData;

    zip.file('preview.png', getBase64StringFromDataURL(previewImage), { base64: true });
    zip.file('top.png', getBase64StringFromDataURL(topImage), { base64: true });
    zip.file('front.png', getBase64StringFromDataURL(frontImage), { base64: true });
    zip.file('right.png', getBase64StringFromDataURL(rightImage), { base64: true });

    // Zip model.glb
    for (const key in exportedModelData) {
      zip.file('model.glb', exportedModelData[key]);
      break;
    }

    const wssArgs = { token, dispatch, userModels };
    const payload = { job_type: 'GLB_TO_RPC', zip };
    const createArgs = {
      token,
      userId,
      payload,
      debugState,
      handleSockets: () => token && wssCallback(wssArgs),
      onComplete: () => {
        updateCredits();
        cleanup();
      },
    };

    dispatch(createRpc(createArgs));
  };

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const message = 'Packaging model…';
    const config = { type: MESSAGE_TYPES.loading, showModal: true };
    const workingFile = values.files?.[0];
    let renamedFile;

    if (workingFile) {
      const { name } = workingFile;
      const ext = name.split('.').pop();
      renamedFile = new File([ workingFile ], `proxyMesh.${ext}`, { type: workingFile.type });
    }

    const submitArgs = {
      values,
      userDataStore,
      publishName,
      useCustomProxyMesh: isCustomMesh && renamedFile?.size > 0,
      metadata,
      debugState,
      dispatch,
      callback: data => {
        const workingPublishData = {
          data,
          setSubmitting,
          values,
          proxyMesh: renamedFile || false,
        };

        dispatch(setExportedModelData());
        publishData.current = workingPublishData;
      },
    };

    dispatch(addMessage([ { message, config } ]));
    dispatch(setExportModel(true));
    buildJSONObjects(submitArgs);
  };

  const submitText = () => {
    if ((onCreators  || onModels) && modelsLoading) {
      return 'Model data loading...';
    }

    if (!onCreators && !modelsLoading) {
      return 'Publish new...';
    }

    return 'Publish to My Files';
  };

  const setRef = form => {
    if (!formikRef.current) {
      formikRef.current = form;
    }
  };
  useEffect(() => {
    if (!_.isEmpty(publishName)) {
      // If file name is changed in AppHeader.js, update field value here in Publish panel.
      formikRef.current?.setFieldValue('contentName', publishName);
    }
  }, [ publishName ]);

  return (
    <MainPublishContainer theme={theme} $selectedTheme={selectedTheme}>
      <CreditBalance>
        <VerticalSpacer size={15} />

        <BalanceContainer>
          <CreditsOutlined width={18} height={18} /> <HorizontalSpacer size={10} />
          {creditBalance?.toLocaleString('en-US', { maximumFractionDigits: 2 })} Credits remaining.
          <VerticalSpacer size={10} />
        </BalanceContainer>


        <AddCreditsContainer>
          <strong>
            <Link to='//archvision.com/fovea/#pricing' rel='noreferrer' target='_blank'>
              Add more credits...
            </Link>
          </strong>

          <DocNote path={[ 'publishPanel', 'addCredits' ]} top={-2} left={157} />
        </AddCreditsContainer>

        <VerticalSpacer size={15} />
        <FovDivider />
      </CreditBalance>


      <Formik
        initialValues={defaults}
        validationSchema={schema}
        enableReinitialization={true}
        onSubmit={(values, handlers) => {
          const { setSubmitting } = handlers;
          handleSubmit({ values, setSubmitting });
        }}
      >
        {form => (
          <Fragment>
            {setRef(form)}

            <Body size='Medium Bold'>
              <VerticalSpacer size={15} />
              Description
              <DocNote path={[ 'publishPanel', 'description' ]} top={13} left={95} />
              <VerticalSpacer size={15} />
            </Body>

            Name*
            <VerticalSpacer size={10} />

            <Field type='text' name='contentName'>
              {data => (
                <FovInput
                  value={data.field.value}
                  disabled={modelsLoading}
                  placeholder={modelsLoading ? submitText() : ''}
                  {...data.field}
                  onKeyPress={e => e.key === 'Enter' && e.target.blur()}
                  onBlur={e => {
                    dispatch(setPublishName(e.target.value));
                    updateCanvas(updateArgs, { title: e.target.value });
                  }}
                  onChange={e => {
                    const newVal = e.target.value;
                    form.setFieldValue(data.field.name, newVal);
                  }}
                />
              )}
            </Field>

            <FovFieldError name='contentName' />

            Category*
            <VerticalSpacer size={10} />

            <Field type='select' name='categoryName'>
              {data => (
                <FovSelect
                  value={data.field.value}
                  placeholder={data.field.value}
                  disabled={_.isEmpty(rpcCategories)}
                  onChange={(e, newVal) => {
                    form.setFieldValue(data.field.name, newVal);
                    updateCanvas(updateArgs, { category: newVal });
                  }}
                >
                  {categories}
                </FovSelect>
              )}
            </Field>

            <FovFieldError name='categoryName' />

            Tags*
            <VerticalSpacer size={10} />

            <TagsContainer theme={theme} $selectedTheme={selectedTheme}>
              <Field type='input' name='tags'>
                {data => (
                  <Tags
                    values={_.isEmpty(data.field.value) ? [] : data.field.value.split(',')}
                    onChange={(e, tags) => {
                      const inboundData = {
                        tags: !_.isEmpty(tags) ? tags.split(',') : [],
                      };

                      form.setFieldValue(data.field.name, tags);
                      updateCanvas(updateArgs, inboundData);
                    }}
                  />
                )}
              </Field>
            </TagsContainer>

            <FovFieldError name='tags' />

            <Body>
              Proxy
              <DocNote path={[ 'publishPanel', 'proxy' ]} top={-1} left={50} />
            </Body>

            <Proxy
              form={form}
              formDefaults={defaults}
              guid={guid}
              updateArgs={updateArgs}
            />

            <VerticalSpacer size={30} />
            <FovDivider />
            <VerticalSpacer size={15} />


            <Body size='Medium Bold'>
                Global optimization
                <Body size="X-Small" color={tooltipColor}>
                  <WarningOutlined width={15} height={15} /> Overrides local material optimizations.
                </Body>
                <DocNote path={[ 'publishPanel', 'globalOptimization' ]} top={-2} left={165} />
            </Body>

            <VerticalSpacer size={15} />

            <ToggleContainer>
              <TitleWithTooltipContainer>
                Texture downscaling
                <Body size="X-Small" color={tooltipColor}>
                  Affects textures larger than selected size.
                </Body>
              </TitleWithTooltipContainer>

              <Field type='checkbox' name='useTextureSimplification'>
                {data => (
                  <FovToggle
                    checked={data.field.value}
                    disabled={!statistics?.triangles}
                    callback={newVal => {
                      const inboundData = {
                        textureDownscaling: {
                          enabled: newVal,
                        },
                      };

                      form.setFieldValue(data.field.name, newVal);
                      updateCanvas(updateArgs, inboundData);
                    }}
                  />
                )}
              </Field>
            </ToggleContainer>

            {textureDownscaling?.enabled && (
              <>
                <VerticalSpacer size={10} />

                <FovSelect
                  value={textureDownscaling?.size}
                  placeholder={DOWNSCALE_SIZES.find(size => size.value === +textureDownscaling?.size)?.name}
                  onChange={(e, newVal) => {
                    const inboundData = {
                      textureDownscaling: {
                        size: +(newVal),
                      },
                    };

                    updateCanvas(updateArgs, inboundData);
                  }}
                >
                  {downscaleOptions}
                </FovSelect>

                <VerticalSpacer size={15} />
              </>
            )}

            <VerticalSpacer size={15} />

            <ToggleContainer>
              <TitleWithTooltipContainer>
                Mesh simplification
              </TitleWithTooltipContainer>

              <Field type='checkbox' name='useModelOptimization'>
                {data => (
                  <FovToggle
                    checked={data.field.value}
                    disabled={!statistics?.triangles}
                    callback={newVal => {
                      const inboundData = {
                        meshSimplification: {
                          enabled: newVal,
                        },
                      };

                      form.setFieldValue(data.field.name, newVal);
                      updateCanvas(updateArgs, inboundData);
                    }}
                  />
                )}
              </Field>
            </ToggleContainer>

            {meshSimplification?.enabled && (
              <SimplificationSwitch updateType={CANVAS_UPDATE_TYPES.PUBLISH} />
            )}

            <VerticalSpacer size={40} />

            <ProtectedContent>
              <FovDivider />
              <VerticalSpacer size={15} />

              <ToggleContainer>
                Debug package

                <FovToggle
                  name='debugToggle'
                  checked={debugState}
                  callback={() => setDebugState(!debugState)}
                />
              </ToggleContainer>

              <VerticalSpacer size={25} />
            </ProtectedContent>

            <ButtonContainer>
              <FovButton
                type='submit'
                disabled={creditBalance === 0
                  || !statistics?.triangles
                  || modelsLoading
                  || form.isSubmitting
                }
                onClick={form.handleSubmit}
              >
                {submitText()}
              </FovButton>
            </ButtonContainer>

            {creditBalance === 0 && (
              <AddCreditsContainer>
                <VerticalSpacer size={10} />

                <strong>
                  <Link to='//archvision.com/fovea/#pricing' rel='noreferrer' target='_blank'>
                    Add more credits...
                  </Link>
                </strong>
              </AddCreditsContainer>
            )}
          </Fragment>
        )}
      </Formik>
    </MainPublishContainer>
  );
});

export default Publish;
