import { getColor } from 'utils';
import styled from 'styled-components';

export const MeshVariantsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  left: 2rem;
  bottom: 2rem;
  width: 17rem;
  border-radius: 0.25rem;
  background-color: ${props => getColor(props, 'primary')};
`;