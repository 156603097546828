import React from 'react';
import { Navigate } from 'react-router-dom';
import { PageError } from 'components';
import { allRoles, subscribedRoles } from 'components/generic/ProtectedContent';

// NOTE: These are imported separately to avoid warnings during testing.
import { Home } from 'pages/Home';
import { Welcome } from 'pages/Welcome';
import { MyFiles } from 'pages/MyFiles';
import { Store } from 'pages/Store';
import { ModelCanvas } from 'pages/ModelCanvas';
import { Creators } from 'pages/Creators';
import { Roadmap } from 'pages/Roadmap';
import { Docs } from 'pages/Documentation';

import NoAccessText from './NoAccessText';
import { Jobs } from 'pages/Jobs';

const routes = [
  {
    path: '*',
    element: <PageError
      title='404: Not Found'
      text='The page you are looking for does not exist.'
    />,
  },
  {
    path: '/no-access',
    element: <PageError
      type='info'
      title="Insufficient privileges."
      text={<NoAccessText />}
    />,
  },
  {
    path: '/',
    element: <Home />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/welcome',
    element: <Welcome />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/my-files',
    element: <MyFiles />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/jobs',
    element: <Jobs />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/store',
    element: <Store />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/models',
    element: <Navigate to='/' />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/creators',
    element: <Creators />,
    authenticate: true,
    allowedRoles: subscribedRoles,
  },
  {
    path: '/creator-models',
    element: <Navigate to='/' />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/create',
    element: <ModelCanvas />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/models/:modelId',
    exact: true,
    element: <ModelCanvas />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/creator-models/:modelId',
    exact: true,
    element: <ModelCanvas />,
    authenticate: true,
    allowedRoles: allRoles,
  },
  {
    path: '/roadmap',
    element: <Roadmap />,
  },
  {
    path: '/documentation',
    element: <Docs />,
    allowedRoles: allRoles,
  },
  {
    path: '/documentation/:id',
    element: <Docs />,
    allowedRoles: allRoles,
  },
];

export default routes;