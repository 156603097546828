import React from 'react';

const CarRightSideIcon = props => {
  const { width, height } = props;

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill='currentColor'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 4.99994H21C22.1 4.99994 23 5.88994 23 6.99994V15.9999H21C21 17.6599 19.66 18.9999 18 18.9999C16.34 18.9999 15 17.6599 15 15.9999H9C9 17.6599 7.66 18.9999 6 18.9999C4.34 18.9999 3 17.6599 3 15.9999H1V10.9999L7 4.99994ZM9 6.99994H8L5 9.99994H9V6.99994ZM15 6.99994H11V9.99994H15V6.99994ZM21 6.99994H17V9.99994H21V6.99994ZM18 17.2499C18.69 17.2499 19.25 16.6899 19.25 15.9999C19.25 15.3099 18.69 14.7499 18 14.7499C17.31 14.7499 16.75 15.3099 16.75 15.9999C16.75 16.6899 17.31 17.2499 18 17.2499ZM6 17.2499C6.69 17.2499 7.25 16.6899 7.25 15.9999C7.25 15.3099 6.69 14.7499 6 14.7499C5.31 14.7499 4.75 15.3099 4.75 15.9999C4.75 16.6899 5.31 17.2499 6 17.2499ZM3 13.9999H3.78C4.33 13.3899 5.12 12.9999 6 12.9999C6.88 12.9999 7.67 13.3899 8.22 13.9999H15.78C16.33 13.3899 17.11 12.9999 18 12.9999C18.89 12.9999 19.67 13.3899 20.22 13.9999H21V11.9999H3V13.9999Z" />
    </svg>
  );
};

export { CarRightSideIcon };
