/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleOutlined, HorizontalSpacer } from 'components';
import {
  RoadmapContainer,
  QuarterContainer,
  Quarter,
  QuarterItems,
  RoadmapItem,
  ItemTitle,
  ItemType,
  ItemDescription,
  RoadmapSpacer,
  RoadmapDot,
} from './roadmapStyles';
import { withTheme } from 'styled-components';
import roadmap from './roadmapItems';
import { ProtectedContent, standardRoles, basicRole, businessRoles, superUsers } from 'components/generic/ProtectedContent';
import { hasAccess } from 'navigation/navigationHelpers';

const Roadmap = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const temporaryRole = userDataStore.temporaryRole;
  const userRoles = temporaryRole !== 'default' ? [ temporaryRole ] : userDataStore?.data?.roles;
  const allowedRoles = [ ...basicRole, ...standardRoles, ...businessRoles ];
  const isSuperUser = hasAccess(userRoles, superUsers);
  const accent = theme.colors[selectedTheme].accent;
  const colors = {
    yellow: '#F4A238',
    orange: '#EC8030',
    magenta: '#863582',
    lavender: '#7461A9',
    blue: '#5EC9F3',
  };

  const quarterlyItems = (requestedQuarter, requestedYear) => {
    const yearObj = roadmap.find(item => +item.year === +requestedYear);
    const quarterObj = yearObj?.quarters.find(item => item.quarter === requestedQuarter);
    const tasks = quarterObj?.items.map((item, index) => {
      const { title, type, description, complete } = item;
      const previousType = quarterObj.items[index - 1]?.type?.toLowerCase();
      const wasIntenral = previousType === 'internal';
      const hideSpacer = index === 1 && wasIntenral;

      return (
        <ProtectedContent
          key={index}
          requiredRoles={type.toLowerCase() !== 'internal' && allowedRoles}
        >
          {((index !== 0 && !hideSpacer) || (index !== 0 && isSuperUser)) && (
            <RoadmapSpacer theme={theme} $selectedTheme={selectedTheme} $complete={complete}>
              <RoadmapDot theme={theme} $selectedTheme={selectedTheme} $complete={complete} />
            </RoadmapSpacer>
          )}

          <RoadmapItem>
            <ItemTitle>
              {title}<HorizontalSpacer size={10} />
              {complete && <CheckCircleOutlined color={accent} />}
            </ItemTitle>

            <ItemDescription>{description}</ItemDescription>

            <ItemType theme={theme} $selectedTheme={selectedTheme} $type={type}>
              {type}
            </ItemType>
          </RoadmapItem>
        </ProtectedContent>
      );
    });

    return tasks;
  };

  const buildQuarter = (quarter, year, color) => {
    return (
      <QuarterContainer theme={theme} $selectedTheme={selectedTheme}>
        <Quarter theme={theme} $selectedTheme={selectedTheme} color={color}>
          {quarter}

          <strong>
            {year.toString().replace('20', `'`)}
          </strong>
        </Quarter>

        <QuarterItems>
          {quarterlyItems(quarter, year)}
        </QuarterItems>
      </QuarterContainer>
    );
  };

  return (
    <RoadmapContainer theme={theme} $selectedTheme={selectedTheme}>
      {buildQuarter('Q3', 2022, colors.yellow)}
      {buildQuarter('Q4', 2022, colors.orange)}
      {buildQuarter('Q1', 2023, colors.magenta)}
      {buildQuarter('Q2', 2023, colors.lavender)}
      {buildQuarter('Q3', 2023, colors.blue)}
    </RoadmapContainer>
  );
});

export { Roadmap };