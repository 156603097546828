import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const UndoOutlined = withTheme(props => {
  const { width, height, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path fill={color || theme.colors[selectedTheme].onPrimary}
        d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V6.36842C0 6.92071 0.447715 7.36842 1 7.36842H6.36842C6.92071 7.36842 7.36842 6.92071 7.36842 6.36842C7.36842 5.81614 6.92071 5.36842 6.36842 5.36842H3.73788C4.18347 4.87961 4.65586 4.37026 4.96045 4.06567C6.23779 2.7883 7.99957 2 9.9474 2C13.8424 2 17 5.15758 17 9.05263C17 12.9477 13.8424 16.1053 9.9474 16.1053C7.99957 16.1053 6.23779 15.317 4.96046 14.0396C4.56994 13.6491 3.93677 13.6491 3.54624 14.0396C3.15571 14.4301 3.1557 15.0633 3.54622 15.4538C5.18331 17.0909 7.44785 18.1053 9.9474 18.1053C14.947 18.1053 19 14.0522 19 9.05263C19 4.05301 14.947 0 9.9474 0C7.44784 0 5.18331 1.01434 3.54622 2.65147C3.15552 3.04217 2.55012 3.70043 2.05517 4.24616C2.03665 4.26658 2.01825 4.28688 2 4.30702V1Z"
      />
    </svg>
  );
});

export { UndoOutlined };