import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { addMessage, clearMessages } from 'store/actions/app';
import {
  setSerializedData,
  clearUpdateData,
  clearSerializedData,
  setReinitializeCanvas,
  setExportModel,
  setExportedModelData,
  setBillboardImages,
  setGuidURL,
} from 'store/actions/canvas';
import { downloadModel } from 'store/actions/rpc';
import { useCookies } from 'react-cookie';
import { CanvasContainer } from './SideMenus/ModelToolbarStyles';
import { DragDropInstructions } from './DragDropInstructions';
import { MeshVariants } from './MeshVariants/MeshVariants';
import { FovConfirmation, Body } from 'components';
import Canvas from '@archvisioninc/canvas';
import ModelToolbar from './SideMenus/ModelToolbar';
import _ from 'lodash';

const debugLogs = false;

const ModelCanvas = withTheme(props => {
  const { theme } = props;
  const [ cookies ] = useCookies([ 'userAuth' ]);
  const [ confirmMessage, setConfirmMessage ] = useState(null);

  const dispatch = useDispatch();
  const token = cookies.archvision_token;
  const userDataStore = useSelector(state => state.userData);
  const appStore = useSelector(state => state.app);
  const canvasStore = useSelector(state => state.canvas);
  const messages = appStore.messages;
  const selectedTheme = userDataStore.userPreferences.theme;
  const location = useLocation();
  const path = location.pathname?.split('models/');
  const guid = path[1];
  const updateData = useMemo(() => canvasStore.updateData, [ canvasStore ]);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const exportModel = useMemo(() => canvasStore.exportModel, [ canvasStore ]);
  const defaultEnvironments = canvasStore.defaultEnvironments;
  const errorColor = theme.colors[selectedTheme].error;
  const errorHoveredColor = theme.colors[selectedTheme].errorHover;
  const reinitializeCanvas = canvasStore.reinitializeCanvas;
  const guidURL = canvasStore.guidURL;

  const cleanupCanvasStore = () => {
    dispatch(clearUpdateData());
    dispatch(clearSerializedData());
    dispatch(setGuidURL(null));
    dispatch(setReinitializeCanvas(false));
    dispatch(setExportModel(false));
    dispatch(setExportedModelData(null));
  };

  const getDownloadURL = useCallback(() => {
    if (guid) {
      const args = { token, rpcGuid: guid, format: 'GLB' };
      dispatch(downloadModel(args)).then(url => dispatch(setGuidURL(url)));
      return;
    }

    cleanupCanvasStore();
  }, [ guid ]);

  useEffect(() => {
    getDownloadURL();
    return () => cleanupCanvasStore();
  }, [ location.pathname, reinitializeCanvas ]);

  useEffect(() => {
    if (debugLogs) {
      if (updateData) console.log(updateData);
      if (!_.isEmpty(serializedData)) console.log(serializedData);
    }
  }, [ updateData, serializedData ]);

  return (
    <CanvasContainer>
      <DragDropInstructions />
      <MeshVariants />

      <Canvas
        data-testid='standardCanvas'
        theme={theme}
        $selectedTheme={selectedTheme}
        notifications={messages}
        defaultEnvironments={defaultEnvironments}
        addNotification={(messages, clearExisting) => dispatch(addMessage([ ...messages ], clearExisting))}
        clearNotifications={() => dispatch(clearMessages())}
        clearUpdateData={() => dispatch(clearUpdateData())}
        file={null}
        guidURL={guidURL}
        updateData={updateData}
        reinitialize={reinitializeCanvas}
        toDownload={false}
        exportModel={exportModel}
        exportType='glb'
        setDownloadedModel={data => dispatch(setExportedModelData(data))}
        setConfirmMessage={confirmConfig => setConfirmMessage(confirmConfig)}
        setSerializedData={data => {
          const cleanData = JSON.stringify(data);
          dispatch(clearSerializedData());
          dispatch(setSerializedData(cleanData));
        }}
        setBillboardImages={newArray => {
          if (!_.isEmpty(newArray)) {
            dispatch(setBillboardImages(newArray));
          }
        }}
      />

      <ModelToolbar />

      <FovConfirmation
        onConfirm={() => confirmMessage?.onConfirm?.()}
        onClose={() => confirmMessage?.onClose?.() || setConfirmMessage(null)}
        confirmationText='Delete'
        isOpened={!_.isEmpty(confirmMessage)}
        confirmColor={errorColor}
        confirmHoverColor={errorHoveredColor}
      >
        <Body size='Medium'>
          {confirmMessage?.message}
        </Body>
      </FovConfirmation>
    </CanvasContainer>
  );
});

export { ModelCanvas };