import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const ShareSVG = styled('svg')`
  color: ${props => (
    props.theme.colors[props.$selectedTheme][props.hoverColor || 'onPrimary']
  )}
`;

const ShareOutlined = withTheme(props => {
  const { width, height, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <ShareSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <circle cx="18" cy="5" r="3" />
          <circle cx="6" cy="12" r="3" />
          <circle cx="18" cy="19" r="3" />
          <path d="m8.59 13.51l6.83 3.98m-.01-10.98l-6.82 3.98" />
        </g>
    </ShareSVG>
  );
});

export { ShareOutlined };