import React, { Fragment, useState, useEffect, useMemo, useContext  } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DocNote, H4, VerticalSpacer, FovCheckbox, FovSkeletonLoader, MESSAGE_TYPES } from 'components';
import { TitleGroup, FilterSection, FilterTitle, NoSelectionMessage } from './panelStyles';
import { setTemporaryRole } from 'store/actions/userData';
import { Category } from './Category';
import { withTheme } from 'styled-components';
import { paramsFromURL, paramsToObject } from 'utils/paramHelpers';
import { filterProtectedCreators } from 'components/generic/ProtectedContent';
import { decode } from 'utils';
import { addMessage } from 'store/actions/app';
import _ from 'lodash';
import { ModelContext } from 'pages/Home/ModelContext';

const Creators = withTheme(props => {
  const { theme } = props;
  const { getModels } = useContext(ModelContext);

  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const userPreferences = userDataStore.userPreferences;
  const selectedTheme = userDataStore.userPreferences.theme;
  const filterPanel = userPreferences?.filterPanel;
  const creators = useMemo(() => userDataStore.creators, [ userDataStore.creators ]);
  const defaultFilteredNames = useMemo(() => (
    creators?.allPartners?.map(creator => creator.name)
  ), [ creators?.allPartners ]);

  const [ selectedCreators, setSelectedCreators ] = useState([]);
  const [ defaultsLoaded, setDefaultsLoaded ] = useState(false);
  const [ searchParams, setSearchParams ] = useSearchParams();

  const params = paramsToObject(decode(paramsFromURL()));
  const totalActiveModels = creators?.allPartners
    ?.filter(partner => selectedCreators.includes(partner.name))
    .reduce?.((prev, current) => {
      const activeModelCount = prev += current.totalModels;
      return activeModelCount;
    }, 0);

  const temporaryRole = userDataStore.temporaryRole;
  const userRoles = temporaryRole !== 'default' ? [ temporaryRole ] : userDataStore?.data?.roles;

  const handleRestrictedContentOnLoad = (originalPartnerList, partners) => {
    if (!_.isEqual(originalPartnerList, partners)) {
      const absent = originalPartnerList?.filter(partner => !partners.includes(partner));
      const message = `Results adjusted.  Please upgrade to view content from ${absent?.join(', ')}.`;
      const options = { type: MESSAGE_TYPES.INFO, timer: 4.5 };

      if (!_.isEmpty(absent)) {
        dispatch(addMessage(message, options));
      }
    }
  };

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    if (temporaryRole !== 'default') {
      dispatch(setTemporaryRole('default'));
    }
  }, [ searchParams ]);

  useEffect(() => {
    const noParamsOnLoad = !_.isEmpty(defaultFilteredNames) && _.isEmpty(selectedCreators) && !defaultsLoaded;
    const roleFilteredPartners = filterProtectedCreators(params.creators, userRoles);
    let originalPartnerList = params?.creators;
    let creators = !_.isEmpty(roleFilteredPartners) ? roleFilteredPartners : defaultFilteredNames;

    if (typeof originalPartnerList === 'string') {
      originalPartnerList = [ originalPartnerList ];
    }

    if (typeof creators === 'string') {
      creators = [ creators ];
    }

    if (noParamsOnLoad) {
      setSearchParams({ ...params, creators });
      handleRestrictedContentOnLoad(originalPartnerList, creators);
      setSelectedCreators(creators);
      setDefaultsLoaded(true);
    }
  }, [ defaultFilteredNames, creators ]);

  const buildCreators = () => {
    if (creators) {
      const creatorsList = creators.allPartners?.map((creator, index) => {
        const { name, totalModels } = creator;

        const label = (
          <Category
            name={name}
            total={totalModels}
            modelCount={totalModels}
            isLabel={true}
            callback={_.noop}
          />
        );

        return (
          <Fragment key={index}>
            <FovCheckbox
              checked={selectedCreators.includes?.(name)}
              label={label}
              inheritHover={true}
              fullWidth={true}
              onChange={newVal => {
                const withoutCreator = selectedCreators.filter?.(creator => creator !== name);
                const newPartnerParam = newVal ? [ ...withoutCreator, name ] : withoutCreator;

                delete params.categories;
                delete params.tags;
                delete params.search;

                setSearchParams({ ...params, creators: newPartnerParam, page: 1 });
                getModels();

                if (newVal) {
                  setSelectedCreators([ ...selectedCreators || [], name ]);
                  return;
                }

                setSelectedCreators(withoutCreator || []);
              }}
            />
          </Fragment>
        );
      });

      return creatorsList;
    }

    return (
      <NoSelectionMessage theme={theme} $selectedTheme={selectedTheme}>
        <p>No creators found.</p>
      </NoSelectionMessage>
    );
  };

  return (
    <FilterSection collapsed={filterPanel}>
      <FilterTitle>
        <TitleGroup>
          <H4>Creators</H4>
          <DocNote path={[ 'filterCreators', 'creators' ]} top={-3} left={95} />
        </TitleGroup>
      </FilterTitle>

      <VerticalSpacer size={12} />

      <Category
        icon={null}
        name="All"
        modelCount={totalActiveModels || creators?.totalModels}
        total={creators?.totalModels}
        isLabel={true}
        callback={() => {
          const allCreators = creators?.allPartners.map(partner => partner.name);
          let newPartnerParam;

          delete params.categories;
          delete params.tags;
          delete params.search;

          if (selectedCreators?.length === allCreators?.length) {
            newPartnerParam = [];

            setSearchParams({
              ...params,
              creators: newPartnerParam,
              page: 1,
            });

            getModels();
            setSelectedCreators([]);
            return;
          }

          newPartnerParam = defaultFilteredNames;

          setSearchParams({ ...params, creators: newPartnerParam, page: 1 });
          getModels();
          setSelectedCreators(allCreators);
        }}
      />

      <FovSkeletonLoader type='creators' resource={creators}>
        {buildCreators()}
      </FovSkeletonLoader>

      <VerticalSpacer size={42} />
    </FilterSection>
  );
});

export { Creators };