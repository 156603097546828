import { actions } from 'store/actions/userData';
import { JOB_STATUSES } from './enums.jsx';
import { MESSAGE_TYPES } from 'components/notifications';
import { addMessage } from 'store/actions/app';

const { VITE_USER_SOCKETS_HOST: userSocketsHost } = process.env;
let existingSocket;
let data;

export const closeSocket = () => existingSocket?.close();

export const socketStatus = () => {
  if (existingSocket) {
    const validStates = [ 0, 1 ];
    return validStates.includes(existingSocket?.readyState);
  }

  return false;
};

export const wssCallback = args => {
  if (!data) {
    const { userModels } = args;
    data = { responses: userModels };
  }

  if (!socketStatus()) {
    connectSocket(args);
  }
};

export const hasPendingJob = data => {
  const pendingJobs = data?.find(model => {
    const { QUEUED, PROCESSING, WAITING_FOR_FILE } = JOB_STATUSES;
    const pendingModel = model.status === WAITING_FOR_FILE || model.status === QUEUED || model.status === PROCESSING;

    if (pendingModel) {
      return model;
    }
  });

  return pendingJobs;
};

export const hasFailedJob = data => {
  const failedJobs = data?.find(model => {
    const { FAILED } = JOB_STATUSES;
    const failedModel = model.status === FAILED;

    if (failedModel) {
      return model;
    }
  });

  return failedJobs;
};

export const connectSocket = args => {
  const { token, dispatch, handleCreditUpdate } = args;
  const url = `${userSocketsHost}/?token=Bearer%20${token}`;
  const socket = new WebSocket(url);

  socket.addEventListener('open', () => {
    if (existingSocket) {
      closeSocket(args);
      existingSocket = null;
    }

    existingSocket = socket;
  });

  socket.addEventListener('message', e => {
    if (e.data) {
      data = JSON.parse(e.data)?.v2;
      const incompleteJobs = data?.filteredResults.models;
      const action = { type: actions.GET_USER_MODELS, payload: data };

      dispatch(action);

      if (hasFailedJob(incompleteJobs) && handleCreditUpdate) {
        handleCreditUpdate();
      }

      if (!hasPendingJob(incompleteJobs)) {
        closeSocket();
      }

      return;
    }

    closeSocket();
  });

  socket.addEventListener('error', () => {
    const config = { type: MESSAGE_TYPES.error, timer: 4.5 };
    const message = 'Job status updates unavailable.';
    dispatch(addMessage([ { message, config } ]));
    closeSocket();
    existingSocket = null;
  });

  socket.addEventListener('close', e => {
    const wasIdle = e.reason === 'Going away';
    const incompleteJobs = data?.filteredResults.models;
    existingSocket = null;

    if (wasIdle && hasPendingJob(incompleteJobs)) {
      connectSocket(args);
    }
  });
};