import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const CabinetSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const CabinetOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CabinetSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 16 18'
    >
      <path fill='currentColor' d='M6.79509 4.58782C6.52181 4.58782 6.301 4.80863 6.301 5.08191C6.301 5.3552 6.52181 5.57601 6.79509 5.57601H10.0985C10.3718 5.57601 10.5926 5.3552 10.5926 5.08191C10.5926 4.80863 10.3718 4.58782 10.0985 4.58782H6.79509Z' />
      <path fill='currentColor' d='M6.79509 11.8601C6.52181 11.8601 6.301 12.0809 6.301 12.3542C6.301 12.6275 6.52181 12.8483 6.79509 12.8483H10.0985C10.3718 12.8483 10.5926 12.6275 10.5926 12.3542C10.5926 12.0809 10.3718 11.8601 10.0985 11.8601H6.79509Z' />
      <path fill='currentColor' d='M2.42987 0.949951C1.61486 0.949951 0.949951 1.61486 0.949951 2.42987V15.0063C0.949951 15.8213 1.61486 16.4862 2.42987 16.4862H2.66649V16.5559C2.66649 16.8291 2.8873 17.0499 3.16058 17.0499C3.43386 17.0499 3.65468 16.8291 3.65468 16.5559V16.483H13.2392V16.5527C13.2392 16.826 13.46 17.0468 13.7333 17.0468C14.0065 17.0468 14.2273 16.826 14.2273 16.5527V16.483H14.464C15.279 16.483 15.9439 15.8181 15.9439 15.0031V2.42987C15.9439 1.61493 15.2822 0.949951 14.464 0.949951H2.42987ZM1.93814 2.42987C1.93814 2.15827 2.15827 1.93814 2.42987 1.93814H14.464C14.7356 1.93814 14.9557 2.15827 14.9557 2.42987V8.22239H1.93814V2.42987ZM1.93814 15.0063V9.21058H14.9557V15.0063C14.9557 15.2778 14.7356 15.498 14.464 15.498H2.42987C2.15827 15.498 1.93814 15.2778 1.93814 15.0063Z' />
    </CabinetSVG>
  );
});

export { CabinetOutlined };