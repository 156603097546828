import * as images from 'assets/documentation';

export const visibility = {
  deleteConfirm: images.deleteConfirm,
  deleteSelectedButton: images.deleteSelectedButton,
  glassSelected: images.glassSelected,
  glassUnhidden: images.glassUnhidden,
  hideSelectedButton: images.hideSelectedButton,
  interiorDeleted: images.interiorDeleted,
  interiorSelected: images.interiorSelected,
  unhideAllButton: images.unhideAllButton,
  visibilityPanel: images.visibilityPanel,
};

export const fromFile = {
  fromFile: images.fromFile,
  uploadForm: images.uploadForm,
  uploadArea: images.uploadArea,
  gltfUpload: images.gltfUpload,
  allFilesAdded: images.allFilesAdded,
  heightField: images.heightField,
  creatingZipFile: images.creatingZipFile,
  zipFile: images.zipFile,
  uploadedZipFile: images.uploadedZipFile,
  completedForm: images.completedForm,
  creating: images.creating,
  uploading: images.uploading,
  published: images.published,
  editingNewModel: images.editingNewModel,
  editModel: images.editModel,
};

export const fromGLTF = {
  fromGLTF: images.fromGLTF,
  dragAndDrop: images.dragAndDrop,
  bikeFile: images.bikeFile,
  bikeInScene: images.bikeInScene,
  multifileUpload: images.multifileUpload,
};

export const downloading = {
  contentMenu: images.contentMenu,
  downloadModal: images.downloadModal,
  downloadModel: images.downloadModel,
  downloadingModel: images.downloadingModel,
};

export const cameras = {
  cameraPanel: images.cameraPanel,
  cameraReset: images.cameraReset,
  defaultCamera: images.defaultCamera,
  deleteViewButton: images.deleteViewButton,
  deleteViewConfirm: images.deleteViewConfirm,
  orthographicLeft: images.orthographicLeft,
  orthographicLeftIcon: images.orthographicLeftIcon,
  orthographicViews: images.orthographicViews,
  ratios: images.ratios,
  resetButton: images.resetButton,
  safeFrame: images.safeFrame,
  saveViewButton: images.saveViewButton,
  saveViewModal: images.saveViewModal,
  screenshot: images.screenshot,
  takeScreenshotButton: images.takeScreenshotButton,
  viewSelectMenu: images.viewSelectMenu,
};

export const environment = {
  addEnvironmentButton: images.addEnvironmentButton,
  backpackNewEnv: images.backpackNewEnv,
  base: images.base,
  blur_0: images.blur_0,
  blur_50: images.blur_50,
  blur_100: images.blur_100,
  blurBox_0: images.blurBox_0,
  blurBox_50: images.blurBox_50,
  blurBox_100: images.blurBox_100,
  defaultEnvironmentSelect: images.defaultEnvironmentSelect,
  emptyViewport: images.emptyViewport,
  environmentPanel: images.environmentPanel,
  environmentSelectMenu: images.environmentSelectMenu,
  environmentUploadWindow: images.environmentUploadWindow,
  intensity_0: images.intensity_0,
  intensity_100: images.intensity_100,
  intensityBox_0: images.intensityBox_0,
  intensityBox_50: images.intensityBox_50,
  intensityBox_100: images.intensityBox_100,
  presetEnvironments: images.presetEnvironments,
  removeEnvironmentButton: images.removeEnvironmentButton,
  uploadingEnvironment: images.uploadingEnvironment,
  viewportVisibility: images.viewportVisibility,
  viewportWithBackpack: images.viewportWithBackpack,
};

export const materials = {
  advancedAttributes: images.advancedAttributes,
  backfaceCullOff: images.backfaceCullOff,
  backfaceCullOn: images.backfaceCullOn,
  basicAttributes: images.basicAttributes,
  channel: images.channel,
  channelDownload: images.channelDownload,
  channelLoaded: images.channelLoaded,
  channelRemove: images.channelRemove,
  clearCoatOn: images.clearCoatOn,
  colorChannel: images.colorChannel,
  colorPalette: images.colorPalette,
  explorerWindow: images.explorerWindow,
  generalProperties: images.generalProperties,
  materialsMenu: images.materialsMenu,
  materialsPanel: images.materialsPanel,
  noWireframe: images.noWireframe,
  optimizationEstimated: images.optimizationEstimated,
  optimizationHigh: images.optimizationHigh,
  optimizationLow: images.optimizationLow,
  optimizationMedium: images.optimizationMedium,
  optimizationOff: images.optimizationOff,
  optimizationTarget: images.optimizationTarget,
  optimizationType: images.optimizationType,
  removeButton: images.removeButton,
  rename: images.rename,
  renameModal: images.renameModal,
  select: images.select,
  shoesMaterial: images.shoesMaterial,
  shoesRenamed: images.shoesRenamed,
  shoesSelected: images.shoesSelected,
  textureChannel: images.textureChannel,
  textureRemoved: images.textureRemoved,
  transparencyComplex: images.transparencyComplex,
  transparencyMenu: images.transparencyMenu,
  transparencySimple: images.transparencySimple,
  upload: images.upload,
  viewportFaces: images.viewportFaces,
  viewportFacesCulled: images.viewportFacesCulled,
  viewportWireframe: images.viewportWireframe,
  wireframeOff: images.wireframeOff,
  wireframeOn: images.wireframeOn,
};

export const properties = {
    axisCompensationMenu: images.axisCompensationMenu,
    axisMismatch: images.axisMismatch,
    boundingBox: images.boundingBox,
    boundingBoxMenu: images.boundingBoxMenu,
    boundingBoxOff: images.boundingBoxOff,
    boundingBoxOn: images.boundingBoxOn,
    defaultPosition: images.defaultPosition,
    deletePositionButton: images.deletePositionButton,
    deletePositionConfirmation: images.deletePositionConfirmation,
    fieldCompensation: images.fieldCompensation,
    modelCorrected: images.modelCorrected,
    moveGizmo: images.moveGizmo,
    moveInputs: images.moveInputs,
    propertiesPanel: images.propertiesPanel,
    rotateGizmo: images.rotateGizmo,
    rotateInputs: images.rotateInputs,
    savePositionButton: images.savePositionButton,
    savePositionModal: images.savePositionModal,
    savePositionMenu: images.savePositionMenu,
    scaleInputs: images.scaleInputs,
    scaleInputsLinked: images.scaleInputsLinked,
    statistics: images.statistics,
    yAxisUp: images.yAxisUp,
    zAxisUp: images.zAxisUp,
};

export const filter = {
  myFilesContentMenu: images.myFilesContentMenu,
  creatorsContentMenu: images.creatorsContentMenu,
  creatorsContent: images.creatorsContent,
  creatorCategories: images.creatorCategories,
  creatorCategoriesOpen: images.creatorCategoriesOpen,
  creators: images.creators,
  myFiles: images.myFiles,
  myFilesCategories: images.myFilesCategories,
  myFilesCategoriesOpen: images.myFilesCategoriesOpen,
  searchField: images.searchField,
  partialResults: images.partialResults,
  wholeSearch: images.wholeSearch,
  wholeResult: images.wholeResult,
  tags: images.tags,
};

export const publish = {
  categoryMenuOpen: images.categoryMenuOpen,
  descriptionArea: images.descriptionArea,
  publishPanel: images.publishPanel,
  downscalingOff: images.downscalingOff,
  downscalingOn: images.downscalingOn,
  downscalingMenu: images.downscalingMenu,
  simplifiedProxyOptions: images.simplifiedProxyOptions,
  proxyMenu: images.proxyMenu,
  opGlobalEstimated: images.opGlobalEstimated,
  opGlobalHigh: images.opGlobalHigh,
  opGlobalLow: images.opGlobalLow,
  opGlobalMedium: images.opGlobalMedium,
  opGlobalOff: images.opGlobalOff,
  opGlobalTarget: images.opGlobalTarget,
  opGlobalType: images.opGlobalType,
  credits: images.credits,
  creditBalanceHeader: images.creditBalanceHeader,
};

export const views = {
  sorting: images.sorting,
  sortingOpen: images.sortingOpen,
  pinning: images.pinning,
  viewPreference: images.viewPreference,
  cardViewIcon: images.cardViewIcon,
  cardView: images.cardView,
  listViewIcon: images.listViewIcon,
  listView: images.listView,
  myFiles: images.myFiles,
  myFilesContentMenu: images.myFilesContentMenu,
  processingJobs: images.processingJobs,
  previewSection: images.previewSection,
};