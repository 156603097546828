import { keyValueMirror } from 'store/store-functions';

const {
  VITE_AUTHENTICATION_HOST: authenticationHost,
  VITE_CLIENT_ID: clientId,
} = process.env;

export const actions = keyValueMirror(
  'AUTHENTICATE_USER',
  'ACTIVATE_PROGRESS_INDICATOR',
  'DEACTIVATE_PROGRESS_INDICATOR',
  'SET_GLOBAL_ERROR_MESSAGE',
  'CLEAR_GLOBAL_ERROR_MESSAGE',
  'SET_ACTIVE_PANEL',
  'ADD_MESSAGE',
  'REMOVE_MESSAGE',
  'CLEAR_MESSAGES',
  'SET_SHOW_ONBOARDING',
);

export const authenticateUser = () => {
  // eslint-disable-next-line
  return (__) => {
    window.location.href = authenticationHost +
    '/oauth/authorize/?response_type=token&' +
    `client_id=${clientId}&` +
    `redirect_uri=${window.location.origin}`;
  };
};

export const activateProgressIndicator = {
  type: actions.ACTIVATE_PROGRESS_INDICATOR,
};

export const deactivateProgressIndicator = {
  type: actions.DEACTIVATE_PROGRESS_INDICATOR,
};

export const setGlobalErrorMessage = message => ({
  type: actions.SET_GLOBAL_ERROR_MESSAGE,
  payload: message,
});

export const clearGlobalErrorMessage = {
  type: actions.CLEAR_GLOBAL_ERROR_MESSAGE,
};

export const setActivePanel = panel => ({
  type: actions.SET_ACTIVE_PANEL,
  payload: panel,
});

/**
 * Adds new notification to the application
 * @param {string} message notification text
 * @param {object} config timer, showModal, type, centeredIcon can be set in the config param
 */
export const addMessage = (messages, clearExisting) => {
  return {
    type: actions.ADD_MESSAGE,
    payload: [ ...messages ],
    meta: {
      clearExisting,
    },
  };
};

/**
 * Removes notification from the list by index
 * @param {number} index index of the removed notification
 */
export const removeMessage = index => {
  return {
    type: actions.REMOVE_MESSAGE,
    payload: index,
  };
};

/**
 * Removes all notifications from the list
 */
export const clearMessages = () => ({
  type: actions.CLEAR_MESSAGES,
});

export const setShowOnBoarding = payload => ({
  type: actions.SET_SHOW_ONBOARDING,
  payload,
});