import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const NoTextureSVG = styled('svg')`
  transform: scaleX(-1);
`;

const NoTextureOutlined = withTheme(props => {
  const { width, height, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <NoTextureSVG
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 32}
      height={height || 32}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 256 256"
    >
      <path fill={theme.colors[selectedTheme].error}
        d="M53.9 34.6a8 8 0 0 0-11.8 10.8l160 176a8 8 0 0 0 5.9 2.6a8.2 8.2 0 0 0 5.4-2.1a7.9 7.9 0 0 0 .5-11.3Z" />
    </NoTextureSVG>
  );
});

export { NoTextureOutlined };