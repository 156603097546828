import { useDispatch, useSelector } from 'react-redux';
import { FovInput, VerticalSpacer } from 'components';
import { updateCanvas, cleanNumber } from 'utils';
import constants from'constants/index';
import styled from 'styled-components';

const AxisContainer = styled('div')`
  display: grid;
  gap: 0 0.5rem;
  align-items: center;
  grid-template-columns: repeat(3, auto);
  width: -webkit-fill-available;
`;

const AxisInput = styled(FovInput)`
  width: 100%;
  min-width: 4rem;
`;

const AxisWrapper = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const AxisLabel = styled('div')`
  position: absolute;
  top: -1.25rem;
  left: 0;
  font-size: 0.85rem;
`;

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const LightingPosition = () => {
    const dispatch = useDispatch();
    const canvasStore = useSelector(state => state.canvas);
    const serializedData = canvasStore.serializedData;
    const lights = serializedData?.metadata.lights;
    const defaultLight = lights[0];
    const { id, position } = defaultLight;
    const xPosition = cleanNumber(position[0]);
    const yPosition = cleanNumber(position[1]);
    const zPosition = cleanNumber(position[2]);

    const updateArgs = {
        dispatch,
        type: CANVAS_UPDATE_TYPES.LIGHTING,
        method: CANVAS_UPDATE_METHODS.UPDATE,
    };

    const handleChange = (key, value) => {
        const inboundData = {
          id,
          transforms: {
            tx: xPosition,
            ty: yPosition,
            tz: zPosition,
            [key]: +(value),
          },
        };
        updateCanvas(updateArgs, inboundData);
    };

  return (
    <>
    Position
    <VerticalSpacer size={15} />
    <AxisContainer>
        <AxisWrapper>
        <AxisLabel>X</AxisLabel>

        <AxisInput
            type={'number'}
            value={xPosition}
            onChange={e => handleChange('tx', e.target.value)}
        />
        </AxisWrapper>

        <AxisWrapper>
        <AxisLabel>Y</AxisLabel>

        <AxisInput
            type={'number'}
            value={yPosition}
            onChange={e => handleChange('ty', e.target.value)}
        />
        </AxisWrapper>

        <AxisWrapper>
        <AxisLabel>Z</AxisLabel>

        <AxisInput
            type={'number'}
            value={zPosition}
            onChange={e => handleChange('tz', e.target.value)}
        />
        </AxisWrapper>
    </AxisContainer>
    </>
  );
};

export default LightingPosition;
