import { keyValueMirror } from 'store/store-functions';
import { addMessage, clearMessages } from './app';
import { MESSAGE_TYPES } from 'components/notifications';
const { VITE_API_HOST: apiHost } = process.env;

export const actions = keyValueMirror(
  'GET_AVAIL_CHANNELS',
);

export const getAvailChannels = (accessToken, setAvailLoggedIn) => {
  return async dispatch => {
    try {
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const res = await fetch(`${apiHost}/utils/v1/avail/channels`, options);
      const data = res.status == 200 ? await res.json() : [];
      const payload = data?.filter(channel => channel.name === 'FOVEA') || [];

      setAvailLoggedIn(res.status == 200 ? true : false);

      const action = { type: actions.GET_AVAIL_CHANNELS, payload };
      dispatch(action);
    } catch (e) {
      const message = e.message;
      const config = { type: MESSAGE_TYPES.error, timer: 2.5 };

      setAvailLoggedIn(false);
      dispatch(clearMessages());
      dispatch(addMessage([ { message, config } ]));
    }
  };
};

export const availLogout = (accessToken, setAvailLoggedIn) => {
  return async dispatch => {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      await fetch(`${apiHost}/connect/v1/avail`, options);
      setAvailLoggedIn(false);
    } catch (e) {
      const message = e.message;
      const config = { type: MESSAGE_TYPES.error, timer: 2.5 };

      setAvailLoggedIn(false);
      dispatch(clearMessages());
      dispatch(addMessage([ { message, config } ]));
    }
  };
};