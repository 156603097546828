import { actions as userDataActions } from 'store/actions/userData';
import constants from 'constants/index';
import u from 'updeep';

const initialState = {
  data: null,
  creatorUser: null,
  isAuthorizing: true,
  temporaryRole: 'default',
  userPreferences: {
    sorting : 'oldestFirst',
    cardScaling: 65,
    modelView: 0,
    pinPending: true,
    showDocumentation: true,
    theme: constants.THEME.LIGHT,
    filterPanel: true,
  },
  filterResults: [],
  filters: {
    category: null,
    tags: [],
  },
  hasUserModels: null,
};

const equals = x => y => x === y;

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case userDataActions.SET_TEMPORARY_ROLE:
      return u({ temporaryRole: action.payload }, state);
    case userDataActions.GET_USER_DATA:
      return u({ data: action.payload }, state);
    case userDataActions.GET_USER_MODELS:
      return u({ userModels: action.payload }, state);
    case userDataActions.GET_USER_JOBS:
      return u({ userJobs: action.payload }, state);
    case userDataActions.GET_CREATORS:
      return u({ creators: action.payload }, state);
    case userDataActions.SET_MODELS_LOADING:
      return u({ modelsLoading: action.payload }, state);
    case userDataActions.GET_CREATOR_USER:
      return u({ creatorUser: action.payload.responses }, state);
    case userDataActions.SET_IS_AUTHORIZING:
      return u({ isAuthorizing: true }, state);
    case userDataActions.RESET_IS_AUTHORIZING:
      return u({ isAuthorizing: false }, state);
    case userDataActions.CLEAN_CREATOR_USER:
      return u({ creatorUser: action.payload }, state);
    case userDataActions.ADD_CATEGORY_FILTER:
      return u(
        {
          filters: {
            category: action.payload,
          },
        },
        state,
      );
    case userDataActions.ADD_TAG_FILTER:
      return u(
        { filters: { tags: [ ...state.filters.tags, action.payload ] } },
        state,
      );
    case userDataActions.REMOVE_TAGS_FILTER:
      return u({ filters: { tags: u.reject(equals(action.payload)) } }, state);
    case userDataActions.CLEAR_CATEGORY_FILTER:
      return u({ filters: { category: null } }, state);
    case userDataActions.CLEAR_TAGS_FILTERS:
      return u({ filters: { tags: [] } }, state);
    case userDataActions.CLEAR_FILTERS:
      return u({ filters: initialState.filters }, state);

    case userDataActions.SET_USER_PREFERENCES:
      localStorage.setItem(
        constants.FOV_USER_PREFERENCES,
        JSON.stringify(u({ ...action.payload }, state.userPreferences)),
      );

      return u({ userPreferences: action.payload }, state);

    case userDataActions.SET_USER_PREFERENCES_FROM_STORE:
      return u({ userPreferences: action.payload }, state);

    case userDataActions.GET_HAS_USER_MODELS:
      return u({ hasUserModels: action.payload }, state);

    default:
      return state;
  }
};

export { userDataReducer };
