import React from 'react';
import { DocSection } from 'components';
import Summary from './Summary';
import { ContentContainer } from '../docStyles';

const Overview = () => {
  const summary = [
    'Simplify business workflows.',
    'FOVEA creates, iterates and optimizes 3D content.',
    'Easily add created content to other apps or rendering engines.',
    'Convert existing assets to a variety of file formats.',
    'Manage created content or start from a selection of pre-existing models.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='What is FOVEA'>
        <p>
          FOVEA helps you transform images and 3D models into rich, platform-agnostic,
          content known as RPCs. RPCs can be used with direct integrations or plugins for
          Revit, 3DS Max, AutoCAD, Rhino, SketchUp, and FormZ. Your RPCs can also be used
          as native 3D content for applications like V-Ray, Enscape, Twinmotion, and Lumion.
        </p>

        <p>
          FOVEA enables you to upload, configure, and publish models in a simple cloud-based interface.
          You can build RPCs by uploading RPC, FBX, OBJ and GLTF or GLB files. Configuring your
          models could be as simple as adding metadata or as complex as optimizing geometry on a per
          material basis.
        </p>

        <p>
          You can also publish models to FOVEA through our exporters. Exporters are
          available for 3DS Max, Rhino and Blender.
        </p>
      </DocSection>

    </ContentContainer>
  );
};

export { Overview };