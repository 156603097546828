import React from 'react';
import { FovModal } from 'components/generic';
import { OktaSignInWidget } from 'components/avail';

const AvailLoginContainer = React.memo(function AvailLoginContainer(props) {
  const { isVisible, onCancel, closeMe, setAvailLoggedIn } = props;

  window.onmessage = function(m) {
    if (m.data == 'avail login success') {
      setAvailLoggedIn(true);
      closeMe();
    }
    if (m.data == 'avail login error') {
      setAvailLoggedIn(false);
      closeMe();
    }
  };

  return (
    <FovModal visible={isVisible} title='Log in to AVAIL' onClose={onCancel} >
      <OktaSignInWidget />
    </FovModal>
  );
});

export default AvailLoginContainer;
