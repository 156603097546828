import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const AnimationContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${props => props.absolutePosition ? 'absolute' : 'relative'};

  animation-name: spin;
  animation-duration: ${props => props.duration}ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform:rotate(${props => props.direction === 'right' ? '0deg' : '360deg'});
    }
    to {
      transform:rotate(${props => props.direction === 'right' ? '360deg' : '0deg'});
    }
}
`;

const SpinAnimation = props => {
  const {
    children,
    duration = 3000,
    direction = 'right',
    absolutePosition,
  } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <AnimationContainer
      $selectedTheme={selectedTheme}
      direction={direction}
      duration={duration}
      absolutePosition={absolutePosition}
    >
      {children}
    </AnimationContainer>
  );
};

export { SpinAnimation };
