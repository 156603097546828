import React from 'react';
import styled, { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';

const Text = styled('b')`
  font-family: ${props => props.theme.typography.primary}
`;

const Bold = withTheme(props => {
  const { children } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Text selectedTheme={selectedTheme}>{ children }</Text>
  );
});

export { Bold };
