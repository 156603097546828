import React from 'react';
import { FovTypography } from 'components';
import { ModelActions, handleStatus } from './ModelActions';
import { formatRpcImage, getScaledValues, buildTitle } from './utils';
import { TableThumbnail } from './styles';

export const listColumns = (isCardView, handleOnDownload, handleOnDelete, selectedTheme) => ([
  {
    title: 'Thumbnail',
    key: 'thumbnail',
    width: 110,
    alignContent: 'center',
    render: (text, model) => (
      <TableThumbnail src={formatRpcImage(model.rpc_guid)} />
    ),
  },
  {
    title: 'Name',
    key: 'title',
    width: 300,
    render: (text, model) => (
      <FovTypography>
        {buildTitle(model.status, text, isCardView)}
      </FovTypography>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    width: 50,
    alignContent: 'center',
    render: (text, model) => {
      const scaling = getScaledValues(70);
      return handleStatus(model.status, model.job_type, scaling, isCardView);
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    width: 200,
    render: (text, model) => (
      <ModelActions
        title={model.title}
        scaling={getScaledValues(70)}
        rpc_guid={model.rpc_guid}
        job_type={model.job_type}
        selectedTheme={selectedTheme}
        isCardView={isCardView}
        formats={model.formats}
        handlers={{
          setIsDeleting: () => {
            return;
          },
          onDownload: handleOnDownload,
          onDelete: () => handleOnDelete(model),
        }}
      />
    ),
  },
]);