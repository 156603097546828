import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const MotorcycleSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const MotorcycleOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <MotorcycleSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 17 15'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9.32415 8.30329C8.65355 7.43137 7.59699 6.94409 6.49843 6.99942H4.63194C2.94211 6.97609 1.45558 8.11131 1.03229 9.74782C0.893639 10.2824 1.21427 10.8284 1.74889 10.967C1.83088 10.9884 1.91487 10.999 1.99953 10.999M1.99953 10.999H8.99885M1.99953 10.999L1.99884 11.499C1.99884 12.8796 3.11806 13.9988 4.4986 13.9988C5.87913 13.9988 6.99835 12.8796 6.99835 11.499V10.9991L1.99953 10.999ZM8.99885 10.999C10.6554 10.999 11.9986 9.65582 11.9986 7.99932M8.99885 10.999L15.4981 10.9991C15.66 10.9991 15.8127 10.9204 15.906 10.7878C16.0027 10.3092 16.0247 9.81855 15.9727 9.33259C15.69 8.53334 14.9348 7.99939 14.0869 7.99939L11.9986 7.99932M11.9986 7.99932V2.99981C11.9986 1.89525 11.1033 1 9.99875 1H8.99885M7.99878 4.99994H2.99927M13.9979 5.00019C13.9979 5.41415 13.662 5.75012 13.248 5.75012C13.086 5.75012 12.9281 5.69746 12.7981 5.60013C11.9148 4.93753 11.7355 3.68365 12.3981 2.80041C12.5121 2.64909 12.6467 2.51443 12.7981 2.40044C13.1294 2.1518 13.5993 2.21913 13.848 2.55043C13.9453 2.68042 13.9979 2.8384 13.9979 3.00039V5.00019ZM14.997 11.499C14.997 12.8796 13.8778 13.9988 12.4973 13.9988C11.1167 13.9988 9.99751 12.8796 9.99751 11.499V10.9991H14.997V11.499Z'
      />
    </MotorcycleSVG>
  );
});

export { MotorcycleOutlined };