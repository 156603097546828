import React, { useState, useEffect, useRef } from 'react';
import { FovTypography } from 'components/typography';
import { ArrowLeftOutlined, EditOutlined } from 'components/icons';
import { HorizontalSpacer } from 'components/layout';
import { clearPublishName, setPublishName } from 'store/actions/rpc';

import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCanvas } from 'utils';
import styled, { withTheme } from 'styled-components';
import constants from 'constants/index';
import _ from 'lodash';

const themePath = props => props.theme.colors[props.$selectedTheme];

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const FileNameContainer = styled(FovTypography)`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  font-weight: 600;
  letter-spacing: 0.025rem;
  color: ${props => (
    props.theme.colors[props.$selectedTheme].onPrimary
  )}
`;

const EditIconContainer = styled('div')`
  cursor: pointer;
`;

const LinkOut = styled('a')`
  display: flex;
  align-items: center;
  height: 2rem;
  width: fit-content;
  color: ${props => themePath(props).onPrimary};

  &:hover {
    color: ${props => themePath(props).accent};
  }
`;

const FileNameInput = styled('input')`
  padding: 0.1rem 0.5rem;
  border: none;
  font-weight: 600;
  min-width: 10rem;
  width: 10rem;
  background-color: ${props => themePath(props).onPrimary};
  color: ${props => themePath(props).primary};

  &:focus {
    outline: none;
    box-shadow: 0 0 0.1rem 0.1rem ${props => themePath(props).accent}
  }
`;

const AppHeaderFileName = withTheme(props => {
  const { theme, path, pageName, fileName, modelOverride } = props;
  const [ isEditing, setIsEditing ] = useState(false);
  const [ inputVal, setInputVal ] = useState(fileName || '');
  const navigate = useNavigate();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const dispatch = useDispatch();
  const filenameRef = useRef();
  const location = useLocation();
  const onModels = location.pathname?.includes('/models') || modelOverride;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.PUBLISH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };


  useEffect(() => {
    if (!_.isEmpty(fileName)) {
      setInputVal(fileName);
    }

    if (isEditing) {
      filenameRef.current?.focus();
    }
  }, [ fileName, isEditing, filenameRef ]);

  useEffect(() => {
    return () => {
      dispatch(clearPublishName());
    };
  }, []);

  const handleRedirect = () => navigate(path);

  const handleSave = () => {
    if (!modelOverride) {
      const notEmpty = !_.isEmpty(inputVal);
      const different = !_.isEqual(inputVal, fileName);

      if (notEmpty && different) {
        dispatch(setPublishName(inputVal));
        updateCanvas(updateArgs, { title: inputVal });
        return setIsEditing(false);
      }


      setInputVal(fileName);
      setIsEditing(false);
    }
  };

  return (
    <FileNameContainer theme={theme} $selectedTheme={selectedTheme}>
      <LinkOut onClick={handleRedirect} theme={theme} $selectedTheme={selectedTheme}>
        <ArrowLeftOutlined /> <HorizontalSpacer size={15} />
        My {pageName}
      </LinkOut>

      {onModels && fileName && (
        <>
          <HorizontalSpacer size={10} /> | <HorizontalSpacer size={10} />
        </>
      )}

      {onModels && (
        <>
          {isEditing
            ? <FileNameInput
                ref={filenameRef}
                value={inputVal}
                theme={theme}
                $selectedTheme={selectedTheme}
                onChange={e => setInputVal(e.currentTarget.value)}
                onKeyPress={e => e.key === 'Enter' && handleSave()}
                onBlur={handleSave}
              />
            : <span role='presentation' onClick={() => setIsEditing(!isEditing)}>
                {inputVal}
              </span>
          }
        </>
      )}

      {onModels && fileName && (
        <>
          <HorizontalSpacer size={10} />

          <EditIconContainer
            $isEditing={isEditing}
            onClick={() => setIsEditing(!isEditing)}
          >
            <EditOutlined
              theme={theme}
              $selectedTheme={selectedTheme}
              isEditing={isEditing}
              width={22}
              height={22}
            />
          </EditIconContainer>
        </>
      )}
    </FileNameContainer>
  );
});

export { AppHeaderFileName };