import React from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined, MinusOutlined, HorizontalSpacer } from 'components';
import { getColor } from 'utils';
import { FilteredModels } from './panelStyles';
import styled, { css, withTheme } from 'styled-components';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: ${props => props.isLabel ? '1fr' : 'auto 1fr'};
  gap: 0.5rem;
  align-items: center;
  min-height: 2.75rem;
  padding-right: 0.5rem;
  user-select: none;
  ${props => props.isSubCategory && css`padding-left: 1rem;`}
  ${props => props.name === 'All' && css`font-weight: 600;`}
`;

const Icon = styled('div')`
  position: relative;
  top: -0.1rem;
  width: fit-content;
  cursor: pointer;
`;

const Name = styled('span')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${props => getColor(props, props.isActive ? 'accent' : 'onPrimary')};

  @media screen and (hover: hover) {
    &:hover {
      color: ${props => getColor(props, 'accent')};
    }
  }
`;

const NameGroup = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const Totals = styled('span')`
  display: flex;
  justify-content: center;
  min-width: 1rem;
  cursor: pointer;
`;

const Category = withTheme(props => {
  const {
    theme,
    icon,
    name,
    modelCount,
    total,
    subCategories,
    isSubCategory,
    isOpen,
    isLabel,
    isActive,
    callback,
  } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Wrapper name={name} isLabel={isLabel} onClick={callback} isSubCategory={isSubCategory}>
      {!isLabel && (
        <Icon>
          {subCategories && !isOpen && <PlusOutlined width={16} />}
          {subCategories && isOpen && <MinusOutlined width={16} />}
          {!subCategories && <HorizontalSpacer size={16} />}
        </Icon>
      )}

      <NameGroup>
        <Name theme={theme} $selectedTheme={selectedTheme} isActive={isActive}>
          {icon && (
            <>
              {icon}
              <HorizontalSpacer size={8} />
            </>
          )}

          {name}
        </Name>

        <Totals>
          {modelCount !== total && (
            <FilteredModels theme={theme} $selectedTheme={selectedTheme}>
              {modelCount} /&nbsp;
            </FilteredModels>
          )}
          {total}
        </Totals>
      </NameGroup>
    </Wrapper>
  );
});

export { Category };