import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FovSelect, FovOption } from 'components/form';
import {
  LabeledItemHolder,
  TitleWithTooltipContainer,
  ChannelTextContainer,
  EndChannelWrapper,
  AlphaContainer,
} from '../MaterialStyles';
import { VerticalSpacer, FovToggle, TextureSwatch, DevNote, FovSlider } from 'components';
import { hasAccess } from 'navigation/navigationHelpers';
import { ProtectedContent, superUsers } from 'components/generic/ProtectedContent';
import { updateCanvas, TRANSPARENCY_MODES as modes } from 'utils';
import constants from 'constants/index';
import _ from 'lodash';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;
const opacityTextureEnabled = false;

const Transparency = () => {
  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const temporaryRole = userDataStore.temporaryRole;
  const userRoles = temporaryRole !== 'default' ? [ temporaryRole ] : userDataStore?.data?.roles;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const transparencyEnabled = selectedMaterial?.transparencyEnabled;
  const transparencyType = selectedMaterial?.transparencyType;
  const isStencil = transparencyType === modes.stencil;
  const isComplexTransparency = transparencyType === modes.complex;
  const hasAlbedoTexture = selectedMaterial?.albedoTexture?.name;
  const defaultMode = modes.simple;
  const stencilOptionText = 'Stencil (from color alpha)';

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const showSimpleOptions = () => {
    return (
      <>
        <VerticalSpacer size={10} />

        <FovSelect
          value={transparencyType || defaultMode}
          placeholder={isStencil ? stencilOptionText : _.startCase(transparencyType || defaultMode)}
          onChange={(e, newVal) => {
            const inboundData = {
              id: selectedMaterialId,
              transparencyType: newVal,
              albedoHasAlpha: newVal === modes.stencil,
            };

            updateCanvas(updateArgs, inboundData);
          }}
        >
          <FovOption value={defaultMode}>Simple</FovOption>
          <FovOption value={modes.complex}>Complex</FovOption>
          <FovOption value={modes.stencil} disabled={!hasAlbedoTexture}>
            {stencilOptionText}
          </FovOption>
        </FovSelect>

        <VerticalSpacer size={25} />

        <ChannelTextContainer>
          <AlphaContainer hideChannel={!hasAccess(userRoles, superUsers) || !opacityTextureEnabled}>
            <DevNote ticketNumber={819} />

            <FovSlider
              label='Alpha'
              defaultValue={selectedMaterial?.alpha ?? 1}
              onAfterChange={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  alpha: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />

            <ProtectedContent>
              <EndChannelWrapper>
                {opacityTextureEnabled && (
                  <>
                    <TextureSwatch
                      texture={{
                        id: selectedMaterial?.opacityTexture?.name,
                        name: selectedMaterial?.opacityTexture?.name,
                        img: selectedMaterial?.opacityTexture?.url,
                      } || null}
                      updateChannel={newVal => {
                        const inboundData = {
                          id: selectedMaterialId,
                          opacityTexture: newVal,
                        };

                        updateCanvas(updateArgs, inboundData);
                      }}
                      removeChannel={() => {
                        const inboundData = {
                          id: selectedMaterialId,
                          removeOpacityTexture: true,
                        };

                        updateCanvas(removeArgs, inboundData);
                      }}
                    />
                  </>
                )}
              </EndChannelWrapper>
            </ProtectedContent>
          </AlphaContainer>
        </ChannelTextContainer>
      </>
    );
  };

  const showComplexOptions = () => {
    if (isComplexTransparency) {
      return (
        <>
          <ChannelTextContainer>
            <FovSlider
              label='Refraction intensity'
              defaultValue={selectedMaterial?.sssRefractionIntensity ?? 0.5}
              onAfterChange={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  sssRefractionIntensity: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />
          </ChannelTextContainer>

          <ChannelTextContainer>
            <FovSlider
              label='IOR'
              defaultValue={selectedMaterial?.sssIOR ?? 1.5}
              min={1}
              max={3}
              onAfterChange={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  sssIOR: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />
          </ChannelTextContainer>

          <VerticalSpacer size={25} />
        </>
      );
    }
  };

  const buildTransparencySection = () => {
    return (
      <>
        <LabeledItemHolder>
          <TitleWithTooltipContainer>
            Transparency settings
          </TitleWithTooltipContainer>

          <FovToggle
            checked={transparencyEnabled}
            callback={newVal => {
              const inboundData = {
                id: selectedMaterialId,
                transparencyEnabled: newVal,
                albedoHasAlpha: transparencyType === modes.stencil,
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />
        </LabeledItemHolder>

        {transparencyEnabled && (
          <>
            {showSimpleOptions()}
            {showComplexOptions()}
          </>
        )}
      </>
    );
  };

  return (
    <div>
      {buildTransparencySection()}
    </div>
  );
};

export default Transparency;
