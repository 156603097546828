import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import {
  ModalMessageContainer,
  ModalBG,
  ModalCloseIcon,
} from './styles';

const ModalMessageHolder = withTheme(props => {
  const {
    theme,
    children,
    onClose,
    visible,
    showCloseButton,
    ...rest
  } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <>
      {visible && (
        <ModalBG
          theme={theme}
          $selectedTheme={selectedTheme}
          onClick={e => e.stopPropagation()}
        >
          <ModalMessageContainer
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={e => e.stopPropagation()}
            {...rest}
          >
            {children}

            {showCloseButton && (
              <ModalCloseIcon heigth={20} width={20} onClick={onClose} />
            )}
          </ModalMessageContainer>
        </ModalBG>
      )}
    </>
  );
});

export { ModalMessageHolder };
