import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

export const CaretIcon = withTheme(props => {
  const { height, width, rotate, theme, color } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      transform={`rotate(${rotate})`}
      width={width || 20}
      height={height || 20}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z"
        fill={color || theme.colors[selectedTheme].onPrimary}
      />
    </svg>
  );
});
