import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FovDivider, VerticalSpacer, FovToggle } from 'components';
import { ProtectedContent } from 'components/generic/ProtectedContent';
import styled from 'styled-components';
import { LabeledItemHolder } from '../MaterialStyles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const GeneralPropertiesContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const GeneralProperties = () => {
  const dispatch = useDispatch();
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const buildGeneralProperties = () => {
    return (
      <GeneralPropertiesContainer>
        <LabeledItemHolder>
          Back-face culling

          <FovToggle
            checked={selectedMaterial?.backfaceCullingEnabled}
            callback={newVal => {
              const inboundData = {
                id: selectedMaterialId,
                backfaceCullingEnabled: newVal,
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />
        </LabeledItemHolder>

          <ProtectedContent requiredRoles={[ 'enterprise' ]}>
            <LabeledItemHolder>
              Wireframe

              <FovToggle
                checked={selectedMaterial?.wireFrameEnabled}
                callback={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    wireFrameEnabled: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
              />
            </LabeledItemHolder>
          </ProtectedContent>

          <ProtectedContent>
            <LabeledItemHolder>
              Points cloud

              <FovToggle
                checked={selectedMaterial?.pointsCloudEnabled}
                callback={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    pointsCloudEnabled: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
              />
            </LabeledItemHolder>
          </ProtectedContent>

      </GeneralPropertiesContainer>
    );
  };

  return (
    <div>
      {buildGeneralProperties()}

      <VerticalSpacer size={25} />
      <FovDivider />
      <VerticalSpacer size={15} />
    </div>
  );
};

export default GeneralProperties;
