import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const CubeSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const CubeOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CubeSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 30 30'
    >
      <path fill='currentColor' d='M3.90552 2.26427H6.42582V0H3.90552C1.75075 0 0 1.75112 0 3.90635V6.4272H2.26379V3.90635C2.26379 2.99551 3.00128 2.26427 3.90552 2.26427Z' />
      <path fill='currentColor' d='M2.26379 26.0936V23.5728H0V26.0936C0 28.2489 1.75075 30 3.90552 30H6.42582V27.7357H3.90552C3.00128 27.7357 2.26379 27.0045 2.26379 26.0936Z' />
      <path fill='currentColor' d='M23.5742 0H26.0945C28.2493 0 30 1.75112 30 3.90635V6.4272H27.7362V3.90635C27.7362 3.00192 26.9987 2.26427 26.0945 2.26427H23.5742V0Z' />
      <path fill='currentColor' d='M26.0945 27.7357H23.5742V30H26.0945C28.2492 30 30 28.2489 30 26.0936V23.5728H27.7362V26.0936C27.7362 27.0045 26.9987 27.7357 26.0945 27.7357Z' />
      <path fill='currentColor' d='M24.4528 18.9801C24.4528 19.8846 23.9654 20.7249 23.1894 21.161L16.2634 25.1572C15.4874 25.6062 14.519 25.6126 13.7431 25.1572L6.80419 21.161C6.02822 20.7184 5.54724 19.8846 5.54724 18.9801V11.0199C5.54724 10.1155 6.02822 9.27521 6.80419 8.8262L13.7431 4.84288C14.1278 4.61837 14.5511 4.50292 15 4.50292C15.4489 4.50292 15.8722 4.61837 16.2634 4.84288L23.1958 8.83903C23.9718 9.28162 24.4528 10.1155 24.4528 11.0199V18.9801ZM7.81103 19.1277L13.8649 22.6107V15.6447L7.81103 12.1168V19.1277ZM15 6.7287L9.00385 10.186L15 13.6883L21.0026 10.186L15 6.7287ZM22.189 12.1168L16.1351 15.6447V22.6107L22.189 19.1277V12.1168Z' />
    </CubeSVG>
  );
});

export { CubeOutlined };