import React from 'react';
import { useSelector } from 'react-redux';
import { VerticalSpacer, Rotate, Zoom, Body, FovDivider, HorizontalSpacer } from 'components';
import { ControlHolder, ControlsContainer, KeyCombo } from './ControlStyles';
import { withTheme } from 'styled-components';
import { ProtectedContent } from 'components/generic/ProtectedContent';

const ControlsTab = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <>
      <VerticalSpacer size={20} />
      <Body size='Medium Bold'>
        Controls
      </Body>

      <VerticalSpacer size={10} />

      <ControlsContainer>
        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <Rotate />
          <VerticalSpacer size={3} />
          Rotate
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <Rotate flipIcon={true} />
          <VerticalSpacer size={3} />
          Pan
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <Zoom />
          <VerticalSpacer size={3} />
          Zoom
        </ControlHolder>
      </ControlsContainer>

      <VerticalSpacer size={40} />
      <FovDivider />
      <VerticalSpacer size={15} />

      <Body size='Medium Bold'>
        Shortcuts
      </Body>

      <VerticalSpacer size={24} />

      <Body size='Small'>
        Gizmos
      </Body>

      <VerticalSpacer size={8} />

      <ControlsContainer>
        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + R</KeyCombo>
          <VerticalSpacer size={3} />
          Rotate gizmo
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + M</KeyCombo>
          <VerticalSpacer size={3} />
          Move gizmo
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + S</KeyCombo>
          <VerticalSpacer size={3} />
          Scale gizmo
        </ControlHolder>
      </ControlsContainer>

      <VerticalSpacer size={24} />

      <Body size='Small'>
        Meshes
      </Body>

      <VerticalSpacer size={8} />

      <ControlsContainer>
        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + O</KeyCombo>
          <VerticalSpacer size={3} />
          Reset to origin
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + B</KeyCombo>
          <VerticalSpacer size={3} />
          Toggle culling
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + C</KeyCombo>
          <VerticalSpacer size={3} />
          Focus camera
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + H</KeyCombo>
          <VerticalSpacer size={3} />
          Hide selected
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + U</KeyCombo>
          <VerticalSpacer size={3} />
          Unhide all
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Delete</KeyCombo>
          <VerticalSpacer size={3} />
          Del. selected
        </ControlHolder>
      </ControlsContainer>

      <VerticalSpacer size={24} />

      <Body size='Small'>
        Utilities
      </Body>

      <VerticalSpacer size={8} />

      <ControlsContainer>
        <ProtectedContent>
          <ControlHolder
            theme={theme}
            $selectedTheme={selectedTheme}
            fontSize={12}
          >
            <KeyCombo>Shift + N</KeyCombo>
            <VerticalSpacer size={3} />
            Inspector
          </ControlHolder>
        </ProtectedContent>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>Shift + F</KeyCombo>
          <VerticalSpacer size={3} />
          Safe Frame
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>/</KeyCombo>
          <VerticalSpacer size={3} />
          Turntable
        </ControlHolder>

        <ControlHolder
          theme={theme}
          $selectedTheme={selectedTheme}
          fontSize={12}
        >
          <KeyCombo>
            F
            <HorizontalSpacer size={3} />
            1
          </KeyCombo>

          <VerticalSpacer size={3} />
          Toggle Docs.
        </ControlHolder>
      </ControlsContainer>
    </>
  );
});

export default ControlsTab;