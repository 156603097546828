import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const MaterialSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const MaterialOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <MaterialSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 30 30'
    >
      <path fill='currentColor' d='M23.8478 0.115922L0.115555 23.8481C0.245555 24.3393 0.505555 24.787 0.852222 25.1481C1.21333 25.4948 1.66111 25.7548 2.15222 25.8848L25.8989 2.15259C25.6244 1.15592 24.8444 0.375922 23.8478 0.115922ZM12.8267 0.000366211L0 12.827V16.9148L16.9144 0.000366211H12.8267ZM2.88889 0.000366211C1.3 0.000366211 0 1.30037 0 2.88926V5.77814L5.77778 0.000366211H2.88889ZM23.1111 26.0004C23.9056 26.0004 24.6278 25.6826 25.1478 25.1481C25.6822 24.6281 26 23.9059 26 23.1115V20.2226L20.2222 26.0004H23.1111ZM9.08556 26.0004H13.1733L26 13.1737V9.08592L9.08556 26.0004Z' />
    </MaterialSVG>
  );
});

export { MaterialOutlined };