import React from 'react';
import { useSelector } from 'react-redux';
import {
  HorizontalSpacer,
  VerticalSpacer,
  ArrowLeftOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Image,
  Caption,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { filter as images } from './images';
import Summary from './Summary';


const Filtering = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    'Filter models by published category.',
    'Click one or more tags to show models with those tags.',
    <>
      Find models from a specific creator on the&nbsp;<strong>&quot;Creators&quot;</strong>&nbsp;
      page.
    </>,
    'Find specific models by whole or partial name using the search field.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='Controlling which models are shown'>
        <ImageContainer>
          <Image src={images.myFilesContentMenu} width={400} alt='My Files content menu.' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.myFiles} width={500} alt='My Files.' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          Your content or can be found by clicking the <strong>&quot;Content&quot;</strong> menu and Selecting&nbsp;
          <strong>&quot;My Files&quot;</strong>.
        </Caption>

        <VerticalSpacer size={30} />

        <p>
          There are several ways to find one or more models you are looking for.  This can be done with filtering,
          and there are many ways to filter content &mdash; let&apos;s take a look at them now.
        </p>

        <VerticalSpacer size={30} />

        <DocSection subsection='Categories'>
          <p>
            Filtering by category allows you to find models based on their physical appearance.  This information is
            derived from the category you, or a creator, sets at the time the model was created and Published.
          </p>

          <VerticalSpacer size={30} />

          <ImageContainer>
            <Image src={images.myFilesCategories} width={300} alt='My Files categories.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.myFilesCategoriesOpen} width={200} alt='My Files categories open.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            You can expand and collapse categories and click on sub-categories to start filtering
            by that category.<br />

            Clicking <strong>&quot;All&quot;</strong> will clear category filtering and show all models.
          </Caption>
        </DocSection>



        <DocSection subsection='Tags'>
          <p>
            Tags are a great way to find a model by a certain characteristic.  Tag information is set at the time
            the model is created and published.  Tags offer more flexibility when publishing because they are defined by
            you or the creator and can be custom tailored to your pipeline&apos;s organization.
          </p>

          <VerticalSpacer size={30} />

          <ImageContainer>
            <Image src={images.tags} width={300} alt='' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            You can select more than one tag to add to filtered results.  Remove tags by clicking them again to
            remove them from the results.<br />
            Clicking <strong>&quot;Clear all&quot;</strong> will quickly remove all tag
            selections.
          </Caption>
        </DocSection>



        <DocSection subsection='Creators'>
          <p>
            When you click the <strong>&quot;Content&quot;</strong> menu and select&nbsp;
            <strong>&quot;Creators&quot;</strong>, you will be taken to the <strong>Creators</strong>&nbsp;
            page.  This page is functionally identical to your My files page, with the exception of new
            creator-specific categories and <strong>Creators</strong> filtering.
          </p>

          <VerticalSpacer size={30} />

          <ImageContainer>
            <Image src={images.creatorsContentMenu} width={400} alt='Creators content menu.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.creatorsContent} width={500} alt='Creators content.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.creatorCategories} width={300} alt='Creator categories.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.creatorCategoriesOpen} width={200} alt='Creator categories open.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Creators categories are set by the creator and will vary from each content provider.
          </Caption>

          <VerticalSpacer size={30} />

          <ImageContainer>
            <Image src={images.creators} width={300} alt='Creators.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Creators filtering will allow you to show that creator&apos;s content.
          </Caption>
        </DocSection>



        <DocSection subsection='Search field'>
          <p>
            If you know the exact model you need, you can use the search field to find it.  Enter the model
            name in full, or partially and if it exists it will show in the results.
          </p>

          <VerticalSpacer size={30} />

          <ImageContainer column={true}>
            <Image src={images.searchField} width={500} alt='Search field.' />
            <VerticalSpacer size={15} />
            <Image src={images.partialResults} width={700} alt='Partial results.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            If you enter a partial value that matches multiple models, all of those models will show until
            the search becomes more refined.
          </Caption>

          <VerticalSpacer size={30} />

          <ImageContainer column={true}>
            <Image src={images.wholeSearch} width={500} alt='Whole search.' />
            <VerticalSpacer size={15} />
            <Image src={images.wholeResult} width={200} alt='Whole result.' />
          </ImageContainer>
        </DocSection>
      </DocSection>
    </ContentContainer>
  );
});

export { Filtering };