import React from 'react';

const CarTopSideIcon = props => {
  const { width, height } = props;

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 12 24"
      fill='currentColor'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.88008 0H2.87957C1.28899 0 0 1.76918 0 3.35976V21.1199C0 22.71 1.28899 24 2.87957 24H8.88008C10.4701 24 11.7602 22.7105 11.7602 21.1199V3.35976C11.7591 1.76918 10.4701 0 8.88008 0ZM11.2555 7.24001V13.1925L9.8629 13.3717V10.9192L11.2555 7.24001ZM10.5247 5.49736C10.0063 7.4875 9.39241 9.83994 9.39241 9.83994H2.36673L1.23286 5.49736C1.23337 5.49736 5.76629 3.95731 10.5247 5.49736ZM1.91257 11.0799V13.3722L0.519476 13.1941V7.40024L1.91257 11.0799ZM0.519476 19.3594V14.0733L1.91257 14.2483V18.4307L0.519476 19.3594ZM1.314 20.8617L2.44582 19.1594H9.47304L10.6054 20.8617H1.314ZM9.8629 18.271V14.254L11.2555 14.0728V19.2002L9.8629 18.271Z" />
    </svg>
  );
};

export { CarTopSideIcon };
