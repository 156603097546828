import React from 'react';
import { useSelector } from 'react-redux';
import { Wrapper } from './styles';
import { withTheme } from 'styled-components';
import OnBoarding from './OnBoarding';

const Welcome = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Wrapper theme={theme} $selectedTheme={selectedTheme}>
      <OnBoarding />
    </Wrapper>
  );
});

export { Welcome };