import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme } from 'styled-components';

const DeleteSVG = styled('svg')`
  color: ${props => props.color || getColor(props, 'error')};
`;

const DeletionSuccessIcon = withTheme(props => {
  const { width, height, theme, color } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <DeleteSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      height={height || 24}
      width={width || 24}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 49 49'
    >
      <path
        d='M21.8692 31.4723L32.161 21.1806C32.9203 20.4212 34.1515 20.4212 34.9108 21.1806C35.6702 21.94 35.6702 23.1711 34.9108 23.9305L23.2442 35.5971C22.4848 36.3565 21.2536 36.3565 20.4943 35.5971L16.6054 31.7082C15.8461 30.9489 15.8461 29.7177 16.6054 28.9584C17.3648 28.199 18.5959 28.199 19.3553 28.9584L21.8692 31.4723Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5916 0C16.4312 0 15.3161 0.471312 14.4921 1.31402C13.6678 2.15712 13.2027 3.30295 13.2027 4.5V10H2.92491C2.41289 10 1.9195 10.2079 1.55393 10.5818C1.188 10.956 0.980469 11.466 0.980469 12C0.980469 12.534 1.188 13.044 1.55393 13.4182C1.9195 13.7921 2.41289 14 2.92491 14H6.00685L8.1537 44.8203C8.23295 45.9589 8.73103 47.0222 9.54437 47.7969C10.3574 48.5713 11.4249 49 12.5316 49H37.3158C38.4224 49 39.49 48.5713 40.303 47.7969C41.1163 47.0222 41.6144 45.9589 41.6937 44.8203L43.8455 14H46.9249C47.4369 14 47.9303 13.7921 48.2959 13.4182C48.6618 13.044 48.8694 12.534 48.8694 12C48.8694 11.466 48.6618 10.956 48.2959 10.5818C47.9303 10.2079 47.4369 10 46.9249 10H36.6471V4.5C36.6471 3.30295 36.1821 2.15712 35.3577 1.31402C34.5337 0.471311 33.4186 0 32.2582 0H17.5916ZM17.0916 4H32.7582V10H17.0916V4ZM9.903 14H39.9493L37.7845 45H12.0677L9.903 14Z'
        fill='currentColor'
      />
    </DeleteSVG>
  );
});

export { DeletionSuccessIcon };