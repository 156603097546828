import { actions as availActions } from 'store/actions/avail';
import u from 'updeep';

const initialState = {
  channels: [],
};

const availReducer = (state = initialState, action) => {
  switch (action.type) {

    case availActions.GET_AVAIL_CHANNELS:
      return u({ channels: action.payload }, state);

    default:
      return state;
  }
};

export { availReducer };
