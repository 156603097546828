import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const ChairSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const ChairOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <ChairSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 19'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12.4286 12.4285C13.0594 12.4285 13.5714 11.9165 13.5714 11.2857C13.5714 10.6548 13.0594 10.1428 12.4286 10.1428M12.4286 12.4285H5.57142M12.4286 12.4285L13.5714 12.4286C14.8339 12.4286 15.8571 11.4053 15.8571 10.1429V7.85716M5.57142 12.4285C4.94056 12.4285 4.42856 11.9165 4.42856 11.2857C4.42856 10.6548 4.94056 10.1428 5.57142 10.1428M5.57142 12.4285L4.42857 12.4286C3.16609 12.4286 2.14285 11.4053 2.14285 10.1429V7.85716M5.57142 10.1428H12.4286M5.57142 10.1428L5.57143 4.42857C5.57143 2.53524 7.10667 1 9 1C10.8933 1 12.4286 2.53524 12.4286 4.42857L12.4286 10.1428M9 12.4286V18.1429M4.01953 18.1428C4.74258 16.027 6.76543 14.635 9.0001 14.7143C11.234 14.635 13.2569 16.0278 13.9807 18.1428M1 7.85716H3.28571M17 7.85716H14.7143'
      />
    </ChairSVG>
  );
});

export { ChairOutlined };