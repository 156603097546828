import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const DeskSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const DeskOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <DeskSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 14'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M1 12.7334V3.13342M1 3.13342H17M1 3.13342V1.35556C1 1.15929 1.15929 1 1.35556 1H16.6444C16.8407 1 17 1.15929 17 1.35556L17 3.13342M17 3.13342H10.6V6.33342M17 3.13342V6.33342M17 6.33342H10.6M17 6.33342V9.53325M10.6 6.33342V9.53325M17 9.53325H10.6M17 9.53325V12.3778C17 12.574 16.8407 12.7333 16.6444 12.7333H10.9555C10.7592 12.7333 10.6 12.574 10.6 12.3778V9.53325'
      />
    </DeskSVG>
  );
});

export { DeskOutlined };