import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DeletionControlsContainer } from '../ModelToolbarStyles';
import {
  VerticalSpacer,
  FovButton,
  FovTypography,
  Body,
  DocNote,
  UndoOutlined,
  FovToggle,
  FovDivider,
} from 'components';
import { VisibilityToggle } from './styles';
import { withTheme } from 'styled-components';
import constants from 'constants/index';
import { updateCanvas } from 'utils';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Visibility = withTheme(props => {
  const { theme, themeColors } = props;

  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const selectedTheme = userDataStore.userPreferences.theme;
  const errorColor = theme.colors[selectedTheme].error;
  const errorHoveredColor = theme.colors[selectedTheme].errorHover;
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const hasSelection = serializedData?.metadata?.selectedMeshes.length > 0 || false;
  const hasHidden = serializedData?.metadata?.hiddenMeshes.length > 0 || false;
  const backgroundEnabled = serializedData?.metadata.viewportEnvironment;
  const gridEnabled = serializedData?.metadata.viewportGround;
  const axesEnabled = serializedData?.metadata.viewportAxes;
  const shadowsEnabled = serializedData?.metadata.viewportShadows;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.VIEWPORT,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  return (
    <>
      <VerticalSpacer size={15} />

      <Body size="Medium Bold">
        Mesh
        <DocNote path={[ 'visibilityPanel' ]} top={-2} left={47} />
        <VerticalSpacer size={15} />
      </Body>

      <DeletionControlsContainer>
        <FovButton
          text="Hide selected"
          color={themeColors.accent}
          hoverColor={themeColors.accentHover}
          disabled={!hasSelection}
          onClick={() => updateCanvas(updateArgs, { hideSelected: true })}
        />

        <FovButton
          text="Delete selected"
          color={errorColor}
          hoverColor={errorHoveredColor}
          disabled={!hasSelection}
          onClick={() => updateCanvas(removeArgs, { deleteSelectedMesh: true })}
        />

        <FovButton
          text="Deselect all"
          color={themeColors.accent}
          hoverColor={themeColors.accentHover}
          disabled={!hasSelection}
          onClick={() => updateCanvas(updateArgs, { deselectAll: true })}
        />

        <FovButton
          text="Unhide last"
          color={themeColors.accent}
          hoverColor={themeColors.accentHover}
          disabled={!hasHidden}
          icon={
            <UndoOutlined
              width={22}
              height={22}
              color={themeColors.onAccent}
            />
          }
          onClick={() => updateCanvas(updateArgs, { unhideLast: true })}
        />
        <FovButton
          text="Unhide All"
          color={themeColors.accent}
          hoverColor={themeColors.accentHover}
          disabled={!hasHidden}
          onClick={() => updateCanvas(updateArgs, { unhideAll: true })}
        />
      </DeletionControlsContainer>

      <VerticalSpacer size={40} />
        <FovDivider />
        <VerticalSpacer size={15} />

        <Body size='Medium Bold'>
          Viewport
          <DocNote path={[ 'environmentPanel', 'viewportVisibility' ]} top={-2} left={142} />
        </Body>

        <VerticalSpacer size={15} />

        <VisibilityToggle>
          <FovTypography>Background</FovTypography>

          <FovToggle
            checked={backgroundEnabled}
            callback={newVal => updateCanvas(updateArgs, { envVisible: newVal })}
          />
        </VisibilityToggle>

        <VerticalSpacer size={15} />

        <VisibilityToggle>
          <FovTypography>Grid</FovTypography>
          <FovToggle
            checked={gridEnabled}
            callback={newVal => updateCanvas(updateArgs, { grid: newVal })}
          />
        </VisibilityToggle>

        <VerticalSpacer size={15} />

        <VisibilityToggle>
          <FovTypography>Axes</FovTypography>
          <FovToggle
            checked={axesEnabled}
            callback={newVal => updateCanvas(updateArgs, { axes: newVal })}
          />
        </VisibilityToggle>

        <VerticalSpacer size={15} />

        <VisibilityToggle>
          <FovTypography>Shadows</FovTypography>
          <FovToggle
            defaultChecked={shadowsEnabled}
            callback={newVal => updateCanvas(updateArgs, { shadows: newVal })}
          />
        </VisibilityToggle>
    </>
  );
});

export default Visibility;