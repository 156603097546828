import styled, { css } from 'styled-components';
import { getColor } from 'utils';
import { FovButton } from 'components/generic';

export const SectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ViewButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2.75rem;
`;

export const ViewButton = styled(FovButton)`
  height: 2.75rem;
  width: 2.75rem;
  background-color: ${props => getColor(props, 'primary')};
  color: ${props => getColor(props, 'onPrimary')};
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  padding-left: 0;
  padding-right: 0;

  ${props => props.$selected && css`
    border: 0.125rem solid ${props => getColor(props, 'accent')};
    color: ${props => getColor(props, 'accent')};
  `}

  &:hover {
    color: ${props => getColor(props, 'accentHover') };
    border: 0.125rem solid ${props => getColor(props, 'accentHover')};
    background-color: ${props => getColor(props, 'primary')};
  }
`;

export const CameraViewButton = styled(FovButton)`
  width: auto;
`;
