import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getIn } from 'formik';
import { isEmpty } from 'lodash';
import { VerticalSpacer, HorizontalSpacer } from 'components';
import styled, { withTheme } from 'styled-components';
import { getColor } from 'utils';

const Error = styled('div')`
  font-size: 0.9rem;
  color: ${props => getColor(props, 'error')};
`;

const FovFieldError = withTheme(props => {
  const { theme, name, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const buildFieldError = () => {
    if (name) {
      return (
        <Field name={name}>
          {payload => {
            const { form } = payload;
            const { errors, touched } = form;
            const msg = getIn(errors, name);
            const isTouched = getIn(touched, name);
            const showError = !isEmpty(msg) && isTouched;

            if (showError) {
              return (
                <>
                  <VerticalSpacer size={8} />
                  <HorizontalSpacer size={12} />

                  <Error theme={theme} $selectedTheme={selectedTheme} {...rest}>
                    {msg}
                  </Error>
                </>
              );
            }

            return null;
          }}
        </Field>
      );
    }
  };

  return (
    <>
      {buildFieldError()}
      <VerticalSpacer size={15} />
    </>
  );
});

export { FovFieldError };