import React from 'react';
import PropertiesContent from './Properties';
import ControlsContent from './Controls';
import MaterialsContent from './Materials';
import EnvironmentContent from './Environment';
import PublishContent from './Publish';
import VisibilityContent from './Visibility';
import CameraContent from './Camera';
import {
  CameraIcon,
  EyeOutlined,
  SettingsOutlined,
  SunOutlined,
  MaterialOutlined,
  CubeOutlined,
  ShareOutlined,
} from 'components/icons';

const menuHoverColor = (theme, selectedTheme, useError) => {
  const themeColors = theme.colors[selectedTheme];

  if (useError) {
    return themeColors.error;
  }

  return themeColors['accent'];
};

export const toolbarTabs = (theme, selectedTheme) => ([
  {
    name: 'Materials',
    icon: <MaterialOutlined
      hoverColor={menuHoverColor(theme, selectedTheme)}
      theme={theme}
      $selectedTheme={selectedTheme}
    />,
    panelContent: <MaterialsContent />,
  },
  {
    name: 'Model properties',
    icon: <CubeOutlined
      hoverColor={menuHoverColor(theme, selectedTheme)}
      theme={theme}
      $selectedTheme={selectedTheme}
    />,
    panelContent: <PropertiesContent />,
  },
  {
    name: 'Camera',
    icon: <CameraIcon
      hoverColor={menuHoverColor(theme, selectedTheme)}
      theme={theme}
      $selectedTheme={selectedTheme}
    />,
    panelContent: <CameraContent />,
  },
  {
    name: 'Environment',
    icon: <SunOutlined
      hoverColor={menuHoverColor(theme, selectedTheme)}
      theme={theme}
      $selectedTheme={selectedTheme}
    />,
    panelContent: <EnvironmentContent />,
  },
  {
    name: 'Visibility',
    icon: <EyeOutlined
      hoverColor={menuHoverColor(theme, selectedTheme, true)}
      theme={theme}
      $selectedTheme={selectedTheme}
    />,
    panelContent: <VisibilityContent themeColors={theme.colors[selectedTheme]} />,
  },
  {
    name: 'Publish',
    icon: <ShareOutlined
      hoverColor={menuHoverColor(theme, selectedTheme)}
      theme={theme}
      $selectedTheme={selectedTheme}
    />,
    panelContent: <PublishContent metadata={null} setMetadata={null}/>,
  },
]);

export const utilityTabs = (theme, selectedTheme) => ([
  {
    name: 'Settings',
    icon: <SettingsOutlined
      hoverColor={menuHoverColor(theme, selectedTheme)}
      theme={theme}
      $selectedTheme={selectedTheme}
    />,
    panelContent: <ControlsContent />,
  },
]);