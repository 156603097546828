import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { Body } from 'components/typography';
import {
  ConfirmationButton,
  CancelButton,
  ConfirmationHolder,
  TextHolder,
} from './styles';
import { ModalMessageHolder } from './ModalMessageHolder';
import constants from 'constants/index';

const FovConfirmation = withTheme(
  props => {
    const {
      children,
      onConfirm,
      onClose,
      theme,
      confirmationText,
      closeText,
      isOpened,
      confirmColor,
      confirmHoverColor,
      ignoreConfirmClose,
      disabled,
      ...rest
    } = props;
    const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

    const light = constants.THEME.LIGHT;
    const cancelColor =
    selectedTheme === light
        ? theme.colors[selectedTheme].darkGrey
        : theme.colors[selectedTheme].lightGrey;
    const cancelHoverColor =
    selectedTheme === light
        ? theme.colors[selectedTheme].lightGrey
        : theme.colors[selectedTheme].darkGrey;
    const primary = theme.colors[selectedTheme].primary;

    const handleConfirm = e => {
      onConfirm(e);

      if (!ignoreConfirmClose) {
        onClose();
      }
    };

    return (
      <ModalMessageHolder onClose={onClose} visible={isOpened}>
        <ConfirmationHolder
          $selectedTheme={selectedTheme}
          theme={theme}
          {...rest}
        >
          <TextHolder>{children}</TextHolder>

          <ConfirmationButton
            $selectedTheme={selectedTheme}
            theme={theme}
            callback={handleConfirm}
            color={confirmColor}
            disabled={disabled}
            hoverColor={confirmHoverColor}
          >
            <Body size='big' color={primary}>
              {confirmationText}
            </Body>
          </ConfirmationButton>
          <CancelButton
            $selectedTheme={selectedTheme}
            theme={theme}
            callback={onClose}
            hoverColor={cancelHoverColor}
          >
            <Body size='big' color={cancelColor}>
              {closeText || 'Cancel'}
            </Body>
          </CancelButton>
        </ConfirmationHolder>
      </ModalMessageHolder>
    );
  },
);

export { FovConfirmation };
