import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme } from 'styled-components';

const ReadySVG = styled('svg')`
  color: ${props => props.color || getColor(props, 'success')};
`;

const ReadyIcon = withTheme(props => {
  const { theme, width, height, color } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <ReadySVG
      theme={theme}
      $selectedTheme={selectedTheme}
      width={width || 24}
      height={height || 24}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 36 35'
      fill='none'
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4805 0C28.1455 0 35.9805 7.83502 35.9805 17.5C35.9805 27.165 28.1455 35 18.4805 35C8.81549 35 0.980469 27.165 0.980469 17.5C0.980469 7.83502 8.81549 0 18.4805 0ZM18.4805 3.5C10.7485 3.5 4.48047 9.76801 4.48047 17.5C4.48047 25.232 10.7485 31.5 18.4805 31.5C26.2125 31.5 32.4805 25.232 32.4805 17.5C32.4805 9.76801 26.2125 3.5 18.4805 3.5ZM24.243 11.0126L14.9805 20.2751L12.7179 18.0126C12.0345 17.3291 10.9264 17.3291 10.243 18.0126C9.55962 18.696 9.55962 19.804 10.243 20.4874L13.743 23.9874C14.4264 24.6709 15.5345 24.6709 16.2179 23.9874L26.7179 13.4874C27.4013 12.804 27.4013 11.696 26.7179 11.0126C26.0345 10.3291 24.9264 10.3291 24.243 11.0126Z'
      />
    </ReadySVG>
  );
});

export { ReadyIcon };