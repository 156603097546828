import { actions as appActions } from 'store/actions/app';
import constants from 'constants/index';
import u from 'updeep';

const initialState = {
  placeholderValue: 'THIS STRING WAS LOADED FROM THE STORE!',
  showProgressOverlay: false,
  theme: constants.THEME.LIGHT,
  errorMessage: null,
  activePanel: null,
  showOnBoarding: false,
  messages: [],
  saveEnabled: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case appActions.SET_SHOW_ONBOARDING:
      return u({ showOnBoarding: action.payload }, state);

    case appActions.ACTIVATE_PROGRESS_INDICATOR:
      return u({ showProgressOverlay: true }, state);

    case appActions.DEACTIVATE_PROGRESS_INDICATOR:
      return u({ showProgressOverlay: false }, state);

    case appActions.SET_GLOBAL_ERROR_MESSAGE:
      return u({ errorMessage: action.payload }, state);

    case appActions.CLEAR_GLOBAL_ERROR_MESSAGE:
      return u({ errorMessage: null }, state);

    case appActions.SET_ACTIVE_PANEL:
      return u({ activePanel: action.payload }, state);

    case appActions.ADD_MESSAGE:
      return u({
        messages: action.meta.clearExisting
          ? [ ...action.payload ]
          : [ ...state.messages, ...action.payload ],
      }, state);

    case appActions.REMOVE_MESSAGE:
      // eslint-disable-next-line
      const messages = state.messages.filter(
        (_, index) => index !== action.payload,
      );
      return u({ messages }, state);

    case appActions.CLEAR_MESSAGES:
      return u({ messages: [] }, state);

    default:
      return state;
  }
};

export { appReducer };
