import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const LeafSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const LeafOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <LeafSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 12 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M6.00038 5.99994V16M8.5004 9C8.5004 10.3807 7.38106 11.5 6.00038 11.5M6.0002 9.50001C4.89552 9.50001 4.00018 8.60467 4.00018 7.5M11.0001 9.00006C11.0001 11.7614 8.76139 14.0001 6.00003 14.0001C3.23868 14.0001 1 11.7614 1 9.00006C1 5.50003 4.50002 1 6.00003 1C7.50004 1 11.0001 5.50003 11.0001 9.00006Z'
      />
    </LeafSVG>
  );
});

export { LeafOutlined };