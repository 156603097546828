import React from 'react';
import { useSelector } from 'react-redux';
import {
  VerticalSpacer,
  HorizontalSpacer,
  ArrowLeftOutlined,
  EditOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Caption,
  Image,
  ContentContainer,
} from '../docStyles';
import { SUPPORTED_EXTENSIONS } from 'utils';
import { withTheme } from 'styled-components';
import { fromFile as images } from './images';
import Summary from './Summary';

const CreateFromFile = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;
  const supportedTypes = SUPPORTED_EXTENSIONS
    .map(ext => !ext.hideInList ? ext.name : null)
    .filter(item => item)
    .join(', ');

  const summary = [
    <>
      Upload a variety of file types&nbsp;<strong>&quot;From File&quot;</strong>,
      in the&nbsp;<strong>&quot;Create&quot;</strong>&nbsp; menu.
    </>,
    <>
      Supported files:&nbsp;<strong>{supportedTypes}</strong>
    </>,
    'Add supporting files with multi-upload.',
    'Package supporting files using a .zip',
    'Add package details using the form fields.',
    <>
      Upload your package using&nbsp;<strong>&quot;Create&quot;</strong>
    </>,
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='The create form'>
        <p>
          Navigate to the Create form, from the <strong>&quot;Create&quot;</strong> menu in the app header
          and click <strong>&quot;From File&quot;</strong>.
        </p>

        <ImageContainer>
          <Image src={images.fromFile} width={400} alt='From file menu' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.uploadForm} width={350} alt='Upload form' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          The Create form will appear, allowing you to upload a variety of file types
          to be converted for use in the app.
        </Caption>
      </DocSection>


      <DocSection section='Add files to the form'>
        <DocSection subsection='Upload field'>
          <p>
            The first section of this form is the upload field.  You can either drag one or more files
            onto the drop zone, or click the drop zone to manually navigate to your local file(s) to add
            it/them to the form.
          </p>

          <p>
            For the most part, you will need to add a single file to the upload area,
            but there are certain file types that will require supported files to be
            uploaded as well, let&apos;s take a look at the upload field and any special cases.
          </p>

          <ImageContainer>
            <Image src={images.uploadArea} width={350} alt='Upload area.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.gltfUpload} width={350} alt='glTF upload.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            If you drag a file that may need a supported file, the form will warn you, shown
            in yellow, after dragging a <strong>.gltf</strong> file to the upload area. <br />
            Since a supported file may not be required for some file formats, this is only a warning. <br />
            If your job fails, the specific file you are using may need the supporting file after all.
          </Caption>
        </DocSection>



        <DocSection subsection='glTF + BIN'>
          <p>
            As mentioned in the caption above, some <strong>.gltf</strong> files may require a supporting&nbsp;
            <strong>.bin</strong> file.  If you know this is needed, select both of your files and
            add them to the upload field &mdash; the warning will go away.
          </p>

          <ImageContainer>
            <Image src={images.allFilesAdded} width={350} alt='All files added.' />
          </ImageContainer>
        </DocSection>



        <DocSection subsection='FBX, OBJ and ZIP'>
          <p>
            Some files, such as <strong>.fbx</strong> or <strong>.obj</strong> may require the main file type, textures
            and any other supporting files. In the case of <strong>.obj</strong>, you may also need to include the&nbsp;
            <strong>.mtl</strong> with your <strong>.obj</strong> and textures.
          </p>

          <p>
            <strong>.zip</strong> files can be created
            by selected all files required for the job to process, in your file explorer,
            right-clicking and selecting <strong>&quot;Compress...&quot;</strong> or&nbsp;
            <strong>&quot;Add to .zip&quot;</strong> &mdash; where they can then be added to the form.
          </p>

          <ImageContainer>
            <Image src={images.creatingZipFile} width={400} alt='Creating zip file.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.zipFile} width={100} alt='zip file.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.uploadedZipFile} width={350} alt='Uploaded zip file.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            If <strong>.fbx</strong> files have textures assigned to them, and those textures are provided
            in the <strong>.zip</strong> file, your model will load with textures already assigned.  If no
            textures are assigned from your 3D application and the <strong>.fbx</strong> is uploaded by itself,
            you will be able to manually upload and assign textures to the materials found in the&nbsp;
            <strong>.fbx</strong> &mdash; The same is true for <strong>.obj</strong> and <strong>.mtl</strong>.
          </Caption>
        </DocSection>



        <DocSection subsection='PNG images'>
          <p>
            <strong>.png</strong> files, when added to the form, will display a new field for height.
            This is unique to the image file type. This is the height that the model should be,
            in your unit of choice.
          </p>

          <ImageContainer>
            <Image src={images.heightField} width={350} alt='Height field.' />
          </ImageContainer>
        </DocSection>
      </DocSection>



      <DocSection section='Publishing'>
        <DocSection subsection='Adding package details'>
          <p>
            With your file added to the form, add package details &mdash; such as a name, category
            or tags.  These will help in placing your newly published model in your&nbsp;
            <strong>&quot;My Files&quot;</strong> page.  As mentioned above, if you are uploading a&nbsp;
            <strong>.png</strong>, please fill out the height field as well.
          </p>

          <ImageContainer>
            <Image src={images.completedForm} width={400} alt='Completed form.' />
          </ImageContainer>

          <p>
            Once the form is filled out, proceed by clicking the <strong>&quot;Create&quot;</strong> button.
            The job will be packaged, created and sent off for processing.  Once the upload is complete,
            you will see a notification informing you that your model has been published.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.creating} width={250} alt='Creating.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.uploading} width={250} alt='Uploading.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.published} width={250} alt='Published.' />
          </ImageContainer>

          <p>
            A new job card will appear in your <strong>&quot;My Files&quot;</strong> page showing the status of the
            job as it completes.  Once it has finished, you will be able to edit the file in the 3D
            viewport by clicking the edit button on the model card <EditOutlined />.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.editModel} width={200} alt='Edit model.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.editingNewModel} width={500} alt='Editing new model.' />
          </ImageContainer>
        </DocSection>
      </DocSection>
    </ContentContainer>
  );
});

export { CreateFromFile };