import React, { useContext } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserPreferences } from 'store/actions/userData';
import { FovOption, DocNote, AppstoreSolid, ListOutlined, VerticalSpacer } from 'components';
import {
  ViewContainer,
  DisplayOptions,
  SortingOptions,
  DisplayButtons,
  ViewButton,
  SortContainer,
  SortSelect,
  Slider,
  SliderContainer,
  SliderMenu,
} from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import { paramsToObject } from 'utils/paramHelpers';
import { decode } from 'utils';
import { getQuerySortingParameters } from './Models/utils';
import { ModelContext } from './ModelContext';

const VIEWS = { CARD: 0, LIST: 1 };

const ViewOptions = withTheme(props => {
  const { theme } = props;
  const { getModels, sortingOptions } = useContext(ModelContext);

  const [ , setSearchParams ] = useSearchParams();

  const dispatch = useDispatch();
  const location = useLocation();
  const userDataStore = useSelector(state => state.userData);
  const userPreferences = userDataStore.userPreferences;
  const selectedTheme = userPreferences?.theme;
  const activeView = userPreferences?.modelView;
  const cardScale = userPreferences?.cardScaling;
  const sorting = sortingOptions.find(option => option === userPreferences?.sorting) || 'newest first';
  const onAccent = theme.colors[selectedTheme].onAccent;
  const mediumGrey = theme.colors[selectedTheme].mediumGrey;
  const params = paramsToObject(decode(location.search));

  const placeholder = currentSelection => {
    const match = sortingOptions.find(key => key === currentSelection);
    return _.startCase(match);
  };

  const sortingSelectOptions = () => {
    return sortingOptions.map(sortType => (
        <FovOption key={sortType} value={sortType}>
          {_.startCase(sortType)}
        </FovOption>
    ));
  };

  return (
    <ViewContainer>
      <SortingOptions>
        <DocNote path={[ 'content', 'viewPreferences' ]} top={7} left={-35} />

        <SortContainer>
          <SortSelect
            value={sorting}
            placeholder={placeholder(sorting)}
            onChange={e => {
              const value = e.target.value;
              setSearchParams({ ...params, order_by: getQuerySortingParameters(value), page: 1 });
              getModels();
              dispatch(setUserPreferences({ sorting: value }));
            }}
          >
            {sortingSelectOptions()}
          </SortSelect>
        </SortContainer>
      </SortingOptions>

      <DisplayOptions>
        <DisplayButtons>
          <ViewButton
            theme={theme}
            $selectedTheme={selectedTheme}
            isActive={activeView === VIEWS.CARD}
            onClick={() => dispatch(setUserPreferences({ modelView: VIEWS.CARD }))}
            icon={
              <AppstoreSolid
                width={40}
                height={40}
                color={activeView === VIEWS.CARD ? onAccent : mediumGrey}
              />
            }
          />

          <ViewButton
            theme={theme}
            $selectedTheme={selectedTheme}
            isActive={activeView === VIEWS.LIST}
            onClick={() => dispatch(setUserPreferences({ modelView: VIEWS.LIST }))}
            icon={
              <ListOutlined
                width={40}
                height={40}
                color={activeView === VIEWS.LIST ? onAccent : mediumGrey}
              />
            }
          />
        </DisplayButtons>

        <VerticalSpacer size={8} />

        <SliderMenu>
          {activeView === VIEWS.CARD && (
            <SliderContainer>
              <Slider
                min={50}
                max={100}
                step={1}
                defaultValue={cardScale ?? 70}
                onChange={value => dispatch(setUserPreferences({ cardScaling: value }))}
              />
            </SliderContainer>
          )}

          {activeView === VIEWS.LIST && (
            <VerticalSpacer size={45} />
          )}
        </SliderMenu>
      </DisplayOptions>
    </ViewContainer>
  );
});

export { ViewOptions };