import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FovToggle, Body, FovSelect, VerticalSpacer, FovOption } from 'components';
import { LabeledItemHolder, TitleWithTooltipContainer } from '../MaterialStyles';
import { DOWNSCALE_SIZES } from 'utils/enums';
import { updateCanvas } from 'utils';
import { withTheme } from 'styled-components';
import constants from 'constants/index';

const MaterialDownscaling = withTheme(props => {
  const { theme } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const downscalingEnabled = selectedMaterial?.textureDownscaling?.enabled;
  const selectedDownscaleSize = selectedMaterial?.textureDownscaling?.size;
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const globalDownscalingEnabled = serializedData?.metadata.publish.textureDownscaling?.enabled;
  const warning = theme.colors[selectedTheme].warning;
  const dispatch = useDispatch();

  const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const materialDownscaleOptions = DOWNSCALE_SIZES?.map((option, index) => (
    <FovOption key={index} value={option.value}>
      {option.name}
    </FovOption>
  ));

  const light = constants.THEME.LIGHT;
  const tooltipColor = selectedTheme === light
    ? theme.colors[selectedTheme].darkGrey
    : theme.colors[selectedTheme].lightGrey;

  return (
    <div>
      <LabeledItemHolder>

        <TitleWithTooltipContainer>
          Texture downscaling
          <Body size="X-Small" color={tooltipColor}>
            Affects textures larger than selected size.
          </Body>
        </TitleWithTooltipContainer>

        <FovToggle
          checked={downscalingEnabled && !globalDownscalingEnabled}
          disabled={globalDownscalingEnabled}
          callback={newVal => {
            const inboundData = {
              id: selectedMaterialId,
              textureDownscaling: { enabled: newVal },
            };

            updateCanvas(updateArgs, inboundData);
          }}
        />
      </LabeledItemHolder>

      {globalDownscalingEnabled && (
        <Body size='Small' color={warning}>
          Publish panel global downscaling enabled.
        </Body>
      )}

      {downscalingEnabled && !globalDownscalingEnabled && (
        <>
          <VerticalSpacer size={10} />

          <FovSelect
            value={selectedDownscaleSize}
            placeholder={DOWNSCALE_SIZES.find(size => size.value === +selectedDownscaleSize)?.name}
            onChange={(e, newVal) => {
              const inboundData = {
                id: selectedMaterialId,
                textureDownscaling: {
                  size: +(newVal),
                },
              };

              updateCanvas(updateArgs, inboundData);
            }}
          >
            {materialDownscaleOptions}
          </FovSelect>

          <VerticalSpacer size={15} />
        </>
      )}
    </div>
  );
});

export default MaterialDownscaling;