import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const BusSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const BusOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <BusSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 16'
    >
      <path
        fill='currentColor'
        d='M4.75902 12.1745C5.31424 12.1745 5.76597 11.7228 5.76597 11.1676C5.76597 10.6124 5.31427 10.1606 4.75902 10.1606C4.20377 10.1606 3.75208 10.6123 3.75208 11.1676C3.75208 11.7228 4.20377 12.1745 4.75902 12.1745ZM4.75902 10.7981C4.96275 10.7981 5.12852 10.9639 5.12852 11.1676C5.12852 11.3713 4.96275 11.5371 4.75902 11.5371C4.55529 11.5371 4.38953 11.3713 4.38953 11.1676C4.38953 10.9639 4.55529 10.7981 4.75902 10.7981Z' />
      <path
        fill='currentColor'
        d='M13.2406 12.1745C13.7958 12.1745 14.2476 11.7228 14.2476 11.1676C14.2476 10.6124 13.7958 10.1606 13.2406 10.1606C12.6854 10.1606 12.2337 10.6123 12.2337 11.1676C12.2337 11.7228 12.6854 12.1745 13.2406 12.1745ZM13.2406 10.7981C13.4444 10.7981 13.6101 10.9639 13.6101 11.1676C13.6101 11.3713 13.4444 11.5371 13.2406 11.5371C13.0369 11.5371 12.8711 11.3713 12.8711 11.1676C12.8711 10.9639 13.0369 10.7981 13.2406 10.7981Z' />
      <path
        fill='currentColor'
        d='M16.6813 5.80807H15.7126V2.59149C15.7126 1.71394 14.9987 1 14.1211 1H3.87892C3.00137 1 2.28743 1.71394 2.28743 2.59149V5.80807H1.31873C1.14269 5.80807 1 5.95076 1 6.12679V9.00727C1 9.1833 1.14269 9.32599 1.31873 9.32599H2.28743V13.2576C2.28743 13.4337 2.43012 13.5764 2.60615 13.5764H2.8532V14.5122C2.8532 15.0988 3.33049 15.5761 3.91716 15.5761H4.10824C4.69492 15.5761 5.17221 15.0988 5.17221 14.5122V13.5764H12.8278V14.5122C12.8278 15.0988 13.3051 15.5761 13.8918 15.5761H14.0829C14.6695 15.5761 15.1468 15.0988 15.1468 14.5122V13.5764H15.3939C15.5699 13.5764 15.7126 13.4337 15.7126 13.2576V9.32599H16.6813C16.8573 9.32599 17 9.1833 17 9.00727V6.12679C17 5.95076 16.8573 5.80807 16.6813 5.80807ZM2.28022 8.68855H1.63745V6.44552H2.28022V8.68855ZM4.53473 14.5122C4.53473 14.7473 4.34339 14.9387 4.10821 14.9387H3.91713C3.68194 14.9387 3.49061 14.7473 3.49061 14.5122V13.5764H4.53473V14.5122ZM14.5094 14.5122C14.5094 14.7473 14.318 14.9387 14.0828 14.9387H13.8918C13.6566 14.9387 13.4652 14.7473 13.4652 14.5122V13.5764H14.5094V14.5122ZM15.0752 12.9389H2.92488V9.33431H15.0752V12.9389ZM15.0752 8.69686H13.6721C13.6552 8.68781 13.6375 8.67994 13.6186 8.67388L9.56759 7.37447C9.3999 7.32067 9.22046 7.41301 9.16673 7.58062C9.11299 7.74824 9.20526 7.92772 9.37288 7.98145L11.6032 8.69683H8.35997L4.25539 7.37447C4.08771 7.32067 3.9083 7.41297 3.85457 7.58062C3.8008 7.74824 3.8931 7.92772 4.06072 7.98145L6.29106 8.69683H2.92488V4.83458H15.0752V8.69686ZM15.0752 4.19713H2.92488V2.59149C2.92488 2.06543 3.35286 1.63745 3.87892 1.63745H14.1211C14.6472 1.63745 15.0752 2.06543 15.0752 2.59149V4.19713ZM16.3626 8.68855H15.7198V6.44552H16.3626V8.68855Z'
      />
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeWidth='0.15'
        d='M4.75902 12.1745C5.31424 12.1745 5.76597 11.7228 5.76597 11.1676C5.76597 10.6124 5.31427 10.1606 4.75902 10.1606C4.20377 10.1606 3.75208 10.6123 3.75208 11.1676C3.75208 11.7228 4.20377 12.1745 4.75902 12.1745ZM4.75902 10.7981C4.96275 10.7981 5.12852 10.9639 5.12852 11.1676C5.12852 11.3713 4.96275 11.5371 4.75902 11.5371C4.55529 11.5371 4.38953 11.3713 4.38953 11.1676C4.38953 10.9639 4.55529 10.7981 4.75902 10.7981Z'
      />
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeWidth='0.15'
        d='M13.2406 12.1745C13.7958 12.1745 14.2476 11.7228 14.2476 11.1676C14.2476 10.6124 13.7958 10.1606 13.2406 10.1606C12.6854 10.1606 12.2337 10.6123 12.2337 11.1676C12.2337 11.7228 12.6854 12.1745 13.2406 12.1745ZM13.2406 10.7981C13.4444 10.7981 13.6101 10.9639 13.6101 11.1676C13.6101 11.3713 13.4444 11.5371 13.2406 11.5371C13.0369 11.5371 12.8711 11.3713 12.8711 11.1676C12.8711 10.9639 13.0369 10.7981 13.2406 10.7981Z'
      />
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeWidth='0.15'
        d='M16.6813 5.80807H15.7126V2.59149C15.7126 1.71394 14.9987 1 14.1211 1H3.87892C3.00137 1 2.28743 1.71394 2.28743 2.59149V5.80807H1.31873C1.14269 5.80807 1 5.95076 1 6.12679V9.00727C1 9.1833 1.14269 9.32599 1.31873 9.32599H2.28743V13.2576C2.28743 13.4337 2.43012 13.5764 2.60615 13.5764H2.8532V14.5122C2.8532 15.0988 3.33049 15.5761 3.91716 15.5761H4.10824C4.69492 15.5761 5.17221 15.0988 5.17221 14.5122V13.5764H12.8278V14.5122C12.8278 15.0988 13.3051 15.5761 13.8918 15.5761H14.0829C14.6695 15.5761 15.1468 15.0988 15.1468 14.5122V13.5764H15.3939C15.5699 13.5764 15.7126 13.4337 15.7126 13.2576V9.32599H16.6813C16.8573 9.32599 17 9.1833 17 9.00727V6.12679C17 5.95076 16.8573 5.80807 16.6813 5.80807ZM2.28022 8.68855H1.63745V6.44552H2.28022V8.68855ZM4.53473 14.5122C4.53473 14.7473 4.34339 14.9387 4.10821 14.9387H3.91713C3.68194 14.9387 3.49061 14.7473 3.49061 14.5122V13.5764H4.53473V14.5122ZM14.5094 14.5122C14.5094 14.7473 14.318 14.9387 14.0828 14.9387H13.8918C13.6566 14.9387 13.4652 14.7473 13.4652 14.5122V13.5764H14.5094V14.5122ZM15.0752 12.9389H2.92488V9.33431H15.0752V12.9389ZM15.0752 8.69686H13.6721C13.6552 8.68781 13.6375 8.67994 13.6186 8.67388L9.56759 7.37447C9.3999 7.32067 9.22046 7.41301 9.16673 7.58062C9.11299 7.74824 9.20526 7.92772 9.37288 7.98145L11.6032 8.69683H8.35997L4.25539 7.37447C4.08771 7.32067 3.9083 7.41297 3.85457 7.58062C3.8008 7.74824 3.8931 7.92772 4.06072 7.98145L6.29106 8.69683H2.92488V4.83458H15.0752V8.69686ZM15.0752 4.19713H2.92488V2.59149C2.92488 2.06543 3.35286 1.63745 3.87892 1.63745H14.1211C14.6472 1.63745 15.0752 2.06543 15.0752 2.59149V4.19713ZM16.3626 8.68855H15.7198V6.44552H16.3626V8.68855Z'
      />
    </BusSVG>
  );
});

export { BusOutlined };