import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const CloseOutlined = withTheme(props => {
  const { width, height, theme, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 1024 1024"
      {...rest}
    >
      <path
        fill={theme.colors[selectedTheme].onPrimary}
        d="m563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8L295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512L196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1l216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
      ></path>
    </svg>
  );
});

export { CloseOutlined };
