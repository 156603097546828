import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const LoadingIcon = withTheme(props => {
  const { width, height, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const accent = theme.colors[selectedTheme].accent;

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox='0 0 35 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      color={accent}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9805 17C29.9805 10.3726 24.6079 5 17.9805 5V0C27.3693 0 34.9805 7.61116 34.9805 17C34.9805 26.3888 27.3693 34 17.9805 34C8.59163 34 0.980469 26.3888 0.980469 17H5.98047C5.98047 23.6274 11.3531 29 17.9805 29C24.6079 29 29.9805 23.6274 29.9805 17Z'
      />
    </svg>
  );
});

export { LoadingIcon };
