import React from 'react';
import { useSelector } from 'react-redux';
import {
  HorizontalSpacer,
  VerticalSpacer,
  CubeOutlined,
  ArrowLeftOutlined,
  Body,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Image,
  Caption,
  ExampleElement,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { properties as images } from './images';
import Summary from './Summary';

const EditingProperties = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    <>
      Compensate for models made in programs that use Z-Axis up with&nbsp;
      <strong>&quot;Axis compensation&quot;</strong>.
    </>,
    <>
      Move or rotate your models with inputs or&nbsp;
      <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
        Shift + M
      </ExampleElement>
      &nbsp;and&nbsp;
      <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
        Shift + R
      </ExampleElement>&nbsp;for toggling move and rotate gizmos, respectively.
    </>,
    'Scale your model with scale input field + linking or bounding box handle dragging.',
    <>
      Save positions by clicking the&nbsp;<strong>&quot;Save positions&quot;</strong>&nbsp;button.
    </>,
    'Select a recently saved position from the saved positions select menu.',
    <>
      Delete a saved position by clicking the&nbsp;<strong>&quot;Delete position&quot;</strong>&nbsp;
      button.
    </>,
    'Enable the bounding box to check unit scale in a unit of your choice.',
    'Check integrity of model between apps or after optimization with statistics.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='Transforms'>
        <p>
          The properties panel <CubeOutlined /> helps with correcting model orientation, manipulating the model
          in 3D space, saving positions, troubleshooting model scale and viewing technical data.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.propertiesPanel} width={350} alt='Properties panel.' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
          You can also use the shortcuts&nbsp;
          <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
            Shift + M
          </ExampleElement>
          &nbsp;and&nbsp;
          <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
            Shift + R
          </ExampleElement>
          &nbsp;to enable the viewport transform gizmos for move and rotate, respectively.
        </Caption>

        <VerticalSpacer size={30} />



        <DocSection subsection='Axis compensation'>
          <p>
            If your model was created and exported with an application that uses the Z-Axis as the
            primary vertical axis, your model may come into FOVEA with incorrect orientation.  Using&nbsp;
            <strong>&quot;Axis compensation&quot;</strong>&nbsp;will correct the orientation of the model.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.axisCompensationMenu} width={350} alt='Axis compensation menu.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.axisMismatch} width={400} alt='Axis mismatch.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.modelCorrected} width={400} alt='Model corrected.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.yAxisUp} width={300} alt='Y-Axis up.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.zAxisUp} width={300} alt='Z-Axis up.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Your model will automatically be rotated and moved to the origin.
          </Caption>

          <VerticalSpacer size={30} />

          <p>
            You may notice values added to the move and rotate input fields after compensating. This is normal and
            shows exactly what was modified to correct the model, and by how much.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.fieldCompensation} width={350} alt='Field compensation.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            All transforms are frozen when publishing and will read as zero when the model is re-opened.
          </Caption>
        </DocSection>



        <DocSection subsection='Moving and rotating a model'>
          <p>
            Moving and rotating a model can be done with the move and rotate gizmos to quickly
            manipulate the model in the viewport. For precise control, the input fields can be used.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.moveGizmo} width={500} alt='Move gizmo.' />
            <Image src={images.rotateGizmo} width={500} alt='Rotate gizmo.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
            The move and rotate gizmos can be toggled with&nbsp;
            <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
              Shift + M
            </ExampleElement>

            &nbsp;and&nbsp;

            <ExampleElement theme={theme} $selectedTheme={selectedTheme} lowProfile={true}>
              Shift + R
            </ExampleElement>, respectively.
          </Caption>

          <VerticalSpacer size={30} />

          <ImageContainer>
            <Image src={images.moveInputs} width={350} alt='Move inputs.' />
            <Image src={images.rotateInputs} width={350} alt='Rotate inputs.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
            Any changes to the model&apos;s X, Y, Z values will appear in the&nbsp;
            <strong>&quot;Move&quot;</strong>&nbsp;
            <strong>&quot;Rotate&quot;</strong>&nbsp;and
            <strong>&quot;Scale&quot;</strong>&nbsp;input fields.
          </Caption>
        </DocSection>



        <DocSection subsection='Scaling a model'>
          <p>
            Scaling a model on individual axes can be done with the scale input fields.  To uniformly scale
            all axes at once, enable scale linking.  When scale linking is enabled, all scale inputs will
            share the same value if any of the other scale field values are changed.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.scaleInputs} width={350} alt='Move inputs.' />
            <Image src={images.scaleInputsLinked} width={350} alt='Rotate inputs.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme} hasElement={true}>
            Linking inputs can be a quick way to not only scale, but reset all fields at once.
          </Caption>

          <VerticalSpacer size={30} />

          <Body size='Small'>
            <strong>Note</strong>: You can also uniformly scale your model in the viewport by
            by enabling the bounding box and scaling from it&apos;s corner handles.  See bounding box section for
            enable and using the bounding box.
          </Body>
        </DocSection>
      </DocSection>



      <DocSection section='Positions'>
        <p>
          You can bookmark model translations as positions and come back to them later using the&nbsp;
          <strong>&quot;Save position&quot;</strong> button and position select menu.
        </p>

        <VerticalSpacer size={15} />

        <DocSection subsection='Saving custom positions'>

          <ImageContainer>
            <Image src={images.savePositionButton} width={200} alt='Save position button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.savePositionModal} width={350} alt='Save position modal.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.savePositionMenu} width={350} alt='Save position menu.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The <strong>&quot;Default&quot;</strong> name is reserved and cannot be used when
            saving a new position.
          </Caption>
        </DocSection>



        <DocSection subsection='Deleting a saved position'>
          <p>
            Removing a previously saved position can be done by selecting the position you
            want to remove in the position select menu and clicking the&nbsp;
            <strong>&quot;Delete position&quot;</strong> button.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.deletePositionButton} width={200} alt='Delete position button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.deletePositionConfirmation} width={350} alt='Delete position confirmation.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.defaultPosition} width={350} alt='Default position.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The <strong>&quot;Delete position&quot;</strong> button will be enabled when a
            position other than&nbsp;<strong>&quot;Default&quot;</strong>&nbsp;is selected.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='Unit scale'>
        <p>
          The bounding box is a great tool for checking your model&apos;s scale so you can adjust it,
          if necessary.  You can show the bounding box by enabling the&nbsp;
          <strong>&quot;Bounding Box&quot;</strong>&nbsp;toggle.
        </p>

        <ImageContainer>
          <Image src={images.boundingBoxOff} width={350} alt='Bounding box off.' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.boundingBoxOn} width={350} alt='Bounding box on.' />
        </ImageContainer>

        <ImageContainer>
          <Image src={images.boundingBox} width={700} alt='Bounding box.' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          Uniformly scale the model by grabbing the corner handles of the bounding box
          and dragging.
        </Caption>

        <VerticalSpacer size={30} />

        <p>
          Once enabled, the bounding box unit select menu will be visible &mdash; allowing
          you to change the units to whatever you are most comfortable with.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.boundingBoxMenu} width={350} alt='Bounding box menu.' />
        </ImageContainer>
      </DocSection>



      <DocSection section='Statistics'>
        <p>
          Statistics tracks model and scene information, which can be handy for checking
          integrity between applications or viewing overall results after optimizing a
          model.
        </p>

        <VerticalSpacer size={15}/>

        <ImageContainer>
          <Image src={images.statistics} width={350} alt='Statistics.' />
        </ImageContainer>
      </DocSection>
    </ContentContainer>
  );
});

export { EditingProperties };