import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const TransportationSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const TransportationOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <TransportationSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 20 14'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12.3968 11.797V3.39929H16.7957C18.0961 3.49287 19.0774 4.61736 18.9951 5.91862V10.9573C19.0223 11.3907 18.6951 11.7658 18.2617 11.797H16.5957M16.5957 11.797C16.5957 12.4593 16.0582 12.9968 15.3959 12.9968C14.7337 12.9968 14.1963 12.4593 14.1963 11.7971M16.5957 11.797C16.5957 11.1348 16.0582 10.5974 15.3959 10.5974C14.7337 10.5974 14.1963 11.1349 14.1963 11.7971M14.1963 11.7971H6.9982M4.59885 11.7971C4.59885 11.1349 5.1363 10.5974 5.79853 10.5974C6.46075 10.5974 6.9982 11.1349 6.9982 11.7971M4.59885 11.7971C4.59885 12.4593 5.1363 12.9968 5.79853 12.9968C6.46075 12.9968 6.9982 12.4593 6.9982 11.7971M4.59885 11.7971L2.19943 11.797C1.53721 11.797 0.999756 11.2596 0.999756 10.5974L0.999822 8.19807M18.995 6.99833H15.496C15.1153 7.00392 14.8026 6.70081 14.7962 6.32011V3.39929M18.995 9.39771H17.4299M0.999822 8.19807H12.3968V2.19968C12.3968 1.53746 11.8593 1 11.1971 1H2.1995C1.53728 1 0.999822 1.53746 0.999822 2.19968V8.19807Z'
      />
    </TransportationSVG>
  );
});

export { TransportationOutlined };