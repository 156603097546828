import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const CheckmarkOutlined = withTheme(props => {
  const { width, height, color, theme, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      width={width || 9}
      height={height || 7}
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25628 0.256282L2.625 4.88756L1.49372 3.75628C1.15201 3.41457 0.59799 3.41457 0.256282 3.75628C-0.0854272 4.09799 -0.0854272 4.65201 0.256282 4.99372L2.00628 6.74372C2.34799 7.08543 2.90201 7.08543 3.24372 6.74372L8.49372 1.49372C8.83543 1.15201 8.83543 0.59799 8.49372 0.256282C8.15201 -0.0854272 7.59799 -0.0854272 7.25628 0.256282Z"
        fill={color || theme.colors[selectedTheme].onPrimary}
      />
    </svg>
  );
});

export { CheckmarkOutlined };
