import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const BookcaseSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const BookcaseOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <BookcaseSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 15 16'
    >
      <path fill='currentColor' d='M0.0998535 0.511352C0.0998535 0.284142 0.284019 0.0999756 0.511229 0.0999756H14.5567C14.7839 0.0999756 14.9681 0.284138 14.9681 0.511363V15.4886C14.9681 15.7158 14.7839 15.9 14.5567 15.9H0.511229C0.284019 15.9 0.0998535 15.7158 0.0998535 15.4886V0.511352ZM14.1453 0.922696H0.922442L0.928142 5.25595H2.82898L2.82885 2.19029C2.82885 1.96308 3.01301 1.77892 3.24022 1.77892H6.91486C7.14207 1.77892 7.32623 1.96308 7.32623 2.19029V5.26186H9.87839C10.1056 5.26186 10.2898 5.44603 10.2898 5.67324C10.2898 5.90045 10.1056 6.08461 9.87839 6.08461H0.922574V10.938H5.58845V8.65318C5.58845 8.42597 5.77261 8.24181 5.99982 8.24181H7.42579V7.87221C7.42579 7.645 7.60996 7.46083 7.83717 7.46083H9.67445C9.90166 7.46083 10.0858 7.645 10.0858 7.87221V8.24181H11.5118C11.739 8.24181 11.9232 8.42597 11.9232 8.65318V10.938H14.1453V0.922696ZM4.66626 2.60167H3.65166V5.25595H4.66626V2.60167ZM6.50357 2.60167H5.48898V5.25595H6.50357V2.60167ZM9.26311 8.28359H8.24855V10.9379H9.26311V8.28359ZM7.42583 9.06456H6.41123V10.9379H7.42583V9.06456ZM11.1004 9.06456H10.0858V10.9379H11.1004V9.06456ZM14.1453 11.7608H0.922574V15.0773H14.1453V11.7608Z' />
    </BookcaseSVG>
  );
});

export { BookcaseOutlined };