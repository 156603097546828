import React from 'react';
import { useSelector } from 'react-redux';
import { hasAccess } from 'navigation/navigationHelpers';
import _ from 'lodash';

export const superUsers = [ 'developer', 'administrator' ];
export const basicRole = [ 'registered' ];
export const standardRoles = [ 'customer', 'creator' ];
export const businessRoles = [ 'enterprise' ];
export const subscribedRoles = [ ...standardRoles, ...businessRoles, ...superUsers ];
export const allRoles = [ ...basicRole, ...standardRoles, ...businessRoles, ...superUsers ];

const ProtectedContent = props => {
  const { requiredRoles, children } = props;
  const userDataStore = useSelector(state => state.userData);
  const temporaryRole = userDataStore.temporaryRole;
  const userRoles = temporaryRole !== 'default' ? [ temporaryRole ] : userDataStore?.data?.roles;
  const allowed = hasAccess(userRoles, [
    ...(requiredRoles || []),
    ...superUsers,
  ]);

  return <>{allowed && children}</>;
};

const filterProtectedCreators = (value, userRoles) => {
  if (value) {
    const asArray = _.isArray(value) ? value : [ value ];

    return asArray.filter?.(item => {
      const enterpriseCreators = [ 'renderpeople' ];
      const superUserCreators = [ 'laubwerk' ];
      const name = (item.name || item.partnerName || item)?.toLowerCase?.();
      const requiresEnterpriseRole = enterpriseCreators.includes(name);
      const requiresSuperUserRole = superUserCreators.includes(name);
      const validEnterpriseAccess = requiresEnterpriseRole && hasAccess(userRoles, [ ...businessRoles, ...superUsers ]);
      const validSuperAccess = requiresSuperUserRole && hasAccess(userRoles, superUsers);
      const generalAccess = !requiresEnterpriseRole && !requiresSuperUserRole
        && hasAccess(userRoles, subscribedRoles);

      if (generalAccess || validEnterpriseAccess || validSuperAccess) {
        return item;
      }
    });
  }

  return [];
};

export { ProtectedContent, filterProtectedCreators };