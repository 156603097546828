import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const Divider = styled('div')`
  height: ${props => props.height ? props.height / 16 + 'rem' : '0.0625rem'};
  width: ${props => props.width ? props.width / 16 + 'rem' : '100%'};
  background-color: ${props => (
    props.theme.colors[props.$selectedTheme].mediumGrey
  )};
`;

const FovDivider = withTheme(props => {
  const { theme, width, height, children } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Divider
      theme={theme}
      $selectedTheme={selectedTheme}
      width={width}
      height={height}
    >
      {children}
    </Divider>
  );
});

export { FovDivider };