import React from 'react';
import { useSelector } from 'react-redux';
import { hexToRgba } from 'theme';
import styled, { withTheme } from 'styled-components';
import { FovTypography } from 'components/typography';
import { CloseOutlined } from 'components/icons/CloseOutlined';
import { VerticalSpacer } from 'components/layout';
import { getColor } from 'utils';

const ModalBG = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: ${props => hexToRgba(getColor(props, 'black'), 0.8)};
`;

const ModalWindow = styled('div')`
  position: fixed;
  top: 5rem;
  right: 0;
  left: 0;
  margin: auto;
  max-height: calc(100dvh - 10rem);
  max-width: ${props => props.$maxWidth ? (props.$maxWidth / 16) + 'rem' : 'calc(100% - 2rem)'};
  min-width: 30rem;
  min-height: 5rem;
  width: max-content;
  height: max-content;
  padding: 1rem;
  border-radius: 0.35rem;
  background-color: ${props => getColor(props, 'primary')};
  color: ${props => getColor(props, 'onPrimary')};
  overflow-y: ${props => props.noOverflow ? 'unset' : 'auto'};
  box-shadow: 0 0.1rem 0.15rem ${props => getColor(props, 'black')};
`;

const ModalHeader = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
`;

const CloseIcon = styled(CloseOutlined)`
  cursor: pointer;
`;

const FovModal = withTheme(props => {
  const { visible, title, theme, children, onClose, noOverflow, maxWidth, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <>
      {visible && (
        <ModalBG theme={theme} $selectedTheme={selectedTheme} onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <ModalWindow
            theme={theme}
            $selectedTheme={selectedTheme}
            $maxWidth={maxWidth}
            onClick={e => e.stopPropagation()}
            noOverflow={noOverflow}
            {...rest}
          >

            <FovTypography>
              <ModalHeader>
                <strong>{title}</strong>
                <CloseIcon onClick={onClose} />
              </ModalHeader>

              <VerticalSpacer size={15} />

              {children}
            </FovTypography>

          </ModalWindow>
        </ModalBG>
      )}
    </>
  );
});

export { FovModal };
