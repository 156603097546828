import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const Logo = withTheme(props => {
  const { theme, width, height } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const wordFill = theme.colors[selectedTheme].onPrimary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 100}
      height={height || 40}
      viewBox="0 0 126.68 50.73"
    >
      <g>
        <g>
          <g>
            <path fill={wordFill} d="M48.15,17.05c0.28,0,0.43,0.15,0.43,0.43v1.78c0,0.28-0.15,0.4-0.43,0.4h-9.64v4.14h8.81
              c0.25,0,0.37,0.12,0.37,0.4v1.81c0,0.28-0.12,0.4-0.37,0.4h-8.81v6.94c0,0.28-0.15,0.43-0.43,0.43h-1.96
              c-0.28,0-0.4-0.15-0.4-0.43V17.48c0-0.28,0.12-0.43,0.4-0.43H48.15z"/>

            <path fill={wordFill} d="M59.67,16.8c4.85,0,8.63,3.62,8.63,8.63c0,4.97-3.78,8.6-8.63,8.6s-8.63-3.62-8.63-8.6
              C51.04,20.43,54.82,16.8,59.67,16.8z M53.86,25.43c0,3.44,2.36,5.99,5.8,5.99s5.8-2.55,5.8-5.99c0-3.47-2.36-6.02-5.8-6.02
              C56.23,19.41,53.86,21.96,53.86,25.43z"/>

            <path fill={wordFill} d="M76.89,33.78c-0.21,0-0.34-0.09-0.43-0.28l-7.03-16c-0.12-0.25,0.03-0.46,0.31-0.46h2.15
              c0.34,0,0.55,0.12,0.64,0.37l3.25,7.55l2.33,6.02h0.15l2.39-6.14l3.16-7.46c0.09-0.21,0.28-0.34,0.52-0.34h2.36
              c0.21,0,0.34,0.22,0.25,0.43l-7.12,16c-0.09,0.21-0.25,0.31-0.46,0.31H76.89z"/>

            <path fill={wordFill} d="M102.13,17.05c0.21,0,0.43,0.18,0.43,0.43v1.75c0,0.21-0.22,0.4-0.43,0.4h-9.7v4.14h8.84
              c0.28,0,0.43,0.15,0.43,0.43v1.75c0,0.28-0.15,0.4-0.43,0.4h-8.84v4.82h10.01c0.28,0,0.4,0.12,0.4,0.37v1.81
              c0,0.28-0.12,0.43-0.4,0.43h-12.4c-0.28,0-0.4-0.15-0.4-0.43V17.48c0-0.28,0.12-0.43,0.4-0.43H102.13z"/>

            <path fill={wordFill} d="M116.01,17.05c0.15,0,0.25,0.06,0.31,0.18l7,16.12c0.09,0.25-0.09,0.43-0.34,0.43h-2.09
              c-0.31,0-0.52-0.15-0.61-0.43l-1.23-2.98h-9.21l-1.32,3.07c-0.12,0.21-0.28,0.34-0.49,0.34h-2.3c-0.28,0-0.31-0.21-0.21-0.43
              l7.06-16.09c0.06-0.15,0.18-0.22,0.37-0.22L116.01,17.05L116.01,17.05z M110.94,27.79h7l-3.32-8.23h-0.4L110.94,27.79z"/>
          </g>
        </g>
        <g>
          <path fill="none" d="M3.42,20.39l0.17,0.1c0.01-0.06,0.01-0.12,0.02-0.18L3.42,20.39z"/>
          <path fill="none" d="M11.84,25.65l-0.11-0.07c0,0.05,0,0.1,0,0.15L11.84,25.65z"/>
          <path fill="none" d="M12.99,16.32l-0.05-0.06c-0.01,0.03-0.02,0.06-0.03,0.09L12.99,16.32z"/>
          <path fill="none" d="M6.64,9.28l0.07,0.08C6.72,9.33,6.74,9.3,6.75,9.28H6.64z"/>
          <path fill="none" d="M7.52,42.52l0.25-0.01c-0.03-0.06-0.07-0.12-0.1-0.18L7.52,42.52z"/>
          <path fill="none" d="M13.78,34.8l-0.53-0.14c0.06,0.19,0.13,0.37,0.2,0.55L13.78,34.8z"/>
          <path fill="none" d="M3.79,32.08l0.19,0.05c-0.01-0.06-0.02-0.12-0.03-0.18L3.79,32.08z"/>

          <path fill="#FFEE52" d="M17.09,8.21L17.09,8.21l-5.72-6.07C10.19,3.56,8.6,5.93,7.69,7.5H7.67l0.01,0.02
            C7.35,8.09,7.04,8.68,6.75,9.28L16.87,8.5C16.94,8.4,17.01,8.31,17.09,8.21z"/>

          <path fill="#5EC9F3" d="M8.35,43.47l-0.01,0.02h0.02c0.91,1.45,2.64,3.77,3.81,5.09l5.4-6.17l0.02-0.02
            c-0.02-0.03-0.04-0.06-0.07-0.08l-9.75,0.21C7.96,42.84,8.15,43.16,8.35,43.47z"/>

          <path fill="#F4A238" d="M13.69,14.11h0.01H13.69c0.81-2.02,1.87-3.88,3.18-5.61L6.75,9.28C6.74,9.3,6.72,9.33,6.71,9.36l6.23,6.9
            C13.16,15.53,13.41,14.81,13.69,14.11z"/>

          <path fill="#7461A9" d="M13.25,34.65l-9.27-2.52c0.29,1.56,0.67,3.07,1.15,4.52l-0.07,0.06h0.09c0.15,0.43,0.3,0.87,0.46,1.29
            c0.58,1.51,1.26,2.95,2.06,4.34l5.77-7.13C13.38,35.02,13.31,34.84,13.25,34.65z"/>

          <path fill="#EC8030" d="M12.94,16.26l-6.23-6.9c-0.5,1.02-0.94,2.07-1.32,3.14c-0.26,0.74-0.5,1.49-0.71,2.25H4.63l0.04,0.03
            c-0.49,1.77-0.85,3.61-1.06,5.53l9.31-3.97C12.92,16.32,12.93,16.29,12.94,16.26z"/>

          <path fill="#6783C0" d="M14.16,36.74h-0.09c-0.22-0.5-0.43-1.02-0.62-1.54l-5.77,7.13c0.03,0.06,0.07,0.12,0.1,0.18l9.75-0.21
            c-1.39-1.69-2.53-3.52-3.42-5.49L14.16,36.74z"/>

          <path fill="#3B2E7F" d="M12.74,33.01c-0.17-0.63-0.32-1.28-0.45-1.94l0.04-0.03l-0.05-0.01c-0.33-1.69-0.51-3.45-0.55-5.3l-7.78,6.22
            c0.01,0.06,0.02,0.12,0.03,0.18l9.27,2.52C13.06,34.11,12.89,33.57,12.74,33.01z"/>

          <path fill="#C74897" d="M11.73,25.54l0.04-0.02l-0.04-0.02c0-0.06,0-0.12,0-0.17c0-0.13-0.01-0.25-0.01-0.38
            c0-1.7,0.12-3.32,0.36-4.89l0.05-0.01l-0.04-0.03c0.13-0.85,0.3-1.68,0.5-2.48c0.1-0.4,0.21-0.8,0.33-1.19l-9.31,3.97
            C3.6,20.38,3.6,20.44,3.59,20.5l8.15,5.08C11.73,25.57,11.73,25.55,11.73,25.54z"/>

          <path fill="#863582" d="M11.73,25.58L3.58,20.5c-0.06,0.51-0.1,1.02-0.14,1.54L3.4,22.05l0.04,0.01c-0.06,0.95-0.1,1.91-0.1,2.89
            c0,0.13,0.01,0.25,0.01,0.38c0.01,1.34,0.09,2.64,0.22,3.91l-0.02,0.01h0.02c0.1,0.92,0.22,1.82,0.38,2.7l7.78-6.22
            C11.73,25.68,11.73,25.63,11.73,25.58z"/>
        </g>
      </g>
    </svg>
  );
});

export { Logo };