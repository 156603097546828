import React from 'react';

const CarBackSideIcon = props => {
  const { width, height } = props;

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill='currentColor'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.92 5.01012C18.72 4.42012 18.16 4.00012 17.5 4.00012H6.5C5.84 4.00012 5.29 4.42012 5.08 5.01012L3 11.0001V19.0001C3 19.5501 3.45 20.0001 4 20.0001H5C5.55 20.0001 6 19.5501 6 19.0001V18.0001H18V19.0001C18 19.5501 18.45 20.0001 19 20.0001H20C20.55 20.0001 21 19.5501 21 19.0001V11.0001L18.92 5.01012ZM6.85 6.00012H17.14L18.18 9.00012H5.81L6.85 6.00012ZM19 16.0001H5V11.3401L5.12 11.0001H18.89L19 11.3401V16.0001Z" />
      <path d="M7.5 15C8.32843 15 9 14.3284 9 13.5C9 12.6716 8.32843 12 7.5 12C6.67157 12 6 12.6716 6 13.5C6 14.3284 6.67157 15 7.5 15Z" />
      <path d="M16.5 15C17.3284 15 18 14.3284 18 13.5C18 12.6716 17.3284 12 16.5 12C15.6716 12 15 12.6716 15 13.5C15 14.3284 15.6716 15 16.5 15Z" />
    </svg>
  );
};

export { CarBackSideIcon };
