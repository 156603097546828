import React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
  height: auto;
  width: ${props => props.size / 16}rem;
`;

const HorizontalSpacer = props => {
  const { children, size } = props;
  return <Container size={size}>{children}</Container>;
};

export { HorizontalSpacer };
