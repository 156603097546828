import React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
  height: ${props => props.size / 16}rem;
  width: auto;
`;

const VerticalSpacer = props => {
  const { children, size } = props;
  return <Container size={size}>{children}</Container>;
};

export { VerticalSpacer };
