import React from 'react';
import { withTheme } from 'styled-components';

const TwoDRpcIcon = withTheme(props => {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.249292 0.093558C0.405015 -0.0100405 0.60224 -0.0288654 0.774749 0.0434037L7.82353 2.99635L14.8723 0.0434037C15.0448 -0.0288654 15.242 -0.0100405 15.3978 0.093558C15.5535 0.197156 15.6471 0.37179 15.6471 0.558826V18.4412C15.6471 18.6291 15.5526 18.8044 15.3957 18.9078C15.2388 19.0112 15.0404 19.0288 14.8678 18.9547L7.82353 15.9303L0.779288 18.9547C0.60662 19.0288 0.408255 19.0112 0.251347 18.9078C0.0944404 18.8044 0 18.6291 0 18.4412V0.558826C0 0.37179 0.0935695 0.197156 0.249292 0.093558ZM1.11765 1.39881L6.37727 3.60223L1.11765 5.80564V1.39881ZM1.11765 7.0174L7.2647 4.44222V14.9539L1.11765 17.5931V7.0174ZM7.80787 15.2857L7.82353 15.3222L7.83919 15.2857H7.80787ZM8.38235 14.9539L14.5294 17.5931V7.0174L8.38235 4.44222V14.9539ZM9.26978 3.60223L14.5294 5.80564V1.39881L9.26978 3.60223Z"
        fill="#383838"
      />
    </svg>
  );
});

export { TwoDRpcIcon };
