import React from 'react';

const CarFrontSideIcon = props => {
  const { width, height } = props;
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill='currentColor'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.92 5.01012C18.72 4.42012 18.16 4.00012 17.5 4.00012H6.5C5.84 4.00012 5.29 4.42012 5.08 5.01012L3 11.0001V19.0001C3 19.5501 3.45 20.0001 4 20.0001H5C5.55 20.0001 6 19.5501 6 19.0001V18.0001H18V19.0001C18 19.5501 18.45 20.0001 19 20.0001H20C20.55 20.0001 21 19.5501 21 19.0001V11.0001L18.92 5.01012ZM6.85 6.00012H17.14L18.18 9.00012H5.81L6.85 6.00012ZM19 16.0001H5V11.3401L5.12 11.0001H18.89L19 11.3401V16.0001Z" />
      <path d="M5.5 13C6.32843 13 7 12.3284 7 11.5C7 10.6716 6.32843 10 5.5 10C4.67157 10 4 10.6716 4 11.5C4 12.3284 4.67157 13 5.5 13Z" />
      <path d="M18.5 13C19.3284 13 20 12.3284 20 11.5C20 10.6716 19.3284 10 18.5 10C17.6716 10 17 10.6716 17 11.5C17 12.3284 17.6716 13 18.5 13Z" />
      <rect x="10" y="13" width="4" height="2" />
    </svg>
  );
};

export { CarFrontSideIcon };
