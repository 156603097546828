import { FovButton, Table, FovTypography } from 'components';
import { getColor } from 'utils';
import styled, { css } from 'styled-components';
import bcg_launch from 'assets/Ui/bcg_launch.png';
import constants from 'constants/index';

const { HEADER_HEIGHT, FOOTER_HEIGHT } = constants;
const offset = HEADER_HEIGHT + FOOTER_HEIGHT;
const light = constants.THEME.LIGHT;
const titleAreaHeight = 216;

export const PopoverContainer = styled('div')`
  height: 110.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModelsControls = styled('div')`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto minmax(auto, 5.5rem);
  grid-template-areas:
    'title title'
    'search views';

  width: 100%;
  padding: 0 1rem;
`;

export const ViewSelector = styled('div')`
  display: grid;
  grid-area: views;
  gap: 0.25rem 1rem;
  grid-template-columns: auto repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'sorting thumbs table'
    '. slider slider';
  position: relative;
`;

export const ExploreCTA = styled('div')`
  grid-area: explore;
`;

export const Title = styled(FovTypography)`
  display: grid;
  grid-area: title;
  gap: 0 1rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto);
  width: 100%;
  min-height: 3.75rem;
  grid-template-areas:
    "title explore"
    "creatorList explore";

  h3 {
    margin: 0;
  }
`;

export const CreatorList = styled('div')`
  font-size: 0.95rem;
  color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'lightGrey');
  }};
`;

export const PopoverMenuItem = styled.div`
  border-bottom: 0.0625rem solid black;
  padding: 0.3125rem 0;
`;

export const TableThumbnail = styled.img`
  width: auto;
  height: 5.25rem;
  max-width: 6.25rem;
  max-height: 6.25rem;
  border-radius: 0.35rem;
`;

export const CardFooter = styled.div`
  display: flex;
  width: 100%;
`;

export const CardStatus = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
`;

export const CustomImage = styled('img')`
  width: ${props => props.width / 16}rem;
  height: ${props => props.height / 16}rem;
  opacity: ${props => (props.$disabled ? '0.5' : '1')};
  border-radius: 0.35rem;
`;

export const CardContainer = styled('div')`
  display: grid;
  grid-template-columns: ${props => props.column / 16}rem 1fr ${props =>
      props.column / 16}rem;
  grid-template-rows: auto ${props => props.row / 16}rem;
  grid-template-areas:
    'icon thumb actions'
    'title title title';

  width: ${props => props.width / 16}rem;
  height: ${props => props.height / 16}rem;
  border: ${props =>
    props.$cardSelected
      ? '0.0625rem solid ' + getColor(props, 'accent')
      : '0.0625rem solid ' + getColor(props, 'mediumGrey')};
  outline: ${props =>
    props.$cardSelected
      ? '0.0625rem solid ' + getColor(props, 'accent')
      : 'none'};
  border-radius: 0.3125rem;
  margin: 1rem;
  padding: 0.5rem 0;
  background: ${props => getColor(props, 'primary')};
  color: ${props => getColor(props, 'onPrimary')};
  cursor: pointer;

  &:hover {
    outline: ${props =>  props.$previewDisabled ? 'none' : '0.0625rem solid ' + getColor(props,'accentHover')};
    border: 0.0625rem solid ${props => props.$previewDisabled
      ?  + getColor(props, 'mediumGrey')
      : getColor(props,'accentHover')
    };
  }
`;

export const IconContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-area: icon;

  width: 100%;
  height: 100%;
  align-items: center;
`;

export const ButtonsContainer = styled('div')`
  grid-area: actions;
  height: ${props => props.height / 16}rem;
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.isCardView ? 'column' : 'row')};
  align-items: center;
`;

export const ImageContainer = styled('div')`
  grid-area: thumb;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled('div')`
  grid-area: title;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const IconButton = styled(FovButton)`
  width: ${props => props.width / 16}rem;
  height: ${props => props.height / 16}rem;
  min-width: unset;
  padding: 0;
  background-color: ${props => getColor(props, 'primary')};

  &:hover {
    color: ${props => getColor(props, 'accent')};
    background-color: ${props => getColor(props, 'primary')};
  }

  &:disabled {
    background-color: ${props => getColor(props, 'transparent')};

    &:hover {
      background-color: unset;
    }
  }
`;

export const ModelsContainer = styled('div')`
  height: calc(100vh - 20rem);
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  padding-bottom: 2rem;
`;

export const TableFullWidth = styled(Table)`
  display: flex;
  width: 100%;
`;

export const ModelsList = styled('div')`
  height: calc(100vh - 8.25rem);
`;

export const ModelsArea = styled('div')`
  display: inline-flex;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
  ${() => css`
    max-height: calc(100vh - ${(offset + titleAreaHeight + 16) / 16}rem);
  `}
`;

export const ModelWithPreviewContainer = styled('div')`
  display: flex;
  flex-direction: row;
  height: ${props => `calc(100% - ${!props.showOnBoarding ? '11.45rem' : '2.7rem'})`};
`;

export const FilesFlexContainer = styled('div')`
  padding: 0 1rem 1rem;
  flex: 1 1 70%;
`;

export const PreviewFlexContainer = styled('div')`
  flex: 1 2 30%;
  margin-bottom: 1rem;
`;

export const NoSelectionMessage = styled(FovTypography)`
  text-align: center;
  width: 100%;
  height: calc(100% - 13rem);
  background-image: url('${bcg_launch}');
  background-position: bottom -5.8rem right;
  background-repeat: no-repeat;
  background-size: 52%;

  p {
    color: ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'darkGrey' : 'lightGrey');
    }}
  }
`;

export const PreviewContainer = styled('div')`
  position: relative;
  width: 20rem;
  height: 20rem;
  border-radius: 0.35rem 0.35rem 0 0;
  margin: 0 auto;
  box-shadow: 0 0.1rem 0.25rem 0 ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'black');
  }};

  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;

    if (lightTheme) return getColor(props, 'lightGrey');
    return getColor(props, 'black') + 25;
  }};
`;

export const UpdatePreviewButton = styled('div')`
  margin-top: -0.125rem;
  width: 100%;
  border-radius: 0 0 0.35rem 0.35rem;

  box-shadow: 0 0.1rem 0.25rem 0 ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'black');
  }};
`;

export const PreviewTitle = styled(FovTypography)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;

  p {
    margin: 0;
  }
`;