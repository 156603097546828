import { FovTypography } from 'components';
import { getColor } from 'utils';
import styled from 'styled-components';

export const InstructionsContainer = styled('div')`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: ${props => getColor(props, 'onPrimary')};
  background-color: ${props => props.modelLoaded ? 'transparent' : getColor(props, 'primary') + 99};
  backdrop-filter: ${props => props.modelLoaded ? 'blur(0rem)' : 'blur(0.2rem)'};
`;

export const UploadInteractions = styled(FovTypography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

export const Droptext = styled('div')`
  position: relative;
  font-size: 1.2rem;
`;

export const Droptext2 = styled('p')`
  position: relative;
  margin: 0;
  font-size: 0.8rem;
`;
export const Droptext3 = styled('p')`
  margin: 0;
  font-size: 0.8rem;
`;

export const LogoContainer = styled('div')`
  width: fit-content;
  height: fit-content;
`;

export const Logo = styled('img')`
  width: 35.625rem;
  user-select: none;
`;