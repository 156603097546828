import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { VerticalSpacer, FovSlider, FovInput } from 'components';
import { EnvironmentIntensityContainer } from '../../Materials/MaterialStyles';
import { ChannelTextContainer } from '../styles';
import { updateCanvas } from 'utils';
import constants from'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const AxisInput = styled(FovInput)`
  width: 4rem;
  min-width: 4rem;
`;

const AxisWrapper = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const LightingRotation = () => {
    const dispatch = useDispatch();
    const canvasStore = useSelector(state => state.canvas);
    const serializedData = canvasStore.serializedData;
    const lights = serializedData?.metadata.lights;
    const defaultLight = lights[0];
    const { id, rotation } = defaultLight;

    const updateArgs = {
        dispatch,
        type: CANVAS_UPDATE_TYPES.LIGHTING,
        method: CANVAS_UPDATE_METHODS.UPDATE,
    };

  return (
    <>
    <ChannelTextContainer>
        <EnvironmentIntensityContainer>
            <FovSlider
                label='Rotation'
                defaultValue={rotation}
                onAfterChange={newVal => updateCanvas(updateArgs, { id, rotation: newVal })}
                min={0}
                max={359}
                step={1}
            />
            <AxisWrapper>
                <AxisInput
                    type={'number'}
                    step={0.1}
                    value={rotation}
                    onChange={e => updateCanvas(updateArgs, { id, rotation: e.target.value })}
                />
            </AxisWrapper>
            <VerticalSpacer size={8} />
        </EnvironmentIntensityContainer>
    </ChannelTextContainer>
    </>
  );
};

export default LightingRotation;
