import React, { Fragment, useState, useRef, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import { CaretIcon } from 'components/icons';
import styled, { css, withTheme } from 'styled-components';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

const InputContainer = styled('div')`
  width: ${props => props.width ? props.width / 16 + 'rem' : '100%'};
  position: relative;
`;

const Input = styled('input')`
  position: relative;
  padding: 0.25rem 0.5rem;
  width: ${props => props.width ? props.width / 16 + 'rem' : '100%'};
  min-height: 2.69625rem;
  min-width: 3.25rem;
  border-radius: 0.25rem;
  background: transparent;
  color: ${props => getColor(props, 'onPrimary')};
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};

  ${props => props.isNumber && css`padding-right: 1.25rem;`}

  &:disabled {
    background-color: ${props => getColor(props, 'lightGrey')};
    color: ${props => getColor(props, 'mediumGrey')};
  }

  &:focus-visible {
    outline: none;
  }

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const SpinnerButton = styled('button')`
  display: flex;
  position: absolute;
  ${props => props.direction === 'up' ? css`top: 0.075rem` : css`bottom: 0.025rem`};
  transform: scaleY(${props => props.direction !== 'up' && '-1'});
  right: 0.15rem;
  width: 0.85rem;
  height: 1.21rem;
  border: none;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'black' : 'darkGrey');
  }};
  align-items: center;
  justify-content: center;
  margin: 0 0 0.1rem 0;
  border-top-right-radius: 0.1875rem;
  padding: 0.0625rem;

  &:focus {
    outline: none;
  }
`;

const Label = styled('span')`
  position: absolute;
  top: 0.55rem;
  right: 1.25rem;
  color: ${props => getColor(props, 'mediumGrey')};
`;

const FovInput = withTheme(forwardRef(
  function FovInput(props, ref) {
    const { theme, type, label, min, max, width, onChange, fallback, value = '', blurOverride, ...rest } = props;
    const [ overrideValue, setOverrideValue ] = useState(null);
    const userDataStore = useSelector(state => state.userData);
    const selectedTheme = userDataStore.userPreferences.theme;
    const fieldRef = useRef();

    const handleChange = e => {
      const newVal = e.target.value;
      const isNumber = type === 'number';
      const minValid = min || min === 0 ? newVal >= min : true;
      const maxValid = max ? newVal <= max : true;
      const isValidNumber = isNumber && minValid && maxValid;

      if (overrideValue === '') {
        setOverrideValue(null);
      }

      if (isNumber && newVal === '') {
        setOverrideValue('');
      }

      if (isValidNumber || !isNumber) {
        onChange?.(e);
      }
    };

    const handleBlur = e => {
      const newVal = e.target.value;

      if (newVal === '' && type === 'number') {
        onChange?.({ target: { value: (value ?? fallback ?? min) ?? 0 } });
      }

      if (overrideValue === '') {
        setOverrideValue(null);
      }
    };

    const stepValue = (e, direction) => {
      const current = fieldRef.current && +(fieldRef.current.value);
      const step = rest.step || 1;
      const newVal = direction === 'up' ? current + step : current - step;
      handleChange({ target: { value: newVal } });
    };

    return (
      <InputContainer width={width}>
        <Input
          ref={ref || fieldRef}
          theme={theme}
          $selectedTheme={selectedTheme}
          type={type || 'text'}
          width={width}
          isNumber={type === 'number'}
          onChange={handleChange}
          onBlur={e => blurOverride?.(e) || handleBlur(e)}
          value={overrideValue ?? value}
          {...rest}
        />

        {type === 'number' && (
          <Fragment>
            <Label theme={theme} $selectedTheme={selectedTheme}>
              {label}
            </Label>

            <SpinnerButton
              theme={theme}
              $selectedTheme={selectedTheme}
              direction='up'
              onClick={e => stepValue(e, 'up')}
              disabled={rest.disabled}
            >
              <CaretIcon
                color={theme.colors[selectedTheme].lightGrey}
                rotate={180}
                width={10}
                height={10}
              />
            </SpinnerButton>

            <SpinnerButton
              theme={theme}
              $selectedTheme={selectedTheme}
              direction='down'
              onClick={e => stepValue(e, 'down')}
              disabled={rest.disabled}
            >
              <CaretIcon
                color={theme.colors[selectedTheme].lightGrey}
                rotate={180}
                width={10}
                height={10}
              />
            </SpinnerButton>
          </Fragment>
        )}
      </InputContainer>
    );
  }),
);

export { FovInput };
