import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { VerticalSpacer, TextureSwatch, ColorSwatch, HorizontalSpacer, FovSlider } from 'components';
import { withTheme } from 'styled-components';
import {
  ChannelTextContainer,
  EnvironmentIntensityContainer,
  AlbedoContainer,
  MetallicContainer,
  EndChannelWrapper,
} from '../MaterialStyles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const ColorTab = props => {
  const { theme } = props;

  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const selectedTheme = userDataStore.userPreferences.theme;
  const diffuseIntensity = selectedMaterial?.environmentIntensity;
  const metallicValue = selectedMaterial?.metallic;
  const white = theme.colors[selectedTheme].white;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const buildColorSection = () => {
    const metallic = true;
    const metallicTexture = true;

    return (
      <>
        <VerticalSpacer size={15} />

        {/* Base Color */}
        <ChannelTextContainer>
          <EnvironmentIntensityContainer>
            <FovSlider
              label='Base color'
              defaultValue={diffuseIntensity ?? 1}
              onAfterChange={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  environmentIntensity: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />

            <AlbedoContainer>
              <ColorSwatch
                color={selectedMaterial?.albedoColor || white}
                updateChannel={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    albedoColor: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
              />

              <HorizontalSpacer size={15} />

              <TextureSwatch
                texture={{
                  id: selectedMaterial?.albedoTexture?.name,
                  name: selectedMaterial?.albedoTexture?.name,
                  img: selectedMaterial?.albedoTexture?.url,
                } || null}
                updateChannel={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    albedoTexture: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
                removeChannel={() => {
                  const inboundData = {
                    id: selectedMaterialId,
                    removeAlbedoTexture: true,
                  };

                  updateCanvas(removeArgs, inboundData);
                }}
              />
            </AlbedoContainer>
          </EnvironmentIntensityContainer>
        </ChannelTextContainer>

        {/* Metalness */}
        <ChannelTextContainer>
          <MetallicContainer>
            {metallic && (
              <>
                <FovSlider
                  label='Metalness'
                  defaultValue={metallicValue ?? 0}
                  onAfterChange={newVal => {
                    const inboundData = {
                      id: selectedMaterialId,
                      metallic: newVal,
                    };

                    updateCanvas(updateArgs, inboundData);
                  }}
                />
              </>
            )}

            <EndChannelWrapper>
              {metallicTexture && (
                <>
                  <TextureSwatch
                    texture={{
                      id: selectedMaterial?.metallicTexture?.name,
                      name: selectedMaterial?.metallicTexture?.name,
                      img: selectedMaterial?.metallicTexture?.url,
                    } || null}
                    updateChannel={newVal => {
                      const inboundData = {
                        id: selectedMaterialId,
                        metallicTexture: newVal,
                      };

                      updateCanvas(updateArgs, inboundData);
                    }}
                    removeChannel={() => {
                      const inboundData = {
                        id: selectedMaterialId,
                        removeMetallicTexture: true,
                      };

                      updateCanvas(removeArgs, inboundData);
                    }}
                  />
                </>
              )}
            </EndChannelWrapper>
          </MetallicContainer>
        </ChannelTextContainer>
      </>
    );
  };

  return (
    <div>
      {buildColorSection()}
    </div>
  );
};

export default withTheme(ColorTab);