import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VerticalSpacer } from 'components';
import styled from 'styled-components';

const StatisticsWrapper = styled('div')`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
`;

const Stat = styled('div')`
  display: inline-flex;
  width: max-content;
`;

const Statistics = () => {
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const statistics = serializedData?.metadata.statistics;
  const selectedMeshes = serializedData?.metadata.selectedMeshes;
  const statisticsByMesh = statistics?.meshes.meshes;
  const triName = 'triangles';
  const vertName = 'vertices';
  const sumCount = type => selectedMeshes?.reduce((prev, current) => {
    const sum = statisticsByMesh.find(mesh => mesh.id === current.id)?.[type] || 0;
    return prev + sum;
  }, 0);

  const uniqueMaterialArr = [];
  const uniqueTextureArr = [];

  const getTextures = mesh => {
    mesh.textures?.forEach(texture => {
      if (!(uniqueTextureArr.indexOf(texture) > -1)) {
        uniqueTextureArr.push(texture);
      }
    });
  };

  selectedMeshes?.forEach(mesh => {
    if (!(uniqueMaterialArr.indexOf(mesh.materialId) > -1)) {
      uniqueMaterialArr.push(mesh.materialId);
    }

    getTextures(mesh);
  });

  const sumMaterials = uniqueMaterialArr.length;
  const sumTextures = uniqueTextureArr.length;

  const buildStatistics = () => {
    if (statistics) {
      const { materialCount, textures, meshes, vertices, triangles } = statistics;

      return (
        <>
          <Stat>
            <strong>Materials</strong>: &nbsp;
            {sumMaterials || materialCount}
          </Stat>

          <Stat>
            <strong>Textures</strong>: &nbsp;
            {selectedMeshes?.length > 0 ? sumTextures : textures}
          </Stat>

          <Stat>
            <strong>Meshes</strong>: &nbsp;
            {selectedMeshes?.length || meshes.count}
          </Stat>

          <Stat>
            <strong>Vertices</strong>: &nbsp;
            {sumCount(vertName) !== 0 ? sumCount(vertName).toLocaleString() : vertices}
          </Stat>

          <Stat>
            <strong>Triangles</strong>: &nbsp;
            {sumCount(triName) !== 0 ? sumCount(triName).toLocaleString() : triangles}
          </Stat>
        </>
      );
    }
  };

  return (
    <>
      <VerticalSpacer size={15} />

      <StatisticsWrapper>
        {buildStatistics()}
      </StatisticsWrapper>
    </>
  );
};

export default Statistics;
