import React, { useMemo } from 'react';
import { ModelContent } from 'pages/Home/ModelContent';
import { useDispatch, useSelector } from 'react-redux';
import { getUserModels } from 'store/actions/userData';
import { ModelContext } from 'pages/Home/ModelContext';
import { useCookies } from 'react-cookie';

const MyFiles = () => {
  const dispatch = useDispatch();
  const [ cookies ] = useCookies([ 'userAuth' ]);
  const token = cookies.archvision_token;
  const userDataStore = useSelector(state => state.userData);
  const userId = userDataStore.data.userId;
  const getModels = () => dispatch(getUserModels({ token, userId }));
  const userModels = useMemo(() => userDataStore.userModels, [ userDataStore.userModels ]);

  const contextValue = {
    getModels: getModels,
    workingData: userModels,
    pageTitle: 'My Files',
    sortingOptions: [ 'ascending', 'descending', 'oldest first', 'newest first' ],
    searchParam: 'categories',
  };

  return <ModelContext.Provider value={contextValue}>
    <ModelContent />
  </ModelContext.Provider>;
};

export { MyFiles };