import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FovDivider, FovTypography, VerticalSpacer, Body, DocNote } from 'components';
import { withTheme } from 'styled-components';
import ScaleCompensation from './ScaleCompensation';
import Scale from './Scale';
import Move from './Move';
import Axis from './Axis';
import Rotate from './Rotate';
import UnitsAndScale from './UnitsAndScale';
import Statistics from './Statistics';
import Positions from './Positions';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

const PropertiesTab = withTheme(props => {
  const { theme, selectedNodes } = props;
  const uploaderStore = useSelector(state => state.uploader);
  const canvasStore = useSelector(state => state.canvas);
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const selectedMeshes = serializedData?.metadata.selectedMeshes;
  const isGlobalTransform = selectedMeshes.length === 0;
  const isSingleSelection = selectedMeshes.length === 1;
  const fieldsDisabled = !isGlobalTransform && !isSingleSelection;
  const statistics = uploaderStore?.properties?.statistics;
  const location = useLocation();
  const guid = location.pathname?.split('models/')[1];
  const darkGrey = theme.colors[selectedTheme][selectedTheme === light ? 'darkGrey' : 'lightGrey'];

  return (
    <div>
      <VerticalSpacer size={25} />
      {selectedNodes && selectedNodes.length > 1 ? (
        <FovTypography>{'Multiple objects selected.'}</FovTypography>
      ) : (
        <>
          <Body size='Medium Bold'>
            Source units &amp; Axis compensation
            <DocNote path={[ 'modelPropertiesPanel', 'axisCompensation' ]} top={-1} left={280} />
            <DocNote path={[ 'modelPropertiesPanel', 'sourceUnits' ]} top={-1} left={305} />
          </Body>

          <VerticalSpacer size={15} />
          <ScaleCompensation />

          <VerticalSpacer size={15} />
          <Axis guid={guid} />

          <VerticalSpacer size={40} />

          <FovDivider />
          <VerticalSpacer size={15} />

          <Body size='Medium Bold'>
            Transforms ({selectedMeshes.length > 0 ? 'Current selection' : 'Global'})
            <DocNote path={[ 'modelPropertiesPanel', 'transforms' ]} top={-2} left={165} />
          </Body>

          <Body size='Small' color={darkGrey}>
            {fieldsDisabled && (
              'Select a single mesh, or no mesh to edit transforms.'
            )}
          </Body>

          <VerticalSpacer size={15} />

          Move
          <Move  guid={guid} />
          <VerticalSpacer size={15} />

          Rotate
          <Rotate guid={guid} />
          <VerticalSpacer size={15} />

          Scale
          <Scale guid={guid} />

          {selectedMeshes.length > 0 && (
            <>
              <VerticalSpacer size={40} />
              <FovDivider />
              <VerticalSpacer size={15} />

              <Body size='Medium Bold'>
                Saved positions
                <DocNote path={[ 'modelPropertiesPanel', 'savedPositions' ]} top={-2} left={127} />
              </Body>

              <VerticalSpacer size={15} />
              <Positions guid={guid} />
            </>
          )}
        </>
      )}

      <VerticalSpacer size={40} />
      <FovDivider />
      <VerticalSpacer size={15} />

      <UnitsAndScale guid={guid} />

      <VerticalSpacer size={40} />
      <FovDivider />
      <VerticalSpacer size={15} />

      <Body size='Medium Bold'>
        {selectedMeshes.length !== 0 ? 'Selected' : 'Total'} statistics
        <DocNote path={[ 'modelPropertiesPanel', 'statistics' ]} top={-1} left={122} />
      </Body>

      <Statistics statistics={statistics} />
    </div>
  );
});

export default PropertiesTab;
