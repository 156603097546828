import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const RefreshSVG = styled('svg')`
  color: ${props => props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']};

  ${props => props.hoverColor && css`
    &:hover {
      color: ${props.hoverColor}
    }
  `};

  animation: ${props => props.spinIcon ? 'spin 2s linear infinite' : 'none'};

  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
`;

const RefreshOutlined = withTheme(props => {
  const { width, height, color, hoverColor, spinIcon, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <RefreshSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      spinIcon={spinIcon}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 15 16'
    >
      <path
        fill='currentColor'
        d='M1.20869 7.26433C1.20869 10.7943 3.76806 13.1989 6.64204 13.6965C6.97092 13.7535 7.19136 14.0662 7.13441 14.3952C7.07747 14.724 6.7647 14.9445 6.43583 14.8875C3.05958 14.3029 0 11.4572 0 7.26433C-1.15956e-06 5.48246 0.810669 4.08214 1.75737 3.01688C2.43553 2.25378 3.21139 1.6342 3.83537 1.15956H1.93786C1.61766 1.15956 1.35808 0.899978 1.35808 0.579778C1.35808 0.259578 1.61766 0 1.93786 0H5.41653C5.73673 0 5.99631 0.259578 5.99631 0.579778V4.05845C5.99631 4.37865 5.73673 4.63823 5.41653 4.63823C5.09633 4.63823 4.83675 4.37865 4.83675 4.05845V1.91841L4.835 1.91973L4.8349 1.91981L4.83489 1.91982H4.83488C4.17222 2.4196 3.36242 3.03034 2.66083 3.81979C1.83231 4.75208 1.20869 5.8784 1.20869 7.26433ZM14.0887 7.73562C14.0887 4.24297 11.5836 1.85248 8.74654 1.31989C8.41848 1.2583 8.20248 0.942453 8.26407 0.614403C8.32565 0.286364 8.6415 0.0703619 8.96954 0.131946C12.3024 0.757643 15.2973 3.5877 15.2973 7.73562C15.2973 9.51748 14.4866 10.9178 13.54 11.9831C12.8618 12.7462 12.0859 13.3657 11.4619 13.8404H13.3596C13.6797 13.8404 13.9393 14.1 13.9393 14.4202C13.9393 14.7404 13.6797 15 13.3596 15H9.88082C9.56062 15 9.30105 14.7404 9.30105 14.4202V10.9416C9.30105 10.6213 9.56062 10.3617 9.88082 10.3617C10.201 10.3617 10.4606 10.6213 10.4606 10.9416V13.0815L10.4624 13.0801C11.1251 12.5804 11.9349 11.9696 12.6365 11.1802C13.4651 10.2479 14.0887 9.12156 14.0887 7.73562Z'
      />
    </RefreshSVG>
  );
});

export { RefreshOutlined };
