import React, { Fragment, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { FovProgressBar, SpinAnimation } from 'components/generic';
import { HorizontalSpacer, VerticalSpacer } from 'components/layout';
import {
  LoadingIcon,
  ReadyIcon,
  DeletionSuccessIcon,
  WarningOutlined,
  InfoOutlined,
  ErrorOutlined,
} from 'components/icons';
import {
  Message,
  IconContainer,
  MessageText,
  CloseButtonContainer,
  CloseIcon,
  MultiStepDot,
  MultiStepContainer,
  Dots,
} from './styles';
import _ from 'lodash';

export const MESSAGE_TYPES = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
  loading: 'loading',
  deletion: 'deletion',
};

const SingleMessage = withTheme(
  forwardRef(function SingleMessage(props, ref) {
    const {
      theme,
      message,
      onClose,
      showCloseButton,
      inModal,
      ...rest
    } = props;
    const userDataStore = useSelector(state => state.userData);
    const selectedTheme = userDataStore.userPreferences.theme;
    const sharedSize = inModal && 32;

    const getIcon = () => {
      switch (message?.config?.type) {
        case MESSAGE_TYPES.error:
          return <ErrorOutlined width={sharedSize} height={sharedSize} />;
        case MESSAGE_TYPES.warning:
          return <WarningOutlined width={sharedSize} height={sharedSize} />;
        case MESSAGE_TYPES.info:
          return <InfoOutlined width={sharedSize} height={sharedSize} />;
        case MESSAGE_TYPES.success:
          return <ReadyIcon width={sharedSize} height={sharedSize} />;
        case MESSAGE_TYPES.loading:
          return (
            <SpinAnimation>
              <LoadingIcon width={sharedSize} height={sharedSize} />
            </SpinAnimation>
          );
        case MESSAGE_TYPES.deletion:
          return <DeletionSuccessIcon width={sharedSize} height={sharedSize} />;

        default:
          return null;
      }
    };

    const buildSteps = () => {
      if (message?.config?.type === MESSAGE_TYPES.loading && message?.config?.totalSteps) {
        const steps = [];

        for (let i = 1; i <= message?.config?.totalSteps; i++) {
          steps.push(
            <Dots key={i}>
              <MultiStepDot
                theme={theme}
                $selectedTheme={selectedTheme}
                $fill={Boolean(message?.config?.currentStep === i)}
              />
            </Dots>,
          );
        }

        return (
          <Fragment>
            <VerticalSpacer size={3} />

            <MultiStepContainer>
              {steps}
            </MultiStepContainer>
          </Fragment>
        );
      }
    };

    const buildProgress = () => {
      if (message?.config?.type === MESSAGE_TYPES.loading && message?.config?.bytesTotal) {
        return (
          <FovProgressBar
            current={message?.config?.bytesLoaded}
            total={message?.config?.bytesTotal}
            noPct={true}
          />
        );
      }
    };

    return (
      <Message
        fontSize={18}
        theme={theme}
        $selectedTheme={selectedTheme}
        $noIcon={!message?.config?.type}
        $showCloseButton={showCloseButton}
        ref={ref}
        $shownAsModal={message?.config?.showModal}
        {...rest}
      >
        {message?.config?.type && (
          <IconContainer>
            <HorizontalSpacer size={10} />
            {getIcon()}
          </IconContainer>
        )}

        <MessageText>
          {(message?.message?.props?.children || _.isString(message?.message)) && message?.message}
          {buildProgress()}
          {buildSteps()}
        </MessageText>

        {showCloseButton && (
          <CloseButtonContainer>
            <CloseIcon onClick={() => onClose()} />
          </CloseButtonContainer>
        )}
      </Message>
    );
  }),
);

export { SingleMessage };
