import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const PlaneSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const PlaneOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <PlaneSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 19 14'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12.3099 4.36947L10.049 1.33173C9.89179 1.12317 9.64621 1 9.38551 1H7.82281C7.36337 1.00151 6.99234 1.37481 6.99386 1.83349C6.99461 1.96119 7.02408 2.08663 7.08076 2.20074L8.16814 4.40045M15.9265 4.40046L4.39064 4.41709L3.55942 2.75464L1.8267 2.67908C1.36726 2.68134 0.997743 3.05615 1.00001 3.51483C1.00077 3.6244 1.02344 3.73322 1.06575 3.83372L2.62769 7.36112C2.88915 7.97093 3.48838 8.36614 4.15185 8.36765H7.20546L5.32841 12.2729C5.12136 12.6764 5.2808 13.1706 5.68357 13.3777C5.79994 13.4373 5.9284 13.4683 6.05913 13.4683H7.646C7.89235 13.4683 8.12509 13.3588 8.28302 13.1698L12.1815 8.36765H15.9265C17.0169 8.42131 17.9441 7.58026 17.9977 6.48985C17.9992 6.4634 18 6.43771 18 6.41126C17.9947 5.29516 17.0864 4.39442 15.9703 4.39971C15.956 4.39971 15.9408 4.39971 15.9265 4.40046Z'
      />
    </PlaneSVG>
  );
});

export { PlaneOutlined };