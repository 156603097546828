import { useSelector, useDispatch } from 'react-redux';
import { FovSelect, FovOption } from 'components';
import { updateCanvas } from 'utils';
import { MeshVariantsContainer } from './styles';
import { withTheme } from 'styled-components';
import constants from 'constants/index';
import _ from 'lodash';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const MeshVariants = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterialVariant = serializedData?.metadata.selectedMaterialVariant;
  const materialVariants = serializedData?.metadata.materialVariants || [];
  const dispatch = useDispatch();

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MESH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const buildOptions = () => {
    const options = [
      <FovOption key='default' value='default'>
        Select a variant...
      </FovOption>,
    ];

    materialVariants.map((variant, index) => {
      options.push(
        <FovOption key={index} value={variant}>
          {variant}
        </FovOption>,
      );
    });

    return options;
  };

  if (!_.isEmpty(materialVariants)) {
    return (
      <MeshVariantsContainer theme={theme} $selectedTheme={selectedTheme}>
        <FovSelect
          position='top'
          value={selectedMaterialVariant || 'default'}
          placeholder={selectedMaterialVariant ? selectedMaterialVariant : 'Select a variant...'}
          onChange={(e, newVal) => {
            const inboundData = { variantName: newVal };
            const isDefault = newVal === 'default';

            if (newVal !== selectedMaterialVariant && !isDefault) {
              updateCanvas(updateArgs, inboundData);
              return;
            }

            updateCanvas(updateArgs, { resetVariant: true });
          }}
        >
          {buildOptions()}
        </FovSelect>
      </MeshVariantsContainer>
    );
  }
});

export { MeshVariants };