import { Body } from 'components';
import { useEffect, useRef } from 'react';

const HubSpotForm = props => {
  const { region, portalId, formId, targetId } = props;
  const formRef = useRef(null);
  const fallbackId = 'hubspotForm';

  const makeForm = () => {
    if (window?.hbspt) {
      window.hbspt?.forms?.create({
        region,
        portalId,
        formId,
        target: `#${targetId || fallbackId}`,
      });
    }
  };

  useEffect(() => {
    if (!window.hbspt) {
      const script = document.createElement('script');

      const onScriptLoad = () => {
        makeForm();
        script.removeEventListener('load', onScriptLoad);
      };

      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.type = 'text/javascript';

      script.addEventListener('load', onScriptLoad);
      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', () => {});
        document.body.removeChild(script);
      };
    }

    makeForm();
  }, []);

  return (
    <Body>
      <div ref={formRef} id={targetId || fallbackId} />
    </Body>
  );
};

export { HubSpotForm };