import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { Wrapper, ModelsContainer } from './styles';

const Store = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Wrapper theme={theme} $selectedTheme={selectedTheme}>
      <ModelsContainer>
        Store models
      </ModelsContainer>
    </Wrapper>
  );
});

export { Store };