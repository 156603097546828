import { getColor } from 'utils';
import { FovInput } from 'components/form/FovInput';
import styled, { css } from 'styled-components';

export const Option = styled('div')`
  min-height: 2.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  user-select: none;
  border: 0.0625rem solid
    ${props =>
      props.$optionSelected
        ? getColor(props, 'transparent')
        : getColor(props, 'mediumGrey')};
  border-right: none;

  background: ${props =>
    props.$optionSelected
      ? getColor(props, 'accent')
      : getColor(props, 'primary')};

  cursor: pointer;
  text-align: center;
  color: ${props =>
    props.$optionSelected
      ? getColor(props, 'white')
      : getColor(props, 'onPrimary')};

  &:last-child {
    border-right: 0.0625rem solid
      ${props =>
        props.$optionSelected
          ? getColor(props, 'transparent')
          : getColor(props, 'mediumGrey')};
    border-radius: 0 0.25rem 0.25rem 0;
  }

  &:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  &:hover {
    background: ${props => getColor(props, 'accentHover')};
    color: ${props => getColor(props, 'white')};
    border-color: ${props => getColor(props, 'accentHover')};
  }

  ${props =>
    props.disabled &&
    css`
      background: ${getColor(props, props.$disabledColor)};
      cursor: not-allowed;

      &:hover {
        background: ${getColor(props, props.$disabledColor)};
        color: ${getColor(props, 'onPrimary')};
        border-color: ${getColor(props, props.$disabledColor)};
      }
    `}
`;

export const SwitchContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TypeContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${props => getColor(props, 'primary')};
  box-sizing: border-box;
  border-radius: 0.25rem;
  margin-top: 1rem;
`;

export const DetailsContainer = styled('div')`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr ${props => props.inputWidth === 'estimated triangles' ? '4.6956rem' : '8rem'};
`;

export const CountInputBox = styled(FovInput)`
  width: 100%;
`;

export const UploadedList = styled('div')`
  display: inline-flex;
  color: ${props => getColor(props, 'accent')};
`;

export const DragDropContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  cursor: pointer;
  color: ${props => getColor(props, props.error ? 'error' : 'onPrimary')};
`;

export const Label = styled('label')`
  width: 100%;
`;

export const UploaderLabel = styled(Label)`
  input {
    width: inherit;
  }
`;

export const MissingFile = styled('div')`
  color: ${props => getColor(props, 'warning')}
`;

export const UploadArea = styled('div')`
  svg {
    margin-right: 0.75rem;

    path {
      fill: ${props => getColor(props, 'accent')};
    }
  }

  label {
    display: flex;
    height: 4rem;
    width: 100%;
    margin: 0;
    border: 0.125rem dashed ${props => getColor(props, props.error ? 'error' : 'accent')};
    border-radius: 0.35rem;
    background-color: ${props => getColor(props, props.error ? 'error' : 'accent') + 15};

    div {
      span.file-types {
        display: none;
      }
    }
  }
`;

export const SupportedFileTypes = styled('div')`
  display: ${props => props.visible ? 'inline-flex' : 'none'};
  align-items: center;
`;

export const ClearButton = styled('div')`
  display: ${props => props.visible ? 'flex' : 'none'};
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0.75rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  height: 100%;
  width: fit-content;
  cursor: pointer;

  svg {
    margin-right: unset;
    path {
      fill: ${props => getColor(props, 'onPrimary')};
    }
  }
`;

export const UploadContainer = styled('div')`
  position: relative;
  width: 100%;
  height: fit-content;
`;