import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage, clearMessages } from 'store/actions/app';
import { clearUpdateData, clearSerializedData, setSerializedData } from 'store/actions/canvas';
import { withTheme } from 'styled-components';
import Canvas from '@archvisioninc/canvas';

const PreviewCanvas = withTheme(props => {
  const { theme, previewURL } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const userDataStore = useSelector(state => state.userData);
  const appStore = useSelector(state => state.app);
  const canvasStore = useSelector(state => state.canvas);
  const updateData = useMemo(() => canvasStore.updateData, [ canvasStore ]);
  const messages = appStore.messages;
  const selectedTheme = userDataStore.userPreferences.theme;
  const defaultEnvironments = canvasStore.defaultEnvironments;

  const cleanup = () => {
    dispatch(clearUpdateData());
    dispatch(clearSerializedData());
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [ location.pathname ]);

  if (!previewURL) return null;

  return (
    <Canvas
      data-testid='previewCanvas'
      theme={theme}
      $selectedTheme={selectedTheme}
      notifications={messages}
      defaultEnvironments={defaultEnvironments}
      clearNotifications={() => dispatch(clearMessages())}
      clearUpdateData={() => dispatch(clearUpdateData())}
      previewMode={true}
      guidURL={previewURL}
      updateData={updateData}
      setSerializedData={data => {
        const cleanData = JSON.stringify(data);
        dispatch(clearSerializedData());
        dispatch(setSerializedData(cleanData));
      }}
      addNotification={(messages, clearExisting) => {
        if (previewURL) {
          dispatch(addMessage([ ...messages ], clearExisting));
        }
      }}
    />
  );
});

export { PreviewCanvas };