import React from 'react';
import { Route } from 'react-router-dom';
import { paramsFromURL } from 'utils/paramHelpers';
import routes from 'navigation/routesController';
import { AuthRoute } from 'components/generic';

const {
  VITE_AUTHENTICATION_HOST: authenticationHost,
  VITE_CLIENT_ID: clientId,
} = process.env;

export const buildAuthUrl = () => {
  const url = new URL('/oauth/v1/login', authenticationHost);
  url.searchParams.append('clientid', clientId);
  url.searchParams.append(
    'redirecturl',
    window.location.origin + window.location?.pathname,
  );

  return url;
};

export const buildRefreshURL = () => {
  const url = new URL('/oauth/v1/refresh', authenticationHost);
  return url;
};

export const hasAccess = (userRoles, allowedRoles) => {
  let allow = false;

  userRoles?.forEach(role => {
    if (allowedRoles?.includes(role)) {
      allow = true;
    }
  });

  return allow;
};

const makeRoutes = (userRoles, redirect) => {
  return routes?.map((route, index) => {
    const { path, exact, element, authenticate, allowedRoles } = route;

    const authElement = (
      <AuthRoute
        hasAccess={hasAccess(userRoles, allowedRoles)}
        redirect={redirect}
        from={{ pathname: path, search: paramsFromURL() }}
      />
    );

    if (authenticate) {
      return (
        <Route key={index} element={authElement}>
          <Route exact={exact} path={path} element={element} />
        </Route>
      );
    }

    return <Route key={index} exact={exact} path={path} element={element} />;
  });
};

export default makeRoutes;