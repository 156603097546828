import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const LinkedSVG = styled('svg')`
  cursor: ${props => props.pointer && !props.disabled ? 'pointer' : 'default'};
  color: ${props => {
    const color = props.isEditing ? 'onPrimary' : 'mediumGrey';
    return props.color || props.theme.colors[props.$selectedTheme][color];
  }};

  &:hover {
    color: ${props => props.hoverColor};
  }
`;

const LinkedIcon = withTheme(props => {
  const { width, height, isEditing, color, pointer, hoverColor, disabled, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <LinkedSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      pointer={pointer}
      color={color}
      hoverColor={hoverColor}
      isEditing={isEditing}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      disabled={disabled}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 20 20'
      >
        <path fill='currentColor' d='M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z' />
      </LinkedSVG>
  );
});

export { LinkedIcon };