import React from 'react';
import { useSelector } from 'react-redux';
import { HorizontalSpacer } from 'components/layout';
import { getColor } from 'utils';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

const Button = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.$borderRadius || '0.25rem'};
  height: 2.75rem;
  width: 100%;
  min-width: 2.75rem;
  border: 0;
  transition: all 0.2s ease;
  font-size: ${props => props.fontSize || 0.9}rem;
  padding: 0 1rem;
  cursor: pointer;
  background-color: ${props => (
    props.color || getColor(props, 'accent')
  )};
  color: ${props => getColor(props, 'onAccent')};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${props =>
      props.hoverColor || getColor(props, 'accentHover')};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${props => getColor(props, 'darkGrey')};
    background-color: ${props => getColor(props, 'lightGrey')};

    &:hover {
      background-color: ${props => getColor(props, 'lightGrey')}
    }
  }
`;

const FovButton = withTheme(props => {
  const {
    theme,
    text,
    icon,
    callback,
    children,
    fontSize,
    color,
    hoverColor,
    borderRadius,
    ...rest
  } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Button
      theme={theme}
      $selectedTheme={selectedTheme}
      $borderRadius={borderRadius}
      color={color}
      hoverColor={hoverColor}
      onClick={callback || _.noop()}
      fontSize={fontSize}
      {...rest}
    >
      {icon && (
        <>
        {icon}{text && <HorizontalSpacer size={10} />}
        </>
      )}

      {text || children}
    </Button>
  );
});

export { FovButton };
