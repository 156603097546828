import { combineReducers, configureStore } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';

import {
  appReducer,
  rpcReducer,
  userDataReducer,
  availReducer,
  materialEditorReducer,
  canvasReducer,
} from './reducers';

const reducers = {
  app: appReducer,
  userData: userDataReducer,
  rpc: rpcReducer,
  avail: availReducer,
  materialEditor: materialEditorReducer,
  canvas: canvasReducer,
};

const allReducers = { ...reducers };
const mainReducer = combineReducers(allReducers);
const middleware = [ ReduxThunk ];

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const devTools = process.env.NODE_ENV !== 'production' && reduxDevTools;

const handleMiddleware = getDefaultMiddleWare => {
  const options = { serializableCheck: false };
  return getDefaultMiddleWare(options).concat(middleware);
};

const storeConfig = {
  reducer: mainReducer,
  devTools,
  middleware: handleMiddleware,
};

const store = configureStore(storeConfig);
const dispatch = store.dispatch;

export { store, dispatch };