import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextureSwatch, ColorSwatch, HorizontalSpacer, FovSlider } from 'components';
import {
  ChannelTextContainer,
  EmissiveIntensityContainer,
  EndChannelWrapper,
} from '../MaterialStyles';
import { withTheme } from 'styled-components';
import { updateCanvas } from 'utils';
import constants from 'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;
const Emissive = withTheme(props => {
  const { theme } = props;
  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const selectedTheme = userDataStore.userPreferences.theme;
  const emissiveValue = selectedMaterial?.emissiveIntensity;
  const white = theme.colors[selectedTheme].white;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const buildLightingSection = () => {
    return (
      <>
        <ChannelTextContainer>
          <EmissiveIntensityContainer>
            <FovSlider
              label='Emissive'
              defaultValue={emissiveValue ?? 1}
              onAfterChange={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  emissiveIntensity: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />

            <EndChannelWrapper>
              <ColorSwatch
                color={selectedMaterial?.emissiveColor || white}
                updateChannel={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    emissiveColor: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
              />

              <HorizontalSpacer size={15} />

              <TextureSwatch
                texture={{
                  id: selectedMaterial?.emissiveTexture?.name,
                  name: selectedMaterial?.emissiveTexture?.name,
                  img: selectedMaterial?.emissiveTexture?.url,
                } || null}
                updateChannel={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    emissiveTexture: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
                removeChannel={() => {
                  const inboundData = {
                    id: selectedMaterialId,
                    removeEmissiveTexture: true,
                  };

                  updateCanvas(removeArgs, inboundData);
                }}
              />
            </EndChannelWrapper>
          </EmissiveIntensityContainer>
        </ChannelTextContainer>
      </>
    );
  };

  return (
    <div>
      {buildLightingSection()}
    </div>
  );
});

export default Emissive;
