import React from 'react';
import { useSelector } from 'react-redux';
import {
  HorizontalSpacer,
  VerticalSpacer,
  ArrowLeftOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import { CameraIcon } from 'components/icons';
import {
  Image,
  Caption,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { cameras as images } from './images';
import Summary from './Summary';

const EditingCameras = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    'Toggle orthographic views by clicking any of the orthographic view icons.',
    <>
      Save a camera view by clicking the&nbsp;<strong>&quot;Save camera view&quot;</strong>&nbsp;button.
    </>,
    'Select a recently saved camera view from the camera view select menu.',
    <>
      Delete a saved camera view by clicking the&nbsp;<strong>&quot;Delete view&quot;</strong>&nbsp;
      button.
    </>,
    <>
      Reset your current view to the default location by clicking the&nbsp;
      <strong>&quot;Reset&quot;</strong>&nbsp;button.
    </>,
    <>
    Toggle safe frames or take screenshots by clicking the&nbsp;
    <strong>&quot;Take screenshot&quot;</strong>&nbsp;button.
  </>,
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='The camera panel'>
        <p>
          The camera panel <CameraIcon /> helps with changing and saving camera views, as well
          as taking screenshots of your model.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.cameraPanel} width={400} alt='Camera panel.' />
        </ImageContainer>
      </DocSection>



      <DocSection section='Camera views'>
        <DocSection subsection='Orthographic views'>
          <p>
            Orthographic views allow you to remove all perspective from the viewport.  This
            can be very helpful when making sure your model aligment is correct, or when
            aligning the model to other meshes.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.orthographicViews} width={350} alt='Orthographic views.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.orthographicLeftIcon} width={50} alt='Orthographic left icon.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.orthographicLeft} width={350} alt='Orthographic left view.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Clicking an orthographic view icon will toggle that orthographic view on and off.
          </Caption>
        </DocSection>



        <DocSection subsection='Saving a new camera view'>
          <p>
            You can bookmark camera views and come back to them later using the&nbsp;
            <strong>&quot;Save camera view&quot;</strong> button and camera view select menu.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.saveViewButton} width={200} alt='Save view button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.saveViewModal} width={350} alt='Save view modal.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.viewSelectMenu} width={350} alt='Save view menu.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The <strong>&quot;Default&quot;</strong> name is reserved and cannot be used when
            saving a new view.
          </Caption>
        </DocSection>



        <DocSection subsection='Deleting saved views'>
          <p>
            Removing a previously saved camera view can be done by selecting the view you
            want to remove in the camera view select menu and clicking the&nbsp;
            <strong>&quot;Delete view&quot;</strong> button.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.deleteViewButton} width={200} alt='Delete view button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.deleteViewConfirm} width={350} alt='Delete view confirmation.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.defaultCamera} width={350} alt='Default position.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The <strong>&quot;Delete view&quot;</strong> button will be enabled when a view other than&nbsp;
            <strong>&quot;Default&quot;</strong>&nbsp;is selected.
          </Caption>
        </DocSection>



        <DocSection subsection='Resetting camera view'>
          <p>
            Reseting the camera view will return the camera to the original view from
            when the model is first loaded.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.resetButton} width={125} alt='Reset view button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.cameraReset} width={700} alt='Model reset.' />
          </ImageContainer>
        </DocSection>
      </DocSection>



      <DocSection section='Screenshots'>
        <DocSection subsection='Safe frame and taking screenshots'>
          <p>
            The safe frame is an area that is used when framing your model in preparation for taking a screenshot.
            Screenshots can be handy when you want to save a transparent <strong>.png</strong> file to show someone.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.ratios} width={300} alt='Safe frame ratios.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.safeFrame} width={300} alt='Safe frame.' />
          </ImageContainer>

          <ImageContainer>
            <Image src={images.takeScreenshotButton} width={150} alt='Screenshot button.' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.screenshot} width={300} alt='A screenshot.' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The <strong>1 : 1</strong> safe frame ratio is used by FOVEA at publish time to generate a screenshot.<br />
            Try enabling the <strong>1 : 1</strong> safe frame and positioning your model before publishing.
          </Caption>
        </DocSection>
      </DocSection>
    </ContentContainer>
  );
});

export { EditingCameras };