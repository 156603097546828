import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FovToggle,
  SimplificationSwitch,
  VerticalSpacer,
  Body,
} from 'components';
import { LabeledItemHolder } from '../MaterialStyles';
import { updateCanvas } from 'utils';
import { withTheme } from 'styled-components';
import constants from 'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Simplification = withTheme(props => {
  const { theme } = props;

  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const selectedTheme = userDataStore.userPreferences.theme;
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const simplificationEnabled = selectedMaterial?.meshSimplification?.enabled;
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const globalSimplificationEnabled = serializedData?.metadata.publish.meshSimplification.enabled;
  const warning = theme.colors[selectedTheme].warning;
  const dispatch = useDispatch();

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  return (
    <>
      <LabeledItemHolder>
        Mesh simplification
        <FovToggle
          checked={simplificationEnabled && !globalSimplificationEnabled}
          disabled={globalSimplificationEnabled}
          callback={newVal => {
            const inboundData = {
              id: selectedMaterialId,
              meshSimplification: { enabled: newVal },
            };

            updateCanvas(updateArgs, inboundData);
          }}
        />
      </LabeledItemHolder>

      {globalSimplificationEnabled && (
        <Body size='Small' color={warning}>
          Publish panel global simplification enabled.
        </Body>
      )}

      {simplificationEnabled && !globalSimplificationEnabled && (
        <>
          <SimplificationSwitch updateType={CANVAS_UPDATE_TYPES.MATERIAL} />
          <VerticalSpacer size={25} />
        </>
      )}
    </>
  );
});

export default Simplification;
