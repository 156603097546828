import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { referenceItems, totalReferencePoints } from './referencePoints';
import { FovModal, Body, MESSAGE_TYPES } from 'components';
import styled, { withTheme, css } from 'styled-components';
import { addMessage } from 'store/actions/app';
import { setUserPreferences } from 'store/actions/userData';
import { getColor } from 'utils';
import _ from 'lodash';

const sharedSize = 1.5;

const DocPoint = styled('div')`
  position: absolute;
  z-index: 1;
  min-width: ${sharedSize}rem;
  min-height: ${sharedSize}rem;
  cursor: pointer;

  ${props => props.top && css`top: ${props.top / 16}rem;`}
  ${props => props.bottom && css`bottom: ${props.bottom / 16}rem;`}
  ${props => props.left && css`left: ${props.left / 16}rem;`}
  ${props => props.right && css`right: ${props.right / 16}rem;`}
`;

const PulseContainer = styled('div')`
  width: ${sharedSize}rem;
  height: ${sharedSize}rem;
  position: relative;

  ${props => {
    if (props.$isMouseOver) {
      return css`div {
        background: radial-gradient(${getColor(props, 'success') + 20}, ${getColor(props, 'success')});
      }`;
    }
  }}
`;

const PrimaryPulse = styled('div')`
  position: absolute;
  width: ${sharedSize}rem;
  height: ${sharedSize}rem;
  opacity: 0;
  border-radius: 50%;
  animation: expand-and-fade-out 2s infinite linear;
  ${props => css`background: radial-gradient(${getColor(props, 'accent') + 20}, ${getColor(props, 'accent')});`};

  @keyframes expand-and-fade-out {
    0% { opacity: 0; transform: scale(0%); }
    50% { opacity: 1; transform: scale(50%); }
    75% { opacity: 0.5; transform: scale(75%); }
    100% { opacity: 0; transform: scale(100%); }
  }
`;

const SecondaryPulse = styled(PrimaryPulse)`
  animation-delay: 0.67s;
`;

const TertiaryPulse = styled(PrimaryPulse)`
  animation-delay: 1.33s;
`;

const DocNote = withTheme(props => {
  const { theme, left, right, top, bottom, path, callback } = props;

  const [ showModal, setShowModal ] = useState(false);
  const [ isMouseOver, setIsMouseOver ] = useState(false);
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const showDocumentation = userDataStore.userPreferences.showDocumentation;
  const firstRun = JSON.parse(localStorage.getItem('fovFirstRun')) ?? true;
  const readItems = JSON.parse(localStorage.getItem('fovReadItems'));
  const alreadyRead = readItems?.find(item => _.isEqual(item, path));
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstRun && !readItems) {
      localStorage.setItem('fovReadItems', JSON.stringify([]));
    }
  }, [ firstRun, readItems ]);

  const getDocumentation = () => {
    const topLevelKey = Object.keys(referenceItems).find(key => key === path[0]);
    const childKey = path[path.length - 1];
    const parent = referenceItems[topLevelKey];
    const text = _.isObject(parent) && path.length > 1 ? parent[childKey] : parent;

    return text;
  };

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
    callback?.();
  };

  const handleClose = e => {
    e.preventDefault();
    setShowModal(false);

    if (firstRun && readItems) {
      const exists = readItems.find(item => _.isEqual(item, path));
      const message = 'Revisit any reference point again from the help menu.';
      const config = { type: MESSAGE_TYPES.info, timer: 6 };

      if (readItems.length === 0) {
        dispatch(addMessage([ { message, config } ]));
      }

      if (readItems.length + 1 === totalReferencePoints()) {
        dispatch(setUserPreferences({ showDocumentation: !showDocumentation }));
      }

      if (!exists) {
        const workingReadItems = [ ...readItems, path ];
        localStorage.setItem('fovReadItems', JSON.stringify(workingReadItems));
      }

    }
  };

  const buildTitle = () => {
    const split = path?.map(path => {
      const result = path.replace(/([A-Z])/g, ' $1');
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

      return finalResult;
    });

    return _.capitalize(split[split.length - 1]);
  };

  return (
    <>
     {showDocumentation && !alreadyRead && (
      <>
        <DocPoint
            theme={theme}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            onClick={handleClick}
          >
            <PulseContainer
              theme={theme}
              $selectedTheme={selectedTheme}
              $isMouseOver={isMouseOver}
              onMouseOver={() => setIsMouseOver(true)}
              onMouseOut={() => setIsMouseOver(false)}
            >
              <PrimaryPulse theme={theme} $selectedTheme={selectedTheme} />
              <SecondaryPulse theme={theme} $selectedTheme={selectedTheme} />
              <TertiaryPulse theme={theme} $selectedTheme={selectedTheme} />
            </PulseContainer>
          </DocPoint>

          <FovModal title={buildTitle()} visible={showModal} maxWidth={560} onClose={handleClose}>
            <Body size="Medium">
              {getDocumentation()}
            </Body>
          </FovModal>
        </>
      )}
    </>
  );
});

export { DocNote };