import styled from 'styled-components';
import { FovTypography } from 'components/typography';
import { getColor } from 'utils';

export const TagsContainer = styled('div')`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: ${props => (props.$opened ? '0.25rem 0.25rem 0 0;' : '0.25rem')};
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  padding: 0.25rem;
`;

export const MainPublishContainer = styled(FovTypography)`
  color: ${props => getColor(props, 'onPrimary')};
`;

export const ToggleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: flex-start;
`;

export const TitleWithTooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CreditBalance = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BalanceContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const AddCreditsContainer = styled('div')`
  position: relative;
`;

export const ButtonContainer = styled('div')`
  display: inline-flex;
  width: 100%;
`;