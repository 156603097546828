import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextureSwatch, FovSlider } from 'components';
import {
  ChannelTextContainer,
  RoughnessValueContainer,
  EndChannelWrapper,
} from '../MaterialStyles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Roughness = () => {
  const dispatch = useDispatch();
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const roughnessValue = selectedMaterial?.roughness;
  const metallicReflectanceTexture = true;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const buildRoughnessSection = () => {
    return (
      <ChannelTextContainer>
        <RoughnessValueContainer>
          <FovSlider
            label='Roughness'
            defaultValue={roughnessValue ?? 1}
            onAfterChange={newVal => {
              const inboundData = {
                id: selectedMaterialId,
                roughness: newVal,
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />

          <EndChannelWrapper>
            {metallicReflectanceTexture && (
              <TextureSwatch
                texture={{
                  id: selectedMaterial?.microSurfaceTexture?.name,
                  name: selectedMaterial?.microSurfaceTexture?.name,
                  img: selectedMaterial?.microSurfaceTexture?.url,
                } || null}
                updateChannel={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    microSurfaceTexture: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
                removeChannel={() => {
                  const inboundData = {
                    id: selectedMaterialId,
                    removeMicroSurfaceTexture: true,
                  };

                  updateCanvas(removeArgs, inboundData);
                }}
              />
            )}
          </EndChannelWrapper>
        </RoughnessValueContainer>
      </ChannelTextContainer>
    );
  };

  return (
    <div>
      {buildRoughnessSection()}
    </div>
  );
};

export default Roughness;
