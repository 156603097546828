import { FovTypography } from 'components/typography';
import { getColor } from 'utils';
import styled, { css } from 'styled-components';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

export const DocsContainer = styled(FovTypography)`
  ${props => props.$onDocs && css`
    display: grid;
    grid-template-columns: 20.80375rem 1fr;
  `}

  width: 100%;
  height: 100%;
  color: ${props => getColor(props, 'onPrimary')};
  overflow-y: auto;
`;

export const CardsContainer = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding: ${props => props.$onDocs ? '0 2rem 1rem 2rem' : '1rem'};
`;

export const DocsNavPanel = styled('div')`
  width: 100%;
  height: 100%;
  padding: 3.25rem 2rem 1rem 2rem;
  border-right: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  overflow-y: auto;
`;

export const MainContent = styled('div')`
  overflow-y: auto;
`;

export const SectionTitleContainer = styled('div')`
  display: inline-flex;
`;

export const PanelTitle = styled('div')`
  display: inline-flex;
`;

export const PrevNextContainer = styled('div')`
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'prev next';
  width: 100%;
`;

export const Prev = styled('div')`
  grid-area: prev;
  user-select: none;
`;

export const Next = styled('div')`
  grid-area: next;
  user-select: none;
  text-align: right;
`;


export const DocsContent = styled('div')`
  width: 100%;

  img {
    border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
    border-radius: 0.35rem;
  }

  h4 {
    text-transform: uppercase;
  }
`;

export const ExampleElement = styled('span')`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: ${props => props.lowProfile ? '0.1rem 0.5rem' : '0.5rem'};
  border-radius: 0.35rem;
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  user-select: none;
`;

export const Image = styled('img')`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  margin: 0.5rem;
`;

export const AvailImage = styled('div')`
  width: 18.75rem;
  height: 6.25rem;
  background: url(${props => props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 1.5rem;
  border-radius: 0.25rem;
`;

export const Caption = styled('div')`
  display: inline-block;
  text-align: center;

  ${props => props.hasElement && css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `};

  width: 100%;
  font-size: 0.9rem;
  color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'lightGrey');
  }};
`;

export const PipelineTargets = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    margin: 0.5rem;
  }
`;

export const InlineContainer = styled('span')`
  position: relative;
  width: fit-content;
`;

export const InlineElement = styled('span')`
  position: absolute;
  top: -0.6rem;
  left: 0.75rem;
  ${props => props.width && css`width: ${props.width / 16}rem;`}
`;

export const ContentContainer = styled('div')`
  h5 {
    display: inline-flex;
    align-items: center;
  }
`;

export const CategoryName = styled('div')`
  color: ${props => props.$selected && getColor(props, 'accent')};
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${props => getColor(props, 'accentHover')};
  }
`;