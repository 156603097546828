import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const FurnitureSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const FurnitureOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <FurnitureSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 17 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M14.0013 8.5V6.5C14.0013 3.46267 11.5387 1 8.50134 1C5.464 1 3.00134 3.46267 3.00134 6.5V8.5M14.0013 8.5C15.106 8.5 16.002 9.39533 16.0013 10.5C16.002 11.2147 15.6207 11.876 15.0013 12.2333V14C15.0013 14.552 14.5533 15 14.0013 15M14.0013 8.5C12.8967 8.49933 12.0013 9.394 12.0013 10.4987C12.0013 11.0733 12.248 11.6207 12.6793 12H4.32333C5.15133 11.2693 5.23067 10.0053 4.49933 9.17733C3.768 8.34933 2.50467 8.27 1.67667 9.00133C0.848665 9.73266 0.769332 10.996 1.50067 11.824C1.644 11.9867 1.81333 12.1247 2.00133 12.2333V14C2.00133 14.552 2.44933 15 3.00133 15M3.00133 15H14.0013M3.00133 15L3.00134 16M14.0013 15L14.0013 16M7.50134 5.5L9.50134 7.5M9.50134 5.5L7.50134 7.5'
      />
    </FurnitureSVG>
  );
});

export { FurnitureOutlined };