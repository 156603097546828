import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserPreferences } from 'store/actions/userData';
import { LightThemeIcon, DarkThemeIcon } from 'components/icons';
import { DocNote } from 'components';
import styled from 'styled-components';
import constants from 'constants/index';

const Switcher = styled('div')`
  position: relative;
`;

const ThemeSwitcher = () => {
  const theme = useSelector(state => state.userData.userPreferences.theme);
  const dispatch = useDispatch();

  const handleThemeSwitch = () => {
    const value = theme === constants.THEME.LIGHT
        ? constants.THEME.DARK
        : constants.THEME.LIGHT;
    dispatch(setUserPreferences({ theme: value }));
  };

  return (
    <Switcher onClick={handleThemeSwitch}>
      <DocNote path={[ 'header', 'themeSwitcher' ]} bottom={-25} />

      {theme === constants.THEME.DARK
      ? <LightThemeIcon cursor="pointer" />
      : <DarkThemeIcon cursor="pointer" />
      }
    </Switcher>
  );
};

export { ThemeSwitcher };