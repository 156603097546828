import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const BicycleSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const BicycleOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <BicycleSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 20 15'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M16.0001 11.4273L12.0112 2.54001C11.8752 2.23841 12.0097 1.8832 12.3113 1.7472C12.3353 1.7368 12.3601 1.7272 12.3849 1.72L14.7721 1M7.60014 3.62726H3.40012M7.21365 7.16008L5.20004 3.62726M7.21365 7.16008L3.99986 11.4274M7.21365 7.16008L13.3383 5.49854M4.00002 8.42725C5.65682 8.42725 7.00003 9.77045 7.00003 11.4273C7.00003 13.0841 5.65682 14.4273 4.00002 14.4273C2.34321 14.4273 1 13.0841 1 11.4273C1 9.77045 2.34321 8.42725 4.00002 8.42725ZM15.9999 8.42725C17.6567 8.42725 18.9999 9.77045 18.9999 11.4273C18.9999 13.0841 17.6567 14.4273 15.9999 14.4273C14.3431 14.4273 12.9999 13.0841 12.9999 11.4273C12.9999 9.77045 14.3431 8.42725 15.9999 8.42725Z'
      />
    </BicycleSVG>
  );
});

export { BicycleOutlined };