import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const BenchSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const BenchOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <BenchSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 20 13'
    >
      <path
        fill='currentColor'
        strokeWidth={0.1}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M18.6016 6.34308H15.8924V5.25405H18.6016C18.8216 5.25405 19 5.0757 19 4.85566V1.3984C19 1.17836 18.8216 1 18.6016 1H1.3984C1.17836 1 1 1.17836 1 1.3984V4.85566C1 5.0757 1.17836 5.25405 1.3984 5.25405H4.10757V6.34308H1.3984C1.17836 6.34304 1 6.52144 1 6.74144V9.16678C1 9.38682 1.17836 9.56518 1.3984 9.56518H4.10757V11.9202H3.98808C3.76804 11.9202 3.58968 12.0986 3.58968 12.3186C3.58968 12.5387 3.76804 12.717 3.98808 12.717H5.02394C5.24398 12.717 5.42234 12.5387 5.42234 12.3186C5.42234 12.0986 5.24398 11.9202 5.02394 11.9202H4.90437V9.56522H15.0956V11.9202H14.9761C14.756 11.9202 14.5777 12.0986 14.5777 12.3186C14.5777 12.5387 14.756 12.717 14.9761 12.717H16.0119C16.232 12.717 16.4103 12.5387 16.4103 12.3186C16.4103 12.0986 16.232 11.9202 16.0119 11.9202H15.8924V9.56522H18.6016C18.8216 9.56522 19 9.38686 19 9.16682V6.74147C19 6.52143 18.8216 6.34308 18.6016 6.34308ZM1.79683 4.45726V1.7968H18.2032V4.45722L1.79683 4.45726ZM15.0956 6.34304H4.90437V5.25405H15.0956V6.34304ZM18.2031 8.76846H1.79683V7.13987H18.2032L18.2031 8.76846Z'
      />
    </BenchSVG>
  );
});

export { BenchOutlined };