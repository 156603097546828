import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import constants from 'constants/index';
import { ChannelTextContainer } from '../styles';
import { VisibilityToggle } from '../../Visibility/styles';
import { ColorSwatch, FovSlider, VerticalSpacer, FovTypography, FovToggle } from 'components';
import { updateCanvas, rgbToHex, hexToRgb } from 'utils';
import { AlbedoContainer, EnvironmentIntensityContainer } from '../../Materials/MaterialStyles';
import LightingPosition from './Position';
import LightingDirection from './Direction';
import LightingRotation from './Rotation';
const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Lighting = props => {
    const { theme } = props;
    const dispatch = useDispatch();
    const [ showAdvanced, setShowAdvanced ] = useState(false);
    const canvasStore = useSelector(state => state.canvas);
    const userDataStore = useSelector(state => state.userData);
    const selectedTheme = userDataStore.userPreferences.theme;
    const serializedData = canvasStore.serializedData;
    const lights = serializedData?.metadata.lights;
    const defaultLight = lights[0];
    const { id, intensity, diffuse, isEnabled } = defaultLight;
    const white = theme.colors[selectedTheme].white;

    const updateArgs = {
        dispatch,
        type: CANVAS_UPDATE_TYPES.LIGHTING,
        method: CANVAS_UPDATE_METHODS.UPDATE,
    };

    return (
        <>
            <VerticalSpacer size={24} />
            <VisibilityToggle>
                <FovTypography>Enabled</FovTypography>

                <FovToggle
                    checked={isEnabled}
                    callback={newVal => {
                        const inboundData = {
                            id,
                            enable: newVal,
                        };

                    updateCanvas(updateArgs, inboundData);
                }}
                />
            </VisibilityToggle>
            <VerticalSpacer size={24} />

            <ChannelTextContainer>
                <EnvironmentIntensityContainer>
                    <FovSlider
                        label='Intensity'
                        defaultValue={intensity}
                        onAfterChange={newVal => updateCanvas(updateArgs, { id, intensity: newVal })}
                        min={0.01}
                        max={10}
                    />
                    <AlbedoContainer>
                        <ColorSwatch
                            color={rgbToHex(...diffuse.map(val => val * 255)) || white}
                            updateChannel={newVal => {
                                if (newVal) {
                                    let { r, g, b } = hexToRgb(newVal);
                                    r /= 255;
                                    g /= 255;
                                    b /= 255;
                                    const inboundData = {
                                        id,
                                        diffuse: { r, g, b },
                                    };
                                    updateCanvas(updateArgs, inboundData);
                                }
                            }}
                        />
                    </AlbedoContainer>
                </EnvironmentIntensityContainer>
            </ChannelTextContainer>
            <VerticalSpacer size={8} />
            <LightingRotation />
            <VerticalSpacer size={15} />
            <VisibilityToggle>
                <FovTypography>Advanced Settings</FovTypography>

                <FovToggle
                    checked={showAdvanced}
                    callback={() => {
                        setShowAdvanced(!showAdvanced);
                }}
                />
            </VisibilityToggle>
            <VerticalSpacer size={8} />
            {
                showAdvanced
                ? <>
                    <LightingPosition />
                    <VerticalSpacer size={15} />
                    <LightingDirection />
                </>
                : <></>
            }

            <VerticalSpacer size={16} />

        </>
    );
};

export default withTheme(Lighting);