/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { getColor } from 'utils';

const RPCLogoSVG = styled('svg')`
  color: ${props => props.color || getColor(props, 'onPrimary')};
`;

const RPCLogo = withTheme(props => {
  const { width, height, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <RPCLogoSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 150}
      height={height || 56}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 212.6 80'
    >
      <g>
        <path fill='currentColor' d='M72.3,73.9h-23L32.4,49.1h-7.3v24.8H4.3V39.8c0-5.1,4.2-9.3,9.3-9.3h33.1c1.6,0,2.9-1.3,2.9-2.9 s-1.3-2.9-2.9-2.9h-33c-5.1,0-9.3-4.2-9.3-9.3V6.1h44.2c10.9,0,19.9,8.1,21.3,18.6c0.1,1,0.2,1.9,0.2,2.9s-0.1,2-0.2,2.9 c-1.1,8.3-7,15.1-14.8,17.6l0.7,1L72.3,73.9z'/>
        <path fill='currentColor' d='M139,27.6c0,1-0.1,2-0.2,2.9c-1.4,10.5-10.4,18.6-21.3,18.6H94v24.8H73.2V39.8c0-5.1,4.2-9.3,9.3-9.3h33.1 c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9H82.5c-5.1,0-9.3-4.2-9.3-9.3V6.1h44.2c10.9,0,19.9,8.1,21.3,18.6 C138.9,25.6,139,26.6,139,27.6z'/>
        <g>
          <path fill='currentColor' d='M163.5,40c0,8.4,6.8,15.2,15.2,15.2H199c1.4,0,2.7,0.3,3.8,0.8c3.2,1.5,5.5,4.7,5.5,8.5v9.4h-35.4 c-3.3,0-6.4-0.5-9.4-1.3c-9-2.6-16.5-8.8-20.8-17c-2.4-4.7-3.8-10-3.8-15.6s1.4-10.9,3.8-15.6c4.2-8.2,11.7-14.4,20.8-17 c3-0.9,6.1-1.3,9.4-1.3h35.4v9.4c0,3.8-2.2,7-5.5,8.5c-1.2,0.5-2.5,0.8-3.8,0.8h-20.3C170.3,24.8,163.5,31.6,163.5,40z'/>
        </g>
      </g>
    </RPCLogoSVG>
  );
});

export default RPCLogo;