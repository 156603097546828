import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setUserPreferences } from 'store/actions/userData';
import { Creators } from './Creators';
import { Categories } from './Categories';
import { Tags } from './Tags';
import { Collapse } from 'components';
import { Wrapper, CollapseContainer } from './panelStyles';
import { withTheme } from 'styled-components';

const FilterPanel = withTheme(props => {
  const { theme } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const userPreferences = userDataStore.userPreferences;
  const filterPanel = userPreferences?.filterPanel;
  const mediumGrey = theme.colors[selectedTheme].mediumGrey;
  const onCreators = location.pathname === '/creators';

  const handleCollapse = () => dispatch(setUserPreferences({ filterPanel: !filterPanel }));

  return (
    <Wrapper theme={theme} $selectedTheme={selectedTheme} collapsed={filterPanel}>
      <CollapseContainer collapsed={filterPanel} onClick={handleCollapse}>
        <Collapse color={mediumGrey} rotate={filterPanel ? 180 : 0} />
      </CollapseContainer>

      {onCreators && <Creators />}
      <Categories onCreators={onCreators} />
      <Tags onCreators={onCreators} />
    </Wrapper>
  );
});

export { FilterPanel };