import React from 'react';
import styled, { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';

const CardContainer = styled('div')`
  background-color: ${props => props.theme.colors[props.selectedTheme].primary};
  box-shadow: ${props => props.theme.colors[props.selectedTheme].mediumGrey};
  border-radius: 0.25rem;
  padding: ${props => props.padding};
  margin: 0.75rem;
  max-height: ${props => props.maxHeight};
  overflow: auto;
  overflow-x: hidden;
  width: ${props => props.width};
`;

const Card = withTheme(props => {
  const { children, maxHeight = '100%', width = 'auto', padding = '1rem' } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CardContainer selectedTheme={selectedTheme} maxHeight={maxHeight} padding={padding} width={width}>
      {children}
    </CardContainer>
  );
});

export { Card };
