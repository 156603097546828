import React, { useMemo } from 'react';
import { ModelContent } from 'pages/Home/ModelContent';
import { getCreators } from 'store/actions/userData';
import { ModelContext } from 'pages/Home/ModelContext';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

const Creators = () => {
  const [ cookies ] = useCookies([ 'userAuth' ]);
  const token = cookies.archvision_token;
  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const creators = useMemo(() => userDataStore.creators, [ userDataStore.creators ]);

  const contextValue = {
    getModels: () => dispatch(getCreators({ token })),
    workingData: creators,
    pageTitle: 'Creators',
    sortingOptions: [ 'ascending', 'descending', 'oldest first', 'newest first' ],
    searchParam: 'categories',
  };

  return <ModelContext.Provider value={contextValue}>
    <ModelContent />
  </ModelContext.Provider>;
};

export { Creators };