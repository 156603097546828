const { VITE_IMAGE_HANDLER_PREFIX: imageHandlerPrefix } = process.env;

// example:
//       <img src={getCdnImageUrl(imgSrc, {width: 20, height: 20})} />
// description:
//    before loading the image, passes the image through our imagecache service.
//    the image cache service downloads the image to our a s3 bucket, resizes it and returns
//    a 304 redirect to the cached downsized image. After the first load, the resized image is
//    reused for faster processing. Use this service when loading all images into the app and set
//    the dimensions to the exact size that the container requires. This will speed up the app load times
//    since it will no longer load full sized images each time.
//    note: cache invalidates every 60 days so the same image will be reprocessed when called after that.
export const getCdnImageUrl = (url, opts) => {
  if (opts) {
    const { width, height } = opts;

    return `${imageHandlerPrefix}${encodeURI(url)}&d=${Math.ceil(
      width,
    )}x${Math.ceil(height)}`;
  }

  return `${imageHandlerPrefix}${encodeURI(url)}`;
};
