import styled from 'styled-components';
import { getColor } from 'utils';

export const LightingWrapper = styled('div')`
  margin: 0.625rem;
`;

const OuterChannelWrapper = styled('div')`
  width: 100%;
`;

export const InnerChannelWrapper = styled('div')`
  display: grid;
  align-items: center;
  justify-content: end;
  gap: 0.5rem 1.5rem;
  grid-template-columns: ${props => props.hideChannel ? '1fr' : '1fr auto'};
  width: 100%;
`;

export const EndChannelWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: auto;
`;

export const UploadInput = styled('input')`
  display: none;
`;

export const ChannelHolder = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ModalWrapper = styled('div')`
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
  outline: none;
  border-radius: 0.25rem;
  margin: 0rem 1rem 0rem 0rem;
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
`;

export const CustomImage = styled('img')`
  border: 0.0625rem solid #9d9d9d;
  border-radius: 0.3125rem;
  background-repeat: round;
  background-size: cover;
  background-color: #fcfcfc;
  height: 2.25rem;
  width: 2.25rem;
  cursor: pointer;
  outline: none;
`;

export const NoSwatch = styled('div')`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.3125rem;
  background-color: white;
  border: 0.0625rem solid #9d9d9d;
  cursor: pointer;
  outline: none;
`;

export const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SimplificationContainer = styled('div')`
  display: flex;
  flex-direction: column;

`;

export const LabeledItemHolder = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0;
  border: none;
`;

export const TitleWithTooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ChannelTextContainer = OuterChannelWrapper;

export const EnvironmentIntensityContainer = InnerChannelWrapper;

export const BumpTextureContainer = InnerChannelWrapper;

export const EmissiveIntensityContainer = InnerChannelWrapper;

export const RoughnessValueContainer = InnerChannelWrapper;

export const MetallicContainer = InnerChannelWrapper;

export const AlphaContainer = InnerChannelWrapper;

export const AlbedoContainer = EndChannelWrapper;

export const EmissiveColorContainer = EndChannelWrapper;

export const ChannelHolderContainer = EndChannelWrapper;