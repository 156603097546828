import React from 'react';
import { withTheme } from 'styled-components';

export const ThreeDRpcIcon = withTheme(props => {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.05278 0.0701501C8.88112 -0.023372 8.6736 -0.0234361 8.50192 0.0702744L0.709506 4.32126L0.464394 4.45619C0.394002 4.49494 0.323931 4.53352 0.295724 4.54925L0.292089 4.55067L0.281921 4.5567C0.107113 4.66032 0 4.84844 0 5.05155V14.9304C0 15.1406 0.114624 15.3341 0.299037 15.435L8.50717 19.9293C8.59329 19.9765 8.68837 20 8.78343 20C8.86787 20 8.95087 19.9809 9.02702 19.9451L9.03263 19.9442L17.2634 15.4349C17.4477 15.334 17.5623 15.1405 17.5623 14.9304V5.05036C17.5623 4.84717 17.4552 4.6589 17.2804 4.55532L16.8521 4.32092L9.05278 0.0701501ZM1.77522 5.05207L8.77762 1.23059L15.787 5.05139L8.78332 8.88498L1.77522 5.05207ZM9.35871 18.4537V9.88171L16.4117 6.02109V14.5896L9.35871 18.4537ZM1.1506 14.5895V6.02189L8.20815 9.88183V18.4539L1.1506 14.5895Z"
        fill="#383838"
      />
    </svg>
  );
});
