import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const PlantSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const PlantOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <PlantSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 16 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M5.13988 4.09784C5.13988 4.09784 8.24479 5.65029 8.76227 10.8251C9.79724 7.72023 11.3497 6.68526 11.3497 6.68526M7.20982 6.16779C7.20982 6.16779 6.15415 7.97554 4.62239 7.72024C1.51748 7.20276 2.03497 3.06288 1 1.51043C3.03337 0.679 5.34204 0.871504 7.20982 2.02791C8.7602 3.06288 8.7602 4.61533 7.20982 6.16779ZM10.6059 7.68439C10.6059 7.68439 11.3394 8.93877 12.4033 8.76283C14.5595 8.40335 14.1972 5.5282 14.919 4.44976C13.5066 3.87294 11.9031 4.0068 10.6059 4.80924C9.52816 5.5282 9.52816 6.60664 10.6059 7.68439ZM10.9254 16H6.59919C6.35356 16 6.14105 15.8268 6.09206 15.586L5.26408 11.4461C5.20681 11.166 5.38759 10.8928 5.66772 10.8355C5.70153 10.8286 5.73672 10.8251 5.77122 10.8251H11.7533C12.039 10.8244 12.2715 11.0563 12.2715 11.3419C12.2715 11.3771 12.2681 11.4116 12.2612 11.4461L11.4332 15.586C11.3835 15.8268 11.1717 16 10.9254 16Z'
      />
    </PlantSVG>
  );
});

export { PlantOutlined };