import React from 'react';
import { withTheme } from 'styled-components';

const FooterLogo = withTheme(() => {
  const wordFill = '#FFFFFF';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={144}
      height={36}
      viewBox="0 0 140 35"
    >
      <g>
        <g>
          <g>
            <path fill={wordFill} d="M42.53,13.97c0.1,0,0.16,0.04,0.2,0.12l4.58,10.55c0.06,0.16-0.06,0.28-0.22,0.28h-1.37
              c-0.2,0-0.34-0.1-0.4-0.28l-0.8-1.95h-6.03l-0.86,2.01c-0.08,0.14-0.18,0.22-0.32,0.22H35.8c-0.18,0-0.2-0.14-0.14-0.28
              l4.62-10.53c0.04-0.1,0.12-0.14,0.24-0.14H42.53z M39.22,21.01h4.58l-2.17-5.39h-0.26L39.22,21.01z"/>

            <path fill={wordFill} d="M54.56,13.97c2.39,0,4.14,1.73,4.14,3.92c0,1.53-0.82,2.81-2.09,3.46l1.83,3.26c0.1,0.18,0,0.32-0.22,0.32
              h-1.47c-0.18,0-0.32-0.14-0.4-0.28l-1.47-2.83c-0.06,0.02-0.16,0.02-0.28,0.02h-3.52v2.81c0,0.18-0.08,0.28-0.26,0.28h-1.27
              c-0.22,0-0.3-0.14-0.3-0.28V14.25c0-0.18,0.1-0.28,0.28-0.28H54.56z M54.56,20.12c1.47,0,2.31-0.94,2.31-2.23
              c0-1.27-0.84-2.21-2.31-2.21h-3.48v4.44H54.56z"/>

            <path fill={wordFill} d="M70.8,16.18c0.1,0.14,0.08,0.24-0.04,0.34l-0.94,0.74c-0.14,0.1-0.28,0.04-0.36-0.06
              c-0.86-1.13-1.91-1.69-3.28-1.69c-2.23,0-3.82,1.69-3.82,3.92c0,2.25,1.59,3.94,3.82,3.94c1.37,0,2.45-0.6,3.32-1.73
              c0.1-0.12,0.2-0.12,0.34-0.02l0.94,0.76c0.12,0.1,0.14,0.2,0.04,0.34c-1.11,1.55-2.77,2.35-4.64,2.35
              c-3.22,0-5.67-2.37-5.67-5.65c0-3.24,2.45-5.63,5.67-5.63C68.05,13.81,69.73,14.66,70.8,16.18z"/>

            <path fill={wordFill} d="M74.38,13.97c0.18,0,0.28,0.1,0.28,0.28v4.12h5.91v-4.12c0-0.18,0.1-0.28,0.3-0.28h1.25
              c0.18,0,0.28,0.1,0.28,0.28v10.39c0,0.18-0.1,0.28-0.28,0.28h-1.25c-0.2,0-0.3-0.1-0.3-0.28v-4.56h-5.91v4.56
              c0,0.18-0.1,0.28-0.28,0.28h-1.27c-0.18,0-0.28-0.1-0.28-0.28V14.25c0-0.18,0.1-0.28,0.28-0.28H74.38z"/>

            <path fill={wordFill} d="M89.04,24.93c-0.14,0-0.22-0.06-0.28-0.18l-4.6-10.47c-0.08-0.16,0.02-0.3,0.2-0.3h1.41
              c0.22,0,0.36,0.08,0.42,0.24l2.13,4.95l1.53,3.94h0.1l1.57-4.02l2.07-4.89c0.06-0.14,0.18-0.22,0.34-0.22h1.55
              c0.14,0,0.22,0.14,0.16,0.28l-4.66,10.47c-0.06,0.14-0.16,0.2-0.3,0.2H89.04z"/>

            <path fill={wordFill} d="M98.93,13.97c0.18,0,0.28,0.1,0.28,0.28v10.39c0,0.18-0.1,0.28-0.28,0.28h-1.27c-0.18,0-0.28-0.1-0.28-0.28
              V14.25c0-0.18,0.1-0.28,0.28-0.28H98.93z"/>

            <path fill={wordFill} d="M110.71,15.5c0.1,0.1,0.1,0.2,0,0.3l-0.78,0.99c-0.12,0.12-0.24,0.12-0.36,0.02
              c-0.97-0.82-2.13-1.29-3.6-1.29c-1.69,0-2.57,0.46-2.57,1.33c0,0.82,1.19,1.21,2.9,1.43c2.79,0.32,4.8,1.15,4.8,3.48
              c0,2.19-2.19,3.34-4.89,3.34c-2.01,0-3.74-0.54-5.11-1.83c-0.1-0.1-0.1-0.22,0-0.34l0.78-0.98c0.1-0.14,0.24-0.16,0.38-0.04
              c1.13,1.05,2.39,1.49,3.94,1.49c2.01,0,3.08-0.68,3.08-1.63c0-1.07-1.23-1.45-3.24-1.69c-2.31-0.26-4.46-0.9-4.46-3.22
              c0-1.83,1.75-3.04,4.42-3.04C107.71,13.81,109.48,14.38,110.71,15.5z"/>

            <path fill={wordFill} d="M114.45,13.97c0.18,0,0.28,0.1,0.28,0.28v10.39c0,0.18-0.1,0.28-0.28,0.28h-1.27c-0.18,0-0.28-0.1-0.28-0.28
              V14.25c0-0.18,0.1-0.28,0.28-0.28H114.45z"/>

            <path fill={wordFill} d="M122.39,13.81c3.18,0,5.65,2.37,5.65,5.65c0,3.26-2.47,5.63-5.65,5.63s-5.65-2.37-5.65-5.63
              C116.74,16.18,119.21,13.81,122.39,13.81z M118.59,19.46c0,2.25,1.55,3.92,3.8,3.92s3.8-1.67,3.8-3.92c0-2.27-1.55-3.94-3.8-3.94
              S118.59,17.19,118.59,19.46z"/>

            <path fill={wordFill} d="M138.45,24.93c-0.14,0-0.26-0.06-0.34-0.18l-3.6-4.54l-2.67-3.68l-0.1,0.02l0.12,3.26v4.85
              c0,0.18-0.1,0.28-0.3,0.28h-1.27c-0.16,0-0.24-0.1-0.24-0.28V14.25c0-0.18,0.08-0.28,0.26-0.28h1.19c0.2,0,0.36,0.06,0.46,0.2
              l3.86,4.82l2.41,3.34l0.1-0.02l-0.14-3.24v-4.82c0-0.18,0.1-0.28,0.28-0.28h1.27c0.18,0,0.26,0.1,0.26,0.28v10.39
              c0,0.18-0.08,0.28-0.26,0.28H138.45z"/>
          </g>
        </g>

        <polygon fill="#4B4B4B" points="7.39,0.1 11.06,8.8 3.69,8.8 	"/>
        <polygon fill="#8B8B8B" points="3.69,8.8 7.37,17.5 0,17.5 	"/>
        <polygon fill="#6B6B6B" points="7.37,17.5 3.69,8.8 11.06,8.8 	"/>
        <polygon fill="#FFEE52" points="11.08,8.8 14.75,0.1 7.39,0.1 	"/>
        <polygon fill="#919191" points="14.75,0.1 11.08,8.8 18.44,8.8 	"/>
        <polygon fill="#C74897" points="18.44,8.8 14.77,17.5 22.13,17.5 	"/>
        <polygon fill="#A6A6A6" points="14.77,17.5 18.44,8.8 11.08,8.8 	"/>
        <polygon fill="#787878" points="11.06,8.8 7.38,0.1 14.75,0.1 	"/>
        <polygon fill="#B4B4B4" points="22.13,17.5 18.46,26.2 25.82,26.2 	"/>
        <polygon fill="#B6B6B6" points="25.82,26.2 22.15,34.9 29.52,34.9 	"/>
        <polygon fill="#989898" points="22.15,34.9 25.82,26.2 18.46,26.2 	"/>
        <polygon fill="#FFEE52" points="18.44,26.2 14.77,17.5 22.13,17.5 	"/>
        <polygon fill="#8B8B8B" points="18.46,8.8 22.13,17.5 14.77,17.5 	"/>
        <polygon fill="#EBEBEB" points="22.13,17.5 18.46,8.8 25.82,8.8 	"/>
        <polygon fill="#A5A5A5" points="14.77,17.5 18.44,26.2 11.08,26.2 	"/>
        <polygon fill="#C74897" points="11.08,26.2 14.75,34.9 7.38,34.9 	"/>
        <polygon fill="#8F8F8F" points="14.75,34.9 11.08,26.2 18.44,26.2 	"/>
        <polygon fill="#9E9E9E" points="18.46,26.2 22.13,17.5 14.77,17.5 	"/>
        <polygon fill="#E5E5E5" points="7.37,17.5 3.69,26.2 11.06,26.2 	"/>
        <polygon fill="#CACACA" points="11.06,26.2 7.38,34.9 14.75,34.9 	"/>
        <polygon fill="#D7D7D7" points="7.38,34.9 11.06,26.2 3.69,26.2 	"/>
        <polygon fill="#F3F3F3" points="3.68,26.2 0,17.5 7.37,17.5 	"/>
      </g>
    </svg>
  );
});

export { FooterLogo };