import React, { useMemo } from 'react';
import { ModelContent } from 'pages/Home/ModelContent';
import { useDispatch, useSelector } from 'react-redux';
import { getUserJobs } from 'store/actions/userData';
import { ModelContext } from 'pages/Home/ModelContext';
import { useCookies } from 'react-cookie';

const Jobs = () => {
  const dispatch = useDispatch();
  const [ cookies ] = useCookies([ 'userAuth' ]);
  const token = cookies.archvision_token;
  const userDataStore = useSelector(state => state.userData);
  const userId = userDataStore.data.userId;
  const userJobs = useMemo(() => userDataStore.userJobs, [ userDataStore.userJobs ]);

  const contextValue = {
    getModels: () => dispatch(getUserJobs({ token, userId })),
    workingData: userJobs,
    sortingOptions: [ 'newest first', 'oldest first' ],
    pageTitle: 'My Jobs',
    searchParam: 'status',
  };

  return <ModelContext.Provider value={contextValue}>
    <ModelContent />
  </ModelContext.Provider>;
};

export { Jobs };