import React from 'react';
import constants from 'constants/index';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const light = constants.THEME.LIGHT;

const RotateSVG = styled('svg')`
  transform: scaleX(${props => props.flipIcon ? -1 : 1});
`;

const Rotate = withTheme(props => {
  const { width, height, color, flipIcon, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const isLight = selectedTheme === light;
  const accent = theme.colors[selectedTheme][isLight ? 'accent' : 'white'];
  const fill = color || theme.colors[selectedTheme][isLight ? 'onPrimary' : 'mediumGrey'];

  return (
    <RotateSVG
      width={width || 16}
      height={height || 22}
      viewBox="0 0 16 22"
      flipIcon={flipIcon}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={fill} d="M0 7.93C0.04 3.54 3.6 0 8 0C12.4 0 15.96 3.54 16 7.93V13.93C16 18.35 12.42 21.93 8 21.93C3.58 21.93 0 18.35 0 13.93V7.93ZM2 7.93H7V2.09C4.19 2.56 2.04 4.99 2 7.93ZM9 2.09V7.93H14C13.96 4.99 11.81 2.56 9 2.09ZM2 13.93C2 17.24 4.69 19.93 8 19.93C11.31 19.93 14 17.24 14 13.93V9.93H2V13.93Z" />
      <path fill={accent} d="M7 7.93H2C2.04 4.99 4.19 2.56 7 2.09V7.93Z" />
      <path fill={fill} d="M16 7.93C15.96 3.54 12.4 0 8 0C3.6 0 0.04 3.54 0 7.93V13.93C0 18.35 3.58 21.93 8 21.93C12.42 21.93 16 18.35 16 13.93V7.93ZM14 7.93H9V2.09C11.81 2.56 13.96 4.99 14 7.93ZM7 2.09V7.93H2C2.04 4.99 4.19 2.56 7 2.09ZM14 13.93C14 17.24 11.31 19.93 8 19.93C4.69 19.93 2 17.24 2 13.93V9.93H14V13.93Z" />
    </RotateSVG>
  );
});

export { Rotate };
