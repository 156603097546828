import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, CloseOutlined } from '../icons';
import { withTheme } from 'styled-components';

import {
  TagInput,
  Tag,
  TagsContainer,
  NewTagButton,
  DeleteTagButton,
} from './styles';
import { HorizontalSpacer } from 'components/layout';
import { addTagFilter, removeTagFilter, clearCategoryFilter } from 'store/actions/userData';
import _ from 'lodash';

const Tags = withTheme(props => {
  const { theme, values, readOnly, ...rest } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const filters = useSelector(state => state.userData.filters.tags);
  const [ tags, setTags ] = useState([]);
  const [ newTagCreation, setNewTagCreation ] = useState(false);
  const [ inputValue, setInputValue ] = useState('');
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const addFilter = useCallback(name => {
    const lowCaseName = _.toLower(name);

    if (_.indexOf(filters, lowCaseName) !== -1){
      return dispatch(removeTagFilter(lowCaseName));
    }

    dispatch(clearCategoryFilter());
    dispatch(addTagFilter(lowCaseName));
  }, [ dispatch, filters ]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [ newTagCreation, inputValue ]);

  useEffect(() => {
    if (values) {
      const workingTags = typeof values === 'string' ? values.split(',') : values;
      const value = workingTags?.map(tag => ({
        tag,
        id: Math.floor(Math.random() * 10000),
      }));

      setTags(value);
    }
  }, [ values ]);

  const handleTagInput = e => setInputValue(e.target.value);

  const handleCloseTag = (e, id) => {
    const newTags = tags.filter(tag => tag.id !== id);
    setTags(newTags);
    rest.onChange(e, newTags.map(item => item.tag).join(','));
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleTags(e, false);
    } else if (e.key === 'Escape') {
      setInputValue('');
      setNewTagCreation(!newTagCreation);
    }
  };

  const handleTags = (e, closeInput) => {
    const tag = e.target.value;
    const id = Math.floor(Math.random() * 10000);

    if (tag.length) {
      const newTags = [ ...tags, { tag, id } ];
      setTags(newTags);
      setInputValue('');
      closeInput && setNewTagCreation(!newTagCreation);
      rest.onChange(e, newTags.map(item => item.tag).join(','));
    } else {
      setNewTagCreation(!newTagCreation);
    }
  };

  return (
    <TagsContainer>
      {tags?.map((tag, index) => (
        <Tag
          key={index}
          theme={theme}
          $selectedTheme={selectedTheme}
          onClick={() => readOnly ? addFilter(tag.tag) : _.noop()}
          $usedAsFilter={readOnly}
          $selected={readOnly && _.indexOf(filters, _.toLower(tag.tag)) !== -1}
        >
          {tag.tag}
          {!readOnly && (
            <DeleteTagButton onClick={e => handleCloseTag(e, tag.id)}>
              <CloseOutlined width={14} height={14} />
            </DeleteTagButton>
          )}
        </Tag>
      ))}
      {!newTagCreation && !readOnly && (
        <NewTagButton
          onClick={() => setNewTagCreation(!newTagCreation)}
          theme={theme}
          $selectedTheme={selectedTheme}
        >
          <PlusOutlined width={15} height={15} />
          <HorizontalSpacer size={5} />
          New tag
        </NewTagButton>
      )}
      {newTagCreation && (
        <TagInput
          theme={theme}
          $selectedTheme={selectedTheme}
          ref={inputRef}
          width={112}
          value={inputValue}
          onChange={handleTagInput}
          onKeyDown={handleKeyDown}
          onBlur={e => handleTags(e, true)}
        />
      )}
    </TagsContainer>
  );
});

export { Tags };
