import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const CarSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const CarOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CarSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 17 14'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M13.6266 5.06667L12.4999 2.25733C12.1966 1.49867 11.4626 1.00133 10.6459 1H6.35394C5.53728 1.00133 4.80328 1.49867 4.49994 2.25733L3.37328 5.06667L2.5 4.00006H1M1.99998 10.2361V12.0001C1.99998 12.5521 2.44798 13.0001 2.99998 13.0001C3.55198 13.0001 3.99998 12.5521 3.99998 12.0001V11.0001M3.99998 11.0001H13M3.99998 11.0001C2.34332 11.0001 1 9.65661 1 7.99994C1 6.34327 2.34333 4.99994 4 4.99994H13C14.6567 4.99994 16 6.34327 16 7.99994C16 9.65661 14.6567 11.0001 13 11.0001M15 10.2361V12.0001C15 12.5521 14.552 13.0001 14 13.0001C13.448 13.0001 13 12.5521 13 12.0001V11.0001M2.99997 8H14M7.00005 4.99994L5.50005 7.99994M9.99986 4.99994L11.4999 7.99994M7.00014 8V11M9.99986 8V11M15.9998 4.00006H14.4998L13.6258 5.06606'
      />
    </CarSVG>
  );
});

export { CarOutlined };