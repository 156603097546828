import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { css, withTheme } from 'styled-components';

const calcWidth = props => {
  const { trackWidth, percent } = props;

  if (trackWidth && !isNaN(percent)) {
    return css`width: ${trackWidth * percent / 16}rem`;
  }

  return css`width: 0`;
};

const Track = styled('div')`
  width: 100%;
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: ${props => getColor(props, 'mediumGrey')};
`;

const Bar = styled('div')`
  ${props => calcWidth(props)};
  height: 100%;
  border-radius: 0.25rem;
  background-color: ${props => getColor(props, 'accent')};
  transition: width 0.2s ease;
`;

const FovProgressBar = withTheme(props => {
  const { theme, current, total } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const trackRef = useRef();
  const percent = current / total;

  return (
    <Track ref={trackRef} theme={theme} $selectedTheme={selectedTheme}>
      <Bar
        theme={theme}
        $selectedTheme={selectedTheme}
        percent={percent}
        trackWidth={trackRef.current?.clientWidth}
      />
    </Track>
  );
});

export { FovProgressBar };