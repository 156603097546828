import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import styled, { css, withTheme } from 'styled-components';
import { FilterIcon, MagnifyGlassIcon } from '../icons';

const Input = styled('input')`
  border: 0.0625rem solid
    ${props =>
      props.theme.hexToRgba(
        props.theme.colors[props.selectedTheme].onPrimary,
        0.2,
      )};
  color: ${props => props.theme.colors[props.selectedTheme].onPrimary};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: transparent;
  display: flex;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 1rem;
  padding-left: 2.5rem;
  margin: 0;
  width: ${props => (props.width / 16) + 'rem' || '100%'};

  &:focus {
    outline: ${props => props.theme.colors[props.selectedTheme].accent}
      solid 0.0625rem;
  }

  &::placeholder {
    color: ${props =>
      props.theme.hexToRgba(
        props.theme.colors[props.selectedTheme].onPrimary,
        0.25,
      )};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.colors[props.selectedTheme].lightGrey};
  }
`;

const SearchIconContainer = styled('div')`
  position: absolute;
  top: 0.5rem;
  left: 0.625rem;
`;

const Wrapper = styled('div')`
  width: 100%;
  position: relative;
  ${props => props.gridArea && css`grid-area: ${props.gridArea}`};
`;

const SearchInputField = withTheme(
  forwardRef(function SingleMessage(props, ref) {
    const { filter, placeholder, theme, width, onChange, gridArea, ...rest } = props;
    const userDataStore = useSelector(state => state.userData);
    const selectedTheme = userDataStore.userPreferences.theme;

    const renderIcon = () => {
      if (filter) {
        return <FilterIcon color={theme.colors[selectedTheme].mediumGrey} />;
      }

      return <MagnifyGlassIcon color={theme.colors[selectedTheme].mediumGrey} />;
    };

    return (
      <Wrapper gridArea={gridArea}>
        <Input
          ref={ref}
          type="text"
          selectedTheme={selectedTheme}
          placeholder={placeholder || 'Search'}
          onChange={onChange}
          width={width}
          {...rest}
        />
        <SearchIconContainer>
          {renderIcon()}
        </SearchIconContainer>
      </Wrapper>
    );
  }),
);

export { SearchInputField };
