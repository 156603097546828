import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme } from 'styled-components';

const Text = styled('h3')`
  color: ${props => props.color || getColor(props, 'onPrimary')};
  font-family: ${props => props.theme.typography.primary.regular};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.05rem;
`;

const H3 = withTheme(props => {
  const { children, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Text color={color} theme={theme} $selectedTheme={selectedTheme}>
      {children}
    </Text>
  );
});

export { H3 };