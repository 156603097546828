import { keyValueMirror } from 'store/store-functions';
import { addMessage, clearMessages } from './app';
import { getBase64StringFromDataURL, createBlobFromBase64String } from 'utils/helpers';
import { buildAuthUrl, buildRefreshURL } from 'navigation/navigationHelpers';
import { MESSAGE_TYPES } from 'components/notifications';
import { filterProtectedCreators } from 'components/generic/ProtectedContent';
import constants from 'constants';
import axios from 'axios';

const { ARCHVISION_TOKEN, ARCHVISION_REFRESH_TOKEN } = constants.TOKEN_NAMES;
const { VITE_API_HOST: apiHost, VITE_API_DEV_HOST: apiDevHost } = process.env;

export const actions = keyValueMirror(
  'GET_USER_DATA',
  'GET_CREATORS',
  'GET_USER_MODELS',
  'GET_USER_JOBS',
  'GET_HAS_USER_MODELS',
  'SET_MODELS_LOADING',
  'GET_CREATOR_USER',
  'ADD_TAG_FILTER',
  'ADD_CATEGORY_FILTER',
  'REMOVE_TAGS_FILTER',
  'SET_IS_AUTHORIZING',
  'RESET_IS_AUTHORIZING',
  'CLEAR_TAGS_FILTERS',
  'CLEAR_CATEGORY_FILTER',
  'CLEAR_FILTERS',
  'CLEAN_CREATOR_USER',
  'SET_USER_PREFERENCES_FROM_STORE',
  'SET_USER_PREFERENCES',
  'SET_TEMPORARY_ROLE',
);

export const getNewAccessToken = (refreshToken, clearTokens, saveTokens) => async dispatch => {
  try {
    const url = buildRefreshURL();
    const options = {
      method: 'POST',
      body: JSON.stringify({ refresh_token: refreshToken }),
      header: {
        'Content-Type': 'application/json',
      },
    };


    const res = await fetch(url, options);

    if (res.status === 404) {
      clearTokens?.();
      window.location.href = buildAuthUrl();
      throw new Error('Unable to refresh access token.');
    }

    const data = await res.json();

    clearTokens?.();
    saveTokens?.(data.access_token, data.refresh_token);
    dispatch(getUserData(data.access_token, clearTokens, saveTokens));
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };

    clearTokens?.();
    dispatch(clearMessages());
    dispatch(addMessage([ { message, config } ]));
    window.location.href = buildAuthUrl();
  }
};

export const getUserData = (token, clearTokens, saveTokens) => async dispatch => {
  try {
    const url = `${apiDevHost}/oauth/me`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('Unable to get user data.');
    }

    const payload = await res.json();
    const error = payload.type === 'Error' && payload.errorMessage;
    const refreshToken = localStorage.getItem(ARCHVISION_REFRESH_TOKEN);

    if (payload.active === false && refreshToken) {
      dispatch(
        getNewAccessToken(refreshToken, clearTokens, saveTokens),
      );
      throw new Error('Refreshing session...');
    }

    if ((payload.active === false && !refreshToken) || error) {
      // Invalidate auth and redirect to log in screen if token is invalid.
      dispatch(invalidateAuth(token, clearTokens, 'Unable to authenticate. Redirecting...'));
      throw new Error(error || 'Invalid token.');
    }

    const action = { type: actions.GET_USER_DATA, payload };
    dispatch(action);
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.warning, timer: 2.5 };

    dispatch(clearMessages());
    dispatch(addMessage([ { message, config } ]));
  }
};

export const getUserJobs = args => async dispatch => {
  const { token, onComplete, onError, userId } = args;

  let params = window.location.search;
  if(!params.includes('status')) {
    params += '&status=FAILED&status=QUEUED&status=PROCESSING&status=WAITING_FOR_FILE';
  }

  try {
    const url = `${apiDevHost}/users/${userId}/jobs${params}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);

    if (!res.ok) {
      throw new Error('Unable to fetch user models.');
    }

    const payload = await res.json();
    const incompleteJobs = payload.jobs.filter(job => job.status !== 'COMPLETED');

    const { currentPage, pageSize, filteredTotalJobs, statuses } = payload;

    const order = [ 'WAITING_FOR_FILE', 'QUEUED', 'PROCESSING', 'FAILED' ];
    const categories = statuses
      .filter(({ status }) => status !== 'COMPLETED') // Skip COMPLETED
      .map(({ status, count }) => ({
        categories: [],
        id: status,
        name: status,
        totalModels: count,
      }))
      .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

    const totalCount = categories.reduce((prev, category) => prev += category.totalModels, 0);

    const filteredResults = [
      {
        models: incompleteJobs,
        name: 'nameMainGroup',
        totalModels: incompleteJobs.length,
      },
    ];

    const userJobs = {
      filteredResults: filteredResults,
      tags: [],
      totalModels: totalCount,
      filteredTotalModels: filteredTotalJobs,
      currentPage,
      pageSize,
      allPartners: categories,
    };

    const action = { type: actions.GET_USER_JOBS, payload: userJobs };

    dispatch(action);
    onComplete?.();
  } catch (e) {
    onError?.();
    dispatch(
      addMessage(e.message, {
        type: MESSAGE_TYPES.ERROR,
        timer: 2.5,
      }),
    );
  }
};

export const getUserModels = args => async dispatch => {
  const { token, onComplete, onError, userId } = args;

  const params = window.location.search;

  try {
    const url = `${apiDevHost}/users/${userId}/models${params}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('Unable to fetch user models.');
    }

    const payload = await res.json();

    const { models, categoryCounts, tags, totalModels, filteredTotalModels, currentPage, pageSize } = payload;

    models.forEach(model => {
      const formats = {};
      model.formats.forEach(format => formats[format.key] = format.availability);
      model.formats = formats;
      model.status = 'COMPLETED';
    });

    const filteredResults = [
      {
        models,
        name: 'nameMainGroup',
        totalModels: models.length,
      },
    ];

    categoryCounts.forEach(category => {
      category.totalModels = category.count;
      delete category.count;
      category.id = category.name;

      category.subcategories.forEach(subcategory => {
        subcategory.id = `${category.name}_${subcategory.name}`;

        subcategory.totalCategoryModels = subcategory.count;
        subcategory.categoryName = subcategory.name;
        delete subcategory.count;
        delete subcategory.name;
      });

      category.categories = category.subcategories;
      delete category.subcategories;
    });

    const userModels = {
      filteredResults,
      tags,
      totalModels,
      filteredTotalModels,
      currentPage,
      pageSize,
      allPartners: categoryCounts,
    };

    const action = { type: actions.GET_USER_MODELS, payload: userModels };

    dispatch(action);
    onComplete?.();
  } catch (e) {
    onError?.();
    dispatch(
      addMessage(e.message, {
        type: MESSAGE_TYPES.ERROR,
        timer: 2.5,
      }),
    );
  }
};

export const getHasUserModels = args => async dispatch => {
  const { token, onComplete, onError, userId } = args;

  try {
    const url = `${apiDevHost}/users/${userId}/models?page=1&per_page=1`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);

    if (!res.ok) {
      throw new Error('Unable to fetch user models.');
    }

    const payload = await res.json();
    const hasUserModels = !!payload.models.length;

    const action = { type: actions.GET_HAS_USER_MODELS, payload: hasUserModels };

    dispatch(action);
    onComplete?.();
  } catch (e) {
    onError?.();
    dispatch(
      addMessage(e.message, {
        type: MESSAGE_TYPES.ERROR,
        timer: 2.5,
      }),
    );
  }
};

export const getCreators = args => async dispatch => {
  const { token, onComplete, onError, userRoles } = args;

  let params = window.location.search;
  // TODO: Fix replace per page by categories in search.
  if (!params.includes('per_page')) {
    params += '&per_page=25';
  }

  try {
    const url = `${apiDevHost}/models${params}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };


    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('Unable to fetch creator content.');
    }

    const responce = await res.json();
    const { models, totalModels, categoryCounts, filteredTotalModels, currentPage, pageSize, tags } = responce;

    models.forEach(model => {
      const formats = {};
      model.formats.forEach(format => formats[format.key] = format.availability);
      model.formats = formats;
    });

    const groupedModels = models.reduce((acc, model) => {
      const partner = acc[model.creator];
      if (partner) {
        partner.modelCount += 1;
        partner.models.push(model);

        const category = partner.categories.find(category => category.name === model.category);
        if (category) {
          category.categoryModelCount += 1;
        } else {
          partner.categories.push({
            name: model.category,
            categoryModelCount: 1,
          });
        }

        return acc;
      }

      acc[model.creator] = {
        partnerName: model.creator,
        modelCount: 1,
        models: [ model ],
        categories: [
          {
          name: model.category,
          categoryModelCount: 1,
          },
        ],
      };

      return acc;
    }, {});
    const filteredResults = Object.values(groupedModels);

    const tagsByPartner = filteredResults.map(partner => {
      const tagsSet = new Set(partner.models.flatMap(model => model.tags));
      return {
        name: partner.partnerName,
        tags: Array.from(tagsSet.keys()),
      };
    });

    categoryCounts.forEach(category => {
      category.totalModels = 0;
      category.categories.forEach(subcategory => {
        category.totalModels += subcategory.count;
        subcategory.id = `${category.creator}_${subcategory.name}`;

        subcategory.totalCategoryModels = subcategory.count;
        subcategory.categoryName = subcategory.name;
        delete subcategory.count;
        delete subcategory.name;
      });

      category.name = category.creator;
      delete category.creator;
    });

    const payload = {
      tags,
      filteredResults,
      totalModels,
      tagsByPartner,
      filteredTotalModels,
      currentPage,
      pageSize,
      allPartners: categoryCounts,
    };
    let roleFilteredData = {};

    if (userRoles) {
      // NOTE: Only for initial load when no params are present.
      // to make sure user is not seeing content they don't have permission to access.
      const allPartners = filterProtectedCreators(payload.allPartners, userRoles);
      const filteredResults = filterProtectedCreators(payload.filteredResults, userRoles);
      const tagsByPartner = payload.tagsByPartner;

      roleFilteredData = {
        ...payload,
        allPartners,
        filteredResults,
        tags: allPartners
          .map(partner => {
            const name = partner.name.toLowerCase();
            const tags = tagsByPartner.find(item => item.name.toLowerCase() === name)?.tags;
            return tags;
          })
          .flat()
          .filter(item => item),
        totalModels: allPartners.reduce((prev, current) => prev += current.totalModels, 0),
      };
    }

    const action = {
      type: actions.GET_CREATORS,
      payload: userRoles ? roleFilteredData : payload,
    };

    dispatch(action);
    onComplete?.();
  } catch (e) {
    onError?.();
    dispatch(
      addMessage(e.message, {
        type: MESSAGE_TYPES.ERROR,
        timer: 2.5,
      }),
    );
  }
};

export const getCreatorUser = (token, onComplete, onError) => async dispatch => {
  try {
    const url = `${apiHost}/creator/v1/integration/user`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('User not found.');
    }

    const payload = await res.json();
    const action = { type: actions.GET_CREATOR_USER, payload };

    dispatch(action);
    onComplete?.();
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };

    onError?.();
    dispatch(addMessage([ { message, config } ]));
  }
};

export const invalidateAuth = (token, clearTokens, customMessage) => async dispatch => {
  try {
    const url = `${apiHost}/oauth/v1/invalidate`;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        redirect_uri: buildAuthUrl(),
        access_token: token,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const message = customMessage || 'Logging out...';
    const config = {
      type: MESSAGE_TYPES.loading,
      showModal: true,
      closeButton: false,
    };

    dispatch(cleanCreatorUser());
    dispatch(addMessage([ { message, config } ]));

    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('Invalidation call path not found.');
    }
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };

    dispatch(clearMessages());
    dispatch(addMessage([ { message, config } ]));
  } finally {
    dispatch(resetIsAuthorizing());
    clearTokens?.();
    localStorage.removeItem(ARCHVISION_TOKEN);
    localStorage.removeItem(ARCHVISION_REFRESH_TOKEN);
    window.location.href = buildAuthUrl();
  }
};

export const addCategoryFilter = category => ({
  type: actions.ADD_CATEGORY_FILTER,
  payload: category,
});

export const addTagFilter = tag => ({
  type: actions.ADD_TAG_FILTER,
  payload: tag,
});

export const removeTagFilter = tag => ({
  type: actions.REMOVE_TAGS_FILTER,
  payload: tag,
});

export const clearCategoryFilter = () => ({
  type: actions.CLEAR_CATEGORY_FILTER,
});

export const clearTagsFilters = () => ({
  type: actions.CLEAR_TAGS_FILTERS,
});

export const clearFilters = () => ({
  type: actions.CLEAR_FILTERS,
});

export const cleanCreatorUser = () => ({
  type: actions.CLEAN_CREATOR_USER,
  payload: null,
});

export const setIsAuthorizing = () => ({
  type: actions.SET_IS_AUTHORIZING,
});

export const resetIsAuthorizing = () => ({
  type: actions.RESET_IS_AUTHORIZING,
});

export const setUserPreferences = value => ({
  type: actions.SET_USER_PREFERENCES,
  payload: value,
});

export const setUserPreferencesFromStore = () => {
  const value = localStorage.getItem(constants.FOV_USER_PREFERENCES);
  return {
    type: actions.SET_USER_PREFERENCES_FROM_STORE,
    payload: value ? JSON.parse(value) : {},
  };
};

export const setTemporaryRole = payload => ({
  type: actions.SET_TEMPORARY_ROLE,
  payload,
});

export const updateModelPreviewImage = args => async dispatch => {
  const { token, guid, payload, onComplete, userId } = args;

  try {
    const url = `${apiHost}/rpc/v1/integration/${guid}/preview-image`;
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data?.message || 'Unable to update metadata.');
    }

    const previewImage = payload?.previewImage;
    const base64String = getBase64StringFromDataURL(previewImage);
    const blob = createBlobFromBase64String(base64String);
    const previewUpdateUrl = data?.url;
    const previewUpdateOptions = {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };

    if (base64String) {
      await axios.put(previewUpdateUrl, blob, previewUpdateOptions);
    }

    dispatch(getUserModels({ token, ignoreLoading: true, userId }));
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  } finally {
    onComplete?.();
  }
};

export const updateModelMetadata = args => async dispatch => {
  const { token, guid, payload, onComplete, userId } = args;

  try {
    const url = `${apiDevHost}/models/${guid}`;
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    const res = await fetch(url, options);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data?.message || 'Unable to update metadata.');
    }

    dispatch(getUserModels({ token, userId, ignoreLoading: true }));
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  } finally {
    onComplete?.();
  }
};