import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SampleCard from './SampleCard';
import warehouseThumb from 'assets/welcome/warehouseThumb.png';
import sketchfabThumb from 'assets/welcome/sketchfabThumb.png';
import turboSquidThumb from 'assets/welcome/turboSquidThumb.png';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { VerticalSpacer, HorizontalSpacer, FovDivider, DocNote } from 'components';
import { MODEL_TYPES } from 'utils';
import { setShowOnBoarding } from 'store/actions/app';
import {
  Welcome,
  Sections,
  Section,
  SectionTitle,
  GetStartedTitle,
  CTA,
  TitleFiller,
  SampleProjects,
} from './onBoardingStyles';
import _ from 'lodash';

const OnBoarding = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const userModels = useMemo(() => userDataStore.userModels, [ userDataStore.userModels ]);
  const selectedTheme = userDataStore.userPreferences.theme;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setShowOnBoarding(false));
  }, []);

  const handleClick = () => {
    _.isEmpty(userModels) ? navigate('/create') : navigate(-1);
    dispatch(setShowOnBoarding(false));
  };

  return (
    <Welcome theme={theme} $selectedTheme={selectedTheme}>
      <VerticalSpacer size={38} />

      <GetStartedTitle>
        Welcome to FOVEA
      </GetStartedTitle>

      <VerticalSpacer size={20} />

      <p>
        FOVEA helps you transform images and 3D models into rich, platform-agnostic, content known as RPCs.
        RPCs can be used with direct integrations or plugins for Revit, 3DS Max, AutoCAD, Rhino, SketchUp, and
        FormZ. Your RPCs can also be used as native 3D content for applications like V-Ray, Enscape,
        Twinmotion, and Lumion.
      </p>

      <VerticalSpacer size={90} />

      <Sections>
        <Section>
          <SectionTitle
            theme={theme}
            $selectedTheme={selectedTheme}
          >
            Sample Files

            <TitleFiller>
              <FovDivider height={2} />
            </TitleFiller>
          </SectionTitle>

          <VerticalSpacer size={20} />

          <p>
            Get started with these three 3D models. You have many choices for getting 3D models online,
            we selected these models from&nbsp;
            <Link to='//turbosquid.com' rel='noreferrer' target='_blank'>TurboSquid</Link>,&nbsp;
            <Link to='//sketchfab.com' rel='noreferrer' target='_blank'>Sketchfab</Link>, and&nbsp;
            <Link to='//3dwarehouse.sketchup.com' rel='noreferrer' target='_blank'>3D Warehouse</Link>.
          </p>

          <VerticalSpacer size={25} />

          <SampleProjects>
            <DocNote path={[ 'sampleFiles' ]} right={-25} top={-35} />

            <SampleCard
              type={MODEL_TYPES.threeD}
              title='TurboSquid'
              image={turboSquidThumb}
              learnText={null}
              learnLink='http://blog.archvision.com/create-an-rpc-from-a-turbosquid-model'
              guid='21-7A71-D51C-AB8A-439F-9ACE-1B6D-65BD-BD89-00'
            />

            <SampleCard
              type={MODEL_TYPES.threeD}
              title='Sketchfab'
              image={sketchfabThumb}
              learnText={null}
              learnLink='http://blog.archvision.com/create-an-rpc-from-any-sketchfab-model'
              guid='21-B8E2-2FF5-3420-1BB0-0014-0222-3E62-9FEC-00'
            />

            <SampleCard
              type={MODEL_TYPES.threeD}
              title='3D Warehouse'
              image={warehouseThumb}
              learnText={null}
              learnLink='http://blog.archvision.com/create-an-rpc-from-any-3dwharehouse-model'
              guid='21-AF0D-2610-2E4C-CB1D-4E4D-A5EF-A527-0478-00'
            />
          </SampleProjects>
        </Section>

        <HorizontalSpacer size={110} />

        <Section>
          <SectionTitle
            theme={theme}
            $selectedTheme={selectedTheme}
          >
            Overview

            <TitleFiller>
              <FovDivider height={2} />
            </TitleFiller>
          </SectionTitle>

          <VerticalSpacer size={20} />

          <p>
            FOVEA enables you to upload, configure, and publish models in a
            simple cloud-based interface. You can build RPCs by uploading RPC,
            FBX, OBJ and GLTF or GLB files. Configuring your models could be as
            simple as adding metadata or as complex as optimizing geometry on a
            per material basis. &mdash;&nbsp;
            <Link to="/documentation/overview" rel="noreferrer" target="_blank">
              Learn more
            </Link>.
          </p>

          <p>
            You can also publish models to FOVEA through our exporters. Exporters are
            available for 3DS Max, Rhino and Blender.
          </p>

          <VerticalSpacer size={40} />

          <CTA
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={handleClick}
          >
            {_.isEmpty(userModels) ? 'Open your first file' : 'Return to content'}
          </CTA>
        </Section>
      </Sections>

    </Welcome>
  );
});

export default OnBoarding;