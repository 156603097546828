import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const EditSVG = styled('svg')`
  color: ${props => {
    return props.color || 'inherit';
  }};

  &:hover {
    color: ${props => props.hoverColor};
  }
`;

const HighlightOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <EditSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      color={color}
      hoverColor={hoverColor}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 32}
      height={height || 32}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path
          fill="none"
          strokeDasharray="44"
          strokeDashoffset="0"
          strokeWidth="2"
          d="M6 14L17 3L21 7L10 18L6 14Z"
        />
        <path
          fill="currentColor"
          fillOpacity="1"
          strokeDasharray="20"
          strokeDashoffset="0"
          d="M9 17L6.5 19.5H2.5L7 15Z"
        />
      </g>
    </EditSVG>
  );
});

export { HighlightOutlined };
