import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  VerticalSpacer,
  ArrowLeftOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import {
  AvailImage,
  Caption,
  ExampleElement,
  PipelineTargets,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import Summary from './Summary';
import RPCLogo from 'assets/documentation/UsingRPC/RPCLogo';
import availLogo from 'assets/branding/availLogo.png';

const UsingRPC = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;
  const summary = [
    'RPC is Rich Photorealistic Content with support in multiple applications.',
    'Render in: Enscape, V-Ray, Arnold and Corona.',
    'Use in: AVAIL, Revit, Rhino, SketchUp, 3DS Max, AutoCAD and formZ.',
    'Convert for: Twinmotion, Unreal Engine, V-Ray plugins and SketchUp.',
    'Convert to a number of different universally supported formats.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='What is an RPC?'>
        <p>
          <strong>RPC</strong> stands for Rich Photorealistic Content.
          RPC technology is application agnostic with support in multiple 3D architecture and modeling applications.
        </p>

        <VerticalSpacer size={30} />

        <ImageContainer>
          <RPCLogo />
        </ImageContainer>

        <ImageContainer>
          <AvailImage background={availLogo}  />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          AVAIL is used as the primary application for managing and accessing RPC
          content in a desktop environment. <br />You can download AVAIL&nbsp;
          <Link to='//getavail.com' rel='noreferrer' target='_blank'>here</Link>.
        </Caption>

        <VerticalSpacer size={15} />
      </DocSection>



      <DocSection section='Where to use RPC'>
        <DocSection subsection='Supported rendering engines'>
          <PipelineTargets>
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Enscape
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              V-Ray
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Arnold
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Corona
            </ExampleElement>
          </PipelineTargets>
        </DocSection>



        <DocSection subsection='Supported applications using RPC'>
          <PipelineTargets>
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              RPC &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; AVAIL
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              RPC &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; Revit
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              RPC &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; Rhino
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              RPC &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; SketchUp
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              RPC &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; 3DS Max
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              RPC &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; AutoCAD
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              RPC &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; formZ
            </ExampleElement>
          </PipelineTargets>

          <VerticalSpacer size={15} />

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            If you already have an ArchVision account, you can download the plugins for RPC&nbsp;
            <Link to='//archvision.com/downloads/' rel='noreferrer' target='_blank'>here</Link>.
          </Caption>
        </DocSection>



        <DocSection subsection='Supported applications through conversion'>
          <PipelineTargets>
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Datasmith &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; Twinmotion
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Datasmith &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; Unreal Engine
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              VRScene &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; V-Ray Plugins
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              Enscape &nbsp;<ArrowLeftOutlined flipIcon={true} />&nbsp; SketchUp
            </ExampleElement>
          </PipelineTargets>

          <VerticalSpacer size={30} />
        </DocSection>



        <DocSection subsection='Universal formats'>
          <p>
            The following are universal formats that can be imported into multiple applications.
          </p>

          <VerticalSpacer size={15} />

          <PipelineTargets>
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              FBX
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              OBJ
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              DAE
            </ExampleElement>

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              USDZ
            </ExampleElement>
          </PipelineTargets>

          <VerticalSpacer size={30} />
        </DocSection>
      </DocSection>
    </ContentContainer>
  );
});

export { UsingRPC };