import React from 'react';

const CarLeftSideIcon = props => {
  const { width, height } = props;

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill='currentColor'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 4.99994H3C1.9 4.99994 1 5.88994 1 6.99994V15.9999H3C3 17.6599 4.34 18.9999 6 18.9999C7.66 18.9999 9 17.6599 9 15.9999H15C15 17.6599 16.34 18.9999 18 18.9999C19.66 18.9999 21 17.6599 21 15.9999H23V10.9999L17 4.99994ZM15 6.99994H16L19 9.99994H15V6.99994ZM9 6.99994H13V9.99994H9V6.99994ZM3 6.99994H7V9.99994H3V6.99994ZM6 17.2499C5.31 17.2499 4.75 16.6899 4.75 15.9999C4.75 15.3099 5.31 14.7499 6 14.7499C6.69 14.7499 7.25 15.3099 7.25 15.9999C7.25 16.6899 6.69 17.2499 6 17.2499ZM18 17.2499C17.31 17.2499 16.75 16.6899 16.75 15.9999C16.75 15.3099 17.31 14.7499 18 14.7499C18.69 14.7499 19.25 15.3099 19.25 15.9999C19.25 16.6899 18.69 17.2499 18 17.2499ZM21 13.9999H20.22C19.67 13.3899 18.88 12.9999 18 12.9999C17.12 12.9999 16.33 13.3899 15.78 13.9999H8.22C7.67 13.3899 6.89 12.9999 6 12.9999C5.11 12.9999 4.33 13.3899 3.78 13.9999H3V11.9999H21V13.9999Z" />
    </svg>
  );
};

export { CarLeftSideIcon };
