import React from 'react';
import { useSelector } from 'react-redux';
import { FovTypography } from 'components/typography';
import styled, { withTheme } from 'styled-components';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

const LoaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.95rem;
  color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return props.theme.colors[props.$selectedTheme][lightTheme ? 'darkGrey' : 'lightGrey'];
  }};
`;

const LoaderSVG = styled('svg')`
  color: ${props => (
    props.$color || props.theme.colors[props.$selectedTheme].accent
  )};
`;

const buildLoader = args => {
  const { theme, selectedTheme, width, height, text, $color } = args;

  return (
    <>
      <LoaderSVG
        theme={theme}
        $selectedTheme={selectedTheme}
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width={width || 42}
        height={height || 42}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        $color={$color}
        fill="currentColor"
      >
          <circle cx="18" cy="12" r="0">
            <animate
              attributeName="r"
              begin=".67"
              calcMode="spline"
              dur="1.5s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0" />
          </circle>

          <circle cx="12" cy="12" r="0" fill="currentColor">
            <animate
              attributeName="r"
              begin=".33"
              calcMode="spline"
              dur="1.5s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0" />
          </circle>

          <circle cx="6" cy="12" r="0" fill="currentColor">
            <animate
              attributeName="r"
              begin="0"
              calcMode="spline"
              dur="1.5s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0" />
          </circle>
      </LoaderSVG>

      {text && (
        <FovTypography>
          {text}
        </FovTypography>
      )}
    </>
  );
};

const FovLoader = withTheme(props => {
  const { resource, text, width, height, children, theme, $color, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const args = { theme, selectedTheme, width, height, text, $color };

  return (
    <>
      {!resource && (
        <LoaderContainer
          theme={theme}
          $selectedTheme={selectedTheme}
          {...rest}>
          {buildLoader(args)}
        </LoaderContainer>
      )}

      {resource && children}
    </>
  );
});

export { FovLoader };