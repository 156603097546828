import { VerticalSpacer } from 'components/layout';
import React from 'react';
import styled, { css } from 'styled-components';

export const ImageWrapper = styled('div')`
  display: inline-flex;
  ${props => props.column && css`flex-direction: column;`}
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ImageContainer = props => {
  const { noSpace, column, children } = props;

  return(
    <>
      <ImageWrapper column={column}>
        {children}
      </ImageWrapper>

      {!noSpace && <VerticalSpacer size={15} />}
    </>
  );
};

export { ImageContainer };