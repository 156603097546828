import React from 'react';
import constants from 'constants/index';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const light = constants.THEME.LIGHT;

const Zoom = withTheme(props => {
  const { width, height, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  const isLight = selectedTheme === light;
  const accent = theme.colors[selectedTheme][isLight ? 'accent' : 'white'];
  const fill = color || theme.colors[selectedTheme][isLight ? 'onPrimary' : 'mediumGrey'];

  return (
    <svg
      width={width || 16}
      height={height || 22}
      viewBox="0 0 16 22"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C12.4 0 15.96 3.54 16 7.93V13.93C16 18.35 12.42 21.93 8 21.93C3.58 21.93 0 18.35 0 13.93V7.93C0.04 3.54 3.6 0 8 0ZM14 13.93C14 17.24 11.31 19.93 8 19.93C4.69 19.93 2 17.24 2 13.93V7C2.4261 4.4895 4.49252 2.5094 7 2.09V7H9V2.09C11.5075 2.5094 13.5739 4.4895 14 7V13.93Z"
      />
      <path fill={accent} d="M7 0H9V11H7V0Z" />
    </svg>
  );
});

export { Zoom };
