import { getColor } from 'utils';
import constants from 'constants/index';
import styled from 'styled-components';

const { THEME } = constants;
const light = THEME.LIGHT;

const getDisabledColor = props => {
  const lightTheme = props.$selectedTheme === light;
  const accent = getColor(props, 'accent');
  const darkGrey = getColor(props, 'darkGrey');
  const lightGrey = getColor(props, 'lightGrey');
  const disabledColor = lightTheme ? darkGrey : lightGrey;

  return props.disabled ? disabledColor : accent;
};

export const MainSliderContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SliderContainer = styled('div')`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 2.695625rem;
`;

export const InputContainer = styled('div')`
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const SliderTrack = styled('div')`
  width: 100%;
  height: 0.25rem;
  border-radius: 0.375rem;
  background: ${props => getColor(props, 'mediumGrey')};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const SliderThumb = styled('div')`
  min-width: 1rem;
  min-height: 1rem;
  border-radius: 50%;
  background: ${props => getColor(props, 'primary')};
  border: 0.125rem solid ${props => getDisabledColor(props)};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${props => props.xPosition ?? 0}%;
`;

export const SliderFilled = styled('div')`
  height: 100%;
  border-radius: inherit;
  background: ${props => getDisabledColor(props)};
  width: ${props => props.width ?? 0}%;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const SliderEmpty = styled('div')`
  height: 100%;
  border-radius: inherit;
  background: ${props => getColor(props, 'mediumGrey')};
  width: ${props => props.width ?? 0}%;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const SliderLabel = styled('div')`
  position: absolute;
  top: -0.5rem;
  left: 0;
  color: ${props => getColor(props, 'onPrimary')};
`;

export const FloatingValue = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: -2rem;
  left: ${props => props.xPosition - 5 ?? 0}%;
  min-width: 2.5rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background: ${props => getColor(props, 'black') + 99};
  opacity: ${props => props.showFloatingValue ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
`;