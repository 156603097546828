import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme, css } from 'styled-components';
import constants from 'constants/index';

const light = constants.THEME.LIGHT;

export const Option = styled('option')`
  color: ${props => {
    const disabled = props.disabled;
    return props.theme.colors[props.$selectedTheme][disabled ? 'mediumGrey' : 'onPrimary'];
  }};
  background-color: ${props => (
    props.theme.colors[props.$selectedTheme].primary
  )};
  padding-left: ${props => props.indent ? 2 : 1}rem;
  font-weight: ${props => props.groupParent && 'bold'};

  &:disabled {
    color: ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'darkGrey' : 'lightGrey');
    }};
  }

  &:after {
    ${props => css`content: "${props.after || ''}"`};
    text-align: right;
    float: right;
    color: ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'darkGrey' : 'lightGrey');
    }};
    padding-right: 1rem;
  }
`;

const FovOption = withTheme(props => {
  const { theme, children, indent, groupParent, after, ...rest } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Option
      theme={theme}
      $selectedTheme={selectedTheme}
      indent={indent}
      groupParent={groupParent}
      after={after}
      {...rest}
    >
      {children}
    </Option>
  );
});

export { FovOption };