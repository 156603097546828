import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const ArrowLeftSVG = styled('svg')`
  transform: rotate(${props => props.flipIcon ? '180deg' : '0deg'});
`;

const ArrowLeftOutlined = withTheme(props => {
  const { width, height, flipIcon, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const onPrimary = theme.colors[selectedTheme].onPrimary;

  return (
    <ArrowLeftSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      flipIcon={flipIcon}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path fill={onPrimary} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 16l-4-4m0 0l4-4m-4 4h18" />
    </ArrowLeftSVG>
  );
});

export { ArrowLeftOutlined };
