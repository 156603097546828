import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme, css } from 'styled-components';

const Text = styled('div')`
  position: relative;
  color: ${props => props.color || getColor(props, 'onPrimary')};
  font-size: 1rem;
  font-family: ${props => props.theme.typography.primary.regular};
  font-weight: 400;
  width:100%;
  line-height: 150%;
  ${props =>
    props.size === 'Big' &&
    css`
      font-size: 1.125rem;
    `}
  ${props =>
    props.size === 'Bold' &&
    css`
      font-weight: 700;
    `}
  ${props =>
    props.size === 'Medium' &&
    css`
      font-size: 0.95rem;
  `}
  ${props =>
    props.size === 'Medium Bold' &&
    css`
      font-size: 1rem;
      font-weight: 700;
  `}
  ${props =>
    props.size === 'Small' &&
    css`
      font-size: 0.875rem;
    `}
  ${props =>
    props.size === 'Small Bold' &&
    css`
      font-size: 0.875rem;
      font-weight: 700;
    `}
  ${props =>
    props.size === 'X-Small' &&
    css`
      font-size: 0.75rem;
    `}
  ${props =>
    props.size === 'X-Small Bold' &&
    css`
      font-weight: 700;
      font-size: 0.75rem;
    `}
  ${props =>
    props.size === 'Big Bold' &&
    css`
      font-weight: 500;
      font-size: 1.25rem;
    `}
`;

const Body = withTheme(props => {
  const { theme, children, color, size } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <Text
      color={color}
      theme={theme}
      selectedTheme={selectedTheme}
      size={size}
    >
      {children}
    </Text>
  );
});

export { Body };
