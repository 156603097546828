import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const TableSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const TableOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <TableSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 18 11'
    >
      <path fill='currentColor' d='M17.4184 0H0.581633C0.260416 0 0 0.260417 0 0.581633C0 0.902849 0.260416 1.16327 0.581633 1.16327H1.12496V9.49992C1.12496 9.82114 1.38537 10.0816 1.70659 10.0816C2.0278 10.0816 2.28822 9.82114 2.28822 9.49992V6.97951H15.6735V9.49992C15.6735 9.82114 15.9339 10.0816 16.2551 10.0816C16.5764 10.0816 16.8368 9.82114 16.8368 9.49992V1.16327H17.4184C17.7396 1.16327 18 0.902849 18 0.581633C18 0.260417 17.7396 0 17.4184 0ZM15.6735 5.81625H2.28822V3.48964H15.6735V5.81625ZM15.6735 2.32634H2.28822V1.16327H15.6735V2.32634Z' />
    </TableSVG>
  );
});

export { TableOutlined };