import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { getColor } from 'utils';

const CaretSVG = styled('svg')`
  color: ${props => props.color || getColor(props, 'onPrimary')};
`;

const DownOutlined = withTheme(props => {
  const { width, height, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CaretSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 256 256"
    >
      <path fill='currentColor'
        d="M128 188a12.2 12.2 0 0 1-8.5-3.5l-80-80a12 12 0 0 1 17-17L128 159l71.5-71.5a12 12 0 0 1 17 17l-80 80a12.2 12.2 0 0 1-8.5 3.5Z"
      />
    </CaretSVG>
  );
});

export { DownOutlined };