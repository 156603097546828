import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import { Collapse } from 'components';
import constants from 'constants/index';
import styled, { withTheme } from 'styled-components';

const headerHeight = constants.HEADER_HEIGHT;
const footerHeight = constants.FOOTER_HEIGHT;
const offset = headerHeight + footerHeight;
const light = constants.THEME.LIGHT;

const DrawerContainer = styled('div')`
  width: ${props => props.isOpen ? '22.75rem' : 0};
  height: calc(100vh - ${offset / 16}rem);
  position: absolute;
  top: ${headerHeight / 16}rem;
  right: ${props => props.isOpen ? 0 : '-5rem'};
  padding: 1rem;
  border-left: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  background-color: ${props => getColor(props, 'primary')};
  z-index: 1;
  overflow-y: auto;
  box-shadow: -0.25rem 0.25rem 0.5rem ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'black');
  }};
  transition: width 0.2s ease;
`;

const StyledDrawer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CollapseContainer = styled('div')`
  position: absolute;
  top: 0.5rem;
  right: 0;
  z-index: 1;
`;

const FovDrawer = withTheme(props => {
  const { theme, closeDrawer, isOpen, children } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const mediumGrey = theme.colors[selectedTheme].mediumGrey;

  return (
    <DrawerContainer
      theme={theme}
      $selectedTheme={selectedTheme}
      isOpen={isOpen}
    >
      <StyledDrawer>
        <CollapseContainer onClick={closeDrawer}>
          <Collapse color={mediumGrey} rotate={180} />
        </CollapseContainer>
        {children}
      </StyledDrawer>
    </DrawerContainer>
  );
});

export { FovDrawer };