import { keyValueMirror } from 'store/store-functions';

export const actions = keyValueMirror(
  'SET_SERIALIZED_DATA',
  'CLEAR_SERIALIZED_DATA',
  'SET_UPDATE_DATA',
  'CLEAR_UPDATE_DATA',
  'SET_REINITIALIZE_CANVAS',
  'SET_EXPORT_MODEL',
  'SET_EXPORTED_MODEL_DATA',
  'SET_BILLBOARD_IMAGES',
  'SET_GUID_URL',
);

export const setSerializedData = payload => ({
  type: actions.SET_SERIALIZED_DATA,
  payload: typeof payload === 'string' ? JSON.parse(payload) : payload,
});

export const clearSerializedData = () => ({
  type: actions.CLEAR_SERIALIZED_DATA,
});

export const setUpdateData = payload => ({
  type: actions.SET_UPDATE_DATA,
  payload,
});

export const clearUpdateData = () => ({
  type: actions.CLEAR_UPDATE_DATA,
});

export const setReinitializeCanvas = payload => ({
  type: actions.SET_REINITIALIZE_CANVAS,
  payload,
});

export const setGuidURL = payload => ({
  type: actions.SET_GUID_URL,
  payload,
});

export const setExportModel = payload => ({
  type: actions.SET_EXPORT_MODEL,
  payload,
});

export const setExportedModelData = payload => ({
  type: actions.SET_EXPORTED_MODEL_DATA,
  payload,
});

export const setBillboardImages = payload => ({
  type: actions.SET_BILLBOARD_IMAGES,
  payload,
});