import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTheme } from 'styled-components';
import {
  VerticalSpacer,
  FovDivider,
  FovButton,
  FovSelect,
  Body,
  FovConfirmation,
  DocNote,
  FovOption,
  CarLeftSideIcon,
  CarBackSideIcon,
  CarRightSideIcon,
  CarTopSideIcon,
  CarFrontSideIcon,
  FovModal,
  FovInput,
} from 'components';
import {
  ViewButton,
  SectionContainer,
  ViewButtonContainer,
  CameraViewButton,
} from './CameraStyles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';
import _ from 'lodash';


const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS, ORTHO } = constants;

export const ratios = {
  square: { name: 'square', label: '1:1', value: 1 },
  broadcast: { name: 'broadcast', label: '4:3', value: 1.33 },
  cinema: { name: 'cinema', label: '16:9', value: 1.77 },
};

const Camera = withTheme(props => {
  const { theme } = props;
  const [ confirmationVisible, setConfirmationVisible ] = useState(false);
  const [ nameModalVisible, setNameModalVisible ] = useState(false);
  const [ nameInputValue, setNameInputValue ] = useState('');
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const selectedTheme = userDataStore.userPreferences.theme;
  const errorColor = theme.colors[selectedTheme].error;
  const errorHoveredColor = theme.colors[selectedTheme].errorHover;
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const selectedCameraView = serializedData?.metadata.selectedOrthoView;
  const selectedUserCameraView = serializedData?.metadata.selectedUserCameraView;
  const userCameraViews = serializedData?.metadata.userCameraViews || [];
  const defaultCameraView = serializedData?.metadata.defaultCameraPosition;
  const selectedRatio = serializedData?.metadata.selectedRatio;
  const safeFrameEnabled = serializedData?.metadata.safeFrameEnabled;
  const dispatch = useDispatch();

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.CAMERA,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const addArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.ADD };
  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const customCameraOptions = () => {
    const cameraViews = [
      <FovOption key='default' value={defaultCameraView}>
        Default
      </FovOption>,
    ];

    userCameraViews.forEach((item, index) => {
      const { id } = item;

      cameraViews.push(
        <FovOption key={index} value={id}>
          {id}
        </FovOption>,
      );
    });

    return cameraViews;
  };

  const renderViewButtons = () => {
    const buttonList = [
      {
        value: ORTHO.LEFT,
        icon: <CarLeftSideIcon />,
      },
      {
        value: ORTHO.RIGHT,
        icon: <CarRightSideIcon />,
      },
      {
        value: ORTHO.FRONT,
        icon: <CarFrontSideIcon />,
      },
      {
        value: ORTHO.BACK,
        icon: <CarBackSideIcon />,
      },
      {
        value: ORTHO.TOP,
        icon: <CarTopSideIcon />,
      },
    ];

    return buttonList.map((button, key) => (
      <ViewButton
        $selected={selectedCameraView === button.value}
        theme={theme}
        $selectedTheme={selectedTheme}
        key={key}
        text={button.icon}
        callback={() => updateCanvas(updateArgs, { orthoCamera: button.value })}
      />
    ));
  };

  const renderPhotoButtons = () => {
    return Object.values(ratios).map((ratio, index) => (
      <ViewButton
        $selected={safeFrameEnabled && selectedRatio.value === ratio.value}
        theme={theme}
        $selectedTheme={selectedTheme}
        key={index}
        text={ratio.label}
        callback={() => {
          const enableSafeFrame = selectedRatio.value !== ratio.value || !safeFrameEnabled;
          updateCanvas(updateArgs, { enableSafeFrame, aspectRatio: ratio.value });
        }}
      />
    ));
  };

  const nameModal = () => {
    const reservedNames = [ 'default', 'front', 'back', 'left', 'right', 'top', 'bottom' ];
    const nameVal = nameInputValue.toLowerCase().trim();
    const onModalClose = () => {
      setNameModalVisible(false);
      setNameInputValue('');
    };

    const nameIsReserved = () => {
      const reservedNameUsed = reservedNames.includes(nameVal);
      const nameExists = userCameraViews.some(view => view?.id?.toLowerCase() === nameVal);

      return reservedNameUsed || nameExists;
    };

    return (
      <FovModal
        visible={nameModalVisible}
        title="Add Custom View"
        onClose={onModalClose}
        noOverflow={true}
      >
        Name
        <VerticalSpacer size={20} />

        <FovInput
          value={nameInputValue}
          onChange={e => setNameInputValue(e.target.value)}
        />

        {nameIsReserved() && (
          <>
            <VerticalSpacer size={15} />

            <Body size="Small" color={errorColor}>
              {nameInputValue}, is already in use.
            </Body>
          </>
        )}

        <VerticalSpacer size={20} />

        <FovButton
          text="Save camera view"
          disabled={nameIsReserved() || _.isEmpty(nameInputValue)}
          callback={() => {
            updateCanvas(addArgs, { id: nameInputValue });
            onModalClose();
          }}
        />
      </FovModal>
    );
  };

  return (
    <>
      <VerticalSpacer size={15} />
      <SectionContainer>
        <Body size='Medium Bold'>
          Orthographic views
          <DocNote path={[ 'cameraPanel', 'orthographicViews' ]} top={-2} left={155} />
        </Body>

        <VerticalSpacer size={15} />
        <ViewButtonContainer>{renderViewButtons()}</ViewButtonContainer>
        <VerticalSpacer size={40} />
        <FovDivider />
      </SectionContainer>

      <VerticalSpacer size={15} />

      <SectionContainer>
        <Body size='Medium Bold'>
          Saved views
          <DocNote path={[ 'cameraPanel', 'savedViews' ]} top={-2} left={103} />
        </Body>

        <VerticalSpacer size={15} />

        <FovSelect
          value={selectedUserCameraView?.id || ''}
          placeholder={selectedUserCameraView?.id || 'Select a view'}
          onChange={(e, newVal) => {
            const updateView =  userCameraViews.find(view => view.id === newVal);
            let inboundData = { resetCamera: true };

            if (updateView) {
              const { id, ...rest } = updateView;
              inboundData = { id, transforms: rest };
            }

            updateCanvas(updateArgs, inboundData);
          }}
        >
          {customCameraOptions()}
        </FovSelect>

        <VerticalSpacer size={15} />

        <FovButton
          text='Add camera view'
          callback={() => setNameModalVisible(true)}
        />

        <VerticalSpacer size={15} />

        <FovButton
          text='Reset current position'
          callback={() => updateCanvas(updateArgs, { resetCamera: true })}
        />

        <VerticalSpacer size={15} />

        <FovButton
          text='Delete view'
          color={errorColor}
          hoverColor={errorHoveredColor}
          disabled={userCameraViews.length === 0}
          callback={() => setConfirmationVisible(true)}
        />
      </SectionContainer>

      <VerticalSpacer size={40} />
      <FovDivider />
      <VerticalSpacer size={15} />

      <SectionContainer>
        <Body size='Medium Bold'>
          Screenshot
          <DocNote path={[ 'cameraPanel', 'screenshot' ]} top={-2} left={95} />
        </Body>

        <VerticalSpacer size={15} />
        <ViewButtonContainer>
          {renderPhotoButtons()}
          <CameraViewButton
            text='Take screenshot'
            callback={() => {
              const inboundData = {
                takeScreenshot: true,
                aspectRatio: selectedRatio.value || ratios.broadcast.value,
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />
        </ViewButtonContainer>
        <VerticalSpacer size={40} />
      </SectionContainer>

      <VerticalSpacer size={15} />

      <FovConfirmation
        onConfirm={() => updateCanvas(removeArgs, { id: selectedUserCameraView.id })}
        onClose={() => setConfirmationVisible(false)}
        confirmationText='Remove camera'
        isOpened={confirmationVisible}
        confirmColor={errorColor}
        confirmHoverColor={errorHoveredColor}
      >
        <Body size='Medium'>
          {`Remove ${selectedUserCameraView?.id}?`}
        </Body>
      </FovConfirmation>

      {nameModal()}
    </>
  );
});

export default Camera;