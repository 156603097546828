import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const AuthRoute = props => {
  const { hasAccess, redirect, from } = props;

  const pathname = () => {
    if (!hasAccess) {
      return '/no-access';
    }

    return redirect;
  };

  const state = { from };

  return hasAccess
    ? <Outlet />
    : <Navigate to={pathname()} state={state} />;
};

export { AuthRoute };