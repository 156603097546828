import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FovSelect, FovOption, FovButton, VerticalSpacer } from 'components';
import { updateCanvas } from 'utils';
import constants from 'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Axis = () => {
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);

  const selectedAxisCompensation = serializedData?.metadata.selectedAxisCompensation;

  const yAxisUp = 'Y-Axis up';
  const zAxisUp = 'Z-Axis up';

  const dispatch = useDispatch();

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MESH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const axisCompensationOptions = () => {
    return (
      <>
        <FovOption key={yAxisUp} value={yAxisUp}>
          Y-Axis up
        </FovOption>

        <FovOption key={zAxisUp} value={zAxisUp}>
          Z-Axis up
        </FovOption>
      </>
    );
  };

  return (
    <>
      <FovSelect
        value={selectedAxisCompensation}
        placeholder={selectedAxisCompensation}
        onChange={(e, newVal) => {
          if (selectedAxisCompensation !== newVal) {
            const transforms = newVal === zAxisUp
              ? { rx: -90, ry: 0, rz: 0 }
              : { rx: 90, ry: 0, rz: 0 };

            const inboundData = { axisCompensation: newVal, transforms };
            updateCanvas(updateArgs, inboundData);
          }
        }}
      >
        {axisCompensationOptions()}
      </FovSelect>

      <VerticalSpacer size={16} />

      <FovButton
        text='Flip model on x-axis'
        onClick={() => updateCanvas(updateArgs, { flipHorizontally: true })}
      />
    </>
  );
};

export default Axis;
