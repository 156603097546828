import React from 'react';
import { useSelector } from 'react-redux';
import {
  VerticalSpacer,
  FovSkeletonLoader,
  FovTypography,
  Body,
  FovDivider,
  DocNote,
} from 'components';
import { withTheme } from 'styled-components';
import ListTab from './ListTab';
import ColorTab from './Color';
import Roughness from './Roughness';
import Emissive from './Emissive';
import Surface from './Surface';
import ClearCoat from './ClearCoat';
import Transparency from './Transparency';
import GeneralProperties from './GeneralProperties';
import Simplification from './Simplification';
import MaterialDownscaling from './MaterialDownscaling';
import _ from 'lodash';

const MaterialTab = withTheme(() => {
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const materials = serializedData?.metadata.materials || [];
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];

  const buildMaterialDetails = () => {
    return (
      <>
        <FovSkeletonLoader type="sliders" resource={true}>
          <Body size='Medium Bold'>
            General properties
            <DocNote path={[ 'materialsPanel', 'generalProperties' ]} top={-2} left={150} />
          </Body>

          <VerticalSpacer size={8} />

          <GeneralProperties />

          <Body size='Medium Bold'>
            Optimization
            <DocNote path={[ 'materialsPanel', 'optimization' ]} top={-2} left={105} />
          </Body>

          <Simplification />
          <MaterialDownscaling />

          <VerticalSpacer size={25} />
          <FovDivider />
          <VerticalSpacer size={15} />

          <Body size='Medium Bold'>
            Basic Attributes
            <DocNote path={[ 'materialsPanel', 'attributes' ]} top={-2} left={125} />
          </Body>

          <ColorTab />
          <Roughness />
          <Emissive />
          <Surface />

          <Body size='Medium Bold'>
            Advanced Attributes
            <DocNote path={[ 'materialsPanel', 'advancedAttributes' ]} top={-2} left={163} />
          </Body>

          <ClearCoat />
          <Transparency />
        </FovSkeletonLoader>
      </>
    );
  };

  const buildMaterialPanel = () => {
    if (_.isEmpty(materials)) {
      return (
        <FovTypography>
          Attributes unavailable — No materials to show.
          </FovTypography>
      );
    }

    if (selectedMaterials.length > 1) {
      return (
        <FovTypography>
          Attributes unavailable — multiple materials selected.
        </FovTypography>
      );
    }

    return (
      <>
        <ListTab />
        <VerticalSpacer size={15} />
        {buildMaterialDetails()}
      </>
    );
  };

  return (
    <div>
      <VerticalSpacer size={25} />
      {buildMaterialPanel()}
    </div>
  );
});

export default MaterialTab;
