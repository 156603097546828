import { useDispatch, useSelector } from 'react-redux';
import { FovInput, VerticalSpacer } from 'components';
import { updateCanvas, cleanNumber } from 'utils';
import constants from'constants/index';
import styled from 'styled-components';

const AxisContainer = styled('div')`
  display: grid;
  gap: 0 0.5rem;
  align-items: center;
  grid-template-columns: repeat(3, auto);
  width: -webkit-fill-available;
`;

const AxisInput = styled(FovInput)`
  width: 100%;
  min-width: 4rem;
`;

const AxisWrapper = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const AxisLabel = styled('div')`
  position: absolute;
  top: -1.25rem;
  left: 0;
  font-size: 0.85rem;
`;

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const LightingDirection = () => {
    const dispatch = useDispatch();
    const canvasStore = useSelector(state => state.canvas);
    const serializedData = canvasStore.serializedData;
    const lights = serializedData?.metadata.lights;
    const defaultLight = lights[0];
    const { id, direction } = defaultLight;
    const xDirection = cleanNumber(direction[0]);
    const yDirection = cleanNumber(direction[1]);
    const zDirection = cleanNumber(direction[2]);

    const updateArgs = {
        dispatch,
        type: CANVAS_UPDATE_TYPES.LIGHTING,
        method: CANVAS_UPDATE_METHODS.UPDATE,
    };

    const handleChange = (key, value) => {
        const inboundData = {
          id,
          transforms: {
            dirX: xDirection,
            dirY: yDirection,
            dirZ: zDirection,
            [key]: +(value),
          },
        };
        updateCanvas(updateArgs, inboundData);
    };

  return (
    <>
    Direction
    <VerticalSpacer size={15} />
    <AxisContainer>
        <AxisWrapper>
        <AxisLabel>X</AxisLabel>

        <AxisInput
            type={'number'}
            step={0.01}
            value={xDirection}
            onChange={e => {
                handleChange('dirX', e.target.value);
            }}
        />
        </AxisWrapper>

        <AxisWrapper>
        <AxisLabel>Y</AxisLabel>

        <AxisInput
            type={'number'}
            step={0.01}
            value={yDirection}
            onChange={e => {
                handleChange('dirY', e.target.value);
            }}
        />
        </AxisWrapper>

        <AxisWrapper>
        <AxisLabel>Z</AxisLabel>

        <AxisInput
            type={'number'}
            step={0.01}
            value={zDirection}
            onChange={e => {
                handleChange('dirZ', e.target.value);
            }}
        />
        </AxisWrapper>
    </AxisContainer>
    </>
  );
};

export default LightingDirection;
