export const hexToRgba = (hex, opacity) => {
  opacity = opacity || 1;
  hex = hex.replace(/[^0-9A-F]/gi, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${[ r, g, b, opacity ].join(',')})`;
};

const headings = {
  xs: '0.625rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '2.25rem',
  xl: '2.6875rem',
  xxl: '3.75rem',
};

/**
 * Values should be hex values and not rgb/rgba
 * https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 * @type {ThemeVariant}
 */
 const colors = {
  light: {
    primary: '#ffffff',
    onPrimary: '#383838',
    accent: '#359eff',
    accentVariant: '#86c5ff',
    onAccent: '#ffffff',
    accentHover: '#276daf',
    error: '#f35b5f',
    onError: '#ffffff',
    errorHover: '#b12b38',
    warning: '#da9c00',
    success: '#00d641',
    lightGrey: '#ebeef0', // tag backgrounds, dividers, drop shadows.
    // input fields, borders, social icons, disabled states (buttons, inputs etc.), division lines.
    mediumGrey: '#d1dbe0',
    darkGrey: '#a3b1b9', // system text/icons, tooltips.
    black: '#000000',
    white: '#ffffff',
    transparent: 'transparent',
  },
  dark: {
    primary: '#202124',
    onPrimary: '#ebeef0',
    accent: '#359eff',
    accentVariant: '#86c5ff',
    onAccent: '#202124',
    accentHover: '#86c5ff',
    error: '#ec5050',
    onError: '#202124',
    errorHover: '#f35b5f',
    warning: '#ffb800',
    success: '#00d641',
    lightGrey: '#767676',
    mediumGrey: '#4e4e4e',
    darkGrey: '#383838',
    black: '#000000',
    white: '#ffffff',
    transparent: 'transparent',
  },
};

const typography = {
  primary: {
    regular: 'Poppins-Regular',
    bold: 'Poppins-Bold',
    light: 'Poppins-Light',
    thin: 'Poppins-Thin',
  },
};

const theme = {
  colors,
  headings,
  typography,
  hexToRgba,
};

export default theme;
