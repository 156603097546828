import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const SofaSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const SofaOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <SofaSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 20 13'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M17.8 4.6001C17.1376 4.6001 16.6 5.1377 16.6 5.8001V7.0001C16.6 7.3313 16.3312 7.6001 16 7.6001H4C3.6688 7.6001 3.4 7.3313 3.4 7.0001V5.8001C3.4 5.1377 2.8624 4.6001 2.2 4.6001M17.8 4.6001C18.4624 4.6001 19 5.1377 19 5.8001V8.2001C19 9.5257 17.9256 10.6001 16.6 10.6001M17.8 4.6001C17.8 2.6121 16.1879 1 14.1999 1H5.79994C3.81194 1 2.2 2.6121 2.2 4.6001M2.2 4.6001C1.5376 4.6001 1 5.1377 1 5.8001V8.2001C1 9.5257 2.0744 10.6001 3.4 10.6001M3.4 10.6001H16.6M3.4 10.6001L3.4001 12.4M16.6 10.6001L16.6001 12.4M10 7.6V1'
      />
    </SofaSVG>
  );
});

export { SofaOutlined };