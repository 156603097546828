import { JOB_STATUSES, RPC_TYPES, trimText } from 'utils';
import { getCdnImageUrl } from 'utils/image-handler';
import { allRoles } from 'components/generic/ProtectedContent';
import default2DRpc from 'assets/Ui/default2DModel.png';
import default3DRpc from 'assets/Ui/default3DModel.png';
import _ from 'lodash';

const { VITE_CDN_HOST: cdnHost } = process.env;

export const formatRpcImage = (guid, jobType) => {
  const thumbnailCache = `${cdnHost}/public/service.thumbnail-cache`;

  if (!guid) {
    return jobType === RPC_TYPES.png ? default2DRpc : default3DRpc;
  }

  return getCdnImageUrl(`${thumbnailCache}/${guid}.rpc.png`, { width: 164, height: 164 });
};

export const getScaledValues = cardScale => {
  const getScale = value => (value * cardScale) / 100;

  return {
    cardContainer: {
      width: getScale(360),
      height: getScale(360),
    },
    grid: {
      column: getScale(60),
      row: getScale(90),
    },
    image: {
      width: getScale(240),
      height: getScale(240),
      spacer: getScale(35),
      default: {
        width: getScale(180),
        height: getScale(220),
      },
    },
    jobStatus: {
      width: getScale(40),
      height: getScale(40),
      spacer: getScale(20),
      processIcon: {
        width: getScale(35),
        height: getScale(35),
      },
    },
    icons: {
      width: getScale(43),
      height: getScale(43),
      spacer: getScale(5),
      spacerBetween: getScale(15),
    },
    font: {
      size: getScale(25),
    },
    popover: {
      top: getScale(75),
      left: getScale(70),
    },
    button: {
      width: getScale(40),
      height: getScale(50),
    },
    buttonContainer: {
      height: getScale(270),
    },
  };
};

export const downloadMenuItems = args => {
  const { formats, rpc_guid, onDownload, onComplete } = args;

  if (formats) {
    const downloadList = Object.keys(formats)?.map(format => {
      const status = formats[format];

      return {
        format,
        status,
        callback: () => onDownload(rpc_guid, format, onComplete),
        allowedRoles: allRoles,
      };
    });

    return downloadList;
  }

  return [];
};

export const buildTitle = (status, title, isCardView) => {
  if (status && status !== JOB_STATUSES.COMPLETED) {
    return _.startCase(status);
  }

  return trimText(title, isCardView ? 20 : 100, true);
};

export const getQuerySortingParameters = value => {
  if (value === 'ascending') return 'TITLE_ASC';
  if (value === 'descending') return 'TITLE_DESC';
  if (value === 'oldest first') return 'CREATED_OLD_TO_NEW';
  if (value === 'newest first') return 'CREATED_NEW_TO_OLD';
};

export const customStartCase = str => {
  if (!str) return '';

  let modified = str.replace(/[_-]+/g, ' ');

  modified = modified.replace(/([a-z])([A-Z])/g, '$1 $2');

  const tokens = modified.split(/\s+/);

  return tokens.join(' ');
};
