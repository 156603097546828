import React from 'react';
import { useSelector } from 'react-redux';
import {
  HorizontalSpacer,
  VerticalSpacer,
  ArrowLeftOutlined,
  SunOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Image,
  Caption,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { environment as images } from './images';

import Summary from './Summary';

const EditingEnvironments = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    'Select a preset to view your model in different lighting conditions.',
    <>
      Upload your own&nbsp;<strong>.hdr</strong>&nbsp;images with the&nbsp;
      <strong>&quot;Add custom environment&quot;</strong>&nbsp;button.
    </>,
    <>
      Select recently uploaded&nbsp;<strong>.hdr</strong>&nbsp;images with the&nbsp;
      <strong>&quot;Custom environments&quot;</strong>&nbsp;select menu.
    </>,
    <>
      Remove recently uploaded custom&nbsp;<strong>.hdr</strong>&nbsp;images with the&nbsp;
      <strong>&quot;Remove selected environment&quot;</strong>&nbsp;button.
    </>,
    'Customize environment intensity and blur.',
    'Control background, grid, axis lines and model shadow visibility with viewport visibility toggles.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />



      <DocSection section='The Environment Panel'>
        <p>
          The environment panel <SunOutlined /> helps with controlling scene lighting and
          viewport visibility of scene controllers such as the grid, axis lines, background and
          model shadows.
        </p>
        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.environmentPanel} width={400} alt='Environment panel.' />
        </ImageContainer>
      </DocSection>



      <DocSection section='Preset & Customer Environments'>
        <p>
          By default, you are provided with a few preset <strong>HDR</strong> environment images to get started.
          These allow you to envision your model in a number of lighting scenario&apos;s that may help accentuate
          reflections or other features of your model.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.presetEnvironments} width={350} alt='Preset environments.'/>
        </ImageContainer>

        <VerticalSpacer size={15} />



        <DocSection subsection='Adding a custom environment'>
          <p>
            If you need a more custom solution, you can upload your own <strong>.hdr</strong> files using the&nbsp;
            <strong>&quot;Add custom environment&quot;</strong> button and menu.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.addEnvironmentButton} width={300} alt='Add environment button.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.environmentUploadWindow} width={650} alt='Explorer window.'/>
          </ImageContainer>

          <ImageContainer>
            <Image src={images.uploadingEnvironment} width={300} alt='Uploading environment.'/>
          </ImageContainer>

          <ImageContainer>
            <Image src={images.environmentSelectMenu} width={350} alt='Environement selection menu.'/>
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Your newly selected HDR environment will be available from the <strong>&quot;Environment&nbsp;
            select&quot;</strong>&nbsp;menu.
          </Caption>
        </DocSection>



        <DocSection subsection='Removing a custom environment'>
          <p>
            You can remove any custom environments you&apos;ve uploaded with the&nbsp;
            <strong>&quot;Remove selected environment&quot;</strong>&nbsp;button.
          </p>

          <VerticalSpacer size={15} />

          <ImageContainer>
            <Image src={images.removeEnvironmentButton} width={300} alt='Add environment button.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.defaultEnvironmentSelect} width={350} alt='Default environment selected.'/>
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            You will need to select something other than the <strong>&quot;Default&quot;</strong>&nbsp;
            environment before the <strong>&quot;Remove selected environment&quot;</strong>&nbsp;button
            is enabled.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='Environment Attributes'>
        <p>
          You can control the intensity and blur of your <strong>HDR</strong> environment
          to yield different results to your scene&apos;s lighting or presentation when taking screenshots.
        </p>

        <DocSection subsection='Intensity'>
          <ImageContainer noSpace={true}>
            <Image src={images.intensity_0} width={250} alt='Intensity at 0%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.base} width={250} alt='Intensity at 50%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.intensity_100} width={250} alt='Intensity at 100%.'/>
          </ImageContainer>

          <ImageContainer>
            <Image src={images.intensityBox_0} width={250} alt='Intensity at 0%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.intensityBox_50} width={250} alt='Intensity at 50%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.intensityBox_100} width={250} alt='Intensity at 100%.'/>
          </ImageContainer>
        </DocSection>



        <DocSection subsection='Blur'>
          <ImageContainer noSpace={true}>
            <Image src={images.blur_0} width={250} alt='Blur at 0%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.blur_50} width={250} alt='Blur at 50%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.blur_100} width={250} alt='Blur at 100%.'/>
          </ImageContainer>

          <ImageContainer>
            <Image src={images.blurBox_0} width={250} alt='Blur at 0%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.blurBox_50} width={250} alt='Blur at 50%.'/>

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.blurBox_100} width={250} alt='Blur at 100%.'/>
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The <strong>&quot;Background&quot;</strong> toggle needs to be enabled for this setting to show results.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='Viewport Visibility'>
        <p>
          The viewport has multiple visibility settings that can be toggled on or off based on your needs.
          These include: the background, axis lines, grid and model shadows.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.viewportVisibility} width={300} alt='Viewport visibility.' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.viewportWithBackpack} width={600} alt='Viewport with backpack.' />
        </ImageContainer>
      </DocSection>

    </ContentContainer>
  );
});

export { EditingEnvironments };