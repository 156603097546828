import { keyValueMirror } from 'store/store-functions';

export const actions = keyValueMirror(
  'SET_GAMEMANAGER',
);

export const setGameManager = gameManager => {
  return {
    type: actions.SET_GAMEMANAGER,
    payload: gameManager,
  };
};
