import React from 'react';
import { useSelector } from 'react-redux';
import {
  HorizontalSpacer,
  ArrowLeftOutlined,
  DownloadOutlined,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Image,
  Caption,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import Summary from './Summary';
import { downloading as images } from './images';

const DownloadingModels = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;
  const summary = [
    <>
      From either the&nbsp;<strong>&quot;My Files&quot;</strong>&nbsp; or&nbsp;
      <strong>&quot;Creators&quot;</strong>&nbsp;page...
    </>,
    'Open download list by clicking the download icon on a model.',
    <>
      Select desired file format and click&nbsp;<strong>&quot;Download&quot;</strong>&nbsp;.
    </>,
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='Accessing Download Formats'>
        <p>
          From the <strong>&quot;My Files&quot;</strong> or <strong>&quot;Creators&quot;</strong> page,
          select the download icon <DownloadOutlined /> on any model card (or action column in list view).
        </p>

        <ImageContainer>
          <Image src={images.contentMenu} width={400} alt='Content menu.' />
        </ImageContainer>

        <ImageContainer>
          <Image src={images.downloadModel} width={200} alt='Download model.' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.downloadModal} width={300} alt='Download modal.' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.downloadingModel} width={300} alt='Format list.' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          You will be presented with a list of available formats to choose from. <br />
          Select the format you need and click the <strong>&quot;Download&quot;</strong>&nbsp;
          button to get the selected format.
        </Caption>
      </DocSection>
    </ContentContainer>
  );
});

export { DownloadingModels };