import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FovSelect, FovOption } from 'components';
import { SCALE_UNITS, updateCanvas } from 'utils';
import constants from 'constants/index';
import _ from 'lodash';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;
const unitless = 'unitless';

const ScaleCompensation = () => {
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const selectedSourceUnit = serializedData?.metadata.selectedSourceUnit;
  const selectedMeshes = serializedData?.metadata.selectedMeshes;
  const friendlyName = SCALE_UNITS.find(unit => unit.abbreviation === selectedSourceUnit)?.name;
  const placeholder = _.capitalize(friendlyName) || 'Unitless';
  const dispatch = useDispatch();

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MESH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const scaleCompensationOptions = () => {
    const options = [
      <FovOption key={unitless} value={unitless}>
        Unitless
      </FovOption>,
    ];

    SCALE_UNITS.forEach(unit => {
      const { name, abbreviation } = unit;

      options.push(
        <FovOption key={name} value={abbreviation}>
          {_.capitalize(name)}
        </FovOption>,
      );
    });

    return options;
  };

  return (
    <>
      <FovSelect
        value={selectedSourceUnit || ''}
        placeholder={placeholder}
        disabled={!_.isEmpty(selectedMeshes)}
        onChange={(e, newVal) => {
          if (selectedSourceUnit !== newVal) {
            const inboundData = { sourceUnit: newVal };
            updateCanvas(updateArgs, inboundData);
          }
        }}
      >
        {scaleCompensationOptions()}
      </FovSelect>
    </>
  );
};

export default ScaleCompensation;
