import React, { Fragment, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import {
  DocsContainer,
  CardsContainer,
  DocsNavPanel,
  PanelTitle,
  SectionTitleContainer,
  PrevNextContainer,
  DocsContent,
  Prev,
  Next,
  MainContent,
  CategoryName,
} from './docStyles';
import {
  VerticalSpacer,
  H3,
  H4,
  DocsOutlined,
  HorizontalSpacer,
} from 'components';
import docItems from './docItems';
import _ from 'lodash';

const Docs = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const onDocs = pathname.includes('/documentation/');

  const scrollToTop = () => ref.current?.scrollTo(0, 0);

  const buildNav = () => {
    const sections = Object.keys(docItems).map(key => {
      const title = key;
      const value = docItems[key];
      const links = value.map(doc => doc);
      return { title, links };
    });

    const links = sections.map((section, index) => {
      const { title, links } = section;
      const docLinks = links.map((link, i) => (
        <Fragment key={i}>
          <CategoryName
            key={i}
            theme={theme}
            $selectedTheme={selectedTheme}
            $selected={pathname === link.path}
            onClick={() => {
              navigate(link.path);
              scrollToTop();
            }}
          >
            <HorizontalSpacer size={25} />
            {link.title}
          </CategoryName>

          <VerticalSpacer size={10} />
        </Fragment>
      ));

      return (
        <Fragment key={index}>
          <SectionTitleContainer>
            <HorizontalSpacer size={15} />
            <strong>
              {_.startCase(title)}
            </strong>
          </SectionTitleContainer>

          <VerticalSpacer size={10} />
          {docLinks}

          {index !== sections.length - 1 && (
            <VerticalSpacer size={30} />
          )}
        </Fragment>
      );
    });

    return links;
  };

  const renderDocs = args => {
    const { i, item, prevTitle, prevPath, nextTitle, nextPath } = args;

    return (
      <DocsContent key={i} theme={theme} $selectedTheme={selectedTheme}>
        <VerticalSpacer size={43} />
        <H3>{item.title}</H3>
        <VerticalSpacer size={7} />

        {item.element}

        <VerticalSpacer size={15} />

        <PrevNextContainer>
          {prevPath && (
            <Prev>
              <Link to={prevPath} onClick={scrollToTop}>
                <strong>Previous</strong>:&nbsp;
                {prevTitle}
              </Link>
            </Prev>
          )}

          {nextPath && (
            <Next>
              <Link to={nextPath} onClick={scrollToTop}>
                <strong>Next</strong>:&nbsp;
                {nextTitle}
              </Link>
            </Next>
          )}
        </PrevNextContainer>
      </DocsContent>
    );
  };

  const getDocs = () => {
    const sections = Object.keys(docItems);
    const content = sections.map((key, index) => {
      const sectionVals = docItems[key];
      const nextSection = sections[index + 1];
      const prevSection = sections[index - 1];
      const firstOfNextSection = docItems[nextSection]?.[0];
      const lastOfPrevSection = docItems[prevSection]?.[docItems[prevSection].length - 1];

      return (
        sectionVals.map((item, i) => {
          const prevItem = sectionVals[i - 1];
          const prevTitle = prevItem?.title || lastOfPrevSection?.title;
          const prevPath = prevItem?.path || lastOfPrevSection?.path;
          const nextItem = sectionVals[i + 1];
          const nextTitle = nextItem?.title || firstOfNextSection?.title;
          const nextPath = nextItem?.path || firstOfNextSection?.path;

          if (item.path === pathname) {
            const args = { i, item, prevTitle, prevPath, nextTitle, nextPath };
            return renderDocs(args);
          }
        })
      );
    });

    return content;
  };

  return (
    <DocsContainer theme={theme} $selectedTheme={selectedTheme} $onDocs={onDocs}>
      {onDocs && (
        <DocsNavPanel theme={theme} $selectedTheme={selectedTheme}>
          <PanelTitle>
            <DocsOutlined isTitleIcon={true} />
            <HorizontalSpacer size={6} />

            <H4>Documentation</H4>
          </PanelTitle>

          <VerticalSpacer size={20} />
          {buildNav()}
        </DocsNavPanel>
      )}

      <MainContent ref={ref}>
        <CardsContainer theme={theme} $selectedTheme={selectedTheme} $onDocs={onDocs}>
          {getDocs()}
        </CardsContainer>
      </MainContent>
    </DocsContainer>
  );
});

export { Docs };