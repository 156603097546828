import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { HorizontalSpacer, Body, VerticalSpacer, FovFieldError, CloseOutlined } from 'components';
import { Field } from 'formik';
import { FileUploader } from 'react-drag-drop-files';
import {
  UploadedList,
  DragDropContainer,
  UploaderLabel,
  MissingFile,
  UploadArea,
  SupportedFileTypes,
  ClearButton,
  UploadContainer,
} from './styles';
import { withTheme } from 'styled-components';
import constants from 'constants/index';
import _ from 'lodash';

const light = constants.THEME.LIGHT;
const fileSizeLimit = 256;
const defaultErrors = { sizeError: false, typeError: false };

const FovUploader = withTheme(props => {
  const {
    theme,
    title,
    instructions,
    form,
    formDefaults,
    updateNameField,
    supportedExtensions,
    setIsImageProvided,
    multipleFiles,
  } = props;

  const [ uploadedFiles, setUploadedFiles ] = useState([]);
  const [ missingFiles, setMissingFiles ] = useState([]);
  const [ uploadErrors, setUploadErrors ] = useState(defaultErrors);

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const lightTheme = selectedTheme === light;
  const onPrimary = theme.colors[selectedTheme].onPrimary;
  const lightGrey = theme.colors[selectedTheme].lightGrey;

  useEffect(() => {
    return () => {
      setIsImageProvided?.(false);
      setUploadedFiles?.([]);
      setMissingFiles([]);
      setUploadErrors(defaultErrors);
    };
  }, [ setUploadedFiles, setUploadErrors ]);

  const anyUploadErrors = () => {
    let errorExist = false;

    Object.keys(uploadErrors).forEach(key => {
      const value = uploadErrors[key];

      if (value) {
        errorExist = true;
      }
    });

    return errorExist;
  };

  const resetUploadField = (e, errorType) => {
    if (!_.isEmpty(form?.values?.files)) {
      form?.setFieldValue('files', '');
      updateNameField && form?.setFieldValue('contentName', '');
      setUploadedFiles?.([]);
      setIsImageProvided?.(false);
    }

    if (errorType) {
      setUploadErrors({ [errorType]: true });
    }
  };

  const handleFilesChange = args => {
    const { array, data, form } = args || {};
    const files = array?.map(item => item.file);

    if (files && !_.isEmpty(files)) {
      const isImage = files.find(item => {
        const name = item.name?.split('.');
        const extension = name?.at(-1)?.toLowerCase();

        if (extension === 'png') {
          return true;
        }
      });

      setIsImageProvided?.(isImage ? true : false);
      form?.setFieldValue(data?.field?.name, files);
      form?.setFieldTouched(data?.field?.name, false, false);

      if (!isImage) {
        form?.setFieldValue('height', formDefaults.height);
        form?.setFieldValue('heightFactor', formDefaults.heightFactor);
      }
    }
  };

  const handleUploaderChange = args => {
    const { files, data } = args || {};

    setUploadErrors(defaultErrors);
    setMissingFiles(null);

    const asArray = Object.keys(files).map(key => files[key]);
    const primaryFiles = asArray.map(file => {
      const fileName = file.name.split('.');
      const lastIndex = fileName.length - 1;
      const name = fileName.slice(0, lastIndex).join();
      const extension = fileName[lastIndex]?.toLowerCase();
      const fileMatch = supportedExtensions?.find(item => item.value.toLowerCase() === extension);

      if (fileMatch) {
        return { name, extension, file };
      }
    });

    if (primaryFiles) {
      const isSingleFile = primaryFiles.length === 1;
      const hasMultipleFiles = primaryFiles.length > 1;
      const validFiles = primaryFiles.map(item => {
        const { name, extension, file } = item || {};
        const isDependency = supportedExtensions?.find(supportedFile => {
          const requirement = supportedFile.required;
          const extensionMatch = requirement?.includes(extension?.toUpperCase());

          if (extensionMatch) {
            return supportedFile;
          }
        });

        if ((hasMultipleFiles && isDependency) || isSingleFile) {
          return { name, extension, file };
        }
      })
      .filter(item => item);

      validFiles.forEach(item => {
        // Checks to make sure all required files are uploaded, shows missing file in UI otherwise.
        const { extension } = item;
        const requiredExtensions = supportedExtensions?.find(supported => (
          supported.value === extension.toUpperCase()
        ))?.required;

        requiredExtensions?.forEach(ext => {
          const isIncluded = validFiles.find(file => file.extension.toUpperCase() === ext);
          setMissingFiles(isIncluded ? null : [ ext ]);
        });
      });

      setUploadedFiles?.(validFiles);
      handleFilesChange({ array: validFiles, data, form });
    }

    const extension = files?.[0]?.name?.split('.').pop();
    const fileName = files?.[0]?.name?.split(`.${extension}`)[0];

    if (fileName && updateNameField) {
      form?.setFieldValue('contentName', fileName);
    }
  };

  const renderFileUploader = data => {
    return (
      <UploadContainer>
        <ClearButton
          theme={theme}
          $selectedTheme={selectedTheme}
          visible={!_.isEmpty(uploadedFiles)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            resetUploadField(e);
          }}
        >
          <CloseOutlined width={16} height={16} />
        </ClearButton>

        <FileUploader
          maxSize={fileSizeLimit}
          onSizeError={e => resetUploadField(e, 'sizeError')}
          onTypeError={e => resetUploadField(e, 'typeError')}
          handleChange={files => handleUploaderChange({ files, data })}
          classes="dropper"
          name={data?.field?.name}
          types={supportedExtensions?.map(type => type.value)}
          multiple={multipleFiles !== undefined ? multipleFiles : true}
        >
          <DragDropContainer theme={theme} $selectedTheme={selectedTheme} error={anyUploadErrors()}>
              {uploadErrors.sizeError && `File size limit exceeded: ${fileSizeLimit} MB, per file, limit.`}
              {uploadErrors.typeError && 'Unsupported file type.'}

              {!_.isEmpty(uploadedFiles) && !anyUploadErrors() && (
                <UploadedList theme={theme} $selectedTheme={selectedTheme}>
                  {uploadedFiles.map((file, index) => (
                    <Fragment key={index}>
                      <span>
                        {`${file.name}.${file.extension}`}
                        {(index !== uploadedFiles.length - 1) && ','}
                      </span>

                      <HorizontalSpacer size={8} />
                    </Fragment>
                  ))}
                </UploadedList>
              )}

              {_.isEmpty(uploadedFiles) && !anyUploadErrors() && (instructions || 'Click or drag files here.')}
            </DragDropContainer>
        </FileUploader>
      </UploadContainer>
    );
  };

  return (
    <Fragment>
        <UploaderLabel>
          {title}

          <VerticalSpacer size={8} />

          <Field type="file" name="files">
            {data => (
              <UploadArea theme={theme} $selectedTheme={selectedTheme} error={anyUploadErrors()}>
                {renderFileUploader(data)}
              </UploadArea>
            )}
          </Field>
        </UploaderLabel>

        <Body size="X-Small" color={lightTheme ? onPrimary : lightGrey}>
          {!_.isEmpty(uploadedFiles) && !_.isEmpty(missingFiles) && (
            <Fragment>
              <MissingFile theme={theme} $selectedTheme={selectedTheme}>
                <VerticalSpacer size={8} />
                This format may also require a <strong>{missingFiles?.join(',')}</strong> file.
              </MissingFile>

            </Fragment>
          )}

          <VerticalSpacer size={16} />

          <SupportedFileTypes visible={!_.isEmpty(supportedExtensions)}>
            <strong>Supported file types</strong>:&nbsp;
            {supportedExtensions
              ?.map(type => !type.hideInList && type.name)
              .filter(type => type)
              .join(', ')
            }
          </SupportedFileTypes>
        </Body>

        <FovFieldError name="files" />
      </Fragment>
  );
});

export { FovUploader };