import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const GeometrySVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const GeometryOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <GeometrySVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 16 18'
    >
      <path fill='currentColor' d='M8.26429 0.968011C8.09765 0.877218 7.89615 0.877135 7.72941 0.968148L1.51727 4.35705L1.19228 4.53609C1.18867 4.53768 1.18515 4.5395 1.18175 4.54151L1.17365 4.54632C1.00391 4.64693 0.899902 4.8296 0.899902 5.02682V12.9019C0.899902 13.106 1.0112 13.2939 1.19027 13.3919L7.73351 16.9746C7.81711 17.0203 7.90945 17.0432 8.00174 17.0432C8.08011 17.0432 8.1569 17.0263 8.22758 16.9949C8.23482 16.9928 8.24182 16.99 8.24844 16.9864L14.8097 13.3918C14.9887 13.2937 15.0999 13.1059 15.0999 12.9019V5.02587C15.0999 4.82861 14.996 4.64576 14.8262 4.54515L14.4817 4.35658L8.26429 0.968011ZM2.62362 5.02733L7.99713 2.09479L13.3761 5.02687L8.00163 7.9687L2.62362 5.02733ZM8.56033 15.5417V8.9365L13.9827 5.96844V12.571L8.56033 15.5417ZM7.44314 8.93662L2.01712 5.96901V12.5709L7.44314 15.5419V8.93662Z' />
    </GeometrySVG>
  );
});

export { GeometryOutlined };