import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { FovTypography } from 'components/typography';
import { FovDivider, VerticalSpacer } from 'components/layout';
import { FovPagination } from 'components/generic';
import constants from 'constants/index';

const TableContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Header = styled('div')`
  width: 100%;
  min-height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.25rem;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
`;

const Column = styled('div')`
  width: ${props => (props.width ? props.width / 16 + 'rem' : '8rem')};
  display: flex;
`;

const Body = styled('div')`
  display: flex;
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
`;

const DataContainer = styled(Header)`
  display: flex;
  height: 6.5rem;
`;

const DataItem = styled('div')`
  line-break: anywhere;
  display: flex;
  justify-content: ${props => props.alignContent};
  width: ${props => (props.width ? props.width / 16 + 'rem' : '8rem')};
  color: ${props => props.fontColor};
`;

const NoDataContainer = styled(FovTypography)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: ${props => props.color};
`;

const getColors = (colors, theme) => ({
  header: {
    backgroundColor:
      theme === constants.THEME.LIGHT
        ? colors[theme].lightGrey
        : colors[theme].darkGrey,
    font: colors[theme].onPrimary,
  },
  body: {
    backgroundColor: colors[theme].transparent,
    font: colors[theme].onPrimary,
  },
  noData: {
    color:
      theme === constants.THEME.DARK
        ? colors[theme].lightGrey
        : colors[theme].darkGrey,
  },
});

const getDataAlignContent = (alignContent = 'left') => {
  const sides = {
    right: 'flex-end',
    center: 'center',
    left: 'flex-start',
  };

  return sides[alignContent];
};

const getColumns = columns => {
  return columns.map((column, key) => (
    <Column width={column.width} key={`column-${key}`}>
      <FovTypography>{column.title}</FovTypography>
    </Column>
  ));
};

const generateData = (data, columns, bodyColors) => {
  return data.map((item, key) => (
    <Fragment key={`data-container-${key}`}>
      <DataContainer>
        {columns.map((column, key) => (
          <DataItem
            alignContent={getDataAlignContent(column.alignContent)}
            width={column.width}
            key={`data-item-${key}`}
            fontColor={bodyColors.font}
          >
            {column.render ? (
              column.render(item[column.key], item)
            ) : (
              <FovTypography>{item[column.key]}</FovTypography>
            )}
          </DataItem>
        ))}
      </DataContainer>
      <FovDivider />
    </Fragment>
  ));
};

const Table = withTheme(props => {
  const {
    theme,
    dataSource,
    columns,
    pageSize = 5,
    onScroll,
    isPagination = true,
    onPageChange,
    currentPage,
    totalCount,
  } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const { header, body, noData } = getColors(theme.colors, selectedTheme);

  return (
    <TableContainer onScroll={onScroll}>
      <VerticalSpacer size={15} />

      <Header backgroundColor={header.backgroundColor} color={header.font}>
        {getColumns(columns)}
      </Header>

      {totalCount ? (
        <Body backgroundColor={body.backgroundColor}>
          {generateData(dataSource, columns, body)}
        </Body>
      ) : (
        <NoDataContainer color={noData.color}>No Data...</NoDataContainer>
      )}

      {isPagination && (
        <FovPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={onPageChange}
        />
      )}
    </TableContainer>
  );
});

export { Table };