import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme } from 'styled-components';

const UploadSVG = styled('svg')`
  color: ${props => (
    props.color || getColor(props, 'onPrimary')
  )};

  &:hover {
    color: ${props => props.hoverColor};
  }
`;

const UploadOutlined = withTheme(props => {
  const { theme, width, height, color, hoverColor } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <UploadSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      color={color}
      hoverColor={hoverColor}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill='currentColor'
        d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"
      />
    </UploadSVG>
  );
});

export { UploadOutlined };
