import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const CameraSVG = styled('svg')`
  color: ${props => (
    props.theme.colors[props.$selectedTheme][props.hoverColor || 'onPrimary']
  )}
`;

const CameraIcon = withTheme(props => {
  const { width, height, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CameraSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
        <path fill="currentColor" d="M20 4h-3.17L15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V6h4.05l1.83-2h4.24l1.83 2H20v12zM12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5s-2.24-5-5-5zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3s3 1.35 3 3s-1.35 3-3 3z" />
    </CameraSVG>
  );
});

export { CameraIcon };