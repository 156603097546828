import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

const ListOutlined = withTheme(props => {
  const { width, height, isTitleIcon, color, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 35 35"
    >
      <path fill={color || theme.colors[selectedTheme][isTitleIcon ? 'accent' : 'onPrimary']}
        d="M7 25.8468C7 25.541 7.10057 25.2477 7.27958 25.0314C7.45859 24.8151 7.70138 24.6936 7.95455 24.6936H27.0455C27.2986 24.6936 27.5414 24.8151 27.7204 25.0314C27.8994 25.2477 28 25.541 28 25.8468C28 26.1527 27.8994 26.446 27.7204 26.6622C27.5414 26.8785 27.2986 27 27.0455 27H7.95455C7.70138 27 7.45859 26.8785 7.27958 26.6622C7.10057 26.446 7 26.1527 7 25.8468ZM7 17.5C7 17.1942 7.10057 16.9008 7.27958 16.6846C7.45859 16.4683 7.70138 16.3468 7.95455 16.3468H27.0455C27.2986 16.3468 27.5414 16.4683 27.7204 16.6846C27.8994 16.9008 28 17.1942 28 17.5C28 17.8058 27.8994 18.0992 27.7204 18.3154C27.5414 18.5317 27.2986 18.6532 27.0455 18.6532H7.95455C7.70138 18.6532 7.45859 18.5317 7.27958 18.3154C7.10057 18.0992 7 17.8058 7 17.5ZM7 9.15318C7 8.84734 7.10057 8.55402 7.27958 8.33776C7.45859 8.1215 7.70138 8 7.95455 8H27.0455C27.2986 8 27.5414 8.1215 27.7204 8.33776C27.8994 8.55402 28 8.84734 28 9.15318C28 9.45902 27.8994 9.75234 27.7204 9.9686C27.5414 10.1849 27.2986 10.3064 27.0455 10.3064H7.95455C7.70138 10.3064 7.45859 10.1849 7.27958 9.9686C7.10057 9.75234 7 9.45902 7 9.15318Z" />
    </svg>
  );
});

export { ListOutlined };
