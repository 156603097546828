import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  FovButton,
  VerticalSpacer,
  FovSelect,
  FovOption,
  FovModal,
  FovInput,
  FovConfirmation,
} from 'components';
import { withTheme } from 'styled-components';
import { updateCanvas } from 'utils';
import constants from'constants/index';
import _ from 'lodash';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Positions = withTheme(props => {
  const { theme } = props;
  const [ confirmationVisible, setConfirmationVisible ] = useState(false);
  const [ nameModalVisible, setNameModalVisible ] = useState(false);
  const [ nameInputValue, setNameInputValue ] = useState('');

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const errorColor = theme.colors[selectedTheme].error;
  const errorHoveredColor = theme.colors[selectedTheme].errorHover;
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const selectedMesh = serializedData?.metadata.selectedMeshes?.[0];
  const selectedUserMeshPositions = serializedData?.metadata.selectedUserMeshPositions;
  const userMeshPositions = serializedData?.metadata.userMeshPositions || [];
  const meshId = selectedMesh?.id;
  const selectedMeshPosition = selectedUserMeshPositions.find(position => position.meshId === meshId) || {};
  const selectedMeshPositionName = selectedMeshPosition.positionId;
  const dispatch = useDispatch();

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MESH,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const addArgs = {
    ...updateArgs,
    method: CANVAS_UPDATE_METHODS.ADD,
  };

  const removeArgs = {
    ...updateArgs,
    method: CANVAS_UPDATE_METHODS.REMOVE,
  };

  const buildUserPositions = () => {
    const userPositions = [];

    userMeshPositions.forEach((item, index) => {
      const { meshId: localMeshId, positionId } = item;

      if (meshId === localMeshId) {
        userPositions.push(
          <FovOption key={index} value={positionId}>
            {positionId}
          </FovOption>,
        );
      }
    });

    return userPositions;
  };

  const nameModal = () => {
    const reservedNames = [ 'default' ];
    const nameVal = nameInputValue.toLowerCase().trim();
    const onModalClose = () => {
      setNameModalVisible(false);
      setNameInputValue('');
    };

    const nameIsReserved = () => {
      const reservedNameUsed = reservedNames.includes(nameVal);
      const nameExists = userMeshPositions
        .some(pos => pos.meshId === meshId && pos?.positionId?.toLowerCase() === nameVal);

      return reservedNameUsed || nameExists;
    };

    return (
      <FovModal
        visible={nameModalVisible}
        title="Add Custom View"
        onClose={onModalClose}
        noOverflow={true}
      >
        Name
        <VerticalSpacer size={20} />

        <FovInput
          value={nameInputValue}
          onChange={e => setNameInputValue(e.target.value)}
        />

        {nameIsReserved() && (
          <>
            <VerticalSpacer size={15} />

            <Body size="Small" color={errorColor}>
              {nameInputValue}, is already in use.
            </Body>
          </>
        )}

        <VerticalSpacer size={20} />

        <FovButton
          text="Save position"
          disabled={nameIsReserved() || _.isEmpty(nameInputValue)}
          callback={() => {
            updateCanvas(addArgs, { id: nameInputValue });
            onModalClose();
          }}
        />
      </FovModal>
    );
  };

  return (
    <>
      <FovSelect
        value={selectedMeshPositionName || ''}
        placeholder={selectedMeshPositionName || 'Select a position'}
        onChange={(e, newVal) => {
          const inboundData = { id: newVal, useSavedPosition: true };
          updateCanvas(updateArgs, inboundData);
        }}
      >
        {buildUserPositions()}
      </FovSelect>

      <VerticalSpacer size={15} />

      <FovButton
        text="Save position"
        callback={() => setNameModalVisible(true)}
      />
      <VerticalSpacer size={15} />

      <FovButton
        text="Delete position"
        color={errorColor}
        hoverColor={errorHoveredColor}
        disabled={selectedMeshPositionName === 'Default'}
        onClick={() => {
          if (selectedMeshPositionName !== 'Default') {
            setConfirmationVisible(true);
          }
        }}
      />

      <FovConfirmation
        onConfirm={() => updateCanvas(removeArgs, { id: selectedMeshPositionName })}
        onClose={() => setConfirmationVisible(false)}
        confirmationText="Remove position"
        isOpened={confirmationVisible}
        confirmColor={errorColor}
        confirmHoverColor={errorHoveredColor}
      >
        <Body size="Medium">{`Remove ${selectedMeshPositionName}?`}</Body>
      </FovConfirmation>

      {nameModal()}
    </>
  );
});

export default Positions;
