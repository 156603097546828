import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  VerticalSpacer,
  HorizontalSpacer,
  AppHeaderFileName,
  ImageContainer,
  DocSection,
} from 'components';
import {
  CubeOutlined,
  CameraIcon,
  CreditsOutlined,
  DarkThemeIcon,
  EditOutlined,
  EyeOutlined,
  LightThemeIcon,
  Logo,
  MaterialOutlined,
  Rotate,
  ShareOutlined,
  SettingsOutlined,
  SunOutlined,
  Zoom,
  UserOutlined,
} from 'components/icons';
import { ExampleElement, Image,  Caption, ContentContainer } from '../docStyles';
import newScene from 'assets/documentation/IntroToFoveaScene/newScene.png';
import styled, { withTheme } from 'styled-components';
import Summary from './Summary';
import { InfoAndSupport } from 'components/blocks/appHeaderStyles';

export const Ellipses = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  position: absolute;
  top: 0.3rem;
`;

const IntroToFoveaScene = withTheme(props => {
  const { theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const modelURL = '/models/21-9016-5E4D-41DB-71F4-87CD-9D3F-C8CB-6789-00';
  const summary = [
    'Rotate and pan the viewport with right/left+click and zoom with mouse wheel.',
    'Navigate home with the FOVEA logo.',
    'Rename a published model with the model name field.',
    'Toggle themes, access documentation, community features or log out with additional menus.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='Getting Familiar with the FOVEA Scene'>
        <p>
          You can follow along using any of the models on the &quot;Explore FOVEA&quot; page.
          For example, you can use&nbsp;

          <Link to={modelURL} rel='noreferrer' target='_blank'>
            this model.
          </Link>
        </p>

        <p>
          After selecting the link, you should see the FOVEA viewport:
        </p>

        <ImageContainer>
          <Image src={newScene} width={800} alt='New scene' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          Use the left mouse button <Rotate /> to spin the camera and right mouse button
          &nbsp;<Rotate flipIcon={true} /> to pan the scene. <br />
          Zooming can be done with middle mouse wheel <Zoom />.
        </Caption>

        <VerticalSpacer size={30} />

        <DocSection subsection='Viewport'>
          <p>
            The viewport allows you to explore your 3D model using your mouse to rotate, pan  and zoom.
          </p>
        </DocSection>
      </DocSection>



      <DocSection section='App header'>

        <DocSection subsection={
          <>
            FOVEA logo &nbsp;
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              <Logo height={30} />
            </ExampleElement>
          </>
        }>
          <p>
            Selecting the logo will return you to the <strong>My Files</strong> page if
            you have already created a model. Otherwise, you will return to the&nbsp;
            <strong>Welcome</strong> page.
          </p>
        </DocSection>


        <DocSection subsection={
          <>
            Model Name &nbsp;
            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              <AppHeaderFileName
                path={null}
                pageName='Models'
                fileName={'Your model name'}
              />
            </ExampleElement>

            &nbsp;or&nbsp;

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              <AppHeaderFileName
                path={null}
                pageName='Models'
                fileName={'Your model name'}
                modelOverride={true}
              />
            </ExampleElement>
          </>
        }>
          <p>
            The middle of the app header allows you to navigate back to your models at any time.
            If you have a published model open, the model&apos;s name will be located here as well.
            Clicking the edit icon <EditOutlined /> or the model name will allow you to change
            the model&apos;s name.
          </p>
        </DocSection>



        <DocSection subsection={
          <>
            User Information and Settings &nbsp;

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              <LightThemeIcon />
              /

              <DarkThemeIcon />
              <HorizontalSpacer size={10} />

              <InfoAndSupport noCursor={true} theme={theme} $selectedTheme={selectedTheme}>
                <Ellipses>•••</Ellipses>
              </InfoAndSupport>
              <HorizontalSpacer size={10} />

              <UserOutlined />
              <HorizontalSpacer size={10} />

              <CreditsOutlined />
            </ExampleElement>
          </>
        }>
          <p>
            The top right portion of the app header allows for changing between light and dark themes,
            accessing <Link to='/roadmap' rel='noreferrer' target='_blank'>the roadmap</Link> for
            FOVEA&apos;s development, as well as access to additional documentation, community
            features and logging out.
          </p>
        </DocSection>
      </DocSection>



      <DocSection section='Side Panel'>
        <DocSection subsection={
          <>
            Scene Panels &nbsp;

            <ExampleElement theme={theme} $selectedTheme={selectedTheme}>
              <MaterialOutlined />
              <HorizontalSpacer size={10} />

              <CubeOutlined />
              <HorizontalSpacer size={10} />

              <CameraIcon />
              <HorizontalSpacer size={10} />

              <SunOutlined />
              <HorizontalSpacer size={10} />

              <EyeOutlined />
              <HorizontalSpacer size={10} />

              <ShareOutlined />
              <HorizontalSpacer size={10} />

              <SettingsOutlined />
            </ExampleElement>
          </>
        }>
          <p>
            Selecting any of these icons will open a panel that can be used to manipulate different properties
            of either the 3D model or FOVEA scene.
          </p>
        </DocSection>
      </DocSection>
    </ContentContainer>
  );
});

export { IntroToFoveaScene };