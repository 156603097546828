import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FovToggle, VerticalSpacer, FovSlider } from 'components';
import {
  LabeledItemHolder,
  TitleWithTooltipContainer,
  ChannelTextContainer,
} from '../MaterialStyles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const ClearCoat = () => {
  const dispatch = useDispatch();
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const hasClearCoat = selectedMaterial?.clearCoatEnabled;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const buildClearCoatSection = () => {
    if (hasClearCoat) {
      return (
        <>
          <ChannelTextContainer>
            <FovSlider
              label='Intensity'
              defaultValue={selectedMaterial?.clearCoatIntensity ?? 1}
              onAfterChange={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  clearCoatIntensity: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />
          </ChannelTextContainer>

          <ChannelTextContainer>
            <FovSlider
              label='Roughness'
              defaultValue={selectedMaterial?.clearCoatRoughness ?? 0}
              min={0}
              max={0.3}
              onAfterChange={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  clearCoatRoughness: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
            />
          </ChannelTextContainer>

            <ChannelTextContainer>
              <FovSlider
                label='IOR'
                defaultValue={selectedMaterial?.clearCoatIOR ?? 1.5}
                min={1}
                max={3}
                onAfterChange={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    clearCoatIOR: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
              />
            </ChannelTextContainer>

            <VerticalSpacer size={25} />
          </>
      );
    }
  };

  return (
    <div>
      <VerticalSpacer size={10} />

      <LabeledItemHolder>
        <TitleWithTooltipContainer>
          Clear-coat settings
        </TitleWithTooltipContainer>

        <FovToggle
          checked={hasClearCoat}
          callback={newVal => {
            const inboundData = {
              id: selectedMaterialId,
              clearCoatEnabled: newVal,
            };

            updateCanvas(updateArgs, inboundData);
          }}
        />
      </LabeledItemHolder>

      {buildClearCoatSection()}
    </div>
  );
};

export default ClearCoat;