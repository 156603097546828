import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import constants from 'constants/index';


const FacebookIcon = withTheme(props => {
  const { width, height, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const light = constants.THEME.LIGHT;
  const color = selectedTheme === light ? 'mediumGrey' : 'lightGrey';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 320 512"
    >
      <path fill={theme.colors[selectedTheme][color]} d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
    </svg>
  );
});

export { FacebookIcon };