import React from 'react';
import { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';

const MinusOutlined = withTheme(props => {
  const { width = 24, height = 24, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill={theme.colors[selectedTheme].onPrimary}
        d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
      ></path>
    </svg>
  );
});

export { MinusOutlined };
