import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const StoolSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const StoolOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <StoolSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      hoverColor={hoverColor}
      color={color}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width={width || 24}
      height={height || 24}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 13 17'
    >
      <path
        fill={theme.colors[selectedTheme].transparent}
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9 6H4M9 6C9.552 6 10 5.552 10 5V4H3V5C3 5.552 3.448 6 4 6M9 6L11 16M4 6L2 16M6.5 6V16M2.60001 13H10.4M10.5 4C11.3287 4 12 3.32867 12 2.5C12 1.67133 11.3287 1 10.5 1H2.5C1.67133 1 1 1.67133 1 2.5C1 3.32867 1.67133 4 2.5 4H10.5Z'
      />
    </StoolSVG>
  );
});

export { StoolOutlined };