import React from 'react';
import { useSelector } from 'react-redux';
import { getColor } from 'utils';
import styled, { withTheme } from 'styled-components';

const CheckSVG = styled('svg')`
  color: ${props => (
    props.color || getColor(props, 'onPrimary')
  )};
`;

const CheckCircleOutlined = withTheme(props => {
  const { theme, width, height, color, hoverColor } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <CheckSVG
      theme={theme}
      $selectedTheme={selectedTheme}
      color={color}
      hoverColor={hoverColor}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width={width || 24}
      height={height || 24}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path fill="currentColor" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
      <path fill="currentColor" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z" />
    </CheckSVG>
  );
});

export { CheckCircleOutlined };