import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ArrowLeftOutlined,
  HorizontalSpacer,
  VerticalSpacer,
  FovSlider,
  ImageContainer,
  DocSection,
} from 'components';
import {
  Image,
  Caption,
  InlineContainer,
  InlineElement,
  ContentContainer,
} from '../docStyles';
import { withTheme } from 'styled-components';
import { views as images } from './images';
import Summary from './Summary';

const ViewPreferences = withTheme(props => {
  const { theme } = props;
  const [ sliderSample, setSliderSample ] = useState(0.5);
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore?.userPreferences.theme;

  const summary = [
    'Sort models by newest/oldest or ascending/descending.',
    'Keep outstanding jobs at the front or back with pinning.',
    'Switch between cards or list view using view icons.',
    'Change model card sizes with the size slider.',
    'Preview models by clicking on model cards.',
  ];

  return (
    <ContentContainer>
      <Summary list={summary} />

      <DocSection section='Changing how content is viewed'>
        <ImageContainer>
          <Image src={images.myFilesContentMenu} width={400} alt='My Files content menu.' />

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.myFiles} width={500} alt='My Files.' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          Your content or can be found by clicking the <strong>&quot;Content&quot;</strong> menu and Selecting&nbsp;
          <strong>&quot;My Files&quot;</strong>.
        </Caption>

        <VerticalSpacer size={30} />

        <ImageContainer>
          <Image src={images.viewPreference} width={400} alt='View Preferences' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          The top-right section of your content page will include everything you need for how your models are
          displayed.
        </Caption>

        <VerticalSpacer size={30} />



        <DocSection subsection='Sorting'>
          <p>
            The sorting menu allows you to change what order your models
            should be shown. Selecting one of the options will automatically reorder
            your model cards or list.
          </p>

          <ImageContainer>
            <Image src={images.sorting} width={275} />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.sortingOpen} width={275} alt='Sorting Options' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            The current options are: Oldest first, Newest first, Ascending, and Descending. <br />
            Oldest and Newest are not available for Creator content.
          </Caption>
        </DocSection>



        <DocSection subsection='Pinning'>
          <ImageContainer>
            <Image src={images.pinning} width={250} alt='Pinning checkbox' />

            <HorizontalSpacer size={15} />
            <ArrowLeftOutlined flipIcon={true} />
            <HorizontalSpacer size={15} />

            <Image src={images.processingJobs} width={700} alt='Pinned jobs' />
          </ImageContainer>

          <Caption theme={theme} $selectedTheme={selectedTheme}>
            Selecting the pinning checkbox will prioritize <strong>Queued</strong>,&nbsp;
            <strong>Processing</strong> or <strong>Failed</strong> jobs to the top of the list.<br />
            Deselecting this option will keep these job types at the end of your models list.
          </Caption>
        </DocSection>
      </DocSection>



      <DocSection section='Card/list views'>
        You can display your content as cards or in a paginated list, depending on user preferences,
        by selecting one of the two view buttons. When in Card view, there is a slider that will
        allow for resizing the cards.

        <InlineContainer>
          <InlineElement width={125}>
            <FovSlider
              onChange={e => setSliderSample(e)}
              defaultValue={0.5}
              value={sliderSample}
            />
          </InlineElement>
        </InlineContainer>

        <VerticalSpacer size={30} />

        <ImageContainer noSpace={true}>
          <Image src={images.cardViewIcon} width={50} alt='Card view icon.'/>

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.cardView} width={500} alt='Card view.' />
        </ImageContainer>

        <ImageContainer>
          <Image src={images.listViewIcon} width={50} alt='List view icon.'/>

          <HorizontalSpacer size={15} />
          <ArrowLeftOutlined flipIcon={true} />
          <HorizontalSpacer size={15} />

          <Image src={images.listView} width={500} alt='List View' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          Selecting the Card icon will change the view to use cards.<br />
          Selecting the List icon will change the View to show in a paginated list.
        </Caption>
      </DocSection>



      <DocSection section='Model preview'>
        <p>
          When in card view, selecting a card will open a preview of that model on the right side of the screen.
        </p>

        <VerticalSpacer size={15} />

        <ImageContainer>
          <Image src={images.previewSection} width={500} alt='A preview of a model' />
        </ImageContainer>

        <Caption theme={theme} $selectedTheme={selectedTheme}>
          You are able to rotate, pan, and zoom in the preview window.
        </Caption>
      </DocSection>
    </ContentContainer>
  );
});

export { ViewPreferences };