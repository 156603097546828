import React from 'react';
import { FovDivider, H4 } from 'components';
import styled from 'styled-components';

const List = styled('ul')`
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
`;

const ListItem = styled('li')`
  min-height: 2.5rem;
`;

const LiContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  height: 100%;
`;

const Summary = props => {
  const { list } = props;

  const buildList = () => {
    const content = list?.map((item, index) => (
      <ListItem key={index}>
        <LiContainer>
          {item}
        </LiContainer>
      </ListItem>
    ));

    return content;
  };

  return (
    <div>
      <H4>Summary</H4>

      <List>
        {buildList()}
      </List>

      <FovDivider />
    </div>
  );
};

export default Summary;